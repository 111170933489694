import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import config from '../../config';
import css from './BookingBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

const { Money } = sdkTypes;

const vatCalculator = (vat, price) => {
  return (vat * price) / 100;
};

const getLineItemValue = (code, lineItems) => {
  return (
    lineItems.find((i) => i.code.includes(code)) &&
    lineItems.find((i) => i.code.includes(code)).lineTotal
  );
};

const LineItemExtraUnitsMaybe = (props) => {
  const {
    transaction,
    intl,
    selectedExtrasCheckoutInfo,
    selectedBedroomsFromForm,
    selectedExtraServicesFromForm,
    selectedExtrasFromForm,
    durationLabel,
  } = props;

  const transactionMetadata =
    (transaction && transaction.attributes && transaction.attributes.metadata) || {};
  const { bedrooms, extraServices, selectedExtras, duration } = transactionMetadata;
  const { lineItems } = transaction.attributes;
  const bedroomsInfo = !!selectedBedroomsFromForm ? selectedBedroomsFromForm : bedrooms;
  const extraServicesInfo = !!selectedExtraServicesFromForm
    ? selectedExtraServicesFromForm
    : extraServices;

  const transactionDurationLabel = duration
    ? intl.formatMessage({ id: `ConfigFilter.${duration}` })
    : null;
  const addExtraFormDurationLabel = durationLabel
    ? intl.formatMessage({ id: `ConfigFilter.${durationLabel}` })
    : null;

  const extraServicesTotalPrice = getLineItemValue('line-item/extra-services', lineItems);
  const bedroomTotalPrice = getLineItemValue('line-item/bedrooms', lineItems);
  const extraServicesVATTotalPrice = getLineItemValue('line-item/extras-VAT', lineItems);
  const bedroomVATTotalPrice = getLineItemValue('line-item/bedrooms-VAT', lineItems);

  const selectedExtrasInfo = !!selectedExtrasFromForm
    ? JSON.stringify(selectedExtrasFromForm)
    : selectedExtras;

  const selectedExtrasObj = !!selectedExtrasCheckoutInfo
    ? selectedExtrasCheckoutInfo
    : selectedExtrasInfo;

  const selectedExtrasQuantity =
    selectedExtrasObj &&
    Object.entries(JSON.parse(selectedExtrasObj)).map((i) => {
      return { id: i[0], quantity: i[1].quantity };
    });

  return (
    <>
      {!!extraServicesInfo && !!extraServicesInfo.length && (
        <div className={classNames(css.lineItem, css.lineItemExtra)}>
          <div className={css.lineItemExtraItem}>
            <h3 className={css.lineItemExtraTitle}>
              <FormattedMessage id="BookingBreakdown.extraServicesLabel" />
            </h3>
          </div>
          <div className={css.lineItemExtraHolder}>
            {extraServicesInfo.map((e) => {
              const itemQuantity =
                selectedExtrasQuantity && selectedExtrasQuantity.find((i) => i.id === e.id);

              const extraServicesPrice =
                itemQuantity && itemQuantity.quantity
                  ? new Money(e.extrasPriceAmount * itemQuantity.quantity, config.currency)
                  : new Money(e.extrasPriceAmount, config.currency);

              const extraServicesVATPrice =
                itemQuantity && itemQuantity.quantity
                  ? new Money(
                      vatCalculator(e.extrasVat, e.extrasPriceAmount) * itemQuantity.quantity,
                      config.currency
                    )
                  : new Money(vatCalculator(e.extrasVat, e.extrasPriceAmount), config.currency);

              return (
                <React.Fragment key={e.id}>
                  <div className={css.lineItemExtraItem}>
                    <span className={css.itemLabel}>
                      {e.extrasName}
                      {itemQuantity && itemQuantity.quantity && ` ( x ${itemQuantity.quantity})`}
                    </span>
                    <span className={css.itemValue}>{formatMoney(intl, extraServicesPrice)}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}

      {!!bedroomsInfo && !!bedroomsInfo.length && (
        <div className={classNames(css.lineItem, css.lineItemExtra)}>
          <div className={css.lineItemExtraItem}>
            <h3 className={css.lineItemExtraTitle}>
              <FormattedMessage id="BookingBreakdown.bedroomsLabel" />
            </h3>
          </div>

          <div className={css.lineItemExtraHolder}>
            {bedroomsInfo.map((b) => {
              const itemQuantity =
                selectedExtrasQuantity && selectedExtrasQuantity.find((i) => i.id === b.id);

              const bedroomsPrice =
                itemQuantity && itemQuantity.quantity
                  ? new Money(b.bedroomPriceAmount * itemQuantity.quantity, config.currency)
                  : new Money(b.bedroomPriceAmount, config.currency);

              const bedroomsVATPrice =
                itemQuantity && itemQuantity.quantity
                  ? new Money(
                      vatCalculator(b.bedroomVat, b.bedroomPriceAmount) * itemQuantity.quantity,
                      config.currency
                    )
                  : new Money(vatCalculator(b.bedroomVat, b.bedroomPriceAmount), config.currency);

              return (
                <React.Fragment key={b.id}>
                  <div className={css.lineItemExtraItem}>
                    <span className={css.itemLabel}>
                      {b.bedroomName}
                      {itemQuantity && itemQuantity.quantity && ` ( x ${itemQuantity.quantity})`}
                    </span>
                    <span className={css.itemValue}>{formatMoney(intl, bedroomsPrice)}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

LineItemExtraUnitsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
};

export default LineItemExtraUnitsMaybe;
