import React from 'react';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { FieldBoolean } from '..';
import labelRseLogo from '../../assets/logo-label-rse.png';

import css from './MeetSafeLabel.module.css';

const MeetSafeLabel = props => {
  const { name = 'rse', intl, rootClassName } = props;

  const label = intl.formatMessage({ id: 'MeetSafeLabel.label' });
  const tooltip = intl.formatMessage({ id: 'MeetSafeLabel.tooltip' });
  const link = intl.formatMessage({ id: 'MeetSafeLabel.link' });
  const placeholder = intl.formatMessage({ id: 'MeetSafeLabel.placeholder' });

  const classes = classNames(css.root, rootClassName);

  return (
    <div className={classes}>
      <p className={css.labelText}>
        <FormattedMessage id="MeetSafeLabel.confirmText" />
      </p>
      <div className={css.wrapper}>
        <div className={css.left}>
          <div className={css.icon}>
            <img src={labelRseLogo} alt="covidLogo" className={css.logo}/>
          </div>
        </div>

        <div className={css.right}>
          <div className={css.input}>
            <FieldBoolean id={name} name={name} label={label} placeholder={placeholder} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MeetSafeLabel);
