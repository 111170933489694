import React, { useState, useEffect } from 'react';
import { bool, func, object, shape } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isProviderRole, propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Footer,
  NamedRedirect,
  NamedLink,
  Page,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperServiceSideNav,
  ManageListingCard,
  PaginationLinks,
  Modal,
  Button,
} from '../../components';
import { TopbarContainer } from '..';
import { parse } from '../../util/urlHelpers';
import {
  queryOnlyParentListings,
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
  createDuplicateListing,
  removeListing,
} from './ServicesPage.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import css from './ServicesPage.module.css';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 42;

export const ServicesPageComponent = props => {
  const {
    currentUser,
    history,
    intl,
    page,
    scrollingDisabled,
    listings,
    queryParams,
    queryInProgress,
    pagination,
    onCloseListing,
    onOpenListing,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    onCreateDuplicateListing,
    createDuplicateProgress,
    onRemoveListing,
    removeListingLoading,
    removeListingRequestError,
    onManageDisableScrolling,
    removeListingResult,
  } = props;

  const [listingMenuOpen, setListingMenuOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeParams, setRemoveParams] = useState(null);

  useEffect(() => {
    if (!removeListingLoading && removeListingResult) {
      setIsModalOpen(false);
      setRemoveParams(null);
    }
  }, [removeListingLoading, removeListingResult]);

  const isProvider = isProviderRole(currentUser);

  if (currentUser && !isProvider) {
    return <NamedRedirect name="LandingPage" />;
  }

  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const listingsAreLoaded = !queryInProgress && hasPaginationInfo;
  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;

  const renderEmpty = () => (
    <>
      <h2 className={css.emptyTitle}>
        <FormattedMessage id="ServicesPage.emptyTitle" />
      </h2>

      <NamedLink name="CreateServicesPage" className={css.emptyButton}>
        <FormattedMessage id="ServicesPage.emptyButton" />
      </NamedLink>
    </>
  );

  const _page = queryParams ? queryParams.page : 1;
  const paginationLinks =
    listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ServicesPage"
        pageSearchParams={{ page: _page }}
        pagination={pagination}
      />
    ) : null;

  const onToggleMenu = listing => {
    setListingMenuOpen(listing);
  };

  const onDeleteListing = params => {
    setIsModalOpen(true);
    setRemoveParams(params);
  };

  const removeListing = () => {
    onRemoveListing(removeParams);
  };

  const listingsNames =
    listings && listings.length > 0 ? listings.map(i => i.attributes.title) : [];

  const modal = onManageDisableScrolling ? (
    <Modal
      id="ServicesPageModal"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div>
        <div className={css.modalTitle}>
          <FormattedMessage id="ServicesPage.modalTitle" />
        </div>
        <div className={css.modalButtonsWrapper}>
          <div
            onClick={() => setIsModalOpen(false)}
            className={css.cancelListingDelete}
            tabIndex="0"
          >
            <FormattedMessage id="ServicesPage.cancel" />
          </div>
          <Button onClick={removeListing} inProgress={removeListingLoading}>
            <FormattedMessage id="ServicesPage.removeListing" />
          </Button>
        </div>
      </div>
    </Modal>
  ) : null;

  const renderListings = () => {
    return (
      <div className={css.listingPanel}>
        <div className={css.listingCards}>
          {listings.map(l => (
            <ManageListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
              actionsInProgressListingId={openingListing || closingListing}
              onToggleMenu={onToggleMenu}
              onCloseListing={onCloseListing}
              onOpenListing={onOpenListing}
              hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
              hasClosingError={closingErrorListingId.uuid === l.id.uuid}
              onCreateDuplicateListing={onCreateDuplicateListing}
              createDuplicateProgress={createDuplicateProgress}
              listingsNames={listingsNames}
              queryParams={queryParams}
              onRemoveListing={onRemoveListing}
              onDeleteListing={onDeleteListing}
              removeListingLoading={removeListingLoading}
              // renderSizes={renderSizes}
            />
          ))}
        </div>
        {/* {paginationLinks} */}
      </div>
    );
  };

  const hasListings = listings && listings.length;

  const content = !queryInProgress ? (hasListings ? renderListings() : renderEmpty()) : null;

  const title = intl.formatMessage({ id: 'ServicesPage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ServicesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperServiceSideNav currentTab="ServicesPage" />
        <LayoutWrapperMain className={css.mainContent}>
          <div className={css.content}>
            {content}
            {paginationLinks}
            {modal}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ServicesPageComponent.defaultProps = {
  currentUser: null,
  onManageDisableScrolling: null,
};

ServicesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  page: object.isRequired,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.ServicesPage;
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    removeListingLoading,
    removeListingResult,
    removeListingRequestError,
    createDuplicateProgress,
  } = state.ServicesPage;

  const allListings = getListingsById(state, currentPageResultIds);
  const listings = allListings.filter(i => !i.attributes.title.toLowerCase().includes('(delete)'));

  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    currentUser,
    page,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    removeListingLoading,
    removeListingResult,
    removeListingRequestError,
    createDuplicateProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onCreateDuplicateListing: (params, queryParams, id) =>
    dispatch(createDuplicateListing(params, queryParams, id)),
  onRemoveListing: (id, params, queryParams) => dispatch(removeListing(id, params, queryParams)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ServicesPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(ServicesPageComponent));

ServicesPage.loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryOnlyParentListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default ServicesPage;
