import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import * as log from '../../util/log';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { getListings } from '../../util/api';
import { removeListing } from '../ServicesPage/ServicesPage.duck';

const { UUID } = sdkTypes;

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditServicesWizard/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/EditServicesWizard/CLEAR_UPDATED_TAB';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_ERROR';

export const PUBLISH_LISTING_REQUEST = 'app/EditServicesWizard/PUBLISH_LISTING_REQUEST';
export const PUBLISH_LISTING_SUCCESS = 'app/EditServicesWizard/PUBLISH_LISTING_SUCCESS';
export const PUBLISH_LISTING_ERROR = 'app/EditServicesWizard/PUBLISH_LISTING_ERROR';

export const UPDATE_LISTING_REQUEST = 'app/EditServicesWizard/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditServicesWizard/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditServicesWizard/UPDATE_LISTING_ERROR';

export const SHOW_LISTINGS_REQUEST = 'app/EditServicesWizard/SHOW_LISTINGS_REQUEST';
export const SHOW_LISTINGS_SUCCESS = 'app/EditServicesWizard/SHOW_LISTINGS_SUCCESS';
export const SHOW_LISTINGS_ERROR = 'app/EditServicesWizard/SHOW_LISTINGS_ERROR';

export const SAVE_PARENT_LISTING = 'app/EditServicesWizard/SAVE_PARENT_LISTING';

export const UPLOAD_IMAGE_REQUEST = 'app/EditServicesWizard/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/EditServicesWizard/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/EditServicesWizard/UPLOAD_IMAGE_ERROR';

export const UPDATE_IMAGE_ORDER = 'app/EditServicesWizard/UPDATE_IMAGE_ORDER';

export const REMOVE_LISTING_IMAGE = 'app/EditServicesWizard/REMOVE_LISTING_IMAGE';

export const FETCH_EXCEPTIONS_REQUEST =
  'app/EditServicesWizard/FETCH_AVAILABILITY_EXCEPTIONS_REQUEST';
export const FETCH_EXCEPTIONS_SUCCESS =
  'app/EditServicesWizard/FETCH_AVAILABILITY_EXCEPTIONS_SUCCESS';
export const FETCH_EXCEPTIONS_ERROR = 'app/EditServicesWizard/FETCH_AVAILABILITY_EXCEPTIONS_ERROR';

export const ADD_EXCEPTION_REQUEST = 'app/EditServicesWizard/ADD_AVAILABILITY_EXCEPTION_REQUEST';
export const ADD_EXCEPTION_SUCCESS = 'app/EditServicesWizard/ADD_AVAILABILITY_EXCEPTION_SUCCESS';
export const ADD_EXCEPTION_ERROR = 'app/EditServicesWizard/ADD_AVAILABILITY_EXCEPTION_ERROR';

export const DELETE_EXCEPTION_REQUEST =
  'app/EditServicesWizard/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS =
  'app/EditServicesWizard/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/EditServicesWizard/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/EditServicesWizard/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/EditServicesWizard/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/EditServicesWizard/SAVE_PAYOUT_DETAILS_ERROR';

export const CHILD_LISTINGS_REQUEST = 'app/EditServicesWizard/CHILD_LISTINGS_REQUEST';
export const CHILD_LISTINGS_SUCCESS = 'app/EditServicesWizard/CHILD_LISTINGS_SUCCESS';
export const CHILD_LISTINGS_ERROR = 'app/EditServicesWizard/CHILD_LISTINGS_ERROR';

export const CREATE_DUPLICATE_LISTING_REQUEST =
  'app/EditServicesWizard/CREATE_DUPLICATE_LISTING_REQUEST';
export const CREATE_DUPLICATE_LISTING_SUCCESS =
  'app/EditServicesWizard/CREATE_DUPLICATE_LISTING_SUCCESS';
export const CREATE_DUPLICATE_LISTING_ERROR =
  'app/EditServicesWizard/CREATE_DUPLICATE_LISTING_ERROR';

export const REMOVE_LISTING_REQUEST = 'app/EditServicesWizard/REMOVE_LISTING_REQUEST';
export const REMOVE_LISTING_SUCCESS = 'app/EditServicesWizard/REMOVE_LISTING_SUCCESS';
export const REMOVE_LISTING_ERROR = 'app/EditServicesWizard/REMOVE_LISTING_ERROR';

export const UPDATE_CHILD_LISTINS_REQUEST = 'app/EditServicesWizard/UPDATE_CHILD_LISTINS_REQUEST';
export const UPDATE_CHILD_LISTINS_SUCCESS = 'app/EditServicesWizard/UPDATE_CHILD_LISTINS_SUCCESS';
export const UPDATE_CHILD_LISTINS_ERROR = 'app/EditServicesWizard/UPDATE_CHILD_LISTINS_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Error instance placeholders for each endpoint
  createListingDraftError: null,
  publishingListing: null,
  publishListingError: null,
  updateListingError: null,
  showListingsError: null,
  uploadImageError: null,
  createListingDraftInProgress: false,
  submittedListingId: null,
  redirectToListing: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  fetchExceptionsError: null,
  fetchExceptionsInProgress: false,
  availabilityExceptions: [],
  addExceptionError: null,
  addExceptionInProgress: false,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  listingDraft: null,
  updatedTab: null,
  updateInProgress: false,
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  childListingsIds: [],
  childListingsProgress: false,
  childListingsError: null,
  parentListing: null,
  createDuplicateProgress: false,
  createDuplicateSuccess: false,
  createDuplicateError: null,
  removeListingLoading: false,
  removeListingResult: false,
  removeListingRequestError: null,
  updateChildListingLoading: false,
  updateChildListingError: false,
  updateChildListingResult: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateListingError: null };

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case PUBLISH_LISTING_REQUEST:
      return {
        ...state,
        publishingListing: payload.listingId,
        publishListingError: null,
      };
    case PUBLISH_LISTING_SUCCESS:
      return {
        ...state,
        redirectToListing: true,
        publishingListing: null,
        createListingDraftError: null,
        updateListingError: null,
        showListingsError: null,
        uploadImageError: null,
        createListingDraftInProgress: false,
        updateInProgress: false,
      };
    case PUBLISH_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        publishingListing: null,
        publishListingError: {
          listingId: state.publishingListing,
          error: payload,
        },
      };
    }

    case UPDATE_LISTING_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_LISTING_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_LISTING_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    case SHOW_LISTINGS_REQUEST:
      return { ...state, showListingsError: null };
    case SHOW_LISTINGS_SUCCESS:
      const newInitialState = Object.assign({}, initialState);
      delete newInitialState.childListingsIds;
      return { ...state, ...newInitialState };
    // return {...initialState}

    case SHOW_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, showListingsError: payload, redirectToListing: false };

    case UPLOAD_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadImageError: null,
      };
    }
    case UPLOAD_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return { ...state, images };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return { ...state, imageOrder, images, uploadImageError: error };
    }
    case UPDATE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    case FETCH_EXCEPTIONS_REQUEST:
      return {
        ...state,
        availabilityExceptions: [],
        fetchExceptionsError: null,
        fetchExceptionsInProgress: true,
      };
    case FETCH_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        availabilityExceptions: payload,
        fetchExceptionsError: null,
        fetchExceptionsInProgress: false,
      };
    case FETCH_EXCEPTIONS_ERROR:
      return {
        ...state,
        fetchExceptionsError: payload.error,
        fetchExceptionsInProgress: false,
      };

    case ADD_EXCEPTION_REQUEST:
      return {
        ...state,
        addExceptionError: null,
        addExceptionInProgress: true,
      };
    case ADD_EXCEPTION_SUCCESS:
      return {
        ...state,
        availabilityExceptions: [...state.availabilityExceptions, payload],
        addExceptionInProgress: false,
      };
    case ADD_EXCEPTION_ERROR:
      return {
        ...state,
        addExceptionError: payload.error,
        addExceptionInProgress: false,
      };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionError: null,
        deleteExceptionInProgress: true,
      };
    case DELETE_EXCEPTION_SUCCESS: {
      const deletedExceptionId = payload.id;
      const availabilityExceptions = state.availabilityExceptions.filter(
        e => e.id.uuid !== deletedExceptionId.uuid
      );
      return {
        ...state,
        availabilityExceptions,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionError: payload.error,
        deleteExceptionInProgress: false,
      };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    case CHILD_LISTINGS_REQUEST:
      return { ...state, childListingsProgress: true, childListingsError: null };
    case CHILD_LISTINGS_SUCCESS:
      return { ...state, childListingsIds: resultIds(payload.data), childListingsProgress: false };
    case CHILD_LISTINGS_ERROR:
      return { ...state, childListingsProgress: false, childListingsError: payload.error };
    case SAVE_PARENT_LISTING:
      return { ...state, parentListing: action.payload };

    case CREATE_DUPLICATE_LISTING_REQUEST:
      return {
        ...state,
        createDuplicateProgress: true,
        createDuplicateError: null,
        createDuplicateSuccess: false,
      };
    case CREATE_DUPLICATE_LISTING_SUCCESS:
      return { ...state, createDuplicateSuccess: true, createDuplicateProgress: false };
    case CREATE_DUPLICATE_LISTING_ERROR:
      return { ...state, createDuplicateProgress: false, createDuplicateError: payload.error };

    case REMOVE_LISTING_REQUEST:
      return { ...state, removeListingLoading: true, removeListingRequestError: false };
    case REMOVE_LISTING_SUCCESS:
      return {
        ...state,
        removeListingLoading: false,
        removeListingRequestError: false,
        removeListingResult: true,
      };
    case REMOVE_LISTING_ERROR:
      return { ...state, removeListingLoading: false, removeListingRequestError: payload.error };

    case UPDATE_CHILD_LISTINS_REQUEST:
      return { ...state, updateChildListingLoading: true, updateChildListingError: false };
    case UPDATE_CHILD_LISTINS_SUCCESS:
      return {
        ...state,
        updateChildListingLoading: false,
        updateChildListingError: false,
        updateChildListingResult: true,
      };
    case UPDATE_CHILD_LISTINS_ERROR:
      return {
        ...state,
        updateChildListingLoading: false,
        updateChildListingError: payload.error,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const updateImageOrder = imageOrder => ({
  type: UPDATE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeListingImage = imageId => ({
  type: REMOVE_LISTING_IMAGE,
  payload: { imageId },
});

export const childListingsRequest = searchParams => ({
  type: CHILD_LISTINGS_REQUEST,
});

export const childListingsSuccess = response => ({
  type: CHILD_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const childListingsError = e => ({
  type: CHILD_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const saveParentListing = listing => ({
  type: SAVE_PARENT_LISTING,
  payload: listing,
});

export const createDuplicateListingRequest = () => ({
  type: CREATE_DUPLICATE_LISTING_REQUEST,
});
export const createDuplicateListingSuccess = () => ({
  type: CREATE_DUPLICATE_LISTING_SUCCESS,
});
export const createDuplicateListingError = e => ({
  type: CREATE_DUPLICATE_LISTING_ERROR,
  payload: e,
});

export const removeListingRequest = () => ({
  type: REMOVE_LISTING_REQUEST,
});
export const removeListingSuccess = () => ({
  type: REMOVE_LISTING_SUCCESS,
});
export const removeListingError = e => ({
  type: REMOVE_LISTING_ERROR,
  payload: e,
});

export const updateChildListingsRequest = () => ({
  type: UPDATE_CHILD_LISTINS_REQUEST,
});
export const updateChildListingsSuccess = () => ({
  type: UPDATE_CHILD_LISTINS_SUCCESS,
});
export const updateChildListingsError = e => ({
  type: UPDATE_CHILD_LISTINS_ERROR,
  payload: e,
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// SDK method: ownListings.publish
export const publishListing = requestAction(PUBLISH_LISTING_REQUEST);
export const publishListingSuccess = successAction(PUBLISH_LISTING_SUCCESS);
export const publishListingError = errorAction(PUBLISH_LISTING_ERROR);

// SDK method: ownListings.update
export const updateListing = requestAction(UPDATE_LISTING_REQUEST);
export const updateListingSuccess = successAction(UPDATE_LISTING_SUCCESS);
export const updateListingError = errorAction(UPDATE_LISTING_ERROR);

// SDK method: ownListings.show
export const showListings = requestAction(SHOW_LISTINGS_REQUEST);
export const showListingsSuccess = successAction(SHOW_LISTINGS_SUCCESS);
export const showListingsError = errorAction(SHOW_LISTINGS_ERROR);

// SDK method: images.upload
export const uploadImage = requestAction(UPLOAD_IMAGE_REQUEST);
export const uploadImageSuccess = successAction(UPLOAD_IMAGE_SUCCESS);
export const uploadImageError = errorAction(UPLOAD_IMAGE_ERROR);

// SDK method: availabilityExceptions.query
export const fetchAvailabilityExceptionsRequest = requestAction(FETCH_EXCEPTIONS_REQUEST);
export const fetchAvailabilityExceptionsSuccess = successAction(FETCH_EXCEPTIONS_SUCCESS);
export const fetchAvailabilityExceptionsError = errorAction(FETCH_EXCEPTIONS_ERROR);

// SDK method: availabilityExceptions.create
export const addAvailabilityExceptionRequest = requestAction(ADD_EXCEPTION_REQUEST);
export const addAvailabilityExceptionSuccess = successAction(ADD_EXCEPTION_SUCCESS);
export const addAvailabilityExceptionError = errorAction(ADD_EXCEPTION_ERROR);

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);

export const savePayoutDetailsRequest = requestAction(SAVE_PAYOUT_DETAILS_REQUEST);
export const savePayoutDetailsSuccess = successAction(SAVE_PAYOUT_DETAILS_SUCCESS);
export const savePayoutDetailsError = errorAction(SAVE_PAYOUT_DETAILS_ERROR);

// ================ Thunk ================ //

export function requestShowListing(actionPayload) {
  return (dispatch, getState, sdk) => {
    dispatch(showListings(actionPayload));
    return sdk.ownListings
      .show(actionPayload)
      .then(response => {
        const parentListingId = response.data.data.attributes.publicData.parent;
        if (parentListingId) {
          return sdk.ownListings.show({ id: parentListingId }).then(parentListingResponse => {
            const parentListing = parentListingResponse.data.data;
            // EditListingPage fetches new listing data, which also needs to be added to global data
            dispatch(addMarketplaceEntities(response));
            // In case of success, we'll clear state.EditListingPage (user will be redirected away)
            dispatch(showListingsSuccess(response));
            dispatch(saveParentListing(parentListing));
            return response;
          });
        } else {
          // EditListingPage fetches new listing data, which also needs to be added to global data
          dispatch(addMarketplaceEntities(response));
          // In case of success, we'll clear state.EditListingPage (user will be redirected away)
          dispatch(showListingsSuccess(response));
          return response;
        }
      })
      .catch(e => dispatch(showListingsError(storableError(e))));
  };
}

export function requestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestPublishListingDraft = listingId => (dispatch, getState, sdk) => {
  dispatch(publishListing(listingId));

  return sdk.ownListings
    .publishDraft({ id: listingId }, { expand: true })
    .then(response => {
      // Add the created listing to the marketplace data
      dispatch(addMarketplaceEntities(response));
      dispatch(publishListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(publishListingError(storableError(e)));
    });
};

// Images return imageId which we need to map with previously generated temporary id
export function requestImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImage(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file })
      .then(resp => dispatch(uploadImageSuccess({ data: { id, imageId: resp.data.data.id } })))
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateListing(tab, data, withoutTab) {
  return (dispatch, getState, sdk) => {
    dispatch(updateListing(data));
    const { id } = data;
    let updateResponse;
    return sdk.ownListings
      .update(data)
      .then(response => {
        updateResponse = response;
        const payload = {
          id,
          include: ['author', 'images'],
          'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        };
        return dispatch(requestShowListing(payload));
      })
      .then(() => {
        if (!withoutTab) {
          dispatch(markTabUpdated(tab));
        }
        dispatch(updateListingSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-listing-failed', { listingData: data });
        dispatch(updateListingError(storableError(e)));
        throw e;
      });
  };
}

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  type: 'availability-plan/time',
  timezone: values.timezone,
  entries: createEntriesFromSubmitValues(values),
});

const updateOldPublicData = (fieldName, oldPublicData, newPublicData, dataFromParent) => {
  const newValue = newPublicData[fieldName];

  if (fieldName === 'listingsMinPrice') {
    for (let key in newValue) {
      if (oldPublicData[fieldName]) {
        if (oldPublicData[fieldName][key]) {
          oldPublicData[fieldName][key] = newValue[key];
        } else {
          oldPublicData[fieldName] = Object.assign(
            {
              [key]: newValue[key],
            },
            dataFromParent.publicData[fieldName]
          );
        }
      } else {
        oldPublicData[fieldName] = { [key]: newValue[key] };
      }
    }
  } else {
    for (let key in newValue) {
      if (oldPublicData[fieldName]) {
        oldPublicData[fieldName][key] = newValue[key];
      } else {
        oldPublicData[fieldName] = {
          [key]: newValue[key],
        };
      }
    }
  }
};

export const updateParentListing = params => (dispatch, getState, sdk) => {
  return dispatch(requestShowListing({ id: params.id }))
    .then(async res => {
      const dataFromParent = Object.assign({}, res.data.data.attributes);
      const oldPublicData = dataFromParent.publicData;
      const newPublicData = params.publicData;

      const isListingsMinPrice = 'listingsMinPrice' in newPublicData;
      const isListingsSafeSpacing = 'listingsSafeSpacing' in newPublicData;
      const isListingsStyles = 'listingsStyles' in newPublicData;
      const isMaxAttendees = 'listingsMaxAttendees' in newPublicData;
      const isAmenities = 'listingsVirtualServices' in newPublicData;
      const isListingsTypes = 'listingsTypes' in newPublicData;
      const isAvailabilityPlan = 'availabilityPlan' in newPublicData;
      const timezone = newPublicData.timezone;

      const data = {
        id: params.id,
        publicData: {},
      };

      if (isListingsMinPrice) {
        updateOldPublicData('listingsMinPrice', oldPublicData, newPublicData, dataFromParent);

        const listingsMinPrice = oldPublicData.listingsMinPrice;
        const minPicesArr = listingsMinPrice && Object.values(listingsMinPrice);
        const minPriceFromArr = minPicesArr && Math.min(...minPicesArr);

        if (minPriceFromArr) {
          data.publicData.minPrice = minPriceFromArr / 100;
          data.publicData.listingsMinPrice = listingsMinPrice;
        }
      }

      if (isListingsSafeSpacing) {
        updateOldPublicData('listingsSafeSpacing', oldPublicData, newPublicData);

        const listingsSafeSpacing = oldPublicData.listingsSafeSpacing;
        const safeSpacingArr = listingsSafeSpacing && Object.values(listingsSafeSpacing);
        const safeSpacingFromArr = safeSpacingArr && safeSpacingArr.includes(true);

        data.publicData.safeSpacing = safeSpacingFromArr;
        data.publicData.listingsSafeSpacing = listingsSafeSpacing;
      }

      if (isListingsStyles) {
        updateOldPublicData('listingsStyles', oldPublicData, newPublicData);

        const listingsStyles = oldPublicData.listingsStyles;
        const listingsStylesArr = listingsStyles && Object.values(listingsStyles);
        const listingsStylesFromArr = [];
        listingsStylesArr.map(i => {
          i.map(j => {
            if (!listingsStylesFromArr.includes(j)) {
              listingsStylesFromArr.push(j);
            }
          });
        });

        data.publicData.listingsStyles = listingsStyles;
        data.publicData.roomStyle = listingsStylesFromArr;
      }

      if (isMaxAttendees) {
        updateOldPublicData('listingsMaxAttendees', oldPublicData, newPublicData);

        const listingsMaxAttendees = oldPublicData.listingsMaxAttendees;
        const listingsMaxAttendeesArr = listingsMaxAttendees && Object.values(listingsMaxAttendees);
        const maxListingsMaxAttendeesArr =
          listingsMaxAttendeesArr && Math.max(...listingsMaxAttendeesArr);

        data.publicData.maxAttendees = maxListingsMaxAttendeesArr;
        data.publicData.listingsMaxAttendees = listingsMaxAttendees;
      }

      if (isAmenities) {
        updateOldPublicData('listingsVirtualServices', oldPublicData, newPublicData);

        const listingsVirtualServices = oldPublicData.listingsVirtualServices;
        const listingsVirtualServicesArr =
          listingsVirtualServices && Object.values(listingsVirtualServices);
        const listingsVirtualServicesFromArr = [];
        listingsVirtualServicesArr.map(i => {
          i.map(j => {
            if (!listingsVirtualServicesFromArr.includes(j)) {
              listingsVirtualServicesFromArr.push(j);
            }
          });
        });

        data.publicData.listingsVirtualServices = listingsVirtualServices;
        data.publicData.virtualServices = listingsVirtualServicesFromArr;
      }

      if (isListingsTypes) {
        updateOldPublicData('listingsTypes', oldPublicData, newPublicData);

        let totalPages = 1;
        let numberListings = 0;
        const res = await sdk.listings
          .query({ pub_parent: params.id, page: totalPages, states: 'published' })
          .then(res => {
            numberListings = res.data.data.length;
            return res.data;
          });

        if (res.meta.totalPages > 1) totalPages = res.meta.totalPages;
        if (totalPages > 1) {
          for (let index = 2; index <= totalPages; index++) {
            await sdk.listings
              .query({ pub_parent: params.id, page: index, states: 'published' })
              .then(res => {
                numberListings = numberListings + res.data.data.length;
              });
          }
        }

        const listingsTypes = oldPublicData.listingsTypes;
        const listingsTypesArr = listingsTypes && Object.values(listingsTypes);
        const listingsTypesNumber = listingsTypesArr.length;

        data.publicData.numberRooms =
          numberListings > listingsTypesNumber ? numberListings : listingsTypesNumber;
        data.publicData.listingsTypes = listingsTypes;
      }

      if (isAvailabilityPlan) {
        const newAvailabilityPlan = newPublicData.availabilityPlan;
        for (let key in newAvailabilityPlan) {
          if (oldPublicData.availabilityPlan) {
            oldPublicData.availabilityPlan[key] = newAvailabilityPlan[key];
          } else {
            oldPublicData.availabilityPlan = {
              [key]: newAvailabilityPlan[key],
            };
          }
        }

        const availabilityPlan = oldPublicData.availabilityPlan;
        let finalAvailability = {};

        if (
          !availabilityPlan ||
          (Object.keys(availabilityPlan).length === 1 &&
            availabilityPlan.hasOwnProperty(Object.keys(newAvailabilityPlan)[0]))
        ) {
          newAvailabilityPlan[Object.keys(newAvailabilityPlan)].map(item => {
            if (!finalAvailability[item.dayOfWeek]) {
              finalAvailability[item.dayOfWeek] = [item];
            } else {
              finalAvailability[item.dayOfWeek].push(item);
            }
          });
        } else {
          const availabilityPlanArr = availabilityPlan && Object.values(availabilityPlan);
          const availabilityPlanFromArr = [];

          availabilityPlanArr.map(i => {
            i.map(j => {
              if (!availabilityPlanFromArr.includes(j)) {
                availabilityPlanFromArr.push(j);
              }
            });
          });

          const refactorAvailability = {};
          const availability = Object.values(availabilityPlan);

          availability.map(childListing => {
            childListing.map(day => {
              if (!refactorAvailability[day.dayOfWeek]) {
                refactorAvailability[day.dayOfWeek] = [day];
              } else {
                refactorAvailability[day.dayOfWeek].push(day);
              }
            });
          });

          const refactorAvailabilityArr = Object.values(refactorAvailability);

          const res = refactorAvailabilityArr.map(day => {
            let hours = [];
            const dayShortName = day[0].dayOfWeek;

            for (let i = 0; i < 24; i++) hours.push(false);

            day.map(item => {
              hours = hours.map((value, index) => {
                return !!(
                  (index >= parseInt(item.startTime.split(':')[0]) &&
                    index < parseInt(item.endTime.split(':')[0])) ||
                  value
                );
              });
            });

            let start = null,
              end = null;

            hours.map((val, index) => {
              if (!start && val) {
                start = index;
              } else if (start && !val) {
                end = index;

                if (!finalAvailability[dayShortName]) {
                  finalAvailability[dayShortName] = [
                    {
                      dayOfWeek: dayShortName,
                      endTime: end > 9 ? `${end}:00` : `0${end}:00`,
                      seats: 1,
                      startTime: start > 9 ? `${start}:00` : `0${start}:00`,
                    },
                  ];
                } else {
                  finalAvailability[dayShortName].push({
                    dayOfWeek: dayShortName,
                    endTime: end > 9 ? `${end}:00` : `0${end}:00`,
                    seats: 1,
                    startTime: start > 9 ? `${start}:00` : `0${start}:00`,
                  });
                }

                start = end = null;
              }
            });
          });
        }
        data.availabilityPlan = createAvailabilityPlan({ timezone, ...finalAvailability });
        data.publicData.availabilityPlan = availabilityPlan;
      }

      if (
        isListingsMinPrice ||
        isListingsSafeSpacing ||
        isListingsStyles ||
        isMaxAttendees ||
        isAmenities ||
        isListingsTypes ||
        isAvailabilityPlan
      ) {
        return sdk.ownListings.update(data, {
          expand: true,
          include: ['author'],
        });
      } else {
        return res;
      }
    })
    .catch(e => {
      log.error(e, 'update-listing-failed', { listingData: params });
      throw e;
    });
};

export const requestAddAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(addAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .create(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(addAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(addAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  dispatch(fetchAvailabilityExceptionsRequest(fetchParams));

  return sdk.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response);
      dispatch(fetchAvailabilityExceptionsSuccess({ data: availabilityExceptions }));
      return availabilityExceptions;
    })
    .catch(e => {
      return dispatch(fetchAvailabilityExceptionsError({ error: storableError(e) }));
    });
};

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = params => (dispatch, getState, sdk) => {
  dispatch(clearUpdatedTab());
  const { id, type } = params;

  if (type === 'new') {
    // No need to listing data when creating a new listing
    return Promise.all([dispatch(fetchCurrentUser())])
      .then(response => {
        const currentUser = getState().user.currentUser;
        if (currentUser && currentUser.stripeAccount) {
          dispatch(fetchStripeAccount());
        }
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const payload = {
    id: new UUID(id),
    include: ['author', 'images'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
  };

  return Promise.all([dispatch(requestShowListing(payload)), dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
      }

      // Because of two dispatch functions, response is an array.
      // We are only interested in the response from requestShowListing here,
      // so we need to pick the first one
      if (response[0].data && response[0].data.data) {
        const listing = response[0].data.data;

        // If the listing doesn't have availabilityPlan yet
        // use the defaul timezone
        const availabilityPlan = listing.attributes.availabilityPlan;

        const tz = availabilityPlan
          ? listing.attributes.availabilityPlan.timezone
          : typeof window !== 'undefined'
          ? getDefaultTimeZoneOnBrowser()
          : 'Etc/UTC';

        const today = new Date();
        const start = resetToStartOfDay(today, tz, 0);
        // Query range: today + 364 days
        const exceptionRange = 364;
        const end = resetToStartOfDay(today, tz, exceptionRange);

        // NOTE: in this template, we don't expect more than 100 exceptions.
        // If there are more exceptions, pagination kicks in and we can't use frontend sorting.
        const params = {
          listingId: listing.id,
          start,
          end,
        };
        dispatch(requestFetchAvailabilityExceptions(params));
      }

      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const queryChildListings = params => (dispatch, getState, sdk) => {
  dispatch(childListingsRequest());
  const requesParams = { ...params, page: 1, per_page: 100 };

  return getListings(requesParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(childListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(childListingsError(storableError(e)));
      throw e;
    });
};

export const createDuplicateListing = (params, id) => async (dispatch, getState, sdk) => {
  dispatch(createDuplicateListingRequest());

  // If the listing doesn't have availabilityPlan yet
  // use the defaul timezone
  const availabilityPlan = params.availabilityPlan;

  const tz = availabilityPlan
    ? params.availabilityPlan.timezone
    : typeof window !== 'undefined'
    ? getDefaultTimeZoneOnBrowser()
    : 'Etc/UTC';

  const today = new Date();
  const start = resetToStartOfDay(today, tz, 0);
  // Query range: today + 364 days
  const exceptionRange = 364;
  const end = resetToStartOfDay(today, tz, exceptionRange);

  const exсeptionParams = {
    listingId: id,
    start,
    end,
  };

  let oldListinsExсeptions = [];

  try {
    oldListinsExсeptions = await dispatch(requestFetchAvailabilityExceptions(exсeptionParams));
  } catch (error) {
    console.log('Error on fetch exсeption for duplicate', error);
  }

  dispatch(requestCreateListingDraft(params))
    .then(response => {
      const listing = response.data.data;
      return listing;
    })
    .then(async response => {
      for (let i = 0; i < oldListinsExсeptions.length; i++) {
        const newExсeptionParams = {
          listingId: response.id,
          start: oldListinsExсeptions[i].attributes.start,
          end: oldListinsExсeptions[i].attributes.end,
          seats: oldListinsExсeptions[i].attributes.seats,
        };
        try {
          const newExсeption = await dispatch(requestAddAvailabilityException(newExсeptionParams));
        } catch (error) {
          console.log('Error on create exсeption for duplicate', error);
        }
      }
      const parent = response.attributes.publicData.parent;
      const params = {
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          // Listing page
          'variants.landscape-crop',
          'variants.landscape-crop2x',
        ],
        pub_category: 'space',
        pub_parent: parent,
      };
      setTimeout(() => {
        return dispatch(queryChildListings(params));
      }, 1000);
    })
    .then(response => {
      dispatch(createDuplicateListingSuccess(response));
      return response;
    })
    .catch(e => {
      log.error(e, 'duplicate-listing-failed', { listingData: params });
      dispatch(createDuplicateListingError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log(storableError(e));
    });
};

export const updateOwnListing = data => (dispatch, getState, sdk) => {
  return sdk.ownListings
    .update(data, {
      expand: true,
      include: ['author'],
    })
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log(storableError(e));
      throw e;
    });
};

export const removeListingFromData = (data, id, timezone) => (dispatch, getState, sdk) => {
  const newUpdateData = {};
  const newData = {};
  const keysListingData = {
    listingsMaxAttendees: 'maxAttendees',
    listingsMinPrice: 'minPrice',
    listingsTypes: 'numberRooms',
    listingsStyles: 'roomStyle',
    listingsSafeSpacing: 'safeSpacing',
    listingsVirtualServices: 'virtualServices',
  };

  for (let key in data) {
    if (typeof data[key] === 'object') {
      if (data[key].hasOwnProperty(id)) {
        for (let listingId in data[key]) {
          if (listingId !== id) {
            newData[key] = {
              [listingId]: data[key][listingId],
              ...newData[key],
            };
          }
        }
      }
    }
  }

  for (let key in newData) {
    const keyData = keysListingData[key];

    switch (key) {
      case 'listingsMinPrice':
        const minPicesArr = Object.values(newData[key]);
        const minPriceFromArr = minPicesArr && Math.min(...minPicesArr);
        newData[keyData] = minPriceFromArr / 100;
        break;
      case 'listingsSafeSpacing':
        const safeSpacingArr = Object.values(newData[key]);
        const safeSpacingFromArr = safeSpacingArr && safeSpacingArr.includes(true);
        newData[keyData] = safeSpacingFromArr;
        break;
      case 'listingsStyles':
        const listingsStylesArr = Object.values(newData[key]);
        const listingsStylesFromArr = [];
        listingsStylesArr.map(i => {
          i.map(j => {
            if (!listingsStylesFromArr.includes(j)) {
              listingsStylesFromArr.push(j);
            }
          });
        });
        newData[keyData] = listingsStylesFromArr;
        break;
      case 'listingsMaxAttendees':
        const listingsMaxAttendeesArr = Object.values(newData[key]);
        const maxListingsMaxAttendeesArr =
          listingsMaxAttendeesArr && Math.max(...listingsMaxAttendeesArr);
        newData[keyData] = maxListingsMaxAttendeesArr;
        break;
      case 'listingsVirtualServices':
        const listingsVirtualServicesArr = Object.values(newData[key]);
        const listingsVirtualServicesFromArr = [];
        listingsVirtualServicesArr.map(i => {
          i.map(j => {
            if (!listingsVirtualServicesFromArr.includes(j)) {
              listingsVirtualServicesFromArr.push(j);
            }
          });
        });
        newData[keyData] = listingsVirtualServicesFromArr;
        break;
      case 'listingsTypes':
        const listingsTypesArr = Object.values(newData[key]);
        const listingsTypesNumber = listingsTypesArr.length;
        newData[keyData] = listingsTypesNumber;
        break;
      case 'availabilityPlan':
        const availabilityPlanArr = Object.values(newData[key]);
        const availabilityPlanFromArr = [];
        const refactorAvailability = {};
        let finalAvailability = {};

        availabilityPlanArr.map(i => {
          i.map(j => {
            if (!availabilityPlanFromArr.includes(j)) availabilityPlanFromArr.push(j);
          });
        });

        availabilityPlanArr.map(childListing => {
          childListing.map(day => {
            if (!refactorAvailability[day.dayOfWeek]) {
              refactorAvailability[day.dayOfWeek] = [day];
            } else {
              refactorAvailability[day.dayOfWeek].push(day);
            }
          });
        });

        const refactorAvailabilityArr = Object.values(refactorAvailability);

        const res = refactorAvailabilityArr.map(day => {
          let hours = [];
          let start = null,
            end = null;
          const dayShortName = day[0].dayOfWeek;

          for (let i = 0; i < 24; i++) hours.push(false);

          day.map(item => {
            hours = hours.map((value, index) => {
              return !!(
                (index >= parseInt(item.startTime.split(':')[0]) &&
                  index < parseInt(item.endTime.split(':')[0])) ||
                value
              );
            });
          });

          hours.map((val, index) => {
            if (!start && val) {
              start = index;
            } else if (start && !val) {
              end = index;

              if (!finalAvailability[dayShortName]) {
                finalAvailability[dayShortName] = [
                  {
                    dayOfWeek: dayShortName,
                    endTime: end > 9 ? `${end}:00` : `0${end}:00`,
                    seats: 1,
                    startTime: start > 9 ? `${start}:00` : `0${start}:00`,
                  },
                ];
              } else {
                finalAvailability[dayShortName].push({
                  dayOfWeek: dayShortName,
                  endTime: end > 9 ? `${end}:00` : `0${end}:00`,
                  seats: 1,
                  startTime: start > 9 ? `${start}:00` : `0${start}:00`,
                });
              }

              start = end = null;
            }
          });
        });

        newUpdateData.availabilityPlan = createAvailabilityPlan({ timezone, ...finalAvailability });
        break;

      default:
        break;
    }
  }

  newUpdateData.publicData = newData;

  return newUpdateData;
};

export const removeListingSpaces = ({ id, updateValues, isLastSpace }) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(removeListingRequest());
  const data = {
    id: id,
    title: updateValues.title,
  };
  const timezone = updateValues.timezone;

  const paramsForListings = {
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
    pub_category: 'space',
    pub_parent: updateValues.parent,
  };

  try {
    await dispatch(closeListing(id));
    await dispatch(requestUpdateListing(null, data, true));
    await dispatch(queryChildListings(paramsForListings));
    const listing = await dispatch(requestShowListing({ id: updateValues.parent }));
    const listingData = listing.data.data.attributes.publicData;
    const newData = await dispatch(removeListingFromData(listingData, id, timezone));
    newData.id = updateValues.parent;

    await dispatch(updateOwnListing(newData));

    if (isLastSpace) {
      const parentTitle = listing.data.data.attributes.title;
      const parentId = listing.data.data.id.uuid;
      const newParentTitle = `${parentTitle} (delete)`;
      const updateParentValues = {
        title: newParentTitle,
      };
      await dispatch(removeListing({ id: parentId, updateValues: updateParentValues }));
    }

    dispatch(removeListingSuccess());
  } catch (error) {
    log.error(error, 'remove-listing-failed', { listingData: updateValues });
    dispatch(removeListingError(storableError(error)));
    throw error;
  }
};

// export const updateOwnListing = (params) => (dispatch, getState, sdk) => {
//   return sdk.ownListings
//     .update(params, { expand: true })
//     .then(response => {
//       return response;
//     })
//     .catch(e => {
//       throw e;
//     });
// }

export const updateChildListings = params => async (dispatch, getState, sdk) => {
  const { parentId, parentTitle, childListings } = params;
  dispatch(updateChildListingsRequest());

  try {
    for (let i = 0; i < childListings.length; i++) {
      await dispatch(
        updateOwnListing({ id: childListings[i], publicData: { parentTitle: parentTitle } })
      );
    }
    dispatch(updateChildListingsSuccess());
  } catch (error) {
    dispatch(updateChildListingsError(storableError(error)));
  }
};
