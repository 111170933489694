export const duplicateListingHelper = (listing, listingsNames) => {
  const {
    publicData,
    title,
    availabilityPlan,
    description,
    geolocation,
    metadata,
    price,
    privateData,
  } = listing.attributes;

  const copyListing = {};

  const filterTitle = title
    .split(' ')
    .filter(i => !i.includes('duplicated'))
    .join(' ');

  let duplicateTitle = `${title} (duplicated)-1`;
  const filterListingsName = listingsNames.filter(i => i.includes(filterTitle));

  if (filterListingsName.length > 0) {
    duplicateTitle = `${filterTitle} (duplicated)-${filterListingsName.length}`;
  }

  if (title) copyListing.title = duplicateTitle;
  if (description) copyListing.description = description;
  if (geolocation) copyListing.geolocation = geolocation;
  if (privateData) copyListing.privateData = privateData;
  if (publicData) copyListing.publicData = publicData;
  if (price) copyListing.price = price;
  // if (metadata) copyListing.metadata = metadata;
  if (availabilityPlan) copyListing.availabilityPlan = availabilityPlan;

  return copyListing;
};
