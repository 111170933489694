import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, ReviewRating } from '../../components';

import css from './ListingPage.module.css';
import classNames from 'classnames';

export const getLocale = () => !!window && window.localStorage.getItem('locale');

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find((c) => c.key === key);
};

const SectionHeading = (props) => {
  const {
    richTitle,
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    subHeading,
    reviews,
    listingCategory,
  } = props;

  const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  const showCertificate = certificate && !certificate.hideFromListingInfo;

  // calculate stars
  let reviewsTotalRating = reviews.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.attributes.rating;
  }, 0);
  const reviewsAverageRating =
    reviewsTotalRating && reviews ? Math.round(reviewsTotalRating / reviews.length) : 0; // left default realization for backward compatibility

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {showCertificate ? <span>{certificate.label}</span> : null}
          {showContactUser ? (
            <span className={css.contactWrapper}>
              {showCertificate ? <span className={css.separator}>•</span> : null}
              <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
          {subHeading ? <span className={css.spacesSubHeading}>{subHeading}</span> : null}
        </div>
      </div>
      {
        <div className={classNames(css.reviewsSubHeading, 'flex flex-col gap-1 items-end')}>
          <ReviewRating
            rating={reviewsAverageRating}
            reviewStarClassName={css.reviewStar}
            className={css.reviewStars}
          />
          <span className={css.reviewTotalInfo}>
            <FormattedMessage
              id="ListingPage.reviewsLabel"
              values={{ count: reviews && reviews.length }}
            />
          </span>
        </div>
      }
    </div>
  );
};

export default SectionHeading;
