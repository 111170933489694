import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditSocialDescriptionForm } from '../../forms';

import css from './EditSocialDescriptionPanel.module.css';

const EditSocialDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, title, description } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;

    const location = publicData && publicData.location ? publicData.location : {};
    const brand = publicData && publicData.brand;
    // const { type: durationType, value: durationValue } = (publicData && publicData.duration) || {};
    const durationType = publicData && publicData.durationType;
    const durationValue = publicData && publicData.durationValue;
    const maxAttendees = publicData && publicData.maxAttendees;
    const safeMaxAttendees = publicData && publicData.safeMaxAttendees;
    const attendees = publicData && publicData.attendees;
    const descriptionFr = publicData && publicData.descriptionFr;
    const rse = publicData && publicData.rse;
    const typeOfActivity = publicData && publicData.typeOfActivity;
    const socialWhereType = publicData && publicData.socialWhereType;
    const socialThemes = publicData && publicData.socialThemes;
    const socialLanguage = publicData && publicData.socialLanguage;
    const showForSearchPub = publicData && publicData.showForSearch;

    const { address, addressValid } = location;

    return {
      title,
      description,
      brand,
      descriptionFr,
      durationType,
      durationValue,
      maxAttendees,
      // safeMaxAttendees,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, addressValid },
          }
        : null,
      rse,
      typeOfActivity,
      socialWhereType,
      socialThemes,
      socialLanguage,
      showForSearchPub,
    };
  };

  const { availabilityPlan } = listing && listing.attributes;

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditVenueDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditVenueDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditSocialDescriptionPanel.createListingTitle" />
  );

  const updateAvailability = (plan, newSeats) => {
    return {
      ...plan,
      entries: plan.entries.map(entry => ({ ...entry, seats: newSeats })),
    };
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditSocialDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            descriptionFr,
            brand = null,
            location,
            durationType,
            durationValue,
            maxAttendees,
            typeOfActivity,
            socialWhereType,
            socialThemes,
            socialLanguage,
            rse,
            safeMaxAttendees,
          } = values;

          const {
            selectedPlace: { address, origin, addressValid },
          } = location;
          const safeSpacing = !!safeMaxAttendees;

          const availabilityNeededForUpdate =
            +maxAttendees !== initialValues.maxAttendees && !!availabilityPlan;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            ...(availabilityNeededForUpdate
              ? { availabilityPlan: updateAvailability(availabilityPlan, +maxAttendees) }
              : {}),
            publicData: {
              brand,
              descriptionFr,
              location: { address, addressValid },
              ...(isNewListingFlow ? { category: params.category } : {}),
              durationValue: durationType === 'perHour' ? '0' : durationValue,
              durationType: durationType,
              maxAttendees: +maxAttendees,
              safeMaxAttendees: +safeMaxAttendees,
              typeOfActivity,
              socialWhereType,
              socialThemes,
              socialLanguage,
              safeSpacing,
              rse,
              showForSearch:
                initialValues.showForSearchPub === true || initialValues.showForSearchPub === false
                  ? initialValues.showForSearchPub
                  : true,
            },
          };

          setInitialValues({
            title,
            description,
            brand,
            durationType,
            durationValue,
            location: { search: address, selectedPlace: { address, origin, addressValid } },
            typeOfActivity,
            socialWhereType,
            socialThemes,
            socialLanguage,
            rse,
            // safeMaxAttendees,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditSocialDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditSocialDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditSocialDescriptionPanel;
