import React from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import equipment from '../../assets/icon/extra_services/equipment.svg';
import foodAndDrinks from '../../assets/icon/extra_services/foodAndDrinks.svg';
import staffActivities from '../../assets/icon/extra_services/staffActivities.svg';
import virtualServices from '../../assets/icon/extra_services/virtualServices.svg';

const { Money } = sdkTypes;

import css from './ListingPage.module.css';

const extrasIcons = {
  equipment,
  foodAndDrinks,
  staffActivities,
  virtualServices,
};

const ExtrasItem = ({ extraItem, intl }) => {
  const { extrasName, extrasPrice, extrasDescription, extrasType } = extraItem;

  const extraPriceToMoney = new Money(extrasPrice.amount, extrasPrice.currency);
  const formattedPrice = formatMoney(intl, extraPriceToMoney);

  return (
    <div className={css.extraItem}>
      <img src={extrasIcons[extrasType]} alt="extrasIcon" className={css.extraItemImg} />
      <div className={css.extraItemTextInfo}>
        <span className={css.extrasName}>
          {extrasName} -{' '}
          <span className={css.formattedPrice}>
            <em>{formattedPrice}</em>
          </span>
        </span>
        {extrasDescription && (
          <span className={css.extrasDescription}>
            <em>{extrasDescription}</em>
          </span>
        )}
      </div>
    </div>
  );
};

const ListingExtras = props => {
  const { publicData, intl } = props;

  const listingExtrasLabel = intl.formatMessage({ id: 'ListingPage.listingExtrasLabel' });
  const extras = publicData.extraServices && JSON.parse(publicData.extraServices).extraServices;

  if (!extras || extras.length === 0) {
    return null;
  }

  return (
    <div className={css.extrasWrapper}>
      <h2 className={css.subTitle}>{listingExtrasLabel}</h2>
      <div className={css.extrasItemsWrapper}>
        {extras.map((item, idx) => (
          <ExtrasItem extraItem={item} key={`extra${idx}`} intl={intl} />
        ))}
      </div>
    </div>
  );
};

export default ListingExtras;
