import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaYoutube.module.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 71.4 50"
      version="1.1"
      viewBox="0 0 71.4 50"
      xmlSpace="preserve"
    >
      <path d="M69.9 7.8c-.8-3.1-3.2-5.5-6.3-6.3C58.1 0 35.7 0 35.7 0S13.4 0 7.8 1.5c-3 .8-5.5 3.3-6.3 6.4C0 13.4 0 25 0 25s0 11.6 1.5 17.2c.8 3.1 3.2 5.5 6.3 6.3C13.4 50 35.7 50 35.7 50s22.4 0 27.9-1.5c3.1-.8 5.5-3.2 6.3-6.3 1.5-5.6 1.5-17.2 1.5-17.2s.1-11.6-1.5-17.2zM28.6 35.7V14.3L47.2 25 28.6 35.7z"></path>
    </svg>
  );
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
