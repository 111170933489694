import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditShuttlePricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import { updateAvailabilityPlan } from '../../helpers/availabilityPlanHelper';

import css from './EditShuttlePricingPanel.module.css';

const { Money } = sdkTypes;

const findMinPrice = priceScheme => {
  const arrOfMin = Object.values(priceScheme)
    .map(i =>
      Object.values(i).filter(
        j => !Array.isArray(j) && j !== null && j !== undefined && typeof j !== 'string'
      )
    )
    .map(k => Math.min(...k.map(s => s.amount)));

  return Math.min(...arrOfMin) / 100;
};

const EditShuttlePricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData, title } = currentListing.attributes;
    const { prices, priceScheme } = publicData || {};

    return {
      prices: {
        prices,
        priceScheme: createPrices(priceScheme),
      },
    };
  };

  const createPrices = priceScheme => {
    for (let key in priceScheme) {
      const { standartPrice, vatRate } = priceScheme[key];

      priceScheme[key] = {
        ...priceScheme[key],
        standartPrice: standartPrice
          ? new Money(standartPrice.amount, standartPrice.currency)
          : null,
      };
    }

    return priceScheme;
  };

  const convertPrices = pricesStructure => {
    const { prices, priceScheme } = pricesStructure;
    const scheme = { ...priceScheme };

    for (let key in scheme) {
      const { standartPrice } = scheme[key];

      scheme[key] = {
        ...scheme[key],
        standartPrice: standartPrice
          ? { amount: standartPrice.amount, currency: standartPrice.currency }
          : null,
      };
    }

    return {
      prices,
      priceScheme: scheme,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditSocialPricingPanel.editPriceTitle"
      // values={{
      //   listingTitle: (
      //     <ListingLink listing={listing}>
      //       <FormattedMessage id="EditListingPricingPanel.listingTitle" />
      //     </ListingLink>
      //   ),
      // }}
    />
  ) : (
    <FormattedMessage id="EditSocialPricingPanel.addPriceTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const form = priceCurrencyValid ? (
    <EditShuttlePricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const { prices } = values;
        const updatedValues = {
          price: new Money(0, config.currency),
          publicData: {
            ...convertPrices(prices),
            minPrice: findMinPrice(prices.priceScheme),
          },
          availabilityPlan: updateAvailabilityPlan(prices, currentListing),
        };

        setInitialValues({
          prices,
        });

        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditShuttlePricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditShuttlePricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditShuttlePricingPanel;
