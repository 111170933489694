import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditSpacesPricingForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';
import { updateAvailabilityPlan } from '../../helpers/availabilityPlanHelper';

import css from './EditSpacePricingPanel.module.css';
import config from '../../config';

const { Money } = sdkTypes;

const EditSpacePricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData, title } = currentListing.attributes;
    const { prices, priceScheme, vatRate, vatPrice } = publicData || {};

    return {
      prices: {
        prices,
        priceScheme: createPrices(priceScheme),
      },
      vatPrice: vatRate || vatPrice,
    };
  };

  const createPrices = priceScheme => {
    for (let key in priceScheme) {
      const { standartPrice, downtimePrice, corporatePrice } = priceScheme[key];

      priceScheme[key] = {
        ...priceScheme[key],
        standartPrice: standartPrice
          ? new Money(standartPrice.amount, standartPrice.currency)
          : null,
        downtimePrice: downtimePrice
          ? new Money(downtimePrice.amount, downtimePrice.currency)
          : null,
        corporatePrice: corporatePrice
          ? new Money(corporatePrice.amount, corporatePrice.currency)
          : null,
      };
    }

    return priceScheme;
  };

  const convertPrices = pricesStructure => {
    const { prices, priceScheme } = pricesStructure;
    const scheme = { ...priceScheme };

    for (let key in scheme) {
      const { standartPrice, downtimePrice, corporatePrice } = scheme[key];

      scheme[key] = {
        ...scheme[key],
        standartPrice: standartPrice
          ? { amount: standartPrice.amount, currency: standartPrice.currency }
          : null,
        downtimePrice: downtimePrice
          ? { amount: downtimePrice.amount, currency: downtimePrice.currency }
          : null,
        corporatePrice: corporatePrice
          ? { amount: corporatePrice.amount, currency: corporatePrice.currency }
          : null,
      };
    }

    return {
      prices,
      priceScheme: scheme,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const numberSeats = currentListing && currentListing.attributes.publicData.coworkingValue;
  const spaceTypeData = currentListing && currentListing.attributes.publicData.spaceType;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditSpacePricingPanel.title" />
  ) : (
    <FormattedMessage id="EditSpacePricingPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <p className={css.subTitle}>
        <FormattedMessage id="EditSpacePricingPanel.subTitle" />
      </p>

      <EditSpacesPricingForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { prices, vatPrice } = values;

          const availabilityPlanResult =
            spaceTypeData === 'coworking'
              ? updateAvailabilityPlan(prices, currentListing, numberSeats)
              : updateAvailabilityPlan(prices, currentListing);

          const updateValues = {
            price: new Money(0, config.currency),
            publicData: {
              vatRate: vatPrice,
              ...convertPrices(prices),
            },
            availabilityPlan: availabilityPlanResult,
          };

          setInitialValues({
            vatPrice,
            prices,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditSpacePricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditSpacePricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditSpacePricingPanel;
