import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaLinkedin,
  IconSocialMediaTwitter,
  IconSocialMediaYoutube,
  ExternalLink,
  NamedLink,
  SectionOurClients,
} from '../../components';

import css from './Footer.module.css';

const tr = {
  fb: {
    en: 'Go to Facebook page',
    fr: 'Aller sur la page Facebook',
  },
  tw: {
    en: 'Go to Instagram page',
    fr: 'Aller sur la page Instagram',
  },
  you: {
    en: 'Go to Youtube page',
    fr: 'Aller sur la page Youtube',
  },
  link: {
    en: 'Go to Linkedin page',
    fr: 'Aller sur la page Linkedin',
  },
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const renderSocialMediaLinks = () => {
    const { siteLinkedinPage, siteYoutubePage, siteFacebookPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const linkedinLink = siteLinkedinPage ? (
      <ExternalLink
        key="linkToLinkedin"
        href={siteLinkedinPage}
        className={css.icon}
        title={tr.link[config.locale]}
      >
        <IconSocialMediaLinkedin />
      </ExternalLink>
    ) : null;

    const youtubeLink = siteYoutubePage ? (
      <ExternalLink
        key="linkToYoutube"
        href={siteYoutubePage}
        className={css.icon}
        title={tr.you[config.locale]}
      >
        <IconSocialMediaYoutube />
      </ExternalLink>
    ) : null;

    const fbLink = siteFacebookPage ? (
      <ExternalLink
        key="linkToFacebook"
        href={siteFacebookPage}
        className={css.icon}
        title={tr.fb[config.locale]}
      >
        <IconSocialMediaFacebook />
      </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
      <ExternalLink
        key="linkToTwitter"
        href={siteTwitterPage}
        className={css.icon}
        title={tr.tw[config.locale]}
      >
        <IconSocialMediaTwitter />
      </ExternalLink>
    ) : null;

    return [linkedinLink, youtubeLink, fbLink, twitterLink].filter(v => v != null);
  };

  const linkTo = params => {
    const { id, href, frHref, style = css.listItem } = params;
    let url = config.locale === 'en' ? href : frHref;
    return (
      <li className={style}>
        <ExternalLink href={url} className={css.link}>
          <span>{intl.formatMessage({ id })}</span>
        </ExternalLink>
      </li>
    );
  };

  const socialMediaLinks = renderSocialMediaLinks();

  const currentYear = new Date().getFullYear();

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.clientsBar}>
          <SectionOurClients />
        </div>
        <div className={css.content}>
          <div className={css.someLinksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.about} id="organization">
              <h3 className={css.listTitle}>
                <FormattedMessage id="Footer.about" />
              </h3>
              <ul className={css.list}>
                {linkTo({
                  id: 'Footer.howYemWorks',
                  href: 'https://event.youna.co/en/about-us',
                  frHref: 'http://event.youna.co/a-propos',
                })}
                {linkTo({
                  id: 'Footer.socialResp',
                  href: 'http://event.youna.co/en/social-responsibility',
                  frHref: 'http://event.youna.co/responsabilite-sociale',
                })}
                {linkTo({
                  id: 'Footer.blog',
                  href: 'https://blog.youna.co/blog',
                  frHref: 'https://blog.youna.co/blog',
                })}
                {linkTo({
                  id: 'Footer.careers',
                  href: 'http://event.youna.co/en/careers',
                  frHref: 'http://event.youna.co/carrieres',
                })}
              </ul>
            </div>
            <div className={css.socialResp}>
              <h3 className={css.listTitle}>
                <FormattedMessage id="Footer.sellers" />
              </h3>
              <ul className={css.list}>
                {linkTo({
                  id: 'TopbarMobileMenu.newListingLink',
                  href: 'http://event.youna.co/en/how-to-sell-small-meetings',
                  frHref: 'http://event.youna.co/comment-vendre-des-petites-reunions',
                })}
                <li className={css.listItem}>
                  <NamedLink name="ServicesPage" className={css.link}>
                    <FormattedMessage id="Footer.accountL" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.suppAndBookers}>
              <h3 className={css.listTitle}>
                <FormattedMessage id="Footer.bookers" />
              </h3>
              <ul className={css.list}>
                {linkTo({
                  id: 'Footer.howitworksR',
                  href: 'http://event.youna.co/en/how-to-book-small-meetings',
                  frHref: 'http://event.youna.co/comment-reserver-de-petites-reunions',
                })}
                <li className={css.listItem}>
                  <NamedLink name="ProfileSettingsPage" className={css.link}>
                    <FormattedMessage id="Footer.account" />
                  </NamedLink>
                </li>
                {linkTo({
                  id: 'Footer.corporate',
                  href: 'http://event.youna.co/en/corporate',
                  frHref: 'http://event.youna.co/corporate-fr',
                })}
              </ul>
            </div>
            <div className={css.support}>
              <h3 className={css.listTitle}>
                <FormattedMessage id="Footer.support" />
              </h3>
              <ul>
                <li className={css.listItem}>
                  <p className={css.linkTel}>
                    <FormattedMessage id="Footer.tel" />
                  </p>
                </li>
                {linkTo({
                  id: 'Footer.customerSupport',
                  href: 'https://knowledgebase.youna.co/knowledge',
                  frHref: 'https://knowledgebase.youna.co/fr/knowledge',
                })}
                {linkTo({
                  id: 'Footer.contactUs',
                  href: 'http://event.youna.co/en/contact-us',
                  frHref: 'http://event.youna.co/contactez-nous',
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={css.horizLinksBarWrapper}>
          <ul className={css.horizLinksBar}>
            {linkTo({
              id: 'Footer.termsPrivacy',
              href: 'http://event.youna.co/en/terms-privacy-policy',
              frHref: 'http://event.youna.co/cgu-mentions-legales',
              style: css.horizListItem,
            })}
            {linkTo({
              id: 'Footer.cookiePolicy',
              href: 'http://event.youna.co/en/cookies',
              frHref: 'http://event.youna.co/cookies',
              style: css.horizListItemTwo,
            })}
          </ul>
        </div>
        <div className={css.socialBar}>
          <div className={css.socialMediaLinks}>{socialMediaLinks}</div>
          <div className={css.visitBlog}>
            <ExternalLink
              name="LandingPage"
              className={css.visitBlogLink}
              href="https://blog.youna.co/blog"
            >
              <FormattedMessage id="Footer.visitBlog" />
            </ExternalLink>
          </div>
        </div>
      </div>
      <div className={css.copyrights}>
        <FormattedMessage id="Footer.copyrights" values={{ year: currentYear }} />
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
