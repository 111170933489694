import React, { useState, Component } from 'react';
import { string, object, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import css from './SearchFormBar.module.css';
import { Form, PrimaryButton, FieldRadioButton, LocationAutocompleteInput } from '../../components';

import leftBar from './images/leftBar.png';
import rightBar from './images/rightBar.png';
import { category } from '../../marketplace-custom-config';
import config from '../../config';

const identity = v => v;

const CategoryRadioButton = ({ category, checked, intl, disabled }) => {
  return (
    <div className={css.checkboxWrapper}>
      <div className={checked && !disabled ? classNames(css.checkbox, css.checked) : css.checkbox}>
        <FieldRadioButton
          radioButtonWrapperClassName={css.locationRadioButton}
          id={category}
          name="category"
          label={intl.formatMessage({ id: `SearchFormBar.${category}` })}
          value={!disabled ? category : 'Venue'}
        />
      </div>
    </div>
  );
};

class SearchFormBar extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    const { intl, isHorizontal } = this.props;

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            initialSearchFormValues,
            handleSubmit,
            isMobile,
            values,
            form,
            isHorizontal,
            withoutBar,
            darkLabel,
            darkBackground,
          } = formRenderProps;
          const classes = classNames(rootClassName || css.root, className, {
            [css.horizontal]: isHorizontal,
          });

          const isChecked = key => values && values.category && values.category === key;
          const isSearchEmpty =
            values && values.location && values.location.search && values.location.search.length;
          const clearInputLocation = () => form.change('location', null);
          const placeholder = isHorizontal
            ? intl.formatMessage({ id: 'SearchFormBar.wherePlaceholder' })
            : intl.formatMessage({ id: 'SearchFormBar.everywherePlaceholder' });
          return (
            <div className={classNames(css.searchContainer, classes)}>
              {withoutBar ? null : <img src={leftBar} className={css.leftBar} alt="leftbar" />}
              {withoutBar ? null : <img src={rightBar} className={css.rightBar} alt="rightbar" />}
              <Form className={css.searchForm} onSubmit={handleSubmit}>
                <div className={darkBackground ? css.fieldsWhatDark : css.fieldsWhat}>
                  <div className={darkLabel ? css.fieldsWhatLabelDark : css.fieldsWhatLabel}>
                    <FormattedMessage id="SearchFormBar.what" />
                  </div>
                  {category && category.length
                    ? category.map(category => (
                        <CategoryRadioButton
                          key={category.key}
                          intl={intl}
                          category={category.key}
                          checked={isChecked(category.key)}
                          disabled={category.disabled}
                        />
                      ))
                    : null}
                </div>
                <div className={darkBackground ? css.fieldsWhereDark : css.fieldsWhere}>
                  <div className={darkLabel ? css.fieldWhereLabelDark : css.fieldsWhereLabel}>
                    <FormattedMessage id="SearchFormBar.where" />
                  </div>
                  <Field
                    name="location"
                    format={identity}
                    render={({ input, meta }) => {
                      const { onChange, ...restInput } = input;

                      // Merge the standard onChange function with custom behaviur. A better solution would
                      // be to use the ForallmSpy component from Final Form and pass this.onChange to the
                      // onChange prop but that breaks due to insufficient subscription handling.
                      // See: https://github.com/final-form/react-final-form/issues/159
                      const searchOnChange = value => {
                        onChange(value);
                        // this.onChange(value);
                      };

                      const searchInput = { ...restInput, onChange: searchOnChange };
                      return (
                        <>
                          <LocationAutocompleteInput
                            className={css.fieldsWhereRoot}
                            iconClassName={css.fieldsWhereIcon}
                            inputClassName={css.fieldsWhereInput}
                            // predictionsClassName={
                            //   isMobile ? css.mobilePredictions : css.desktopPredictions
                            // }
                            // predictionsAttributionClassName={
                            //   isMobile ? css.mobilePredictionsAttribution : null
                            // }
                            placeholder={placeholder}
                            // closeOnBlur={!isMobile}
                            inputRef={node => {
                              this.searchInput = node;
                            }}
                            input={searchInput}
                            meta={meta}
                            isIconLocation={true}
                          />
                          {isSearchEmpty ? (
                            <span
                              onClick={() => clearInputLocation()}
                              className={css.iconResetSearch}
                            >
                              +
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  />
                </div>

                <div className={css.formBottom}>
                  <PrimaryButton type="submit">
                    <FormattedMessage id="SearchFormBar.signUp" />
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

SearchFormBar.defaultProps = {
  rootClassName: null,
  className: null,
  initialSearchFormValues: {},
};

SearchFormBar.propTypes = {
  rootClassName: string,
  className: string,
  initialSearchFormValues: object,
  onSubmit: func,
};

export default injectIntl(SearchFormBar);
