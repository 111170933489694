import React from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { LISTING_PAGE_PARAM_TYPES } from '../../util/urlHelpers';
import {
  EditCateringDescriptionPanel,
  EditListingPhotosPanel,
  EditCateringExtrasPanel,
  EditCateringMenusPanel,
  EditListingDescriptionPanel,
} from '..';

import css from './EditServicesWizard.module.css';

export const DESCRIPTION = 'description';
export const EXTRAS = 'extras';
export const PHOTOS = 'photos';
export const MENUS = 'menus';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [DESCRIPTION, PHOTOS, MENUS, EXTRAS];

const EditServiceWizardTab = (props) => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onCreateDuplicateListing,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    onCompleteEditListingWizardTab,
    isNewListingFlow,
    edit,
    fetchChildListings,
    childListings,
    childListingsProgress,
    onRemoveListing,
    removeListingLoading,
    removeListingResult,
  } = props;

  const panelProps = (tab) => {
    return {
      params,
      isNewListingFlow,
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      edit,
      history,
    };
  };

  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = !edit
        ? 'EditServicesWizard.saveEditDescription'
        : 'EditServicesWizard.saveNewDescriptionVenue';
      return (
        <EditCateringDescriptionPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={(values) => {
            onCompleteEditListingWizardTab(tab, values, marketplaceTabs);
          }}
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = !edit
        ? 'EditVenueWizard.saveEditPhotos'
        : 'EditVenueWizard.saveNewPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={(values) => {
            onCompleteEditListingWizardTab(tab, values, marketplaceTabs);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
          isVenue={true}
        />
      );
    }

    case MENUS: {
      return (
        <EditCateringMenusPanel
          {...panelProps(MENUS)}
          fetchChildListings={fetchChildListings}
          childListings={childListings}
          childListingsProgress={childListingsProgress}
          onCreateDuplicateListing={onCreateDuplicateListing}
          onRemoveListing={onRemoveListing}
          removeListingLoading={removeListingLoading}
          removeListingResult={removeListingResult}
          onSubmit={(values) => {
            onCompleteEditListingWizardTab(tab, values, marketplaceTabs);
          }}
        />
      );
    }

    case EXTRAS: {
      const submitButtonTranslationKey = !edit
        ? 'EditCateringWizard.saveEditExtras'
        : 'EditCateringWizard.saveNewExtras';
      return (
        <EditCateringExtrasPanel
          {...panelProps(EXTRAS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={(values, noRedirect, noPublishListing) => {
            onCompleteEditListingWizardTab(
              tab,
              values,
              marketplaceTabs,
              false,
              noRedirect,
              noPublishListing
            );
          }}
        />
      );
    }

    default:
      return null;
  }
};

EditServiceWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditServiceWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onCreateDuplicateListing: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default EditServiceWizardTab;
