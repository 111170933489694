import React from 'react';
import config from '../../config';

import { getLabelsFromFilters } from '../../util/data';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingPage.module.css';

const ShuttleDescriptionItems = props => {
  const {
    publicData,
    shuttleVehicleTypes,
    shuttleEnergyTypes,
    shuttleWaitingTimeMaxTypes,
    shuttleActivityLanguageType,
    intl,
  } = props;

  const shuttleTypeOfVehicleLabel = intl.formatMessage({ id: 'ListingPage.shuttleTypeOfVehicle' });
  const shuttleMaxSeatsLabel = intl.formatMessage({ id: 'ListingPage.shuttleMaxSeats' });
  const shuttleWaitingTimeLabel = intl.formatMessage({ id: 'ListingPage.shuttleWaiting' });
  const shuttleLanguageLabel = intl.formatMessage({ id: 'ListingPage.shuttleLanguage' });
  const shuttleEnergyLabel = intl.formatMessage({ id: 'ListingPage.shuttleEnergy' });

  const typeOfVehicle = publicData.typeOfVehicle;
  const energy = publicData.energy;
  const shuttleLanguage = publicData.shuttleLanguage;
  const maxSeats = publicData.maxAttendees ? publicData.maxAttendees : '';
  const waitingTime = publicData.waitingTimeMax ? publicData.waitingTimeMax : '';

  const shuttleTypeOfVehicleFormattedList = getLabelsFromFilters(
    shuttleVehicleTypes,
    typeOfVehicle
  );
  const shuttleEnergyFormattedList = getLabelsFromFilters(shuttleEnergyTypes, energy);
  const shuttleWaitingTimeFormattedList = getLabelsFromFilters(
    shuttleWaitingTimeMaxTypes,
    waitingTime
  );
  const shuttleLanguageFormattedList = getLabelsFromFilters(
    shuttleActivityLanguageType,
    shuttleLanguage
  );

  return (
    <div className={css.descriptionItems}>
      <div>
        {shuttleTypeOfVehicleFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{shuttleTypeOfVehicleLabel}</span>
            {/* <span>{shuttleTypeOfVehicleFormattedList}</span> */}
            <span>
              {typeOfVehicle
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
        {shuttleEnergyFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{shuttleEnergyLabel}</span>
            {/* <span>{shuttleEnergyFormattedList}</span> */}
            <span>
              {energy
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
        {shuttleLanguageFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{shuttleLanguageLabel}</span>
            {/* <span>{shuttleLanguageFormattedList}</span> */}
            <span>
              {shuttleLanguage
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>

      <div>
        {maxSeats ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{shuttleMaxSeatsLabel}</span>
            <span>{maxSeats}</span>
          </div>
        ) : null}
        {shuttleWaitingTimeFormattedList ? (
          <div>
            <span className={css.descriptionLabel}>{shuttleWaitingTimeLabel}</span>
            {/* <span>{shuttleWaitingTimeFormattedList}</span> */}
            <span>
              {waitingTime
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ShuttleDescriptionItems.defaultProps = {
  shuttleVehicleTypes: config.custom.shuttleVehicleTypes,
  shuttleEnergyTypes: config.custom.shuttleEnergyTypes,
  shuttleWaitingTimeMaxTypes: config.custom.shuttleWaitingTimeMaxTypes,
  shuttleActivityLanguageType: config.custom.shuttleActivityLanguageType,
};

export default ShuttleDescriptionItems;
