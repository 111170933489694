import React from 'react';
import { string, object, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionHero.module.css';
import { SearchFormBar } from '../../components';
import { category } from '../../marketplace-custom-config';

const SectionHero = (props) => {
  const { rootClassName, className, initialSearchFormValues, onSubmit } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" values={{ lineBreak: <br /> }} />
          </h1>
          <p className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </p>
        </div>
        <SearchFormBar
          initialValues={{
            category: category[0].key,
          }}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null, initialSearchFormValues: {} };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  initialSearchFormValues: object,
  onSubmit: func,
};

export default SectionHero;
