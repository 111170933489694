import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="86"
      fill="none"
      viewBox="0 0 90 86"
    >
      <path d="M90 52.727V86H70.71V54.955c0-7.799-2.79-13.121-9.773-13.121-5.33 0-8.502 3.587-9.897 7.056-.509 1.241-.64 2.967-.64 4.704V86H31.104s.26-52.58 0-58.028h19.295v8.225c-.039.062-.09.128-.127.188h.127v-.188c2.563-3.948 7.141-9.588 17.388-9.588C80.483 26.609 90 34.903 90 52.727zM10.919 0C4.318 0 0 4.33 0 10.024c0 5.57 4.193 10.031 10.663 10.031h.129c6.729 0 10.914-4.46 10.914-10.031C21.579 4.33 17.521 0 10.919 0zM1.146 86h19.289V27.972H1.146V86z"></path>
    </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
