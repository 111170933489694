import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, PrimaryButton, FieldSelect } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import { shouldDisplayCorporatePrices } from '../../helpers/utils';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendeeMax: [],
      selectedSlot: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSelectedTimeSlot = this.onSelectedTimeSlot.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, seats } = formValues.values;
    const {
      listingId,
      isOwnListing,
      fetchLineItemsInProgress,
      onFetchTransactionLineItems,
      vatRate,
      listing,
      currentUser,
    } = this.props;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const durationType = listing.attributes.publicData.durationType;
    const pricingType = listing.attributes.publicData.pricingType;
    const maxAttendees = listing.attributes.publicData.maxAttendees;

    const useCorporatePrice = shouldDisplayCorporatePrices(currentUser);

    if (bookingStartTime && bookingEndTime && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          vatRate,
          duration: durationType,
          bookingSeats: pricingType == 'package' ? maxAttendees : null,
          seats,
          useCorporatePrice,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  onSelectedTimeSlot(selectedSlot) {
    if (!selectedSlot) {
      return;
    }

    const { listing } = this.props;

    const minAttendees =
      (listing.attributes.publicData.pricingType == 'person' &&
        listing.attributes.publicData.minAttendees) ||
      1;

    const availableSeats = selectedSlot.attributes.seats;

    const attendeeMax = Array.from(new Array(+availableSeats))
      .map((item, i) => i + 1)
      .slice(+minAttendees - 1);

    this.setState({ attendeeMax, selectedSlot });
  }

  render() {
    const { rootClassName, className, price: unitPrice, listingCategory, ...rest } = this.props;
    const { attendeeMax } = this.state;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            listing,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;

          const {
            pricingType,
            minAttendees: minAttendeesListing,
            maxAttendees = 1,
          } = this.props.listing.attributes.publicData;

          const isPackage = pricingType == 'package';
          const minAttendees = (!isPackage && minAttendeesListing) || 1;

          const durationType = this.props.listing.attributes.publicData.durationType;
          let durationPerHour =
            values &&
            values.bookingStartTime &&
            ((values.bookingEndTime - values.bookingStartTime) / (1000 * 60 * 60)).toFixed(2);
          const durationFixed = this.props.listing.attributes.publicData.durationValue || null;
          const duration = durationType === 'fixed' ? durationFixed : durationPerHour;
          const isPerPerson = this.props.listing.attributes.publicData.pricingType === 'person';

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          let endTime = null;

          switch (durationType) {
            case 'fixed':
              endTime = values && +values.bookingStartTime + durationFixed * 3600000;
              break;
            case 'perHour':
              endTime = values && values.bookingEndTime;
              break;
          }

          const filteredMonthlyTimeSlots =
            monthlyTimeSlots &&
            Object.entries(monthlyTimeSlots).reduce((acc, item, ind) => {
              const _temp =
                item[1] &&
                item[1].timeSlots &&
                item[1].timeSlots.filter(
                  i =>
                    (!isPackage && i.attributes.seats >= minAttendees) ||
                    (isPackage && i.attributes.seats === maxAttendees)
                );
              acc[item[0]] = { ...item[1], timeSlots: _temp };
              return acc;
            }, {});

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const attendeeLabel = intl.formatMessage({ id: 'BookingTimeForm.attendeeLabel' });

          const placeholderAttendee = intl.formatMessage({
            id: 'BookingTimeForm.placeholderAttendee',
          });

          const durationMessage = intl.formatMessage({
            id: 'BookingTimeForm.durationLabel',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              {/* <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3> */}
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                isPerSession={durationType == 'fixed'}
                isPerPerson={isPerPerson}
                listingCategory={listingCategory}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />

              {duration && (
                <div className={css.duration}>
                  <span className={css.durationTitle}>{durationMessage}</span>
                  <span className={css.durationCounter}>{duration}h</span>
                </div>
              )}
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={filteredMonthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  listingCategory={listingCategory}
                  durationFixed={durationFixed}
                  durationType={durationType}
                  onSelectedTimeSlot={this.onSelectedTimeSlot}
                />
              ) : null}
              <div className={css.formRow}>
                <FieldSelect
                  name="seats"
                  id="seats"
                  defaultValue={minAttendees}
                  className={css.fieldSelect}
                  selectClassName={css.select}
                  label={attendeeLabel}
                  disabled={!startDate}
                >
                  <option disabled value="">
                    {placeholderAttendee}
                  </option>
                  {attendeeMax.map(i => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
