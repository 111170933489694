import React from 'react';
import {
  BookingDateRangeFilter,
  BookingDateRangeLengthFilter,
  PriceFilter,
  KeywordFilter,
  SelectSingleFilter,
  SelectMultipleFilter,
  SelectMultipleManyFilters,
} from '../../components';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = (props) => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    listingCategory,
    intl,
    ...rest
  } = props;
  const { id, type, queryParamNames, label, config } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  const isVenue = listingCategory === 'venue';
  const isShuttle = listingCategory === 'shuttle';
  const isVirtual = listingCategory === 'virtual';
  const isSocial = listingCategory === 'social';
  const isCatering = listingCategory === 'catering';

  const labelWithTranslate = intl.formatMessage({ id: `ConfigFilter.${id}` }) || label;

  switch (type) {
    case 'SelectSingleFilter': {
      return (
        <SelectSingleFilter
          id={componentId}
          label={labelWithTranslate}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={labelWithTranslate}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilterCatering': {
      if (isCatering) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    // case 'SelectMultipleFilterFoodType': {
    //   if (isCatering) {
    //     return (
    //       <SelectMultipleFilter
    //         id={componentId}
    //         label={labelWithTranslate}
    //         name={name}
    //         queryParamNames={queryParamNames}
    //         initialValues={initialValues(queryParamNames)}
    //         onSubmit={getHandleChangedValueFn(useHistoryPush)}
    //         {...config}
    //         {...rest}
    //       />
    //     );
    //   } else {
    //     return null;
    //   }
    // }
    case 'SelectMultipleFilterMealType': {
      if (isCatering) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSpecificDiets': {
      if (isCatering) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    // case 'SelectMultipleFilterSocialResponsability': {
    //   if (isCatering) {
    //     return (
    //       <SelectMultipleFilter
    //         id={componentId}
    //         label={labelWithTranslate}
    //         name={name}
    //         queryParamNames={queryParamNames}
    //         initialValues={initialValues(queryParamNames)}
    //         onSubmit={getHandleChangedValueFn(useHistoryPush)}
    //         {...config}
    //         {...rest}
    //       />
    //     );
    //   } else {
    //     return null;
    //   }
    // }
    case 'SelectMultipleFilterVenue': {
      if (isVenue) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterShuttle': {
      if (isShuttle) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterVirtual': {
      if (isVirtual) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSocialActivity': {
      if (isSocial) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSecondaryVenue': {
      if (isVenue) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSecondaryVenue': {
      if (isVenue) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSecondaryShuttle': {
      if (isShuttle) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            numberColumns={1}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSecondaryVirtual': {
      if (isVirtual) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleFilterSecondarySocial': {
      if (isSocial) {
        return (
          <SelectMultipleFilter
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'BookingDateRangeFilter': {
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={labelWithTranslate}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'BookingDateRangeLengthFilter': {
      return (
        <BookingDateRangeLengthFilter
          id={componentId}
          label={labelWithTranslate}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          dateRangeLengthFilter={filterConfig}
          {...rest}
        />
      );
    }
    case 'PriceFilter': {
      if (isVenue || isShuttle || isCatering) {
        return (
          <PriceFilter
            id={componentId}
            label={labelWithTranslate}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'PriceFilterDefault': {
      if (isVirtual || isSocial) {
        return (
          <PriceFilter
            id={componentId}
            label={labelWithTranslate}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'KeywordFilter':
      return (
        <KeywordFilter
          id={componentId}
          label={labelWithTranslate}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    case 'SelectMultipleManyFiltersVenue': {
      if (isVenue) {
        return (
          <SelectMultipleManyFilters
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            isVenue={true}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    case 'SelectMultipleManyFiltersDefault': {
      if (isShuttle || isVirtual || isSocial) {
        return (
          <SelectMultipleManyFilters
            id={componentId}
            label={labelWithTranslate}
            name={name}
            queryParamNames={queryParamNames}
            initialValues={initialValues(queryParamNames)}
            onSubmit={getHandleChangedValueFn(useHistoryPush)}
            isVenue={false}
            {...config}
            {...rest}
          />
        );
      } else {
        return null;
      }
    }
    default:
      return null;
  }
};

export default FilterComponent;
