import React from 'react';
import css from './AmenityItem.module.css';
import { FieldCheckbox, FieldCheckboxWithIcon } from '../index';
import classNames from 'classnames';

const AmenityItem = ({ items, formValues, isFullwidth, className, childClassName, intl }) => {
  return (
    <div className={classNames(css.amenitiesContainer, className)}>
      {items.map((option, idx) => {
        const showFreeOfCharge = option.key !== 'naturalLight';
        return (
          <div
            className={classNames(css.amenitiesCheckboxWrap, childClassName)}
            key={`${option.key}${idx}`}
          >
            {option.img ? (
              <FieldCheckboxWithIcon
                id={option.key}
                name={option.key}
                label={intl.formatMessage({ id: `ConfigFilter.${option.key}` }) || option.label}
                value={option.key}
                icon={option.img}
              />
            ) : (
              <FieldCheckbox
                id={option.key}
                name={option.key}
                label={intl.formatMessage({ id: `ConfigFilter.${option.key}` }) || option.label}
                value={option.key}
              />
            )}
            {/* {formValues[option.key] && formValues[option.key].length ? ( */}
            {showFreeOfCharge ? (
              <FieldCheckbox
                id={`free${option.key}`}
                name={`free${option.key}`}
                label={intl.formatMessage({ id: `AmenityItem.freeOfCharge` })}
                value={true}
                className={css.freeCheckbox}
                disabled={!(formValues[option.key] && formValues[option.key].length)}
              />
            ) : null}
            {/* ) : null} */}
          </div>
        );
      })}
    </div>
  );
};

export default AmenityItem;
