import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  validAddressRequired,
  isAboveZero,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldSelect,
  FieldCheckboxGroup,
  MeetSafeLabel,
  FieldSquareRadioButton,
} from '../../components';

import css from './EditSocialDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const identity = v => v;

const durationValues = Array.from(Array(24)).map((e, i) => i + 1);

// const socialActivityTypes = [
//   {key: "teamBuilding", label: "Team Building"},
//   {key: "entertainment", label: "Entertainment"},
//   {key: "coaching", label: "Coaching"},
// ];
// const socialActivityWhereType = [
//   {key: "indoor", label: "Indoor"},
//   {key: "outdoor", label: "Outdoor"},
//   {key: "virtual", label: "Virtual"},
//   {key: "hybrid", label: "Hybrid"},
// ];
// const socialActivityThemesType = [
//   {key: "inspiring", label: "Inspiring"},
//   {key: "cultural", label: "Cultural"},
//   {key: "party", label: "Party"},
//   {key: "creative", label: "Creative"},
//   {key: "rallye", label: "Rallye"},
//   {key: "sport", label: "Sport"},
//   {key: "murderParty", label: "Murder Party"},
//   {key: "teamSpirit", label: "Team Spirit"},
//   {key: "other", label: "Other"},
//   {key: "challenge", label: "Challenge"},
//   {key: "food", label: "Food"},
// ];
// const socialActivityLanguageType = [
//   {key: "french", label: "French"},
//   {key: "english", label: "English"},
//   {key: "spanish", label: "Spanish"},
// ];

const RenderGroups = props => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const EditSocialDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
        } = formRenderProps;
        const titleMessage = intl.formatMessage({ id: 'EditSocialDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditSocialDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderEn',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.descriptionRequired',
        });

        const brandMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brand',
        });
        const brandPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brandPlaceholder',
        });

        const locationLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.location' });
        const locationPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationPlaceholder',
        });
        const locationRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationRequired',
        });
        const locationNotRecognizedMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationNotRecognized',
        });
        const durationMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.duration',
        });
        const durationFixedMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.fixed',
        });
        const durationPerHourMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.perHour',
        });
        const durationHoursMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.hours',
        });

        const descriptionPlaceholderMessageFr = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderFr',
        });

        const durationHoursPlaceholderMessage = '0';

        const maxAttendeesPlaceholderMessage = 100;
        const safeMaxPlaceholder = +maxAttendeesPlaceholderMessage / 2;

        const maxAttendeesMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.maxAttendees',
        });
        const safeMaxMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.safeMax',
        });
        const addressValidMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.addressInvalid',
        });

        const durationLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.duration' });
        const attendeesLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.attendees' });
        const attendeesValidMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.attendeesValidateMsg',
        });

        const perHourLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.perHourLabel' });

        const disabledDurationValue = values.durationType !== 'fixed';
        const maximumAttendees = values.maxAttendees;
        const safeMaximumAttendees = maximumAttendees && Math.floor(+maximumAttendees / 2);

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const themesCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.themesLabel',
        });
        const activityTypeCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.activityTypeLabel',
        });
        const languageCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.languageLabel',
        });
        const whereCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.whereLabel',
        });
        const tooltiptext = intl.formatMessage({
          id: 'EditSocialDescriptionForm.tooltipText',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />
            <p className={css.titleInputIcon}>{descriptionMessage}</p>
            {config.locale === 'en' ? (
              <>
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            ) : (
              <>
                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            )}

            <FieldTextInput
              id="brand"
              name="brand"
              className={css.description}
              type="textarea"
              label={brandMessage}
              placeholder={brandPlaceholderMessage}
            />

            <LocationAutocompleteInputField
              className={css.location}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={locationLabel}
              placeholder={locationPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(locationRequiredMessage),
                autocompletePlaceSelected(locationNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
            />
            <p className={css.durationTitle}>{durationMessage}</p>
            <div className={css.durationBlock}>
              <div className={css.durationFixed}>
                <FieldSquareRadioButton
                  id="Fixed"
                  name="durationType"
                  label={durationFixedMessage}
                  value="fixed"
                  className={css.fixedValue}
                />
                <FieldTextInput
                  id="durationValue"
                  name="durationValue"
                  className={css.durationValue}
                  type="number"
                  defaultValue={1}
                  min="1"
                  disabled={disabledDurationValue}
                  label={durationHoursMessage}
                  placeholder={durationHoursPlaceholderMessage}
                />
              </div>
              <FieldSquareRadioButton
                id="perHour"
                name="durationType"
                label={perHourLabel}
                value="perHour"
                className={css.durationPerHourMessage}
              />
            </div>
            <div className={css.attendeesContainer}>
              <FieldTextInput
                id="maxAttendees"
                name="maxAttendees"
                className={css.maxAttendees}
                type="number"
                min={0}
                label={maxAttendeesMessage}
                placeholder={maxAttendeesPlaceholderMessage}
              />
              <div className={css.safeMaxContainer}>
                <FieldTextInput
                  id="safeMaxAttendees"
                  name="safeMaxAttendees"
                  className={css.maxSafeAttendees}
                  type="number"
                  disabled={true}
                  min={0}
                  label={safeMaxMessage}
                  placeholder={safeMaxPlaceholder}
                  defaultValue={safeMaximumAttendees}
                />
                <div className={css.tipContainer}>
                  <span className={css.safeMaxItem} title={tooltiptext} tabIndex="0">
                    i
                  </span>
                </div>
              </div>
            </div>
            <RenderGroups
              label={activityTypeCheckboxesLabel}
              options={props.socialActivityTypes}
              name="typeOfActivity"
              className={css.groupsFourRows}
              intl={intl}
            />

            <RenderGroups
              label={whereCheckboxesLabel}
              options={props.socialActivityWhereType}
              name="socialWhereType"
              className={css.groupsFourRows}
              intl={intl}
            />

            <RenderGroups
              label={themesCheckboxesLabel}
              options={props.socialActivityThemesType}
              name="socialThemes"
              className={css.groupsFourRows}
              intl={intl}
            />

            <RenderGroups
              label={languageCheckboxesLabel}
              options={props.socialActivityLanguageType}
              name="socialLanguage"
              className={css.groupsFourRows}
              intl={intl}
            />
            <MeetSafeLabel />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditSocialDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  socialActivityTypes: config.custom.socialActivityTypes,
  socialActivityWhereType: config.custom.socialActivityWhereType,
  socialActivityThemesType: config.custom.socialActivityThemesType,
  socialActivityLanguageType: config.custom.socialActivityLanguageType,
};

EditSocialDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditSocialDescriptionFormComponent);
