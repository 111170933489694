import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ImagedLink } from '../../components';
import config from '../../config';

import css from './SectionServices.module.css';

import meetingRoom from './images/reunion.jpg';
import teamBuilding from './images/teambuilding.jpg';
import residentialSeminar from './images/seminaire.jpg';
import catering from './images/traiteur.jpg';
import videoconference from '../../assets/icon/videoconferenceWhite.svg';

const { venueVirtualServices } = config.custom;

const services = [
  {
    key: 'meetingRoom',
    src: meetingRoom,
    link: 'pub_category=venue&pub_locationType=has_any%3Acoworking',
  },
  {
    key: 'residentialSeminar',
    src: residentialSeminar,
    link: 'pub_category=venue&pub_prices=has_any%3AresidentialPackage',
    videoBadge: true,
  },
  {
    key: 'teamBuilding',
    src: teamBuilding,
    link: 'pub_category=social&pub_typeOfActivity=has_any%3AteamBuilding',
    videoBadge: true,
  },
  {
    key: 'catering',
    src: catering,
    link:
      'pub_category=catering',
  },
];

const SectionServices = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionServices.title" values={{ lineBreak: <br /> }} />
      </h2>
      <div className={css.services}>
        <div className={css.servicesWrapper}>
          {services &&
            !!services.length &&
            services.map(service => (
              <div className={css.servicesCol} key={service.key}>
                <ImagedLink
                  key={service.key}
                  sectionName="SectionServices"
                  name={service.key}
                  image={service.src}
                  searchQuery={service.link}
                  rootClassName={css.service}
                  textClassName={css.serviceText}
                  disabled={!!service.disabled}
                />

                {service.videoBadge && (
                  <div className={css.iconConference}>
                    <img
                      src={videoconference}
                      alt="conference icon"
                      className={css.conferenceImg}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

SectionServices.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionServices.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionServices;
