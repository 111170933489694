import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ImagedLink } from '..';

import css from './SectionAroundYou.module.css';

const items = [
  { key: 'item1', link: '' },
  { key: 'item2', link: '' },
  { key: 'item3', link: '' },
  { key: 'item4', link: '' },
];

const delimiter = <br />;

const SectionAroundYou = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionAroundYou.title" />
      </h2>
      <div className={css.items}>
        <div className={css.itemsWrapper}>
          {items.map(item => (
            <div className={css.itemsCol}>
              <ImagedLink
                key={item.key}
                sectionName="SectionAroundYou"
                name={item.key}
                searchQuery={item.link}
                rootClassName={css.item}
                textClassName={css.itemText}
                messageValues={{ delimiter }}
                disabled={true}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SectionAroundYou.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAroundYou.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAroundYou;
