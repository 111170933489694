import React from 'react';
import css from './InboxPage.module.css';
import { animateScroll as scroll } from 'react-scroll';

const InBoxPageLazyLoading = ({
  pageSize,
  isRevers,
  setPageShow,
  stateTransactions,
  scrollBar,
  pageShow,
  intl,
}) => {
  const handleShowMore = () => {
    if (!isRevers) setPageShow(stateTransactions.slice(0, pageShow.length + pageSize));
    else
      setPageShow(
        stateTransactions
          .sort((a, b) => a.attributes.lastTransitionedAt - b.attributes.lastTransitionedAt)
          .slice(0, pageShow.length + pageSize)
      );
  };

  const goToTopInBoxPage = () => {
    scroll.scrollToTop();
  };
  const linkToTop =
    scrollBar > 1400 ? (
      <p className={css.pulse} onClick={goToTopInBoxPage}>
        Up
      </p>
    ) : null;
  const pagingLinks =
    pageShow.length >= pageSize && pageShow.length !== stateTransactions.length ? (
      <div className={css.showMore} onClick={handleShowMore}>
        {intl.formatMessage({ id: 'InboxPage.showMore' })}
      </div>
    ) : null;

  return (
    <div className={css.wrapperLazyLoad}>
      {linkToTop}
      {pagingLinks}
    </div>
  );
};

export default InBoxPageLazyLoading;
