import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ValidationError } from '..';
import { required } from '../../util/validators';
import { Form, FieldTextInput, FieldCheckbox } from '..';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import css from './FieldSpacesStyle.module.css';

const createName = (type, name, index) => {
  return `FieldSpacesStyle_${name}__${type}__${index}`;
};

const getName = name => {
  return name.split('__').slice(1);
};

class FieldRow extends Component {
  componentDidUpdate(prevProps) {
    const { safeSpacing, form, name, index } = this.props;
    if (!safeSpacing && safeSpacing !== prevProps.safeSpacing) {
      const safeValueName = createName('s', name, index);
      form.change(safeValueName, '');
    }
  }

  render() {
    const { item, name, index, intl, values, safeSpacing } = this.props;
    const placeholder = intl.formatMessage({ id: `FieldSpacesStyle.valuePlaceholder` });
    const requiredMessage = required(intl.formatMessage({ id: `FieldSpacesStyle.valueRequired` }));
    const checkboxName = createName('c', name, index);
    const isActive = values[checkboxName] && values[checkboxName].length;

    return (
      <tr className={css.tableRow}>
        <td>
          <FieldCheckbox
            className={css.checkbox}
            id={checkboxName}
            name={checkboxName}
            label={intl.formatMessage({ id: `ConfigFilter.${item.key}` }) || item.label}
            value={item.key}
          />
        </td>

        <td className={css.imageContainer}>
          <img width="48" src={item.icon} alt={item.label} />
        </td>

        <td>
          <FieldTextInput
            type="number"
            name={createName('v', name, index)}
            placeholder={placeholder}
            disabled={!isActive}
            {...(isActive ? { validate: requiredMessage } : {})}
          />
        </td>
        {safeSpacing && (
          <td className={css.coloredBackground}>
            <FieldTextInput
              type="number"
              name={createName('s', name, index)}
              placeholder={placeholder}
              disabled
            />
          </td>
        )}
      </tr>
    );
  }
}

class FieldSpacesStyleComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      styles: {},
      initialValues: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ initialValues: this.initialValues() });
  }

  initialValues() {
    const {
      input: { name },
      values,
      items,
    } = this.props;
    const structure = (values[name] && values[name].structure) || {};
    const initialValues = {};
    Object.keys(structure).forEach(key => {
      const index = items.findIndex(item => item.key === key);
      if (index === -1) {
        return;
      }
      initialValues[createName('c', name, index)] = [key];
      initialValues[createName('v', name, index)] = structure[key].value;
      if (structure[key].safeValue) {
        initialValues[createName('s', name, index)] = structure[key].safeValue;
      }
    });
    return initialValues;
  }

  checkDiffs(newStyles) {
    const { styles } = this.state;

    return !isEqual(styles, newStyles);
  }

  checkValues(values, name, form) {
    const { safeSpacing } = this.props;

    Object.keys(values).forEach(key => {
      const [type, index] = getName(key);
      const value = values[key];
      const valueName = createName('v', name, index);
      const safeName = createName('s', name, index);

      if (type === 'c' && !value.length) {
        form.change(valueName, '');
        form.resetFieldState(valueName);
      }

      if (type === 'v') {
        form.change(safeName, value ? Math.floor(value / 2) || 1 : '');
      }

      if (type === 's' && value && !values[valueName]) {
        form.change(key, '');
      }
    });
  }

  createStructure(values) {
    const {
      input: { name },
      safeSpacing,
      items,
      form,
    } = this.props;
    const structure = {};
    Object.keys(values).forEach(key => {
      const [type, index] = getName(key);
      const checkbox = values[createName('c', name, index)];
      if (checkbox && checkbox.length && !structure[checkbox[0]]) {
        const checkbox = values[createName('c', name, index)];
        const value = values[createName('v', name, index)];
        const safeValue = values[createName('s', name, index)];

        if (value) {
          structure[checkbox[0]] = {
            value: +value,
            ...(safeSpacing ? { safeValue } : {}),
          };
        }
      }
    });

    const styles = {
      structure,
      styles: Object.keys(structure),
    };

    items.forEach(({ key }) => {
      styles[`${key}Value`] = (structure[key] && structure[key].value) || null;
      styles[`${key}SafeValue`] = (structure[key] && structure[key].safeValue) || null;
    });

    if (this.checkDiffs(styles)) {
      this.setState({ styles }, () => {
        form.change(name, styles);
      });
    }
  }

  handleChange({ values }, innerForm) {
    const {
      input: { name },
    } = this.props;
    this.checkValues(values, name, innerForm);
    this.createStructure(values);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      placeholder,
      form,
      values,
      items,
      intl,
      safeSpacing,
      ...rest
    } = this.props;
    const { initialValues } = this.state;

    const spaceStyleValueTitle = intl.formatMessage({
      id: 'FieldSpacesStyle.spaceStyleValueTitle',
    });
    const spaceStyleSafeValueTitle = intl.formatMessage({
      id: 'FieldSpacesStyle.spaceStyleSafeValueTitle',
    });

    const { value, touched: inputTouched } = this.state;
    /* eslint-enable no-unused-vars */

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError =
      (inputTouched && errorText) || !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;

    const wrapperClasses = classNames(
      css.inputsWrapper
      // {
      //   [css.inputSuccess]: valid,
      //   [css.inputError]: hasError,
      // }
    );

    const inputHiddenProps = {
      // defaultValue,
      ...inputWithoutValue,
      ...rest,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {label && <label>{label}</label>}

        <FinalForm
          onSubmit={() => {}}
          initialValues={initialValues}
          render={({ form, values }) => {
            return (
              <div className={css.tableWrapper}>
                <FormSpy onChange={values => this.handleChange(values, form)} />

                <table className={css.table}>
                  <thead>
                    <tr className={css.tableRow}>
                      <th></th>
                      <th></th>
                      <th>{spaceStyleValueTitle}</th>
                      {safeSpacing && (
                        <th className={css.coloredBackground}>{spaceStyleSafeValueTitle}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, i) => (
                      <FieldRow
                        key={item.key}
                        item={item}
                        index={i}
                        name={input.name}
                        intl={intl}
                        form={form}
                        values={values}
                        safeSpacing={safeSpacing}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            );
          }}
        />

        <input value={value} {...inputHiddenProps} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldSpacesStyleComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
};

FieldSpacesStyleComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
  }).isRequired,
  meta: object.isRequired,
  form: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

class FieldSpacesStyle extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldSpacesStyleComponent} {...this.props} type="hidden" />;
  }
}

export default compose(injectIntl)(FieldSpacesStyle);
