import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import Switch from 'react-switch';
import { Field } from 'react-final-form';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { parseSelectFilterOptions } from '../../util/search';
import { FieldCheckbox, FieldWithIncrementDecrement } from '../../components';

import { FilterPopup, FilterPlain } from '../../components';
import css from './SelectMultipleManyFilters.module.css';

import covidLogo from '../../assets/logo_label_covid.svg';
import keepDistance from '../../assets/icon/keep-distance.svg';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options, intl } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox
                id={fieldId}
                name={name}
                label={intl.formatMessage({ id: `ConfigFilter.${option.key}` })}
                value={option.key}
                icon={option.icon}
              />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamNames, searchMode) => {
  const mode = searchMode ? `${searchMode}:` : '';

  const params = {};
  queryParamNames.map(i => {
    const paramName = i.split('_')[1];
    if (selectedOptions[paramName]) {
      if (paramName === 'roomStyle') {
        params[i] = `${mode}${selectedOptions[paramName].join(',')}`;
      } else if (
        paramName === 'maxAttendees' ||
        paramName === 'singleBedroomsCount' ||
        paramName === 'twinBedroomsCount' ||
        paramName === 'singleBedsCount' ||
        paramName === 'numberRooms'
      ) {
        params[i] = `${selectedOptions[paramName]},`;
      } else {
        params[i] = selectedOptions[paramName];
      }
    } else {
      params[i] = undefined;
    }
  });

  return params;
};

class SelectMultipleManyFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meetSafe: false,
      antiCovid: false,
      roomsCount: null,
    };
    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
  }

  componentDidMount() {
    const isMeetSafe = this.props.initialValues.pub_safeSpacing === 'true';
    const isantiCovid = this.props.initialValues.pub_antiCovid === 'true';

    const namedInitialValues = Object.fromEntries(
      Object.entries(this.props.initialValues).map(([key, value]) => [
        key.split('_')[1],
        key === 'pub_roomStyle' && value
          ? parseSelectFilterOptions(value)
          : key === 'pub_numberRooms' && value
          ? value.split(',')[0]
          : value,
      ])
    );

    this.setState({
      meetSafe: isMeetSafe,
      antiCovid: isantiCovid,
      roomsCount: namedInitialValues.numberRooms,
    });
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  increment() {
    this.setState({ roomsCount: this.state.roomsCount ? +this.state.roomsCount + 1 : 1 });
    this.props.changeResetClick();
  }

  decrement() {
    this.props.changeResetClick();
    if (+this.state.roomsCount < 1) {
      return;
    } else {
      this.setState({ roomsCount: +this.state.roomsCount - 1 });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      searchMode,
      intl,
      showAsPopup,
      showMap,
      isVenue,
      isResetClick,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const hasInitialValues =
      !!initialValues && Object.values(initialValues).filter(i => i !== undefined).length > 0;

    const filterSelectedOptions = hasInitialValues
      ? Object.fromEntries(
          Object.entries(initialValues).filter(([key, value]) => value !== undefined)
        )
      : null;

    let numberOfSelectedOptions = filterSelectedOptions
      ? Object.values(filterSelectedOptions).length
      : 0;

    // if (this.state.meetSafe) numberOfSelectedOptions += 1
    // if (this.state.antiCovid) numberOfSelectedOptions += 1;
    for (let key in filterSelectedOptions) {
      if (key === 'pub_roomStyle')
        // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
        numberOfSelectedOptions =
          numberOfSelectedOptions - 1 + parseSelectFilterOptions(filterSelectedOptions[key]).length;
    }

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: numberOfSelectedOptions }
        )
      : label;

    const labelForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { labelText: label, count: numberOfSelectedOptions }
        )
      : label;

    const meetSafeInfo = intl.formatMessage({ id: 'SelectMultipleFilterPlainForm.meetSafeInfo' });
    const antiCovidInfo = intl.formatMessage({ id: 'SelectMultipleFilterPlainForm.antiCovidInfo' });

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = Object.fromEntries(
      Object.entries(initialValues).map(([key, value]) => [
        key.split('_')[1],
        key === 'pub_roomStyle' && value
          ? parseSelectFilterOptions(value)
          : (key === 'pub_maxAttendees' && value) ||
            (key === 'pub_singleBedroomsCount' && value) ||
            (key === 'pub_twinBedroomsCount' && value) ||
            (key === 'pub_singleBedsCount' && value) ||
            (key === 'pub_numberRooms' && value)
          ? value.split(',')[0]
          : value,
      ])
    );

    const handleSubmit = (values, reset, cancel) => {
      const { meetSafe, antiCovid } = this.state;
      // const usedValue = values ? values[name] : values;
      const params = Object.assign({}, values);

      if (reset) {
        this.setState(
          {
            meetSafe: false,
            antiCovid: false,
            roomsCount: null,
          },
          () => {
            params.safeSpacing = this.state.meetSafe;
            params.antiCovid = this.state.antiCovid;
          }
        );
      } else if (cancel) {
        this.setState({
          meetSafe: params.safeSpacing,
          antiCovid: params.antiCovid,
          roomsCount: +params.numberRooms,
        });
      } else {
        params.safeSpacing = meetSafe;
        params.antiCovid = antiCovid;
      }

      onSubmit(format(params, queryParamNames, searchMode));
    };

    const resetInitValue = () => {
      this.setState({ roomsCount: null });
    };

    const Content = ({ form }) => (
      <>
        <div className={classNames(css.rowInput, css.bottomBorder)}>
          <p className={css.inputLabel}>
            <FormattedMessage id="SelectMultipleManyFilters.attendees" />
          </p>
          <Field
            id="maxAttendees"
            name="maxAttendees"
            className={css.inputField}
            component="input"
            type="number"
            placeholder="1"
            min={1}
            step={1}
            value={10}
          />
        </div>
        {isVenue ? (
          <div className={classNames(css.rowInputContainer, css.bottomBorder)}>
            <p className={classNames(css.inputLabel, css.labelWithPadding)}>
              <FormattedMessage id="SelectMultipleManyFilters.roomStyle" />
            </p>
            <GroupOfFieldCheckboxes
              className={css.fieldGroup}
              name={'roomStyle'}
              id={`${id}-checkbox-group`}
              options={options}
              intl={intl}
            />
          </div>
        ) : null}
        {isVenue ? (
          <div className={classNames(css.rowInputContainer, css.bottomBorder)}>
            <p className={classNames(css.inputLabel, css.labelWithPadding)}>
              <FormattedMessage id="SelectMultipleManyFilters.meetingRooms" />
            </p>
            <FieldWithIncrementDecrement
              id="numberRooms"
              name="numberRooms"
              className={css.inputFieldDecrementIncrement}
              placeholder="1"
              // min={1}
              step={1}
              disabled={true}
              initialValue={this.state.roomsCount}
              form={form}
              increment={this.increment}
              decrement={this.decrement}
              isResetClick={isResetClick}
              resetInitValue={() => resetInitValue()}
            />
          </div>
        ) : null}
        {isVenue ? (
          <div className={classNames(css.rowInputContainer, css.bottomBorder)}>
            <p className={css.inputLabel}>
              <FormattedMessage id="SelectMultipleManyFilters.bedrooms" />
            </p>
            <div className={css.inputContainer}>
              <div
                className={css.rowInput}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <p className={css.inputLabel}>
                  <FormattedMessage id="SelectMultipleManyFilters.simpleRoom" />
                </p>
                <Field
                  id="singleBedroomsCount"
                  name="singleBedroomsCount"
                  component="input"
                  className={css.inputField}
                  type="number"
                  placeholder="1"
                  min={1}
                />
              </div>
              <div
                className={css.rowInput}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <p className={css.inputLabel}>
                  <FormattedMessage id="SelectMultipleManyFilters.doubleRoom" />
                </p>
                <Field
                  id="twinBedroomsCount"
                  name="twinBedroomsCount"
                  component="input"
                  className={css.inputField}
                  type="number"
                  placeholder="1"
                  min={1}
                />
              </div>
              <div
                className={css.rowInput}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <p className={css.inputLabel}>
                  <FormattedMessage id="SelectMultipleManyFilters.singleBed" />
                </p>
                <Field
                  id="singleBedsCount"
                  name="singleBedsCount"
                  component="input"
                  className={css.inputField}
                  type="number"
                  placeholder="1"
                  min={1}
                />
              </div>
            </div>
          </div>
        ) : null}
      </>
    );

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <Content />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <Content />
      </FilterPlain>
    );
  }
}

SelectMultipleManyFilters.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleManyFilters.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleManyFilters);
