import pick from 'lodash/pick';
import { transactionLineItems } from '../../util/api';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';

import * as log from '../../util/log';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/AddExtraPage/SET_INITIAL_VALUES';

export const FETCH_LINE_ITEMS_REQUEST = 'app/AddExtraPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/AddExtraPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/AddExtraPage/FETCH_LINE_ITEMS_ERROR';

export const PARENT_LISTING_LOAD_REQUEST = 'app/AddExtraPage/PARENT_LISTING_LOAD_REQUEST';
export const PARENT_LISTING_LOAD_SUCCESS = 'app/AddExtraPage/PARENT_LISTING_LOAD_SUCCESS';
export const PARENT_LISTING_LOAD_ERROR = 'app/AddExtraPage/PARENT_LISTING_LOAD_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  extrasData: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  parentListingInProgress: false,
  parentListing: null,
  parentListingError: null,
};

export default function addExtraPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case PARENT_LISTING_LOAD_REQUEST:
      return { ...state, parentListingInProgress: true, parentListingError: null };
    case PARENT_LISTING_LOAD_SUCCESS:
      return { ...state, parentListingInProgress: false, parentListing: payload };
    case PARENT_LISTING_LOAD_ERROR:
      return { ...state, parentListingInProgress: false, parentListingError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const fetchParentListingRequest = () => ({ type: PARENT_LISTING_LOAD_REQUEST });
export const fetchParentListingSuccess = listing => ({
  type: PARENT_LISTING_LOAD_SUCCESS,
  payload: listing,
});
export const fetchParentListingError = error => ({
  type: PARENT_LISTING_LOAD_ERROR,
  error: true,
  payload: error,
});

/* ================ Thunks ================ */

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      return dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

export const loadListing = (id, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(fetchParentListingRequest());
  const params = {
    id: id,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      const listing = denormalisedResponseEntities(data);
      dispatch(fetchParentListingSuccess(listing[0]));
      return data;
    })
    .catch(e => {
      dispatch(fetchParentListingError(storableError(e)));
    });
};
