import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import Switch from 'react-switch';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, PrimaryButton, FieldSelect } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';

import keepDistance from '../../assets/icon/keep-distance.svg';
import { CustomQuoteRequestLink } from '../../components/CustomQuoteRequestLink/CustomQuoteRequestLink';

const { meetingStyles, coworkingStyles } = config.custom;

export class BookingTimeFormShuttleComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSafeSpacingClicked: false,
      attendeeMax: [],
      clearForm: false,
      isSetDuration: null,
      attendeeValue: null,
      attendeeError: null,
      isDurationLoading: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onAttendeeChange = this.onAttendeeChange.bind(this);
    this.onSafeSpacingChange = this.onSafeSpacingChange.bind(this);
    this.attendeeSet = this.attendeeSet.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
    this.durationLoading = this.durationLoading.bind(this);
  }

  componentDidMount() {
    if (this.props.listing) {
      const maxAttendee = this.props.listing.attributes.publicData.maxAttendees || 0;
      const maxAttendeeList = Array.from(new Array(maxAttendee)).map((n, i) => i + 1);

      this.setState({ attendeeMax: maxAttendeeList });
    }
  }

  attendeeSet() {
    const { listing } = this.props;

    const maxPeople = this.state.isSafeSpacingClicked
      ? listing.attributes.publicData.safeMaxAttendees
      : listing.attributes.publicData.maxAttendees;

    this.setState({
      attendeeMax: [...Array(maxPeople).keys()].map((i) => ++i),
    });
  }

  onChangeDuration(value) {
    this.setState({
      isSetDuration: value,
    });
  }

  durationLoading(value) {
    this.setState({
      isDurationLoading: value,
    });
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime, duration, pickUpLocation, destination } =
      formValues.values;
    const {
      priceScheme,
      listingId,
      isOwnListing,
      fetchLineItemsInProgress,
      onFetchTransactionLineItems,
    } = this.props;

    const vatRate = duration && priceScheme[duration].vatRate;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    if (
      bookingStartTime &&
      bookingEndTime &&
      duration &&
      pickUpLocation &&
      destination &&
      destination.selectedPlace &&
      !fetchLineItemsInProgress
    ) {
      onFetchTransactionLineItems({
        bookingData: { startDate, endDate, duration, vatRate, pickUpLocation, destination },
        listingId,
        isOwnListing,
      });
    }
  }

  onAttendeeChange = (value) => {
    this.setState({
      attendeeValue: value,
      attendeeError: null,
    });
  };

  onSafeSpacingChange = () => {
    const { attendeeValue, isSafeSpacingClicked } = this.state;
    const { listing, isSafeSpacing } = this.props;

    const maxPeople = !isSafeSpacingClicked
      ? listing.attributes.publicData.safeMaxAttendees
      : listing.attributes.publicData.maxAttendees;

    if (attendeeValue > maxPeople) {
      this.setState({
        attendeeError: maxPeople,
      });
    } else {
      this.setState(
        {
          isSafeSpacingClicked: !isSafeSpacingClicked,
          attendeeError: null,
        },
        () => this.attendeeSet()
      );
      isSafeSpacing();
    }
  };

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listingPrices,
      priceScheme,
      styles,
      structure,
      safeSpacing,
      listingCategory,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      if (listingCategory !== 'shuttle') {
        return (
          <div className={classes}>
            <p className={css.error}>
              <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
            </p>
          </div>
        );
      } else {
        return null;
      }
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={(fieldRenderProps) => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            listing,
          } = fieldRenderProps;

          if (this.state.clearForm) {
            form.restart();
            this.setState({
              clearForm: false,
            });
          }

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
          const destination = values && values.destination ? values.destination : null;
          const pickUpLocation = values && values.pickUpLocation ? values.pickUpLocation : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitleShuttle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const attendeeLabel = intl.formatMessage({ id: 'BookingTimeForm.attendeeLabel' });
          const placeholderAttendee = intl.formatMessage({
            id: 'BookingTimeForm.placeholderAttendee',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe =
            showEstimatedBreakdown && this.state.isSetDuration && !this.state.isDurationLoading ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>
                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={lineItems}
                  isPerSession={this.state.isSetDuration !== 'perHour'}
                  listingCategory={listingCategory}
                />
              </div>
            ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={(values) => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  listingPrices={listingPrices}
                  priceScheme={priceScheme}
                  durationLoading={this.durationLoading}
                  onChangeDuration={this.onChangeDuration}
                  listingCategory={listingCategory}
                  clearForm={this.state.clearForm}
                />
              ) : null}
              <div className={css.formRow}>
                <FieldSelect
                  name="seats"
                  id="seats"
                  // defaultValue={1}
                  className={css.fieldSelect}
                  selectClassName={css.select}
                  label={attendeeLabel}
                  disabled={!startDate}
                  onChange={this.onAttendeeChange}
                >
                  <option disabled value="">
                    {placeholderAttendee}
                  </option>
                  {this.state.attendeeMax.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>

              {safeSpacing ? (
                <div className={css.safeSpacingSwitchHolder}>
                  <Switch
                    className={css.safeSpacingSwitch}
                    onChange={this.onSafeSpacingChange}
                    checked={this.state.isSafeSpacingClicked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="inset 0px 4px 8px rgba(0, 0, 0, 0.1)"
                    activeBoxShadow=""
                    offColor="#E6E6E6"
                    onColor="#48C39C"
                    handleDiameter={20}
                    width={40}
                    height={24}
                  />
                  <p className={css.meetSafeContainer}>
                    <FormattedMessage id="BookingDatesForm.meetSafe" />
                  </p>
                  <div>
                    <img src={keepDistance} alt="keep distance" />
                  </div>
                </div>
              ) : null}

              {this.state.attendeeError ? (
                <p className={css.error}>
                  <FormattedMessage
                    id={'BookingTimeForm.attendeeError'}
                    values={{ maxAttendee: this.state.attendeeError }}
                  />
                </p>
              ) : null}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={!destination || !pickUpLocation}>
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
              <CustomQuoteRequestLink />
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormShuttleComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormShuttleComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeFormShuttle = compose(injectIntl)(BookingTimeFormShuttleComponent);
BookingTimeFormShuttle.displayName = 'BookingTimeForm';

export default BookingTimeFormShuttle;
