import React from 'react';
import { object, string, filterConfig } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { NamedLink, Button } from '..';
import config from '../../config';
import { duplicateListingHelper } from '../../helpers/duplicateListingHelper';
import css from './EditCateringMenusPanel.module.css';
import noImage from './noimage.png';

const MenusCard = (props) => {
  const {
    className,
    rootClassName,
    listing,
    mobileLayout,
    filterConfig,
    onCreateDuplicateListing,
    listingsNames,
    parent,
    removeClick,
    isLoading,
    isLastSpace,
  } = props;

  const classes = classNames(rootClassName || css.rootSpacescard, className);
  const id = listing.id.uuid;
  const {
    state,
    publicData,
    title,
    availabilityPlan,
    description,
    geolocation,
    metadata,
    price,
    privateData,
  } = listing.attributes;
  const { category, sizes, styles, pubAmenities } = publicData;
  const slug = createSlug(title);
  const image = listing.images[0] && listing.images[0].attributes.variants['landscape-crop2x'].url;
  const naturalLight = pubAmenities && pubAmenities.naturalLight;
  const timezone = availabilityPlan && availabilityPlan.timezone;
  const isDraft = state === LISTING_STATE_DRAFT;
  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const duplicateListingClick = () => {
    const copyListing = duplicateListingHelper(listing, listingsNames);
    onCreateDuplicateListing(copyListing, id);
  };

  const removeListingClick = () => {
    const newTitle = `${title} (delete)`;
    const updateValues = {
      title: newTitle,
      parent: parent,
      timezone: timezone,
    };
    removeClick({ id, updateValues, isLastSpace });
  };

  return (
    <div className={classes}>
      <div className={css.imageContainer}>
        <img src={image ? image : noImage} alt="Listing image" className={css.listingImage} />
        <div className={css.createContainer}>
          <NamedLink
            className={css.buttonEditMobile}
            name="EditServicesPage"
            params={{ id, slug, type: editListingLinkType, tab: 'description', category }}
          >
            <FormattedMessage id="SpaceCard.modify" />
          </NamedLink>
          <Button className={css.duplicateButtonMobile} onClick={duplicateListingClick}>
            <FormattedMessage id="SpaceCard.duplicate" />
          </Button>
          <Button className={css.removeButtonMobile} onClick={removeListingClick}>
            <FormattedMessage id="ManageListingCard.remove" />
          </Button>
        </div>
      </div>

      <div className={css.sizeContainer}>
        <h3 className={css.listingTitle}>{title}</h3>
      </div>

      <div className={css.createContainer}>
        <NamedLink
          className={css.buttonEdit}
          name="EditServicesPage"
          params={{ id, slug, type: editListingLinkType, tab: 'description', category }}
        >
          <FormattedMessage id="SpaceCard.modify" />
        </NamedLink>
        <Button className={css.duplicateButton} onClick={duplicateListingClick}>
          <FormattedMessage id="SpaceCard.duplicate" />
        </Button>
        <Button className={css.removeButton} onClick={removeListingClick}>
          <FormattedMessage id="ManageListingCard.remove" />
        </Button>
      </div>
    </div>
  );
};

MenusCard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  filterConfig: config.custom.filters,
};

MenusCard.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,
  filterConfig: filterConfig,
};

export default MenusCard;
