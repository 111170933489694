/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../util/types';
import readMoreIcon from '../../assets/down-chevron.svg';
import readLessIcon from '../../assets/up-chevron.svg';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemUnitPriceMaybe from './LineItemUnitPriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './BookingBreakdown.module.css';
import LineItemYounaFeesMaybe from './LineItemYounaFeesMaybe';
import LineItemTotalVatMaybe from './LineItemTotalVatMaybe';
import { storedData } from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';

const STORAGE_KEY = 'CheckoutPage';

export const BookingBreakdownComponent = (props) => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    timeZone,
    isPerSession,
    isShowMore,
    isMobileLayout,
    clickShowMore,
    showMore,
    isPerPerson,
    useNewStyle,
    showExtraServicesAndBedrooms,
    bookingBreakdownOpen,
    onOpenBookingBreakdown,
    onCloseBookingBreakdown,
    selectedExtrasCheckoutInfo,
    selectedBedroomsFromForm,
    selectedExtraServicesFromForm,
    selectedExtrasFromForm,
    durationLabel,
    durationHours,
    listingCategory,
    standartPrice,
    bookingData,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find((item) => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className, {
    [css.useNewStyle]: useNewStyle,
  });

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  const showInfo = !isShowMore || showMore || isMobileLayout;

  const showMoreText = intl.formatMessage({
    id: 'BookingBreakdown.showMore',
  });
  const showLessText = intl.formatMessage({
    id: 'BookingBreakdown.showLess',
  });

  return (
    <div className={classes}>
      {showInfo ? (
        <>
          <h3 className={css.lineItemTitle}>
            <FormattedMessage
              id={
                listingCategory
                  ? `BookingBreakdown.title.${listingCategory}`
                  : 'BookingBreakdown.title'
              }
            />
          </h3>
          <LineItemBookingPeriod
            booking={booking}
            unitType={unitType}
            dateType={dateType}
            timeZone={timeZone}
            useNewStyle={useNewStyle}
          />
          <LineItemUnitPriceMaybe
            transaction={transaction}
            unitType={unitType}
            intl={intl}
            isPerSession={isPerSession}
            isPerPerson={isPerPerson}
            durationHours={durationHours}
            standartPrice={standartPrice}
          />
          <LineItemBasePriceMaybe
            transaction={transaction}
            unitType={unitType}
            intl={intl}
            isPerSession={isPerSession}
            isPerPerson={isPerPerson}
            standartPrice={standartPrice}
          />
          {bookingBreakdownOpen === false ? (
            <>
              {isCustomer && (
                <div className={css.totalPriceHolder}>
                  <LineItemTotalPrice
                    transaction={transaction}
                    isProvider={isProvider}
                    intl={intl}
                  />
                </div>
              )}
              <p onClick={onOpenBookingBreakdown} className={css.seeMore}>
                <FormattedMessage id="BookingBreakdown.seeMore" />
                <img src={readMoreIcon} alt="readMoreIcon" className={css.readMoreIcon} />
              </p>
            </>
          ) : null}
          {bookingBreakdownOpen !== false && (
            <div>
              <LineItemUnknownItemsMaybe
                transaction={transaction}
                isProvider={isProvider}
                intl={intl}
                showExtraServicesAndBedrooms={showExtraServicesAndBedrooms}
                selectedExtrasCheckoutInfo={selectedExtrasCheckoutInfo}
                selectedBedroomsFromForm={selectedBedroomsFromForm || bookingData?.bedrooms}
                selectedExtraServicesFromForm={
                  selectedExtraServicesFromForm || bookingData?.extraServices
                }
                selectedExtrasFromForm={selectedExtrasFromForm}
                durationLabel={durationLabel}
              />

              <div className={css.lineItemGroup}>
                <LineItemRefundMaybe transaction={transaction} intl={intl} />

                <LineItemCustomerCommissionMaybe
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                />
                <LineItemCustomerCommissionRefundMaybe
                  transaction={transaction}
                  isCustomer={isCustomer}
                  intl={intl}
                />

                <LineItemProviderCommissionRefundMaybe
                  transaction={transaction}
                  isProvider={isProvider}
                  intl={intl}
                />
              </div>

              <div className={css.lineItemGroup}>
                <LineItemSubTotalMaybe
                  transaction={transaction}
                  unitType={unitType}
                  userRole={userRole}
                  intl={intl}
                />
                {isCustomer && <LineItemTotalVatMaybe transaction={transaction} intl={intl} />}
              </div>
            </div>
          )}
        </>
      ) : null}

      {bookingBreakdownOpen !== false && (
        <>
          {isCustomer && (
            <LineItemTotalPrice transaction={transaction} isProvider={isProvider} intl={intl} />
          )}

          {onOpenBookingBreakdown && showExtraServicesAndBedrooms && (
            <p onClick={onCloseBookingBreakdown} className={css.seeLess}>
              <FormattedMessage id="BookingBreakdown.seeLess" />
              <img src={readMoreIcon} alt="readMoreIcon" className={css.readMoreIcon} />
            </p>
          )}
        </>
      )}

      {isShowMore && !isMobileLayout ? (
        <div className={css.showMore} onClick={clickShowMore}>
          {showInfo ? showLessText : showMoreText}
          <img
            src={showMore ? readLessIcon : readMoreIcon}
            alt="readMoreIcon"
            className={css.readMoreIcon}
          />
        </div>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  timeZone: null,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
