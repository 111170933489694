import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_YOUNA_FEES, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const LineItemYounaFeesMaybe = props => {
  const { transaction, isCustomer, intl } = props;

  const customerYounaFeesLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_YOUNA_FEES && !item.reversal
  );

  console.log('customerYounaFeesLineItem', customerYounaFeesLineItem);

  // If commission is passed it will be shown as a fee already reduces from the total price
  let feesItem = null;

  if (isCustomer && customerYounaFeesLineItem) {
    if (!isValidCommission(customerYounaFeesLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', customerYounaFeesLineItem);
      throw new Error('Commission should be present and the value should be zero or positive');
    }

    const fees = customerYounaFeesLineItem.lineTotal;
    const formattedFees = fees ? formatMoney(intl, fees) : null;

    feesItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="BookingBreakdown.commission" />{' '}
          {customerYounaFeesLineItem.percentage.toString()}%
          <div
            style={{
              fontSize: '.6rem',
              fontStyle: 'italic',
              opacity: 0.5,
              lineHeight: 1,
              marginTop: '.35em',
            }}
          >
            * Ces frais de service nous aident à faire vivre le site et vous offrir le meilleur
            service possible !
          </div>
        </span>
        <span className={css.itemValue}>{formattedFees}</span>
      </div>
    );
  }

  return feesItem;
};

LineItemYounaFeesMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemYounaFeesMaybe;
