import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import css from './FieldWithIncrementDecrement.module.css';

function FieldWithIncrementDecrement(props, ref) {
  const {
    input,
    initialValue,
    form,
    decrement,
    increment,
    isResetClick,
    resetInitValue,
    ...rest
  } = props;

  const [count, setCount] = useState();

  useEffect(() => {
    const numberRooms = form.getState().values.numberRooms;
    if (initialValue && +numberRooms !== +initialValue) {
      if (isResetClick) {
        resetInitValue();
        setCount();
      } else {
        form.batch(() => {
          form.change(props.name, +initialValue);
        });
        setCount(+initialValue);
      }
    }

    return () => {
      if (props.onUnmount) {
        props.onUnmount();
      }
    };
  }, [initialValue]);

  return (
    <div className={css.container}>
      <button className={css.decrement} onClick={decrement} type="button" />
      <Field
        key={count}
        defaultValue={count}
        value={count}
        component="input"
        type="number"
        {...rest}
      />
      <button className={css.increment} onClick={increment} type="button" />
    </div>
  );
}
export default FieldWithIncrementDecrement;
