import React from 'react';
import classNames from 'classnames';
import { Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import config from '../../config';

import css from './MultiLanguageSelect.module.css';

import enFlagIcon from './en.svg';
import frFlagIcon from './fr.svg';

const MultiLanguageSelect = () => {
  const changeLanguage = locale => {
    if (config.locale === locale) {
      return;
    }

    language.set(locale);
  };

  let currentLocale = config.locale;

  return (
    <Menu className={classNames(css.root)}>
      <MenuLabel className={css.languageButton}>
        {currentLocale === 'fr' ? (
          <img src={frFlagIcon} className={css.flagImg} />
        ) : (
          <img src={enFlagIcon} className={css.flagImg} />
        )}
      </MenuLabel>
      <MenuContent className={css.languagesOptions} useDefaultStyles={false}>
        <MenuItem key="en">
          <div className={css.languageOption} onClick={() => changeLanguage('en')}>
            <img src={enFlagIcon} className={css.flagImg} />
          </div>
        </MenuItem>
        <MenuItem key="fr">
          <div className={css.languageOption} onClick={() => changeLanguage('fr')}>
            <img src={frFlagIcon} className={css.flagImg} />
          </div>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

export default MultiLanguageSelect;
