import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ListOfSomething } from '..';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import getVideoId from 'get-video-id';

import css from './FieldVideos.module.css';

const videoIdReplaceToken = '{{VIDEOID}}';

const serviceBase = {
  youtube: `https://www.youtube.com/watch?v=${videoIdReplaceToken}`,
  vimeo: `https://vimeo.com/${videoIdReplaceToken}`,
};

const ENTER_KEYCODE = 13;

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      meta: {},
    };
  }

  validateVideo(data) {
    const urlFormatRequiredMessage = this.props.intl.formatMessage({
      id: 'FieldVideos.validationMessage',
    });

    const error =
      !data || !data.id || !config.allowedVideoServices.includes(data.service)
        ? urlFormatRequiredMessage
        : null;
    const meta = error ? { touched: true, error } : {};

    this.setState({ meta });
    return !!error;
  }

  handleChange(value) {
    this.setState({
      value,
      meta: {},
    });
  }

  extractVideo(link) {
    const result = getVideoId(link);
    return result.id && !result.service ? result : null;
  }

  addVideoLink() {
    const { onAddVideo } = this.props;
    const { value } = this.state;

    const result = getVideoId(value);

    if (this.validateVideo(result)) {
      return;
    }

    const { id, service } = result;

    onAddVideo(serviceBase[service].replace(videoIdReplaceToken, id));
    this.setState({ value: '' });
  }

  onKeyDown(e) {
    if (e.which == ENTER_KEYCODE) {
      e.preventDefault();
      e.stopPropagation();

      if (e.target.value) {
        this.addVideoLink();
      }
    }
  }

  handleDelete(data) {
    this.props.onDeleteVideo(data);
  }

  render() {
    const { intl, disabled, showButton, input, id, fieldMeta } = this.props;

    const { value, meta } = this.state;

    const addFormPlaceholder = intl.formatMessage({ id: 'FieldVideos.youtubeAddPlaceholder' });
    const addFormButtonTitle = intl.formatMessage({ id: 'FieldVideos.youtubeAddButtonTitle' });
    const listButtonTitle = intl.formatMessage({ id: 'FieldVideos.youtubeListButtonTitle' });
    const listTitle = intl.formatMessage({ id: 'FieldVideos.youtubeListTitle' });

    const form = (
      <div>
        <div className={css.addField}>
          <input
            id={id}
            className={css.mediaBlockInput}
            type="text"
            // placeholder={addFormPlaceholder}
            onChange={e => this.handleChange(e.target.value)}
            onKeyDown={e => this.onKeyDown(e)}
            value={value}
            disabled={disabled}
          />
          {showButton && (
            <button
              className={css.mediaBlockButtonWrapper}
              type="button"
              onClick={() => this.addVideoLink()}
            >
              {addFormButtonTitle}
            </button>
          )}
        </div>
        <small className={css.infoTip}>
          <FormattedMessage id="FieldVideos.videoTip" />
        </small>
      </div>
    );

    const videos = (input && input.value) || [];

    return (
      <div className={css.videoWrapper}>
        <label htmlFor={id} className={css.mediaLabel}>
          {listTitle}
          <span className={css.optionalText}>
            <FormattedMessage id="FieldVideos.optionalFieldText" />
          </span>
        </label>

        {input && input.value.length === 0 ? (
          <>
            {form}
            <ValidationError fieldMeta={{ ...fieldMeta, ...meta }} />
          </>
        ) : (
          <ListOfSomething
            items={videos}
            renderContent={(index, value) => (
              <a href={value} className={css.videoLink} target="_blank">
                {value}
              </a>
            )}
            onDelete={index => this.handleDelete(index)}
          />
        )}
      </div>
    );
  }
}

class FieldVideosComponent extends Component {
  constructor(props) {
    super(props);

    this.addVideo = this.addVideo.bind(this);
    this.removeVideo = this.removeVideo.bind(this);
  }

  addVideo(video) {
    const {
      input: { value, name },
      form,
    } = this.props;
    form.change(name, [...value, video]);
  }

  removeVideo(index) {
    const {
      input: { value, name },
      form,
    } = this.props;
    form.change(name, value.filter((e, i) => i !== index));
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      showButton,
      intl,
      ...rest
    } = this.props;
    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // const inputClasses =
    //   inputRootClass ||
    //   classNames(css.input, {
    //     [css.inputSuccess]: valid,
    //     [css.inputError]: hasError,
    //   });

    const inputProps = { ...input, ...rest };
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <Videos
          id={id}
          input={input}
          onAddVideo={this.addVideo}
          onDeleteVideo={this.removeVideo}
          showButton={showButton}
          intl={intl}
          fieldMeta={fieldMeta}
        />
        <input {...inputProps} />
      </div>
    );
  }
}

FieldVideosComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldVideosComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldVideos extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldVideosComponent} {...this.props} type="hidden" />;
  }
}

export default injectIntl(FieldVideos);
