import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAlert.module.css';

const IconAlert = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#474445"
        fillRule="evenodd"
        d="M8 1.75a6.25 6.25 0 100 12.5 6.25 6.25 0 000-12.5zM.25 8a7.75 7.75 0 1115.5 0A7.75 7.75 0 01.25 8zM8 3.75a.75.75 0 01.75.75V9a.75.75 0 01-1.5 0V4.5A.75.75 0 018 3.75zM8 13a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

IconAlert.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconAlert.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconAlert;
