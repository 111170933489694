import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import moment from 'moment';
import css from './BookingBreakdown.module.css';

const getTranslationKey = (isPerSession, isPerPerson, duration) => {
  if (isPerPerson && duration === 'perHour') return 'BookingBreakdown.pricePerHourPerPerson';
  if (isPerSession && isPerPerson) return 'BookingBreakdown.pricePerSessionPerPerson';
  if (isPerSession && !isPerPerson) return 'BookingBreakdown.priceAllFixed';
  if (!isPerSession && isPerPerson) return 'BookingBreakdown.pricePerSessionPerPerson';
  if (!isPerSession && !isPerPerson) return 'BookingBreakdown.pricePerHourPerPackage';
};

const { Money } = sdkTypes;

const LineItemBasePriceMaybe = (props) => {
  const { transaction, unitType, intl, isPerSession, isPerPerson, standartPrice } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const duration =
    transaction && transaction.attributes.metadata && transaction.attributes.metadata.duration;

  const translationKey = getTranslationKey(isPerSession, isPerPerson, duration);

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    (item) => item.code === unitType && !item.reversal
  );

  const seats = unitPurchase && unitPurchase.seats && parseInt(unitPurchase.seats);
  const units = unitPurchase && unitPurchase.units && parseInt(unitPurchase.units);
  const quantityResult = seats && units ? seats * units : null;
  const { start, end } = transaction && transaction.booking && transaction.booking.attributes;
  const hours = moment(end).diff(moment(start), 'h');

  const quantity = quantityResult
    ? quantityResult
    : unitPurchase && unitPurchase.quantity
      ? unitPurchase.quantity.toString()
      : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const formattedStandartPrice =
    standartPrice && formatMoney(intl, new Money(standartPrice.amount, standartPrice.currency));

  return quantity && total && translationKey !== 'BookingBreakdown.priceAllFixed' ? (
    <div className={css.lineItem}>
      {formattedStandartPrice && (
        <span className={css.standartPrice}>{formattedStandartPrice}</span>
      )}
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, seats, units: hours }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
