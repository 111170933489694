import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ReviewRating } from '../../components';

import css from './SectionComments.module.css';

import women from './images/women.jpg';

const SectionComments = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.commentContainer}>
        <div className={css.main}>
          <div className={css.header}>
            <h2 className={css.title}>
              <FormattedMessage id="SectionComments.title" />
            </h2>
            {/* <div className={css.subtitle}>
              <FormattedMessage id="SectionComments.subtitle" />
            </div> */}
          </div>
          <div className={css.commentText}>
            <FormattedMessage id="SectionComments.comment" />
          </div>
          <div className={css.stars}>
            <ReviewRating rating={4} reviewStarClassName={css.star} />
          </div>
        </div>
        <div className={css.author}>
          <div className={css.authorAvatar}>
            <img src={women} alt="avatar" />
          </div>
          <div className={css.authorTitle}>
            <FormattedMessage id="SectionComments.authorTitle" />
          </div>
          <div className={css.authorSubtitle}>
            <FormattedMessage id="SectionComments.authorSubtitle" />
          </div>
        </div>
      </div>
    </div>
  );
};

SectionComments.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionComments.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionComments;
