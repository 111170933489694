import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  validSizes,
  validStyles,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldRadioButton,
  FieldBoolean,
  FieldSizes,
  FieldSpacesStyle,
} from '../../components';
import config from '../../config';

import css from './EditSpaceDescriptionForm.module.css';

import banquetIcon from '../../assets/icon/banquet.svg';
import boardroomIcon from '../../assets/icon/boardroom.svg';
import classroomIcon from '../../assets/icon/classroom.svg';
import receptionIcon from '../../assets/icon/reception.svg';
import theatreIcon from '../../assets/icon/theatre.svg';
import amphitheaterIcon from '../../assets/icon/amphitheater.svg';
import ushapeIcon from '../../assets/icon/ushape.svg';
import coworkingIcon from '../../assets/icon/coworking.svg';

const TITLE_MAX_LENGTH = 60;

const identity = v => v;

const { meetingStyles, coworkingStyles } = config.custom;

const styles = { meetings: meetingStyles, coworking: coworkingStyles };
const icons = {
  meetings: [
    boardroomIcon,
    classroomIcon,
    banquetIcon,
    theatreIcon,
    amphitheaterIcon,
    ushapeIcon,
    receptionIcon,
  ],
  coworking: [coworkingIcon],
};

const getStyles = values => {
  const { spaceType } = values;

  return styles[spaceType].map((item, i) => ({
    ...item,
    icon: icons[spaceType][i],
  }));
};

const EditSpaceDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    initialValues={props.initialValues}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        isNew,
        errors,
      } = formRenderProps;

      const useSafeSpacing = values.safeSpacing && !props.isCoworking;

      const coworkingLabel = intl.formatMessage({ id: 'EditSpaceDescriptionForm.coworkingLabel' });
      const meetingsLabel = intl.formatMessage({ id: 'EditSpaceDescriptionForm.meetingsLabel' });

      const titleMessage = intl.formatMessage({ id: 'EditSpaceDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditSpaceDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const safeSpacingMessage = intl.formatMessage({ id: 'EditSpaceDescriptionForm.safeSpacing' });
      const safeSpacingPlaceholderMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.safeSpacingPlaceholder',
      });
      const safeSpacingRequiredMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.safeSpacingRequired',
      });

      const spaceStyleTitle = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.spaceStyleTitle',
      });
      const spaceStyleRequiredMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.spaceStyleRequiredMessage',
      });

      const sizesLabel = intl.formatMessage({ id: 'EditSpaceDescriptionForm.sizesLabel' });
      const requiredSizesMessage = intl.formatMessage({
        id: 'EditSpaceDescriptionForm.requiredSizes',
      });
      const validSizesMessage = intl.formatMessage({ id: 'EditSpaceDescriptionForm.validSizes' });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditSpaceDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditSpaceDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditSpaceDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const showAsRequired = pristine;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <h3 className={css.label}>
            <FormattedMessage id="EditSpaceDescriptionForm.titleType" />
          </h3>

          <div className={css.radioButtonRow}>
            <FieldRadioButton
              className={css.radio}
              id="meetings"
              name="spaceType"
              label={meetingsLabel}
              value="meetings"
              showAsRequired={showAsRequired}
              disabled={!isNew}
            />
            <FieldRadioButton
              className={css.radio}
              id="coworking"
              name="spaceType"
              label={coworkingLabel}
              value="coworking"
              showAsRequired={showAsRequired}
              disabled={!isNew}
            />
          </div>

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldSizes
            id="sizes"
            name="sizes"
            form={form}
            className={css.field}
            label={sizesLabel}
            // validate={composeValidators(
            //   required(requiredSizesMessage),
            //   validSizes(validSizesMessage)
            // )}
          />

          {!props.isCoworking ? (
            <FieldBoolean
              id="safeSpacing"
              name="safeSpacing"
              className={css.field}
              label={safeSpacingMessage}
              placeholder={safeSpacingPlaceholderMessage}
              // validate={composeValidators(required(safeSpacingRequiredMessage))}
            />
          ) : null}

          <FieldSpacesStyle
            key={values.spaceType}
            form={form}
            values={props.initialValues}
            name="style"
            label={spaceStyleTitle}
            items={getStyles(values)}
            safeSpacing={useSafeSpacing}
            validate={validStyles(spaceStyleRequiredMessage)}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditSpaceDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditSpaceDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditSpaceDescriptionFormComponent);
