import React, { useState, useEffect } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, Button, Modal } from '..';
import MenusCard from './MenusCard';
import css from './EditCateringMenusPanel.module.css';

const EditCateringMenusPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    fetchChildListings,
    childListings,
    onCreateDuplicateListing,
    onRemoveListing,
    removeListingLoading,
    removeListingResult,
    onManageDisableScrolling,
    history,
  } = props;

  const parent = listing.id && listing.id.uuid;
  const isLastMenu = childListings.length === 1;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeParams, setRemoveParams] = useState(null);

  useEffect(() => {
    if (parent && fetchChildListings) {
      const params = {
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          // Listing page
          'variants.landscape-crop',
          'variants.landscape-crop2x',
        ],
        pub_category: 'menus',
        pub_parent: parent,
      };
      fetchChildListings(params);
    }
  }, []);

  useEffect(() => {
    if (!removeListingLoading && removeListingResult) {
      setIsModalOpen(false);
      setRemoveParams(null);
      if (childListings.length < 1) {
        setTimeout(() => {
          history.push('/services');
        }, 300);
      }
    }
  }, [removeListingLoading, removeListingResult]);

  const classes = classNames(rootClassName || css.root, className);
  const hasMenus = !!(childListings && childListings.length);

  const listingsNames = hasMenus ? childListings.map((i) => i.attributes.title) : [];

  const removeClick = (params) => {
    setIsModalOpen(true);
    setRemoveParams(params);
  };

  const removeListing = () => {
    onRemoveListing(removeParams);
  };

  const renderListings = () => {
    return (
      <div className={css.spaceCardContainer}>
        {childListings.map((listing) => {
          return (
            <MenusCard
              listing={listing}
              key={listing.id.uuid}
              onCreateDuplicateListing={onCreateDuplicateListing}
              listingsNames={listingsNames}
              parent={parent}
              removeClick={removeClick}
              isLastMenu={isLastMenu}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage id="EditCateringMenusPanel.title" />
      </h1>
      {!hasMenus && (
        <div className={css.emptyMessage}>
          <h1 className={css.emptyMessageText}>
            <FormattedMessage id="EditCateringMenusPanel.titleEmpty" />
          </h1>
        </div>
      )}
      {hasMenus && renderListings()}
      <div className={hasMenus ? css.createContainer : css.createContainerCenter}>
        <NamedLink
          name="CreateChildServicePage"
          className={css.buttonCreate}
          params={{ parent, category: 'menus' }}
        >
          <FormattedMessage id="EditCateringMenusPanel.buttonCreate" />
        </NamedLink>
      </div>
      <Button
        className={css.submitButtonNext}
        onClick={() => props.onSubmit({})}
        disabled={!hasMenus}
      >
        <FormattedMessage id="EditCateringMenusPanel.nextExtrasServices" />
      </Button>
      {onManageDisableScrolling ? (
        <Modal
          id="ServicesPageModal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div>
            <div className={css.modalTitle}>
              {isLastMenu ? (
                <FormattedMessage id="EditCateringMenusPanel.modalTitleLastSpace" />
              ) : (
                <FormattedMessage id="EditCateringMenusPanel.modalTitle" />
              )}
            </div>
            <div className={css.modalButtonsWrapper}>
              <div
                onClick={() => setIsModalOpen(false)}
                className={css.cancelListingDelete}
                tabIndex="0"
              >
                <FormattedMessage id="EditCateringMenusPanel.cancel" />
              </div>
              <Button onClick={removeListing} inProgress={removeListingLoading}>
                <FormattedMessage id="EditCateringMenusPanel.removeListing" />
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

EditCateringMenusPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditCateringMenusPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  childListings: array,
  fetchChildListings: func.isRequired,
};

export default EditCateringMenusPanel;
