import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditMenuDescriptionForm } from '../../forms';
import { updateAvailabilityPlan } from '../../helpers/availabilityPlanHelper';

import css from './EditMenuDescriptionPanel.module.css';

const EditMenuDescriptionPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const isNew = params.type === 'new';

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData, title } = currentListing.attributes;
    const { mealType = 'buffet', sizes, minAttendees, maxAttendees, menuItems } = publicData || {};

    return {
      title,
      mealType,
      sizes,
      minAttendees: minAttendees || 1,
      maxAttendees: maxAttendees || 1,
      menuItems,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isAvailabilityPlan = currentListing && currentListing.attributes.availabilityPlan;
  const priceScheme = currentListing && currentListing.attributes.publicData.priceScheme;
  const mealTypeData = currentListing && currentListing.attributes.publicData.mealType;
  const isCoworking = mealTypeData === 'coworking';

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditMenuDescriptionPanel.title" />
  ) : (
    <FormattedMessage id="EditMenuDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditMenuDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        isNew={isNew}
        isCoworking={isCoworking}
        onSubmit={(values) => {
          const { category } = params;
          const { title, mealType, sizes, safeSpacing, minAttendees, maxAttendees, menuItems } =
            values;
          const seatsNumber = maxAttendees;

          const updateValues = {
            title: title.trim(),
            publicData: {
              ...(isNew ? { category } : {}),
              mealType,
              sizes,
              showForSearch: false,
              minAttendees,
              maxAttendees,
              menuItems,
            },
          };

          if (isAvailabilityPlan && seatsNumber && priceScheme) {
            updateValues.availabilityPlan = updateAvailabilityPlan(
              { priceScheme },
              currentListing,
              seatsNumber
            );
          }

          setInitialValues({
            title,
            mealType,
            sizes,
            minAttendees,
            maxAttendees,
            menuItems,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditMenuDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditMenuDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditMenuDescriptionPanel;
