import React, { Component } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { bool, object } from 'prop-types';
import { FieldVideos } from '../../components';

class ListingVideo extends Component {
  render() {
    const { form, disabled, showButton } = this.props;

    return (
      <FieldVideos
        form={form}
        disabled={disabled}
        name={'videos'}
        id={'videos'}
        showButton={showButton}
      />
    );
  }
}

ListingVideo.propTypes = {
  form: object,
  disabled: bool,
};

export default injectIntl(ListingVideo);
