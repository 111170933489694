import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import Switch from 'react-switch';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, PrimaryButton, FieldSelect } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';

import keepDistance from '../../assets/icon/keep-distance.svg';
import { shouldDisplayCorporatePrices } from '../../helpers/utils';
import { CustomQuoteRequestLink } from '../../components/CustomQuoteRequestLink/CustomQuoteRequestLink';

const { meetingStyles, coworkingStyles } = config.custom;
export class BookingTimeFormVenueComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSafeSpacingClicked: false,
      roomStyle: null,
      attendeeMax: [],
      clearForm: false,
      isSetDuration: null,
      attendeeValue: null,
      attendeeError: null,
      isDurationLoading: false,
      selectedSlot: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    // this.onTypeChange = this.onTypeChange.bind(this);
    this.onStyleChange = this.onStyleChange.bind(this);
    this.onAttendeeChange = this.onAttendeeChange.bind(this);
    this.onSafeSpacingChange = this.onSafeSpacingChange.bind(this);
    this.attendeeSet = this.attendeeSet.bind(this);
    this.durationLoading = this.durationLoading.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
    this.onSelectedTimeSlot = this.onSelectedTimeSlot.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.listingId !== this.props.listingId) {
      this.setState({
        isSafeSpacingClicked: false,
        roomStyle: null,
        attendeeMax: [],
        isSetDuration: null,
        attendeeValue: null,
        attendeeError: null,
        clearForm: true,
        isDurationLoading: false,
        selectedSlot: null,
      });
    }

    if (this.props.spaceType === 'coworking' && this.state.roomStyle !== 'coworking') {
      this.setState({
        roomStyle: 'coworking',
      });
    }

    if (prevState.selectedSlot !== this.state.selectedSlot) {
      this.attendeeSet();
      this.onAttendeeChange('reset');
    }
  }

  attendeeSet() {
    const { roomStyle } = this.state;
    const { structure, spaceType } = this.props;

    const availableSeats = this.state.selectedSlot && this.state.selectedSlot.attributes.seats;

    const maxPeople = roomStyle
      ? this.state.isSafeSpacingClicked
        ? structure[roomStyle].safeValue
        : structure[roomStyle].value
      : 1;

    const seats = spaceType === 'coworking' ? availableSeats : maxPeople;

    this.setState({
      roomStyle: roomStyle,
      attendeeMax: [...Array(seats).keys()].map((i) => ++i),
    });
  }

  onChangeDuration(value) {
    this.setState({
      isSetDuration: value,
    });
  }

  durationLoading(value) {
    this.setState({
      isDurationLoading: value,
    });
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { values } = formValues;
    const { bookingStartTime, bookingEndTime, duration, seats } = values;

    const {
      vatRate,
      fetchLineItemsInProgress,
      listingId,
      isOwnListing,
      onFetchTransactionLineItems,
      spaceType,
      currentUser,
    } = this.props;

    const useCorporatePrice = shouldDisplayCorporatePrices(currentUser);

    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const bookingData = {
      startDate,
      endDate,
      duration,
      vatRate,
      seats,
      bookingSeats: spaceType === 'coworking' ? null : seats,
      useCorporatePrice,
    };

    if (bookingStartTime && bookingEndTime && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        bookingData: bookingData,
        listingId,
        isOwnListing,
      });
    }
  }

  // onTypeChange = value => {
  //   console.log('value', value);
  // };

  onStyleChange = (value) => {
    this.setState(
      {
        roomStyle: value,
      },
      () => this.attendeeSet()
    );
  };

  onAttendeeChange = (value) => {
    this.setState({
      attendeeValue: value,
      attendeeError: null,
    });
  };

  onSafeSpacingChange = () => {
    const { roomStyle, attendeeValue, isSafeSpacingClicked } = this.state;
    const { structure, isSafeSpacing } = this.props;

    const maxPeople = roomStyle
      ? !isSafeSpacingClicked
        ? structure[roomStyle].safeValue
        : structure[roomStyle].value
      : 1;

    if (attendeeValue > maxPeople) {
      this.setState({
        attendeeError: maxPeople,
      });
    } else {
      this.setState(
        {
          isSafeSpacingClicked: !isSafeSpacingClicked,
          attendeeError: null,
        },
        () => this.attendeeSet()
      );
      isSafeSpacing();
    }
  };

  onSelectedTimeSlot(selectedTimeSlot) {
    this.setState({
      selectedSlot: selectedTimeSlot,
    });
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      listingPrices,
      priceScheme,
      styles,
      structure,
      safeSpacing,
      listingCategory,
      spaceType,
      resetCurrentMonth,
      onClickSelect,
      currentUser,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const useCorporatePrice = shouldDisplayCorporatePrices(currentUser);
    const hasCorporatePrice = priceScheme && priceScheme[this.state.isSetDuration]?.corporatePrice;

    console.log('priceScheme', priceScheme);

    console.log('priceScheme', priceScheme);

    const filterMeetingStyles = structure
      ? meetingStyles.filter((i) => Object.keys(structure).includes(i.key))
      : [];
    const filterCoworkingStyles = structure
      ? coworkingStyles.filter((i) => Object.keys(structure).includes(i.key))
      : [];
    const spaceStyles = [...filterMeetingStyles, ...filterCoworkingStyles];

    if (!unitPrice) {
      if (listingCategory !== 'venue') {
        return (
          <div className={classes}>
            <p className={css.error}>
              <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
            </p>
          </div>
        );
      } else {
        return null;
      }
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={(fieldRenderProps) => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
          } = fieldRenderProps;

          if (this.state.clearForm) {
            form.restart();
            this.setState({
              clearForm: false,
            });
          }

          if (this.state.attendeeValue === 'reset') {
            form.batch(() => {
              form.change('seats', null);
            });
          }

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const spaceTypeLabel = intl.formatMessage({
            id: 'BookingTimeForm.spaceTypeLabel',
          });
          const placeholderSpaceType = intl.formatMessage({
            id: 'BookingTimeForm.spaceTypePlaceholder',
          });

          const roomStyleLabel = intl.formatMessage({
            id: 'BookingTimeForm.roomStyleLabel',
          });
          const placeholderRoomStyle = intl.formatMessage({
            id: 'BookingTimeForm.roomStylePlaceholder',
          });

          const attendeeLabel = intl.formatMessage({ id: 'BookingTimeForm.attendeeLabel' });
          const placeholderAttendee = intl.formatMessage({
            id: 'BookingTimeForm.placeholderAttendee',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe =
            showEstimatedBreakdown && this.state.isSetDuration && !this.state.isDurationLoading ? (
              <div className={css.priceBreakdownContainer}>
                <h3 className={css.priceBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </h3>

                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={lineItems}
                  isPerSession={this.state.isSetDuration !== 'perHour'}
                  listingCategory={listingCategory}
                  standartPrice={
                    hasCorporatePrice &&
                    useCorporatePrice &&
                    priceScheme[this.state.isSetDuration]?.standartPrice
                  }
                />
              </div>
            ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={(values) => {
                  this.handleOnChange(values);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  listingPrices={listingPrices}
                  priceScheme={priceScheme}
                  onChangeDuration={this.onChangeDuration}
                  durationLoading={this.durationLoading}
                  listingCategory={listingCategory}
                  clearForm={this.state.clearForm}
                  resetCurrentMonth={resetCurrentMonth}
                  onClickSelect={onClickSelect}
                  onSelectedTimeSlot={this.onSelectedTimeSlot}
                  isVenue={true}
                  // For future purpose
                  // showSafeSpacing={safeSpacing && this.props.spaceType !== 'coworking'}
                />
              ) : null}

              {/* <div className={css.formRow}>
                <FieldSelect
                  name="typeSpace"
                  id="typeSpace"
                  className={css.fieldSelect}
                  selectClassName={css.select}
                  label={spaceTypeLabel}
                  disabled={!this.state.isSetDuration}
                  onChange={this.onTypeChange}
                >
                  <option disabled value="">
                    {placeholderSpaceType}
                  </option>
                  <option value={spaceType}>{spaceType}</option>
                </FieldSelect>
              </div> */}

              {spaceType !== 'coworking' ? (
                <div className={css.formRow}>
                  <FieldSelect
                    name="roomStyle"
                    id="roomStyle"
                    className={css.fieldSelect}
                    selectClassName={css.select}
                    label={roomStyleLabel}
                    disabled={!this.state.isSetDuration}
                    onChange={this.onStyleChange}
                  >
                    {spaceType !== 'coworking' && (
                      <option disabled value="">
                        {placeholderRoomStyle}
                      </option>
                    )}
                    {spaceStyles &&
                      spaceStyles.map((i) => {
                        return (
                          <option value={i.key} key={i.key}>
                            {intl.formatMessage({ id: `ConfigFilter.${i.key}` }) || i.label}
                          </option>
                        );
                      })}
                  </FieldSelect>
                </div>
              ) : null}

              <div className={css.formRow}>
                <FieldSelect
                  name="seats"
                  id="seats"
                  className={css.fieldSelect}
                  selectClassName={css.select}
                  label={attendeeLabel}
                  disabled={
                    spaceType !== 'coworking' ? !this.state.roomStyle : !this.state.isSetDuration
                  }
                  onChange={this.onAttendeeChange}
                >
                  <option disabled value="">
                    {placeholderAttendee}
                  </option>
                  {this.state.attendeeMax.map((i) => {
                    return (
                      <option value={i} key={i}>
                        {i}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>

              {safeSpacing && this.props.spaceType !== 'coworking' ? (
                <div className={css.safeSpacingSwitchHolder}>
                  <Switch
                    className={css.safeSpacingSwitch}
                    onChange={this.onSafeSpacingChange}
                    checked={this.state.isSafeSpacingClicked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="inset 0px 4px 8px rgba(0, 0, 0, 0.1)"
                    activeBoxShadow=""
                    offColor="#E6E6E6"
                    onColor="#48C39C"
                    handleDiameter={20}
                    width={40}
                    height={24}
                  />
                  <p className={css.meetSafeContainer}>
                    <FormattedMessage id="BookingDatesForm.meetSafe" />
                  </p>
                  <div>
                    <img src={keepDistance} alt="keep distance" />
                  </div>
                </div>
              ) : null}

              {this.state.attendeeError ? (
                <p className={css.error}>
                  <FormattedMessage
                    id={'BookingTimeForm.attendeeError'}
                    values={{ maxAttendee: this.state.attendeeError }}
                  />
                </p>
              ) : null}
              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  disabled={!this.state.attendeeValue}
                  className={css.submitButton}
                >
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
              <CustomQuoteRequestLink />
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormVenueComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormVenueComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeFormVenue = compose(injectIntl)(BookingTimeFormVenueComponent);
BookingTimeFormVenue.displayName = 'BookingTimeForm';

export default BookingTimeFormVenue;
