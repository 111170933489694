import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '..';

import css from './FieldSizes.module.css';

const Input = ({ item, value, onChange, id = 'FieldInputSize', intl, onTouch }) => {
  const placeholder = intl.formatMessage({ id: `FieldSizes.${item}Label` });
  // const label = intl.formatMessage({id: `FieldSizes.${item}Label`});
  const inputId = `${id}.${item}`;

  return (
    <li className={css.inputItem}>
      {/* {label && <label htmlFor={inputId}>{label}</label>} */}

      <input
        id={inputId}
        className={css.input}
        placeholder={placeholder}
        type="number"
        value={value}
        onChange={onChange}
        onBlur={() => onTouch(true)}
      />
    </li>
  );
};

class FieldSizesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sizes: {},
      fields: ['w', 'l', 'h'],
      value: '',
      touched: false,
    };

    this.handleTouched = this.handleTouched.bind(this);
  }

  componentDidMount() {
    const { input } = this.props;
    this.setState({ sizes: input.value || {} });
  }

  getValue(type) {
    return (this.state.sizes && this.state.sizes[type]) || '';
  }

  renderInputs() {
    const { intl, id } = this.props;
    const { fields } = this.state;

    return fields.map((item, i) => (
      <Input
        key={String(i)}
        item={item}
        value={this.getValue(item)}
        onChange={e => this.handleChange(e.target.value, item)}
        onTouch={this.handleTouched}
        intl={intl}
        id={id}
      />
    ));
  }

  compareValue(sizes) {
    const { fields } = this.state;
    return fields.every(f => !!sizes[f]) ? sizes : null;
  }

  handleChange(value, type) {
    const {
      form,
      input: { name },
    } = this.props;
    const { fields } = this.state;

    const sizes = {
      ...fields.reduce((res, item) => ({ ...res, [item]: null }), {}),
      ...this.state.sizes,
      [type]: value,
    };

    this.setState({
      sizes,
      value: sizes,
      // value: this.compareValue(sizes)
    });

    this.handleTouched(false);
    form.change(name, sizes);
  }

  handleTouched(touched) {
    this.setState({ touched });
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      placeholder,
      form,
      values,
      ...rest
    } = this.props;

    const { value, touched: inputTouched } = this.state;
    /* eslint-enable no-unused-vars */

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError =
      (inputTouched && errorText) || !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;

    const wrapperClasses = classNames(
      css.inputsWrapper
      // {
      //   [css.inputSuccess]: valid,
      //   [css.inputError]: hasError,
      // }
    );

    const inputHiddenProps = {
      // defaultValue,
      ...inputWithoutValue,
      ...rest,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {label && <label>{label}</label>}

        <ul className={css.inputsWrapper}>{this.renderInputs()}</ul>
        <input value={value} {...inputHiddenProps} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldSizesComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
};

FieldSizesComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
  }).isRequired,
  meta: object.isRequired,
  form: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

class FieldSizes extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldSizesComponent} {...this.props} type="hidden" />;
  }
}

export default compose(injectIntl)(FieldSizes);
