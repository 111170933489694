import React, { Component } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { LISTING_PAGE_PARAM_TYPES } from '../../util/urlHelpers';

import { NamedRedirect, Tabs, NamedLink } from '..';
import EditServicesWizardTab, {
  DESCRIPTION,
  PHOTOS,
  SPACES,
  BEDROOMS,
  EXTRAS,
} from './VenueWizardTab';

import css from './EditServicesWizard.module.css';

export const TABS = [DESCRIPTION, PHOTOS, SPACES, BEDROOMS, EXTRAS];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditServicesWizard.tabLabelDescription';
  } else if (tab === PHOTOS) {
    key = 'EditServicesWizard.tabLabelPhotosAndVideo';
  } else if (tab === SPACES) {
    key = 'EditServicesWizard.tabLabelSpaces';
  } else if (tab === BEDROOMS) {
    key = 'EditServicesWizard.tabLabelBedrooms';
  } else if (tab === EXTRAS) {
    key = 'EditServicesWizard.tabLabelExtras';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing, childListings) => {
  const { description, geolocation, title, publicData } = listing.attributes;
  const images = listing.images;
  const isDescription = !!(
    description &&
    title &&
    geolocation &&
    publicData &&
    publicData.location &&
    publicData.location.address
  );

  switch (tab) {
    case DESCRIPTION:
      return isDescription;
    case PHOTOS:
      return images && images.length > 0;
    case SPACES:
      return childListings.length > 0;
    case BEDROOMS:
      return isDescription && images && images.length > 0 && childListings.length > 0;
    case EXTRAS:
      return false;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNew, listing, childListings) => {
  return TABS.reduce((acc, tab) => {
    const previousTabIndex = TABS.findIndex(t => t === tab) - 1;
    const isActive =
      previousTabIndex >= 0
        ? !isNew || tabCompleted(TABS[previousTabIndex], listing, childListings)
        : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

// Create a new or edit listing through EditListingWizard
class VenueWizard extends Component {
  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      onManageDisableScrolling,
      currentUser,
      isNewListingFlow,
      edit,
      handlePublishListing,
      childListings,
      ...rest
    } = this.props;

    const selectedTab = params.tab;
    const tabsStatus = tabsActive(isNewListingFlow, listing, childListings);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = TABS.indexOf(selectedTab);
      const nearestActiveTab = TABS.slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return (
        <NamedRedirect name="EditServicesPage" params={{ ...params, tab: nearestActiveTab }} />
      );
    }

    const tabLink = tab => {
      return { name: 'EditServicesPage', params: { ...params, tab } };
    };

    return (
      <div className={css.tabsHolder}>
        <div className={css.backToVenueBtn}>
          <NamedLink name="ServicesPage" className={css.backButton}>
            <FormattedMessage id="Wizard.backLabel" />
          </NamedLink>
        </div>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
        >
          {TABS.map(tab => {
            return (
              <EditServicesWizardTab
                {...rest}
                edit={edit}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={TABS}
                errors={errors}
                handlePublishListing={handlePublishListing}
                fetchInProgress={fetchInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                childListings={childListings}
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}

VenueWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
};

VenueWizard.propTypes = {
  // id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS).isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  // getAccountLinkInProgress: bool.isRequired,
  // payoutDetailsSaveInProgress: bool.isRequired,
  // payoutDetailsSaved: bool.isRequired,
  // onPayoutDetailsFormChange: func.isRequired,
  // onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withViewport,
  injectIntl
)(VenueWizard);
