/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TabNav } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './LayoutWrapperSideNav.module.css';

const LayoutWrapperSideNav = props => {
  const {
    className,
    rootClassName,
    children,
    tabs,
    title,
    tabNavClassName,
    tabClassName,
    titleClassName,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const classesForTabNav = classNames(tabNavClassName || css.profilePageNav);
  const classesForTab = classNames(tabClassName || css.tab);
  const classesForTitle = classNames(titleClassName || css.sidebarTitle);

  return (
    <aside className={classes}>
      {title ? <div className={classesForTitle}>{title}</div> : null}
      {tabs ? (
        <TabNav
          className={classesForTabNav}
          activeLinkClass={css.activeLinkClass}
          rootClassName={css.tabs}
          tabRootClassName={classesForTab}
          tabs={tabs}
        />
      ) : null}
      {children}
    </aside>
  );
};

LayoutWrapperSideNav.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  tabs: null,
};

const { node, string, array } = PropTypes;

LayoutWrapperSideNav.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  tabs: array,
};

export default LayoutWrapperSideNav;
