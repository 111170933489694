import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { intlShape, FormattedMessage } from '../../util/reactIntl';
// import Switch from 'react-switch';
// import { Field } from 'react-final-form';
import {
  getStartHours,
  getStartHoursCustomMinutes,
  getEndHours,
  getEndHoursCustomMinutes,
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  dateIsAfter,
  findNextBoundary,
  findCustomNextBoundaryMinutes,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
  formatDateWithTime,
  schemeInTimeRange,
  addHourToTime,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  bookingDateRequired,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  validAddressRequired,
} from '../../util/validators';
import { FieldDateInput, FieldSelect, LocationAutocompleteInputField } from '../../components';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import NextMonthIcon from './NextMonthIcon';
import PreviousMonthIcon from './PreviousMonthIcon';
import css from './FieldDateAndTimeInput.module.css';

// import keepDistance from '../../assets/icon/keep-distance.svg';

// MAX_TIME_SLOTS_RANGE is the maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged:
// https://stripe.com/docs/connect/account-balances#holding-funds
//
// See also the API reference for querying time slots:
// https://www.sharetribe.com/api-reference/marketplace.html#query-time-slots

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;
const { spacePricesScheme, spacePackagePricesScheme, menuPricesScheme } = config.custom;

const TODAY = new Date();

const safeSpacingName = 'safeSpacing';

const identity = (v) => v;

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }

  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

    // const hours = getStartHours(intl, timeZone, startLimit, endLimit);
    const hours = getStartHoursCustomMinutes(intl, timeZone, startLimit, endLimit);
    return availableHours.concat(hours);
  }, []);
  return allHours;
};

const getAvailableEndTimes = (
  intl,
  timeZone,
  bookingStartTime,
  bookingEndDate,
  selectedTimeSlot
) => {
  if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
    return [];
  }

  const endDate = selectedTimeSlot.attributes.end;
  const bookingStartTimeAsDate = timestampToDate(bookingStartTime);

  const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
  const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
  const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);

  let startLimit;
  let endLimit;

  if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
    startLimit = bookingStartTimeAsDate;
    endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
  } else {
    // If the end date is on the same day as the selected booking start time
    // use the start time as limit. Otherwise use the start of the selected end date.
    startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
      ? bookingStartTimeAsDate
      : startOfEndDay;

    // If the selected end date is on the same day as timeslot end, use the timeslot end.
    // Else use the start of the next day after selected date.
    endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
      ? endDate
      : dayAfterBookingEnd;
  }

  // return getEndHours(intl, timeZone, startLimit, endLimit);
  return getEndHoursCustomMinutes(intl, timeZone, startLimit, endLimit);
};

const getTimeSlots = (timeSlots, date, timeZone) => {
  return timeSlots && timeSlots[0]
    ? timeSlots.filter((t) =>
        isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone)
      )
    : [];
};

// Use start date to calculate the first possible start time or times, end date and end time or times.
// If the selected value is passed to function it will be used instead of calculated value.
const getAllTimeValues = (
  intl,
  timeZone,
  timeSlots,
  startDate,
  selectedStartTime,
  selectedEndDate
) => {
  const startTimes = selectedStartTime
    ? []
    : getAvailableStartTimes(
        intl,
        timeZone,
        startDate,
        getTimeSlots(timeSlots, startDate, timeZone)
      );

  const startTime = selectedStartTime
    ? selectedStartTime
    : startTimes.length > 0 && startTimes[0] && startTimes[0].timestamp
      ? startTimes[0].timestamp
      : null;

  const startTimeAsDate = startTime ? timestampToDate(startTime) : null;

  // Note: We need to remove 1ms from the calculated endDate so that if the end
  // date would be the next day at 00:00 the day in the form is still correct.
  // Because we are only using the date and not the exact time we can remove the
  // 1ms.
  // const endDate = selectedEndDate
  //   ? selectedEndDate
  //   : startTimeAsDate
  //   ? new Date(findNextBoundary(timeZone, startTimeAsDate).getTime() - 1)
  //   : null;

  const endDate = selectedEndDate
    ? selectedEndDate
    : startTimeAsDate
      ? new Date(findCustomNextBoundaryMinutes(timeZone, startTimeAsDate).getTime() - 1)
      : null;

  const selectedTimeSlot = timeSlots.find((t) =>
    isInRange(startTimeAsDate, t.attributes.start, t.attributes.end)
  );

  const endTimes = getAvailableEndTimes(intl, timeZone, startTime, endDate, selectedTimeSlot);
  const endTime =
    endTimes.length > 0 && endTimes[0] && endTimes[0].timestamp ? endTimes[0].timestamp : null;

  return { startTime, endDate, endTime, selectedTimeSlot };
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
      ? monthlyTimeSlots[monthId].timeSlots
      : [];
};

const Next = (props) => {
  const { currentMonth, timeZone } = props;
  const nextMonthDate = nextMonthFn(currentMonth, timeZone);

  return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
};
const Prev = (props) => {
  const { currentMonth, timeZone } = props;
  const prevMonthDate = prevMonthFn(currentMonth, timeZone);
  const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

  return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
};

// If we have an hourly booking we need to filter out all time slots with the hours availability
const onFilterAvailableTime = (data) => {
  const { duration, availableTimesData, priceScheme, type, isDurationFixed } = data;
  const [start, end] =
    isDurationFixed > 0 && availableTimesData.length
      ? [
          availableTimesData[0].timeOfDay,
          availableTimesData[availableTimesData.length - 1].timeOfDay,
        ]
      : priceScheme && priceScheme[duration] && priceScheme[duration].duration
        ? priceScheme[duration].duration
        : availableTimesData.length
          ? [
              availableTimesData[0].timeOfDay,
              availableTimesData[availableTimesData.length - 1].timeOfDay,
            ]
          : ['', ''];

  let newArr = availableTimesData;

  if (type === 'start' && end && !!isDurationFixed) {
    newArr = newArr.filter((i) => {
      let endTimeArr = end.split(':');
      const endTime = parseInt(endTimeArr[0]) - 1;
      endTimeArr[0] = endTime > 10 ? `${endTime}` : `0${endTime}`;
      endTimeArr = endTimeArr.join(':');
      const newEnd = parseInt(i.timeOfDay.split(':')[0]) + +isDurationFixed;
      const newEndTimeMinuntes = parseInt(i.timeOfDay.split(':')[1]);
      return endTime + 2 > newEnd || (endTime + 2 === newEnd && newEndTimeMinuntes === 0);
    });
    newArr = newArr.map(({ timestamp, timeOfDay }) => {
      const _temp = timeOfDay.split(':');
      const formattedTime = _temp[0] === '24' ? `00:${_temp[1]}` : timeOfDay;
      return {
        timestamp,
        timeOfDay: formattedTime,
      };
    });
  } else if (type === 'start' && end) {
    const currendEndValue = +end.split(':').join('');
    newArr = newArr
      .filter(({ timeOfDay }) => {
        const currentTimeValue = +timeOfDay
          .split(':')
          .map((i) => (i === '24' ? '0' : i))
          .join('');
        return currentTimeValue <= currendEndValue;
      })
      .map(({ timeOfDay, ...rest }) => {
        const _temp = timeOfDay.split(':');
        return {
          ...rest,
          timeOfDay: _temp[0] === '24' ? `00:${_temp[1]}` : timeOfDay,
        };
      });
  } else if (type === 'end' && start) {
    newArr = newArr.filter((i) => {
      const startTimeArr = start.split(':');
      const startTime = parseInt(startTimeArr[0]) + 1;
      startTimeArr[0] = startTime > 10 ? `${startTime}` : `0${startTime}`;
      const newStart = startTimeArr.join(':');
      return i.timeOfDay >= newStart && i.timeOfDay <= end;
    });
  }

  return newArr;
};

const dayHasAvailableTime = (day, timeSlots, timeZone, priceScheme) => {
  const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, day, timeZone);
  const availableSchemes = schemeInTimeRange(day, priceScheme, timeSlotsOnSelectedDate, timeZone);
  return availableSchemes && Object.keys(availableSchemes).length;
};

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
      duration: null,
      selectedDate: null,
    };

    this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
    this.onBookingStartDateChange = this.onBookingStartDateChange.bind(this);
    this.onBookingStartTimeChange = this.onBookingStartTimeChange.bind(this);
    this.onBookingEndDateChange = this.onBookingEndDateChange.bind(this);
    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onClickSelect !== this.props.onClickSelect && this.props.onClickSelect) {
      this.setState({
        currentMonth: getMonthStartInTimeZone(TODAY, this.props.timeZone),
      });
      this.props.resetCurrentMonth();
    }
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      (prevState) => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.monthlyTimeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onBookingStartDateChange = (value) => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      durationFixed,
      durationType,
      onSelectedTimeSlot,
    } = this.props;

    if (!value || !value.date) {
      form.batch(() => {
        form.change('bookingStartTime', null);
        form.change('duration', null);
        form.change('bookingEndDate', { date: null });
        form.change('bookingEndTime', null);
      });
      // Reset the currentMonth too if bookingStartDate is cleared
      this.setState({ currentMonth: getMonthStartInTimeZone(TODAY, timeZone) });

      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.

    const startDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    this.setState({
      selectedDate: startDate,
      duration: null,
    });

    const { startTime, endDate, endTime, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate
    );

    if (typeof onSelectedTimeSlot === 'function') {
      onSelectedTimeSlot(selectedTimeSlot);
    }

    const optionEndTime =
      durationType === 'fixed' && durationFixed
        ? moment(startTime).tz(timeZone).add(parseInt(durationFixed), 'hour').valueOf()
        : endTime;

    form.batch(() => {
      form.change('bookingStartTime', startTime);
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', optionEndTime);
      form.change('duration', null);
    });
  };

  onBookingStartTimeChange = (value) => {
    const {
      monthlyTimeSlots,
      timeZone,
      intl,
      form,
      values,
      durationFixed,
      durationType,
      onSelectedTimeSlot,
      isVenue,
    } = this.props;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const startDate = values.bookingStartDate.date;

    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);
    const { endDate, endTime, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      value
    );

    if (typeof onSelectedTimeSlot === 'function') {
      onSelectedTimeSlot(selectedTimeSlot);
    }

    const optionEndTime =
      durationType === 'fixed' && durationFixed
        ? moment(parseInt(value)).tz(timeZone).add(parseInt(durationFixed), 'hour').valueOf()
        : endTime;

    form.batch(() => {
      form.change('bookingEndDate', { date: endDate });
      form.change('bookingEndTime', optionEndTime);
    });
  };

  onBookingEndDateChange = (value) => {
    const { monthlyTimeSlots, timeZone, intl, form, values } = this.props;
    if (!value || !value.date) {
      form.change('bookingEndTime', null);
      return;
    }

    // This callback function (onBookingStartDateChange) is called from react-dates component.
    // It gets raw value as a param - browser's local time instead of time in listing's timezone.
    const endDate = timeOfDayFromLocalToTimeZone(value.date, timeZone);

    const { bookingStartDate, bookingStartTime } = values;
    const startDate = bookingStartDate.date;
    const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, timeZone);
    const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, timeZone);

    const { endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate,
      bookingStartTime,
      endDate
    );

    form.change('bookingEndTime', endTime);
  };

  isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone) {
    if (!selectedTimeSlot) {
      return true;
    }

    // 'day' is pointing to browser's local time-zone (react-dates gives these).
    // However, bookingStartDate and selectedTimeSlot refer to times in listing's timeZone.
    const localizedDay = timeOfDayFromLocalToTimeZone(day, timeZone);
    // Given day (endDate) should be after the start of the day of selected booking start date.
    const startDate = resetToStartOfDay(bookingStartDate, timeZone);
    // 00:00 would return wrong day as the end date.
    // Removing 1 millisecond, solves the exclusivity issue.
    const inclusiveEnd = new Date(selectedTimeSlot.attributes.end.getTime() - 1);
    // Given day (endDate) should be before the "next" day of selected timeSlots end.
    const endDate = resetToStartOfDay(inclusiveEnd, timeZone, 1);
    return !(dateIsAfter(localizedDay, startDate) && dateIsAfter(endDate, localizedDay));
  }

  onDurationChange = (value) => {
    const { listingPrices, priceScheme, intl, form, timeZone, durationLoading, onChangeDuration } =
      this.props;

    this.setState({
      duration: value,
    });

    onChangeDuration(value);
    durationLoading(true);

    const values = form.getState().values;
    const date = values.bookingStartDate ? values.bookingStartDate.date : null;

    if (!date) {
      durationLoading(false);
      return;
    }

    const isPerHour = value === 'perHour';
    const now = moment().tz(timeZone).startOf('hour').add(1, 'hours');
    const selectedDayIsNow = now.clone().isSame(moment(date).tz(timeZone), 'day');
    const defaultStartTimeNow = selectedDayIsNow ? now.format('HH:mm') : null;
    const [defaultStartTime, defaultEndTime] = priceScheme[value].duration;

    const startTime = +formatDateWithTime(defaultStartTimeNow || defaultStartTime, date, timeZone);
    const endTime = +formatDateWithTime(defaultEndTime, date, timeZone);

    setTimeout(() => {
      form.batch(() => {
        form.change('bookingStartTime', startTime);
        form.change('bookingEndTime', isPerHour ? addHourToTime(timeZone, startTime) : endTime);
      });
      durationLoading(false);
    }, 0);
  };

  render() {
    const {
      rootClassName,
      className,
      formId,
      startDateInputProps,
      endDateInputProps,
      values,
      monthlyTimeSlots,
      timeZone,
      intl,
      listingPrices,
      listingCategory,
      clearForm,
      priceScheme,
      durationFixed,
      durationType,
      showSafeSpacing,
      form,
    } = this.props;

    let durationFixedEndTime = null;
    if (durationFixed > 0 && values && values.bookingStartTime) {
      const startTime = values.bookingStartTime;
      durationFixedEndTime =
        startTime &&
        moment(Number(startTime)).tz(timeZone).add(durationFixed, 'hours').format('HH:mm');
    }

    const { duration } = this.state;

    if (clearForm) {
      this.setState({
        duration: null,
        selectedDate: null,
      });
    }

    const classes = classNames(rootClassName || css.root, className);

    const isPerHour = duration === 'perHour';
    const isMenu = listingCategory === 'menus';
    const isSpace = listingCategory === 'space';
    const isShuttle = listingCategory === 'shuttle';
    const isTimeFieldDisable =
      (isSpace && duration !== 'perHour') || (isShuttle && duration !== 'perHour');
    const bookingStartDate =
      values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
    const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
    const bookingEndDate =
      values.bookingEndDate && values.bookingEndDate.date ? values.bookingEndDate.date : null;

    const startTimeDisabled = !bookingStartDate || isTimeFieldDisable;
    const endDateDisabled = !bookingStartDate || !bookingStartTime;
    const endTimeDisabled =
      !bookingStartDate || !bookingStartTime || !bookingEndDate || isTimeFieldDisable;

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      monthlyTimeSlots,
      this.state.currentMonth,
      timeZone
    );
    const timeSlotsOnSelectedDate = getTimeSlots(
      timeSlotsOnSelectedMonth,
      bookingStartDate,
      timeZone
    );
    const filterPriceScheme = this.state.selectedDate
      ? schemeInTimeRange(this.state.selectedDate, priceScheme, timeSlotsOnSelectedDate, timeZone)
      : [];

    const specificPriceScheme = isMenu ? menuPricesScheme : spacePricesScheme;

    const durationArr = filterPriceScheme
      ? specificPriceScheme.filter((i) => filterPriceScheme[i.key])
      : null;

    const packageArr = filterPriceScheme
      ? spacePackagePricesScheme.filter((i) => filterPriceScheme[i.key])
      : null;

    const availableStartTimesData = getAvailableStartTimes(
      intl,
      timeZone,
      bookingStartDate,
      timeSlotsOnSelectedDate
    );

    const availableStartTimesFixedDuration = durationFixed
      ? onFilterAvailableTime({
          duration: durationFixed,
          availableTimesData: availableStartTimesData,
          priceScheme,
          type: 'start',
          isDurationFixed: durationFixed,
          pou: 123,
        })
      : availableStartTimesData;

    const availableStartTimes = isPerHour
      ? onFilterAvailableTime({
          duration,
          availableTimesData: availableStartTimesData,
          priceScheme,
          type: 'start',
        })
      : availableStartTimesData;

    const firstAvailableStartTime =
      availableStartTimes.length > 0 && availableStartTimes[0] && availableStartTimes[0].timestamp
        ? availableStartTimes[0].timestamp
        : null;

    const { startTime, endDate, selectedTimeSlot } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      bookingStartDate,
      bookingStartTime || firstAvailableStartTime,
      bookingEndDate || bookingStartDate
    );

    const availableEndTimesData = getAvailableEndTimes(
      intl,
      timeZone,
      bookingStartTime || startTime,
      bookingEndDate || endDate,
      selectedTimeSlot
    );

    const availableEndTimes = isPerHour
      ? onFilterAvailableTime({
          duration,
          availableTimesData: availableEndTimesData,
          priceScheme,
          type: 'end',
        })
      : availableEndTimesData;

    const isDayBlocked = timeSlotsOnSelectedMonth
      ? (day) =>
          priceScheme
            ? !dayHasAvailableTime(day, timeSlotsOnSelectedMonth, timeZone, priceScheme)
            : !timeSlotsOnSelectedMonth.find((timeSlot) =>
                isDayMomentInsideRange(
                  day,
                  timeSlot.attributes.start,
                  timeSlot.attributes.end,
                  timeZone
                )
              )
      : () => false;

    // const placeholderTime = localizeAndFormatTime(
    //   intl,
    //   timeZone,
    //   findNextBoundary(timeZone, TODAY)
    // );

    const placeholderTime = localizeAndFormatTime(
      intl,
      timeZone,
      findCustomNextBoundaryMinutes(timeZone, TODAY)
    );

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.startTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.endTime' });
    const durationLabel = intl.formatMessage({ id: 'FieldDateTimeInput.duration' });
    const placeholderDuration = intl.formatMessage({
      id: 'FieldDateTimeInput.durationPlaceholder',
    });

    const placeholderRoomOnly = intl.formatMessage({
      id: 'ConfigFilter.roomOnly',
    });

    const placeholderPackages = intl.formatMessage({
      id: 'ConfigFilter.packages',
    });

    const pickUpLocationLabel = intl.formatMessage({
      id: 'FieldDateTimeInput.pickUpLocationLabel',
    });
    const pickUpLocationPlaceholder = intl.formatMessage({
      id: 'FieldDateTimeInput.pickUpLocationPlaceholder',
    });
    const destinationLabel = intl.formatMessage({ id: 'FieldDateTimeInput.destinationLabel' });
    const destinationPlaceholder = intl.formatMessage({
      id: 'FieldDateTimeInput.destinationPlaceholder',
    });
    const locationRequiredMessage = intl.formatMessage({
      id: 'EditSocialDescriptionForm.locationRequired',
    });
    const locationNotRecognizedMessage = intl.formatMessage({
      id: 'EditSocialDescriptionForm.locationNotRecognized',
    });
    const addressValidMessage = intl.formatMessage({
      id: 'EditSocialDescriptionForm.addressInvalid',
    });
    const deliveryAddressLabel = intl.formatMessage({
      id: 'FieldDateTimeInput.deliveryAddressLabel',
    });
    const deliveryAddressPlaceholder = intl.formatMessage({
      id: 'FieldDateTimeInput.deliveryAddressPlaceholder',
    });
    const deliveryAddressRequiredMessage = intl.formatMessage({
      id: 'FieldDateTimeInput.deliveryAddressRequired',
    });
    const deliveryAddressNotRecognizedMessage = intl.formatMessage({
      id: 'FieldDateTimeInput.deliveryAddressNotRecognized',
    });

    // const attendeeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.attendeeLabel' });
    // const placeholderAttendee = intl.formatMessage({
    //   id: 'FieldDateTimeInput.placeholderAttendee',
    // });
    /**
     * NOTE: In this template the field for the end date is hidden by default.
     * If you want to enable longer booking periods, showing the end date in the form requires some code changes:
     * 1. Move the bookingStartTime field to the same formRow with the bookingStartDate field
     * 2. Remove the div containing the line between dates
     * 3. Remove the css related to hiding the booking end date from the bottom of the FieldDateAndTimeInput.css field
     */

    const timeFieldDisabled = bookingStartDate ? (isShuttle ? duration : true) : false;

    return (
      <div className={classes}>
        <div className={css.formRow}>
          <div className={classNames(css.field, css.startDate)}>
            <FieldDateInput
              className={css.fieldDateInput}
              name="bookingStartDate"
              id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
              label={startDateInputProps.label}
              placeholderText={startDateInputProps.placeholderText}
              format={(v) =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={(v) =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingStartDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
            />
          </div>
        </div>

        {isSpace || isShuttle ? (
          <div className={css.formRow}>
            <FieldSelect
              name="duration"
              id={formId ? `${formId}.duration` : 'duration'}
              className={
                bookingStartDate ? css.fieldSelectDuration : css.fieldSelectDurationDisabled
              }
              selectClassName={bookingStartDate ? css.selectDuration : css.selectDurationDisabled}
              label={durationLabel}
              disabled={!bookingStartDate}
              onChange={this.onDurationChange}
            >
              <option disabled value="">
                {placeholderDuration}
              </option>
              {durationArr && durationArr.length > 0 && (
                <optgroup label={placeholderRoomOnly} className={css.optgroup}>
                  {durationArr.map((i) => (
                    <option key={i.key} value={i.key}>
                      {intl.formatMessage({ id: `ConfigFilter.${i.key}` }) || i.label}
                    </option>
                  ))}
                </optgroup>
              )}

              {packageArr && packageArr.length > 0 && (
                <optgroup label={placeholderPackages}>
                  {packageArr.map((i) => (
                    <option key={i.key} value={i.key}>
                      {intl.formatMessage({ id: `ConfigFilter.${i.key}` }) || i.label}
                    </option>
                  ))}
                </optgroup>
              )}
            </FieldSelect>
          </div>
        ) : null}

        <div className={css.formRow}>
          <div className={classNames(css.field, css.endDateHidden)}>
            <FieldDateInput
              {...endDateInputProps}
              name="bookingEndDate"
              id={formId ? `${formId}.bookingEndDate` : 'bookingEndDate'}
              className={css.fieldDateInput}
              label={endDateInputProps.label}
              placeholderText={endDateInputProps.placeholderText}
              format={(v) =>
                v && v.date ? { date: timeOfDayFromTimeZoneToLocal(v.date, timeZone) } : v
              }
              parse={(v) =>
                v && v.date ? { date: timeOfDayFromLocalToTimeZone(v.date, timeZone) } : v
              }
              isDayBlocked={isDayBlocked}
              onChange={this.onBookingEndDateChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
              isOutsideRange={(day) =>
                this.isOutsideRange(day, bookingStartDate, selectedTimeSlot, timeZone)
              }
              useMobileMargins
              showErrorMessage={false}
              validate={bookingDateRequired('Required')}
              disabled={endDateDisabled}
              showLabelAsDisabled={endDateDisabled}
            />
          </div>

          {!isSpace || this.state.duration ? (
            <>
              <div className={css.field}>
                <FieldSelect
                  name="bookingStartTime"
                  id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
                  className={timeFieldDisabled ? css.fieldSelect : css.fieldSelectDisabled}
                  selectClassName={timeFieldDisabled ? css.select : css.selectDisabled}
                  label={startTimeLabel}
                  disabled={startTimeDisabled}
                  onChange={this.onBookingStartTimeChange}
                >
                  {timeFieldDisabled ? (
                    (durationFixed ? availableStartTimesFixedDuration : availableStartTimes).map(
                      (p) => (
                        <option key={p.timeOfDay} value={p.timestamp}>
                          {p.timeOfDay}
                        </option>
                      )
                    )
                  ) : (
                    <option>{placeholderTime}</option>
                  )}
                </FieldSelect>
              </div>

              <div className={timeFieldDisabled ? css.lineBetween : css.lineBetweenDisabled}>-</div>

              <div className={css.field}>
                <FieldSelect
                  name="bookingEndTime"
                  id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
                  className={timeFieldDisabled ? css.fieldSelect : css.fieldSelectDisabled}
                  selectClassName={timeFieldDisabled ? css.select : css.selectDisabled}
                  label={endTimeLabel}
                  disabled={endTimeDisabled || durationType === 'fixed'}
                >
                  {timeFieldDisabled &&
                  (bookingStartTime || startTime) &&
                  !(durationType === 'fixed') ? (
                    availableEndTimes.map((p) => (
                      <option
                        key={p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                        value={p.timestamp}
                      >
                        {p.timeOfDay === '00:00' ? '24:00' : p.timeOfDay}
                      </option>
                    ))
                  ) : (
                    <option>
                      {durationType === 'fixed' ? durationFixedEndTime : placeholderTime}
                    </option>
                  )}
                </FieldSelect>
              </div>
            </>
          ) : null}
        </div>
        {isShuttle ? (
          <div className={css.formRow}>
            <LocationAutocompleteInputField
              name="pickUpLocation"
              id={formId ? `${formId}.pickUpLocation` : 'pickUpLocation'}
              inputClassName={
                this.state.duration
                  ? css.locationAutocompleteInput
                  : css.locationAutocompleteInputDisabled
              }
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              rootClassName={css.autocompleteRoot}
              label={pickUpLocationLabel}
              placeholder={pickUpLocationPlaceholder}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(locationRequiredMessage),
                autocompletePlaceSelected(locationNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
              disabled={!this.state.duration}
            />
          </div>
        ) : null}

        {isShuttle ? (
          <div className={css.formRow}>
            <LocationAutocompleteInputField
              name="destination"
              id={formId ? `${formId}.destination` : 'destination'}
              inputClassName={
                this.state.duration
                  ? css.locationAutocompleteInput
                  : css.locationAutocompleteInputDisabled
              }
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              rootClassName={css.autocompleteRoot}
              label={destinationLabel}
              placeholder={destinationPlaceholder}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(locationRequiredMessage),
                autocompletePlaceSelected(locationNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
              disabled={!this.state.duration}
            />
          </div>
        ) : null}

        {isMenu ? (
          <div className={css.formRow}>
            <LocationAutocompleteInputField
              name="deliveryAddress"
              id={formId ? `${formId}.deliveryAddress` : 'deliveryAddress'}
              inputClassName={
                this.state.duration
                  ? css.locationAutocompleteInput
                  : css.locationAutocompleteInputDisabled
              }
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              rootClassName={css.autocompleteRoot}
              label={deliveryAddressLabel}
              placeholder={deliveryAddressPlaceholder}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(deliveryAddressRequiredMessage),
                autocompletePlaceSelected(deliveryAddressNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
