import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { findRouteByRouteName, createResourceLocatorString } from '../../util/routes';
import { propTypes, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import { vatHelper } from './VatHelper';

import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { BookingAddExtraForm } from '../../forms';

import { setInitialValues, fetchTransactionLineItems, loadListing } from './AddExtraPage.duck';
import { storeData, storedData, clearData } from './AddExtraPageSessionHelpers';

import {
  selectedExtraValues,
  selectedBedroomsHelper,
  selectedExtraServicesHelper,
  selectedExtrasObj,
} from '../../helpers/extraServicesHelper';

import css from './AddExtraPage.module.css';

const STORAGE_KEY = 'AddExtraPage';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const { Money } = sdkTypes;

export class AddExtraPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
    };

    this.loadInitialData = this.loadInitialData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   */
  loadInitialData() {
    const { bookingData, bookingDates, extrasData, listing, history, onLoadListing } = this.props;

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, extrasData, listing, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, extrasData, listing }
      : storedData(STORAGE_KEY);

    const parentId = pageData.listing && pageData.listing.attributes.publicData.parent;
    onLoadListing(parentId);

    this.setState({ pageData: pageData || {}, dataLoaded: true });

    if (Object.keys(pageData).length > 0) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate: pageData.bookingDates.bookingStart,
          endDate: pageData.bookingDates.bookingEnd,
          vatRate: pageData.bookingData.vatRate,
          quantity: pageData.bookingData.quantity,
          duration: pageData.bookingData.duration,
          extraServices: pageData.bookingData.extraServices,
          destination: pageData.bookingData.destination,
          pickUpLocation: pageData.bookingData.pickUpLocation,
          bedrooms: pageData.bookingData.bedrooms,
          seats: pageData.bookingData.seats,
          durationFixed: pageData.bookingData.durationFixed,
          bookingSeats: pageData.bookingData.bookingSeats,
          useCorporatePrice: pageData.bookingData.useCorporatePrice,
        },
        listingId: pageData.listing.id,
        isOwnListing: false,
      });
    }
  }

  handleSubmit(values) {
    const { history, callSetInitialValues, parentListing } = this.props;

    const { listing, bookingDates, bookingData, extrasData } = this.state.pageData;

    const bedrooms =
      extrasData &&
      extrasData.bedrooms &&
      (JSON.parse(extrasData.bedrooms).bedrooms || JSON.parse(extrasData.bedrooms));
    const extraServices =
      extrasData && extrasData.extraServices && JSON.parse(extrasData.extraServices).extraServices;

    const selectedBedroomsValues = selectedExtraValues(values, 'bedroom');
    const selectedExtraServicesValues = selectedExtraValues(values, 'extras');

    const selectedBedrooms = selectedBedroomsHelper(bedrooms, selectedBedroomsValues);
    const selectedExtraServices = selectedExtraServicesHelper(
      extraServices,
      selectedExtraServicesValues
    );

    const destination =
      bookingData && bookingData.destination ? bookingData.destination.search : null;
    const pickUpLocation =
      bookingData && bookingData.pickUpLocation ? bookingData.pickUpLocation.search : null;

    const listingParentImg = parentListing && parentListing.images && parentListing.images[0];
    const listingParentTitle =
      parentListing && parentListing.attributes && parentListing.attributes.title;

    let bedroomsPrice = 0;
    let bedroomsVat = 0;
    let extrasPrice = 0;
    let extrasVat = 0;

    for (let key in values) {
      if (key.includes('extras')) {
        const { amount, quantity } = values[key];
        extrasVat = vatHelper(extraServices, key, values, extrasVat, 'extrasName', 'extrasVat');
        extrasPrice += amount * quantity;
      }
      if (key.includes('bedroom')) {
        const { amount, quantity } = values[key];
        bedroomsVat = vatHelper(bedrooms, key, values, bedroomsVat, 'bedroomName', 'bedroomVat');
        bedroomsPrice += amount * quantity;
      }
    }

    const selectedExtras = selectedExtrasObj(values);

    if (bedroomsPrice) bookingData.totalBedrooms = { amount: bedroomsPrice };
    if (extrasPrice) bookingData.totalExtraServices = { amount: extrasPrice };
    if (bedroomsVat) bookingData.totalBedroomsVat = { amount: bedroomsVat };
    if (extrasVat) bookingData.totalExtrasVat = { amount: extrasVat };

    if (bedrooms) bookingData.bedrooms = selectedBedrooms;
    if (extraServices) bookingData.extraServices = selectedExtraServices;

    if (destination) bookingData.destination = destination;
    if (pickUpLocation) bookingData.pickUpLocation = pickUpLocation;

    if (listingParentImg) bookingData.listingParentImg = listingParentImg;
    if (listingParentTitle) bookingData.listingParentTitle = listingParentTitle;

    if (Object.keys(selectedExtras).length)
      bookingData.selectedExtras = JSON.stringify(selectedExtras);

    const bookingStart = bookingDates.bookingStart;
    const bookingEnd = bookingDates.bookingEnd;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  render() {
    const {
      scrollingDisabled,
      intl,
      currentUser,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      lineItems,
      unitType,
      onFetchTransactionLineItems,
      viewport,
      parentListing,
    } = this.props;

    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

    const { listing, bookingDates, extrasData, bookingData } = this.state.pageData;

    const bedrooms =
      extrasData &&
      extrasData.bedrooms &&
      (JSON.parse(extrasData.bedrooms).bedrooms || JSON.parse(extrasData.bedrooms));

    const extraServices =
      extrasData && extrasData.extraServices && JSON.parse(extrasData.extraServices).extraServices;
    const duration = bookingData && bookingData.duration;

    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);
    const publicData = currentListing && currentListing.attributes.publicData;
    const isShuttle = publicData && publicData.category === 'shuttle';
    const isPerPerson = publicData && publicData.pricingType === 'person';

    const priceScheme = publicData && publicData.priceScheme;
    const vatRateDefault = publicData && publicData.vatRate;
    const vatRateShuttle = isShuttle && duration && priceScheme[duration].vatRate;
    const vatRate = vatRateDefault || vatRateShuttle;

    const seats = bookingData && bookingData.seats;
    const quantity = bookingData && bookingData.quantity;
    const durationFixed = bookingData && bookingData.durationFixed;
    const numberOfPeople = bookingData && bookingData.seats;
    const isPerHour = publicData && publicData.durationType === 'perHour';
    const bookingSeats = bookingData && bookingData.bookingSeats;

    const price =
      (currentListing && currentListing.attributes.price) || new Money(0, config.currency);
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = isPerHour
      ? `${formattedPrice} ${intl.formatMessage({
          id: unitTranslationKey,
        })}`
      : `${formattedPrice} ${intl.formatMessage({
          id: 'CheckoutPage.perSession',
        })}`;

    const parentLisitngTitle = parentListing && parentListing.attributes.title;
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });

    const parentFirstImage =
      parentListing && parentListing.images && parentListing.images.length > 0
        ? parentListing.images[0]
        : null;
    const defaultFirstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
    const firstImage = parentFirstImage || defaultFirstImage;

    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const pageProps = { title, scrollingDisabled };
    const topbar = <TopbarContainer />;

    const startTime = bookingDates && bookingDates.bookingStart;
    const endTime = bookingDates && bookingDates.bookingEnd;

    const startDate = startTime;
    const endDate = endTime;

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const timeZone =
      listing &&
      listing.attributes.availabilityPlan &&
      listing.attributes.availabilityPlan.timezone;

    return (
      <Page {...pageProps}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentContainer}>
              {listing ? (
                <BookingAddExtraForm
                  className={css.bookingForm}
                  formId="BookingAddExtra"
                  // submitButtonWrapperClassName={css.submitButtonWrapper}
                  unitType={unitType}
                  onSubmit={this.handleSubmit}
                  listingId={listing.id}
                  isOwnListing={isOwnListing}
                  timeZone={timeZone}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  startDate={startDate}
                  endDate={endDate}
                  bedrooms={bedrooms}
                  seats={seats}
                  quantity={quantity}
                  durationFixed={durationFixed}
                  extraServices={extraServices}
                  duration={duration}
                  listingTitle={listingTitle}
                  parentLisitngTitle={parentLisitngTitle}
                  firstImage={firstImage}
                  currentAuthor={currentAuthor}
                  price={price}
                  detailsSubTitle={detailsSubTitle}
                  isMobileLayout={isMobileLayout}
                  vatRate={vatRate}
                  numberOfPeople={numberOfPeople}
                  bookingSeats={bookingSeats}
                  isPerPerson={isPerPerson}
                  useNewStyle
                  showExtraServicesAndBedrooms
                  useCorporatePrice={bookingData && bookingData.useCorporatePrice}
                />
              ) : null}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

AddExtraPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  currentUser: null,
};

AddExtraPageComponent.propTypes = {
  unitType: propTypes.bookingUnitType,

  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),

  currentUser: propTypes.currentUser,

  confirmPaymentError: propTypes.error,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    extrasData,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    parentListing,
  } = state.AddExtraPage;
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    bookingData,
    bookingDates,
    extrasData,
    listing,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    parentListing,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onLoadListing: id => dispatch(loadListing(id)),
});

const AddExtraPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AddExtraPageComponent);

AddExtraPage.setInitialValues = (initialValues, saveToSessionStorage = false) => {
  if (saveToSessionStorage) {
    const { listing, bookingData, bookingDates } = initialValues;
    storeData(bookingData, bookingDates, listing, null, STORAGE_KEY);
  }

  return setInitialValues(initialValues);
};

AddExtraPage.displayName = 'AddExtraPage';

export default AddExtraPage;
