import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  validAddressRequired,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldCheckboxGroup,
  FieldRadioButton,
  MeetSafeLabel,
  FieldCheckbox,
} from '../../components';
import config from '../../config';

const { venueType, hotelCategory, venueCloseTo, venueFacilities } = config.custom;

import airport_shuttle from '../../assets/icon/airport_shuttle.svg';
import bar from '../../assets/icon/bar.svg';
import concierge from '../../assets/icon/concierge.svg';
import disabled from '../../assets/icon/disabled.svg';
import golf from '../../assets/icon/golf.svg';
import health_club from '../../assets/icon/health_club.svg';
import hybrid from '../../assets/icon/hybrid.svg';
import parking from '../../assets/icon/parking.svg';
import pool from '../../assets/icon/pool.svg';
import restaurant from '../../assets/icon/restaurant.svg';
import spa from '../../assets/icon/spa.svg';
import videoconference from '../../assets/icon/videoconference.svg';
import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import tvStudio from '../../assets/icon/Spaces_amenities/television.svg';
import lightingSystem from '../../assets/icon/extra_services/virtualServices.svg';
import liverstreamControl from '../../assets/icon/coworking.svg';

const facilitiesImages = [
  hybrid,
  videoconference,
  restaurant,
  parking,
  golf,
  airport_shuttle,
  bar,
  health_club,
  disabled,
  concierge,
  pool,
  spa,
  conditionedAir,
  tvStudio,
  lightingSystem,
  liverstreamControl,
];

import css from './EditVenueDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const identity = (v) => v;

const RenderGroups = (props) => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const RenderRadioGroups = (props) => {
  return (
    <div>
      <legend className={css.radioLabel}>{props.label}</legend>
      <ul className={css.radioButtonRow}>
        {props.options.map((item) => {
          return (
            <li key={item.key}>
              <FieldRadioButton
                className={css.radio}
                id={item.key}
                name={props.name}
                value={item.key}
                label={props.intl.formatMessage({ id: `ConfigFilter.${item.key}` }) || item.label}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const combineIconsAndText = (data, icons) => {
  return data.map(({ key, label }, i) => ({
    key,
    label: (
      <React.Fragment key={key}>
        <img className={css.icon} src={icons[i]} alt={label} />
        {label}
      </React.Fragment>
    ),
  }));
};

const EditVenueDescriptionFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={(formRenderProps) => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditVenueDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditVenueDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.description',
      });
      const descriptionPlaceholderMessageEn = intl.formatMessage({
        id: 'EditVenueDescriptionForm.descriptionPlaceholderEn',
      });

      const descriptionPlaceholderMessageFr = intl.formatMessage({
        id: 'EditVenueDescriptionForm.descriptionPlaceholderFr',
      });

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.descriptionRequired',
      });

      const brandMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.brand',
      });
      const brandPlaceholderMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.brandPlaceholder',
      });

      const locationLabel = intl.formatMessage({ id: 'EditVenueDescriptionForm.location' });
      const locationPlaceholderMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.locationPlaceholder',
      });
      const locationRequiredMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.locationRequired',
      });
      const locationNotRecognizedMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.locationNotRecognized',
      });
      const addressValidMessage = intl.formatMessage({
        id: 'EditVenueDescriptionForm.addressInvalid',
      });

      const locationTypeLabel = intl.formatMessage({ id: 'EditVenueDescriptionForm.locationType' });

      const hotelCategoryLabel = intl.formatMessage({
        id: 'EditVenueDescriptionForm.hotelCategory',
      });

      const closeToLabel = intl.formatMessage({ id: 'EditVenueDescriptionForm.closeTo' });

      const facilitiesLabel = intl.formatMessage({ id: 'EditVenueDescriptionForm.facilities' });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};

      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditVenueDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditVenueDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditVenueDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />
          <p className={css.titleInputIcon}>{descriptionMessage}</p>
          {config.locale === 'en' ? (
            <>
              <FieldTextInput
                icon={'iconEn'}
                id="description"
                name="description"
                className={css.field}
                type="textarea"
                placeholder={descriptionPlaceholderMessageEn}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
              <FieldTextInput
                icon={'iconFr'}
                id="descriptionFr"
                name="descriptionFr"
                className={css.field}
                type="textarea"
                placeholder={descriptionPlaceholderMessageFr}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
            </>
          ) : (
            <>
              <FieldTextInput
                icon={'iconFr'}
                id="descriptionFr"
                name="descriptionFr"
                className={css.field}
                type="textarea"
                placeholder={descriptionPlaceholderMessageFr}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
              <FieldTextInput
                icon={'iconEn'}
                id="description"
                name="description"
                className={css.field}
                type="textarea"
                placeholder={descriptionPlaceholderMessageEn}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
            </>
          )}
          <FieldTextInput
            id="brand"
            name="brand"
            className={css.field}
            type="textarea"
            label={brandMessage}
            placeholder={brandPlaceholderMessage}
          />

          <LocationAutocompleteInputField
            rootClassName={css.field}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            label={locationLabel}
            placeholder={locationPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            validate={composeValidators(
              autocompleteSearchRequired(locationRequiredMessage),
              autocompletePlaceSelected(locationNotRecognizedMessage),
              validAddressRequired(addressValidMessage)
            )}
          />

          <RenderGroups
            label={locationTypeLabel}
            options={venueType}
            name="locationType"
            className={css.groupsFiveRows}
            intl={intl}
          />

          {/* <RenderGroups
            label={hotelCategoryLabel}
            options={hotelCategory}
            name="hotelCategory"
            className={css.groupsFiveRows}
          /> */}

          <RenderRadioGroups
            label={hotelCategoryLabel}
            options={hotelCategory}
            name="hotelCategory"
            className={css.groupsFiveRows}
            intl={intl}
          />

          <RenderGroups
            label={closeToLabel}
            options={venueCloseTo}
            name="closeTo"
            className={css.groupsFourRows}
            intl={intl}
          />

          <RenderGroups
            label={facilitiesLabel}
            options={combineIconsAndText(venueFacilities, facilitiesImages)}
            name="facilities"
            className={css.groupsThreeRows}
            intl={intl}
          />

          <FieldCheckbox
            id="instantBooking"
            name="instantBooking"
            label={intl.formatMessage({ id: `ConfigFilter.instantBooking` })}
          />

          <MeetSafeLabel />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditVenueDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditVenueDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditVenueDescriptionFormComponent);
