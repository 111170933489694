import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionOurClients.module.css';

import poleEmploi from './images/poleEmploi.png';
import gbta from './images/gbta.png';
import planetSoar from './images/planetSoar.png';
import grandEst from './images/grandEst.png';

const SectionOurClients = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionOurClients.title" />
      </div>
      <div className={css.imageWrapper}>
        <img src={poleEmploi} alt="poleEmploi" />
      </div>
      <div className={css.imageWrapper}>
        <img src={gbta} alt="gbta" />
      </div>
      <div className={classNames(css.imageWrapper, css.imageBig)}>
        <img src={planetSoar} alt="planetSoar" />
      </div>
      <div className={css.imageWrapper}>
        <img src={grandEst} alt="grandEst" />
      </div>
    </div>
  );
};

SectionOurClients.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionOurClients.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionOurClients;
