import React from 'react';
import classNames from 'classnames';
import { IconEdit, IconClose } from '..';

import css from './ListOfSomething.module.css';

const ListOfSomething = props => {
  const { items, renderContent, onEdit, onDelete } = props;

  if (!items || !items.length) {
    return null;
  }

  const showEdit = onEdit && typeof onEdit === 'function';
  const showDelete = onDelete && typeof onDelete === 'function';
  const showControl = showDelete || showEdit;

  const handleAction = (e, type, index, value) => {
    e.preventDefault();

    if (type === 'edit') {
      onEdit(index, value);
    } else if (type === 'delete') {
      onDelete(index, value);
    }
  };

  return (
    <ul className={css.list}>
      {items.map((value, index) => {
        return (
          <li key={`${value}-${index}`} className={css.item}>
            <div className={css.itemContent}>
              {typeof renderContent === 'function' ? renderContent(index, value) : value}
            </div>

            {showControl && (
              <div className={css.itemControl}>
                {showEdit && (
                  <a
                    href="#"
                    className={css.itemControlButton}
                    onClick={e => handleAction(e, 'edit', index, value)}
                  >
                    <IconEdit />
                  </a>
                )}

                {showDelete && (
                  <a
                    href="#"
                    className={css.itemControlButton}
                    onClick={e => handleAction(e, 'delete', index, value)}
                  >
                    <IconClose innerClassName={css.delete} />
                  </a>
                )}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ListOfSomething;
