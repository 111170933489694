import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldRadioButton,
  FieldTextInput,
} from '../../components';
import css from './EditVirtualPricingForm.module.css';
import { isPercents } from '../../util/validators';

const { Money } = sdkTypes;

export const EditVirtualPricingFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(formRenderProps) => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const isPricingTypePerson = values.pricingType === 'person';

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditSocialPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const priceFieldLabel = intl.formatMessage({
        id: 'EditSocialPricingForm.priceInputLabel',
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditSocialPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const vatLabel = intl.formatMessage({ id: 'EditSocialPricingForm.vatInputLabel' });
      const vatPlaceholder = intl.formatMessage({
        id: 'EditSocialPricingForm.vatInputPlaceholder',
      });
      const vatValid = intl.formatMessage({ id: 'EditSpacePricingForm.vatValid' });

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const isCheckboxSelected = form.getState().values.pricingType;
      const submitDisabled = invalid || disabled || submitInProgress || !isCheckboxSelected;

      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <p className={css.title}>
            <FormattedMessage id="EditListingPricingForm.pricePer" />
          </p>
          <div className={css.radioButtonRow}>
            <div className={css.personContainer}>
              <FieldRadioButton
                id="person"
                name="pricingType"
                label={intl.formatMessage({ id: 'EditListingPricingForm.person' })}
                value="person"
              />
              {isPricingTypePerson ? (
                <div className={css.attendeesContainer}>
                  <p className={css.attendeesTitle}>
                    <FormattedMessage id="EditListingPricingForm.minimumAttendees" />
                  </p>
                  <Field
                    className={css.attendeesInput}
                    id="minAttendees"
                    name="minAttendees"
                    component="input"
                    type="number"
                    placeholder={'10'}
                    min={1}
                  />
                </div>
              ) : null}
            </div>
            <FieldRadioButton
              id="package"
              name="pricingType"
              label={intl.formatMessage({ id: 'EditListingPricingForm.package' })}
              value="package"
            />
          </div>

          <div className={css.priceInputsWrap}>
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              autoFocus
              label={priceFieldLabel}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
            <div style={{ display: 'none' }}>
              <FieldTextInput
                id="vatRate"
                name="vatRate"
                label={vatLabel}
                placeholder={vatPlaceholder}
                validate={isPercents(vatValid)}
              />
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditVirtualPricingFormComponent.defaultProps = { fetchErrors: null };

EditVirtualPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditVirtualPricingFormComponent);
