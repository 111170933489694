import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './BookingBreakdown.module.css';
import classNames from 'classnames';
import { formatMoney, getLineItemValue } from '../../util/currency';

const LineItemTotalVatMaybe = ({ transaction, intl }) => {
  const { lineItems } = transaction.attributes;
  const totalVAT = getLineItemValue('line-item/VAT', lineItems);

  if (!totalVAT) return <div>** VAT is missing **</div>;

  return (
    <div className={classNames(css.lineItemTotalVat, css.lineItemListingTotalVat)}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.totalVat" /> (20%)
      </span>
      <span className={css.itemValue}>{formatMoney(intl, totalVAT)}</span>
    </div>
  );
};

export default LineItemTotalVatMaybe;
