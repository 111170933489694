import React, { Component, useEffect } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import { createSlug } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { ensureCurrentUser } from '../../util/data';

import { Menu, Modal, NamedRedirect, StripeConnectAccountStatusBox } from '..';
import { StripeConnectAccountForm } from '../../forms';

import CateringWizard from './CateringWizard';
import VenueWizard from './VenueWizard';
import SpaceWizard from './SpaceWizard';
import MenuWizard from './MenuWizard';
import SocialWizard from './SocialWizard';
import ShuttleWizard from './ShuttleWizard';
import VirtualWizard from './VirtualWizard';

import css from './EditServicesWizard.module.css';

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditServicesStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType, stripe: 'true' }, // new param "stripe" added to URL to prevent firing route with the same count of params
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = (stripeAccount) => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => (type) => () => {
  getLinkFn({ type, ...commonParams })
    .then((url) => {
      window.location.href = url;
    })
    .catch((err) => console.error(err));
};

const RedirectToStripe = ({ redirectFn }) => {
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

const imageIds = (images) => {
  return images ? images.map((img) => img.imageId || img.id) : null;
};

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex((s) => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditServicesPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditServicesPage', routes, nextPathParams, {});
  history.push(to);
};

const redirectToNextTab = (listingId, params, tab, marketplaceTabs, history) => {
  const currentPathParams = {
    ...params,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('EditServicesPage', routes, nextPathParams, {});
  history.push(to);
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

const findMinPrice = (priceScheme) => {
  const arrOfMin = Object.values(priceScheme)
    .map((i) => Object.values(i).filter((j) => !Array.isArray(j) && j !== null))
    .map((k) => Math.min(...k.map((s) => s.amount)));

  return Math.min(...arrOfMin);
};

const findMaxAttendees = (data) => {
  const arrOfMax = Object.values(data)
    .map((i) => Object.values(i))
    .map((k) => Math.max(...k));

  return Math.max(...arrOfMax);
};

class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      portalRoot: null,
    };
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
    this.onCompleteEditListingWizardTab = this.onCompleteEditListingWizardTab.bind(this);
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
  }

  componentDidMount() {
    console.log('EditServicesWizard', this.props);
    const { stripeOnboardingReturnURL } = this.props;

    if (stripeOnboardingReturnURL != null && !this.showPayoutDetails) {
      this.setState({ showPayoutDetails: true });
    }
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  updateParentData = (updateValues, listing) => {
    const publicData = updateValues && updateValues.publicData;
    const priceScheme = publicData && publicData.priceScheme;
    const safeSpacing = publicData && publicData.safeSpacing;
    const structure = publicData && publicData.structure;
    const styles = publicData && publicData.styles;
    const amenities = publicData && publicData.pubAmenities;
    const minPrice = priceScheme && findMinPrice(priceScheme);
    const maxAttendees = structure && findMaxAttendees(structure);
    const virtualServices = amenities && Object.keys(amenities);
    const parentListingId = listing.attributes.publicData.parent;
    const currentListingId = listing.id.uuid;
    const spacesTypes =
      (publicData && publicData.spaceType) || listing.attributes.publicData.spaceType;
    const menusTypes =
      (publicData && publicData.menuType) || listing.attributes.publicData.menuType;
    const availabilityPlan = updateValues.availabilityPlan && updateValues.availabilityPlan.entries;
    const timezone = updateValues.availabilityPlan && updateValues.availabilityPlan.timezone;

    const parentPublicData = {};
    if (minPrice) parentPublicData.listingsMinPrice = { [currentListingId]: minPrice };
    if (safeSpacing === true || safeSpacing === false)
      parentPublicData.listingsSafeSpacing = { [currentListingId]: safeSpacing };
    if (styles) parentPublicData.listingsStyles = { [currentListingId]: styles };
    if (maxAttendees) parentPublicData.listingsMaxAttendees = { [currentListingId]: maxAttendees };
    if (amenities)
      parentPublicData.listingsVirtualServices = { [currentListingId]: virtualServices };
    if (spacesTypes) parentPublicData.listingsTypes = { [currentListingId]: spacesTypes };
    if (menusTypes) parentPublicData.listingsTypes = { [currentListingId]: menusTypes };
    if (availabilityPlan)
      parentPublicData.availabilityPlan = { [currentListingId]: availabilityPlan };
    if (timezone) parentPublicData.timezone = timezone;

    return {
      id: parentListingId,
      publicData: parentPublicData,
    };
  };

  async handlePublishListing(id) {
    const {
      onPublishListingDraft,
      params,
      parentListing,
      listing,
      onUpdateParentListing,
      onUpdateChildListings,
    } = this.props;

    const isSpace = params.category === 'space';
    const isMenu = params.category === 'menus';
    const isVenue = params.category === 'venue';

    const childListings = parentListing && parentListing.attributes.publicData.listingsTypes;
    const childListingsIds = childListings && Object.keys(childListings);
    const parentListingTitle = parentListing && parentListing.attributes.title;
    const parentListingId = parentListing && parentListing.id.uuid;

    if (isSpace) {
      const slug = createSlug(parentListing.attributes.title);
      const parentListingState = parentListing && parentListing.attributes.state;
      const parentListingCategory = parentListing && parentListing.attributes.publicData.category;
      await onPublishListingDraft(id).then((res) => {
        parentListingId &&
          onUpdateParentListing(this.updateParentData(listing.attributes, listing));
        this.props.history.push(
          `/services/${parentListingCategory}/${slug}/${parentListingId}/${parentListingState}/spaces`
        );
      });
    }
    if (isMenu) {
      const slug = createSlug(parentListing.attributes.title);
      const parentListingState = parentListing && parentListing.attributes.state;
      const parentListingCategory = parentListing && parentListing.attributes.publicData.category;
      await onPublishListingDraft(id).then((res) => {
        parentListingId &&
          onUpdateParentListing(this.updateParentData(listing.attributes, listing));
        this.props.history.push(
          `/services/${parentListingCategory}/${slug}/${parentListingId}/${parentListingState}/menus`
        );
      });
    }

    onPublishListingDraft(id).then((res) => {
      if (isVenue) {
        parentListingId && parentListingTitle && childListingsIds;
        onUpdateChildListings({
          parentId: parentListingId,
          parentTitle: parentListingTitle,
          childListings: childListingsIds,
        });
      }
    });
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handlePayoutSubmit(values) {
    this.props
      .onPayoutDetailsSubmit(values)
      .then((response) => {
        this.props.onManageDisableScrolling('EditServicesWizard.payoutModal', false);
      })
      .catch(() => {
        // do nothing
      });
  }

  getWizard(category) {
    const Placeholder = () => {
      return <div>COMING SOON</div>;
    };

    switch (category) {
      case 'catering':
        return CateringWizard;
      case 'venue':
        return VenueWizard;
      case 'social':
        return SocialWizard;
      case 'shuttle':
        return ShuttleWizard;
      case 'virtual':
        return VirtualWizard;
      case 'space':
        return SpaceWizard;
      case 'menus':
        return MenuWizard;
      default:
        return null;
    }
  }

  onCompleteEditListingWizardTab = (
    tab,
    updateValues,
    marketplaceTabs,
    passThrownErrors = false,
    noRedirect,
    noPublishListing
  ) => {
    // Normalize images for API call
    const {
      params,
      onCreateListingDraft,
      onUpdateListing,
      onUpdateParentListing,
      onUpdateChildListings,
      listing,
      history,
    } = this.props;

    const { type, parent } = params;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
    const isNewListingFlow = isNewURI || isDraftURI;
    const hasParent = listing.attributes.publicData.parent;
    const isTitle = updateValues.title;
    const childListings = listing && listing.attributes.publicData.listingsTypes;
    const childListingsIds = childListings && Object.keys(childListings);

    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      let upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: listing.id };

      if (isNewURI && parent) {
        upsertValues = {
          ...upsertValues,
          publicData: {
            ...(upsertValues.publicData || {}),
            parent,
          },
        };
      }

      return onUpsertListingDraft(tab, upsertValues)
        .then((r) => {
          if (noPublishListing) {
            return r;
          }
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            this.handleCreateFlowTabScrolling(false);
            // After successful saving of draft data, user should be redirected to next tab
            if (!noRedirect) {
              redirectAfterDraftUpdate(r.data.data.id.uuid, params, tab, marketplaceTabs, history);
            }
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            this.handlePublishListing(r.data.data.id);
          }
        })
        .catch((e) => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: listing.id }).then((res) => {
        hasParent && onUpdateParentListing(this.updateParentData(updateValues, listing));
        if (isTitle && childListingsIds) {
          onUpdateChildListings({
            parentId: listing.id.uuid,
            parentTitle: isTitle,
            childListings: childListingsIds,
          });
        }
        if (!noRedirect) {
          redirectToNextTab(listing.id.uuid, params, tab, marketplaceTabs, history);
        }
        return res;
      });
    }
  };

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      stripeAccountError,
      stripeAccountLinkError,
      currentUser,
      ...rest
    } = this.props;

    const { category } = params;
    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );

    const edit = isNewListingFlow;

    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = (tab) => {
      return { name: 'EditListingPage', params: { ...params, tab } };
    };

    const setPortalRootAfterInitialRender = () => {
      if (!this.state.portalRoot) {
        this.setState({ portalRoot: document.getElementById('portal-root') });
      }
    };
    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const successURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
      rootURL,
      routes,
      pathParams
    );
    const failureURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_FAILURE,
      rootURL,
      routes,
      pathParams
    );

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      return <NamedRedirect name="EditServicesPage" params={pathParams} />;
    }

    const Wizard = this.getWizard(category);

    if (!Wizard) {
      return <NamedRedirect name="LandingPage" />;
    }

    return (
      <div className={classes} ref={setPortalRootAfterInitialRender}>
        <Wizard
          {...rest}
          edit={edit}
          onCompleteEditListingWizardTab={this.onCompleteEditListingWizardTab}
          intl={intl}
          params={params}
          listing={listing}
          isNewListingFlow={isNewListingFlow}
          errors={errors}
          handlePublishListing={this.handlePublishListing}
          fetchInProgress={fetchInProgress}
          onManageDisableScrolling={onManageDisableScrolling}
          parentListing={this.props.parentListing}
        />
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
            </h1>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : returnedAbnormallyFromStripe && !stripeAccountLinkError ? (
              <p className={css.modalMessage}>
                <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
              </p>
            ) : (
              <>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <StripeConnectAccountForm
                  disabled={formDisabled}
                  inProgress={payoutDetailsSaveInProgress}
                  ready={payoutDetailsSaved}
                  currentUser={ensuredCurrentUser}
                  stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                  savedCountry={savedCountry}
                  submitButtonText={intl.formatMessage({
                    id: 'StripePayoutPage.submitButtonText',
                  })}
                  stripeAccountError={stripeAccountError}
                  stripeAccountFetched={stripeAccountFetched}
                  stripeAccountLinkError={stripeAccountLinkError}
                  onChange={onPayoutDetailsFormChange}
                  onSubmit={rest.onPayoutDetailsSubmit}
                  onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                  stripeConnected={stripeConnected}
                >
                  {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                    <StripeConnectAccountStatusBox
                      type="verificationNeeded"
                      inProgress={getAccountLinkInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_verification'
                      )}
                    />
                  ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                    <StripeConnectAccountStatusBox
                      type="verificationSuccess"
                      inProgress={getAccountLinkInProgress}
                      disabled={payoutDetailsSaveInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_update'
                      )}
                    />
                  ) : null}
                </StripeConnectAccountForm>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: string.isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
