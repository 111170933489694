import React, { useState, useEffect } from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '..';
import { injectIntl } from '../../util/reactIntl';

import css from './FieldTimePeriod.module.css';

const timeFormat = {};
for (let i = 1; i <= 24; i++) {
  if (i < 10) timeFormat[`0${i}`] = `0${i}`;
  if (i > 9) timeFormat[i] = `${i}`;
}

const getDefaultStartTime = type => {
  switch (type) {
    case 'morning':
      return [7, 0];
    case 'afternoon':
      return [12, 0];
    case 'fullday':
      return [7, 0];
    case 'dinner':
      return [18, 0];
    default:
      return [0, 0];
  }
};

const parseTime = (itemKey, isFrom, startTime) => {
  const [defaultH, defaultM] = getDefaultStartTime(itemKey);
  startTime = startTime || `${defaultH}:${defaultM}`;
  const [h, m] = startTime.split(':');
  if (isFrom) {
    return { h, m };
  }

  return m >= 45 ? { h: +h + 1, m: 0 } : { h, m: +m + 15 };
};

const completeTime = t => (t.toString().length === 1 ? `0${t}` : `${t}`);

const formatText = t => t.replace(':', 'h');

const Period = ({
  step = 15,
  initialTime = '',
  onChange,
  intl,
  disabled,
  itemKey,
  type,
  startTime,
  currentValue,
}) => {
  // const [start, end] = type === 'from' ? [initialTime, null] : [null, initialTime] || ['', ''];
  const [value, setValue] = useState(initialTime);
  // const [period, setPeriod] = useState();

  useEffect(() => {
    setValue(initialTime);
  }, [initialTime]);

  const handleChange = (currentValue, type) => {
    console.log(currentValue);
    setValue(currentValue);
    onChange(currentValue);
  };

  const createTime = ({ h, m }, itemKey, isFrom) => {
    const result = {
      time: [],
    };

    let toHour = 24;

    if (itemKey === 'morning') toHour = 12;
    if (itemKey === 'afternoon') toHour = 18;
    if (itemKey === 'fullday') toHour = 18;
    if (itemKey === 'dinner') toHour = 23;

    let y = m.toString();
    if (isFrom) {
      for (let i = h.toString(); i < toHour; i++) {
        for (; y < 60; y = +y + step) {
          result.time.push(`${completeTime(i || 12)}:${completeTime(y)}`);
        }
        y = 0;
      }
    } else {
      for (let i = h; i <= toHour; i++) {
        if (i === toHour) {
          result.time.push(`${completeTime(i || 12)}:${completeTime(y)}`);
        } else {
          for (; y < 60; y = +y + step) {
            result.time.push(`${completeTime(i || 12)}:${completeTime(y)}`);
          }
        }
        y = 0;
      }
    }

    // const formatTime = result.time.map(i => {
    //   const arr = i.split(':');
    //   arr[0] = timeFormat[`${arr[0]}`];
    //   return arr.join(':');
    // });

    // result.time = formatTime;
    return result;
  };

  const isFrom = type === 'from';
  const { time, periods } = createTime(parseTime(itemKey, isFrom, startTime), itemKey, isFrom);

  const timePlaceholder = intl.formatMessage({ id: 'FieldTimePeriod.timePlaceholder' });
  const periodPlaceholder = intl.formatMessage({ id: 'FieldTimePeriod.periodPlaceholder' });
  const key = time.join('') + startTime;

  return (
    <div className={css.period}>
      <div className={css.col}>
        <select
          className={css.select}
          value={value}
          onChange={e => handleChange(e.target.value, 'value')}
          disabled={disabled}
          key={key}
        >
          <option value="" disabled>
            {timePlaceholder}
          </option>
          {time.map(t => (
            <option value={t} key={t}>
              {formatText(t)}
            </option>
          ))}
        </select>
      </div>

      {/* <div className={css.col}>
        <select
          className={css.select}
          value={period}
          onChange={e => handleChange(e.target.value, 'period')}
          disabled={disabled}
        >
          <option value="" disabled>
            {periodPlaceholder}
          </option>
          {periods.map(p => (
            <option value={p} key={p}>
              {p}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

const Periods = ({ onChange, input: { value }, intl, disabled, itemKey }) => {
  const [start] = useState(value && value[0]);
  const [end] = useState(value && value[1]);
  const [values, setValues] = useState(value || ['', '']);
  const [startTime, setStartTime] = useState(value ? value[0] : null);
  const handleChange = (value, updateInitial = false, type) => {
    let result = [values[0], value];

    if (type == 'from') {
      const [hF, mF] = value.split(':');
      const [hT, mT] = values[1].split(':');
      const to =
        Number(hF + mF) > Number(hT + mT)
          ? `${mF < 45 ? hF : +hF + 1}:${mF < 45 ? +mF + 15 : '00'}`
          : values[1];
      result = [value, to];
    }

    setValues(result);
    onChange(result);
    console.log(type, result);
    if (updateInitial) setStartTime(value);
  };

  const fromLabel = intl.formatMessage({ id: `FieldTimePeriod.fromLabel` });
  const toLabel = intl.formatMessage({ id: `FieldTimePeriod.toLabel` });

  return (
    <div className={css.periods}>
      <div className={css.periodCol}>
        <label>{fromLabel}</label>
        <Period
          intl={intl}
          initialTime={start}
          onChange={value => handleChange(value, true, 'from')}
          disabled={disabled}
          itemKey={itemKey}
          type={'from'}
        />
      </div>
      <div className={css.periodCol}>
        <label>{toLabel}</label>
        <Period
          intl={intl}
          initialTime={end}
          onChange={value => handleChange(value, false, 'to')}
          disabled={disabled}
          itemKey={itemKey}
          type={'to'}
          startTime={startTime}
        />
      </div>
    </div>
  );
};

const FieldTimePeriodComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    form,
    intl,
    disabled,
    itemKey,
    ...rest
  } = props;

  const handleChange = value => {
    console.log(value);
    form.change(input.name, value);
  };

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(selectClassName, css.select, {
    [css.selectSuccess]: input.value && valid,
    [css.selectError]: hasError,
  });

  const inputProps = {
    className: selectClasses,
    id,
    ...input,
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <Periods
        intl={intl}
        input={input}
        onChange={handleChange}
        disabled={disabled}
        itemKey={itemKey}
      />

      {/* <select {...selectProps}>{children}</select> */}
      <input {...inputProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldTimePeriodComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldTimePeriodComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldTimePeriod = props => {
  return <Field component={FieldTimePeriodComponent} {...props} type="hidden" />;
};

export default injectIntl(FieldTimePeriod);
