import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '..';

import css from './SectionActivities.module.css';

import img1 from './images/img-1.jpg';
import img2 from './images/img-2.jpg';
import img3 from './images/img-3.jpg';
import img4 from './images/img-4.jpg';
import img5 from './images/img-5.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (text, name, linkHref, image, subText) => {
  const link = (
    <a className={css.activityLink} href={linkHref}>
      {name}
    </a>
  );

  return (
    <div className={css.activity}>
      <div className={css.imageWrapper}>
        <a className={css.aspectWrapper} href={linkHref}>
          <LazyImage src={image} alt={name} className={css.activityImage} />
        </a>
      </div>
      <div className={css.activityText}>
        <FormattedMessage id={`SectionActivities.link`} values={{ text: text, link }} />
        <div className={css.activitySubText}>
          {subText && <FormattedMessage id={`SectionActivities.${subText}`} />}
        </div>
      </div>
    </div>
  );
};

const SectionActivities = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <div className={css.headings}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionActivities.title" />
          </h2>
          <p className={css.subtitle}>
            <FormattedMessage id="SectionActivities.subtitle" />
          </p>
        </div>
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
          }}
          className={css.goAll}
        >    
          <FormattedMessage id="SectionLocations.locationsAll" />
        </NamedLink>  */}
      </div>

      <div className={css.activities}>
        <div className={css.activitiesWrapper}>
          {locationLink(
            'Reinvention and resilience by ',
            'Arnaud Collery',
            'https://youna.co/l/virtual-masterclass-reinvention-et-resilience/6169ad89-e49f-4c07-a68a-ede8c33da5ff',
            img1,
            'subText'
          )}
          {locationLink(
            'Women’s leadership by',
            'Marie-Laure Hubert',
            'https://youna.co/l/virtual-masterclass-le-leadership-feminin/618145be-3732-4b8f-9c2d-3b32259086ba',
            img2
          )}
          {locationLink(
            'WOptimism by',
            'Jean-Philippe Ackermann',
            'https://youna.co/l/virtual-masterclass-l-optimisme/61814a45-2307-4b0d-91cd-114dce56a713',

            img3
          )}
          {locationLink(
            'The art of oratory by',
            'Thierry Watelet',
            'https://youna.co/l/virtual-masterclass-l-art-oratoire/61814875-f474-4daf-bacb-331d428b785e',

            img4
          )}
          {locationLink(
            'Diversity and inclusion by',
            'Samira Djouadi',
            'https://youna.co/l/virtual-masterclass-la-diversite-et-linclusion/61814d2c-f7a0-4890-8cf3-12b8ee4eb498',

            img5
          )}
        </div>
      </div>
    </div>
  );
};

SectionActivities.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionActivities.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionActivities;
