import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, FieldSelect, FieldCurrencyInput } from '../../components';
import config from '../../config';

import css from './EditCateringExtrasForm.module.css';
import { required } from '../../util/validators';
import { getLabelsFromFilters } from '../../util/data';

const EXTRAS_TYPES = config.custom.extrasTypes;

const EditCateringExtrasFormComponent = (props) => {
  const { intl } = props;

  const [servicesItems, setServicesItems] = useState(props.initVal || []);

  const submitExtras = (values) => {
    const editedValues = Object.assign(
      { id: `${values.extrasName}_${Math.floor(Math.random() * 100)}` },
      values
    );
    setServicesItems([...servicesItems, editedValues]);
  };

  const deleteService = (idx) => setServicesItems(servicesItems.filter((item, i) => i !== idx));

  const extrasDataNameLabel = intl.formatMessage({
    id: 'EditCateringExtrasPanel.extrasDataNameLabel',
  });
  const extrasDataTypeLabel = intl.formatMessage({
    id: 'EditCateringExtrasPanel.extrasDataTypeLabel',
  });
  const extrasDataVatLabel = intl.formatMessage({
    id: 'EditCateringExtrasPanel.extrasDataVatLabel',
  });
  const extrasDataCapacityLabel = intl.formatMessage({
    id: 'EditCateringExtrasPanel.extrasDataCapacityLabel',
  });
  const extrasTypePlaceholder = intl.formatMessage({
    id: 'EditCateringDescriptionForm.extrasTypePlaceholder',
  });
  const extrasRequired = intl.formatMessage({
    id: 'EditCateringExtrasPanel.extrasRequired',
  });

  return (
    <>
      {servicesItems &&
        servicesItems.map((value, idx) => {
          return (
            <div className={css.bedroomItemWrap} key={`extra${idx}`}>
              <div className={css.bedroomDataItemWrap}>
                <div className={css.bedroomDataItem}>
                  <span>
                    <b>{extrasDataNameLabel}</b> {value.extrasName}
                  </span>
                  <span>
                    <b>{extrasDataTypeLabel}</b>{' '}
                    {intl.formatMessage({ id: `ConfigFilter.${value.extrasType}` })}
                  </span>
                </div>
                <div className={css.bedroomDataItem}>
                  <span>
                    <b>{extrasDataVatLabel}</b> {value.extrasVat}%
                  </span>
                  <span>
                    <b>{extrasDataCapacityLabel}</b> €{value.extrasPrice.amount / 100}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={() => deleteService(idx)}
                  className={css.saveBedroomBtn}
                >
                  <FormattedMessage id="EditCateringDescriptionForm.deleteButton" />
                </button>
              </div>
            </div>
          );
        })}
      <FinalForm
        {...props}
        onSubmit={submitExtras}
        render={(formRenderProps) => {
          const {
            className,
            disabled,
            ready,
            intl,
            invalid,
            pristine,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            initVal,
            form,
            handleSubmit,
          } = formRenderProps;
          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditCateringDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditCateringDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditCateringDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          const extrasNamePlaceholderMessage = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasNamePlaceholder',
          });
          const extrasPriceLabel = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasPriceLabel',
          });
          const extrasPricePlaceholder = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasPricePlaceholder',
          });

          const descriptionMessage = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasDescriptionLabel',
          });
          const descriptionPlaceholderMessage = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasDescriptionPlaceholder',
          });

          const extrasVatLabel = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasFormNameLabel',
          });
          const extrasVatPlaceholder = intl.formatMessage({
            id: 'EditCateringExtrasPanel.extrasVatPlaceholder',
          });

          // // Just save the extras and no need to redirect to next step or publish listing.
          // const saveService = e => {
          //   e.preventDefault();
          //   const noPublishListing = true;
          //   const noRedirect = true;
          //   const valuesArray =
          //     initVal && initVal.extraServices ? Object.values(initVal.extraServices) : [];
          //   valuesArray.push(values);

          //   props.onSubmit({ extraServices: valuesArray }, noRedirect, noPublishListing);
          //   form.restart();
          // };

          // const deleteService = e => {
          //   e.preventDefault();
          //   const val = +e.target.value || +e.currentTarget.attributes.value.nodeValue;
          //   const currentServices = [...servicesItems.extraServices];
          //   const updatedServicesList = [
          //     ...currentServices.slice(0, val),
          //     ...currentServices.slice(val + 1),
          //   ];

          const innerSubmit = (values) => {
            handleSubmit(values);
            form.reset();
          };

          return (
            <Form className={classes} onSubmit={innerSubmit}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.mainBedroomInfo}>
                <FieldSelect
                  id={`extrasType`}
                  name={`extrasType`}
                  label={' '}
                  className={css.bedroomSelect}
                  validate={required(extrasRequired)}
                >
                  <option disabled value="">
                    {extrasTypePlaceholder}
                  </option>
                  {EXTRAS_TYPES.map(({ key, label }) => (
                    <option key={key} value={key}>
                      {intl.formatMessage({ id: `ConfigFilter.${key}` })}
                    </option>
                  ))}
                </FieldSelect>
                <FieldTextInput
                  id={`extrasName`}
                  name={`extrasName`}
                  className={css.title}
                  label={' '}
                  type="text"
                  placeholder={extrasNamePlaceholderMessage}
                  validate={required(extrasRequired)}
                />

                <FieldCurrencyInput
                  id={`extrasPrice`}
                  name={`extrasPrice`}
                  className={css.title}
                  // autoFocus
                  label={extrasPriceLabel}
                  placeholder={extrasPricePlaceholder}
                  currencyConfig={config.currencyConfig}
                  validate={required(extrasRequired)}
                />
              </div>

              <FieldTextInput
                id={`extrasDescription`}
                name={`extrasDescription`}
                className={css.decriptionField}
                type="textarea"
                label={' '}
                placeholder={descriptionPlaceholderMessage}
              />

              <div className={css.additionalBedroomInfo}>
                <FieldTextInput
                  id={`extrasVat`}
                  name={`extrasVat`}
                  className={css.vat}
                  label={extrasVatLabel}
                  placeholder={extrasVatPlaceholder}
                  type="number"
                  validate={required(extrasRequired)}
                />
              </div>

              <Button
                type="submit"
                className={css.submitButton}
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                <FormattedMessage id="EditCateringDescriptionForm.addServiceButton" />
              </Button>
            </Form>
          );
        }}
      />
      <Button className={css.submitButton} onClick={() => props.onSubmit(servicesItems)}>
        {props.saveActionMsg}
      </Button>
    </>
  );
};

EditCateringExtrasFormComponent.defaultProps = { className: null, fetchErrors: null };

EditCateringExtrasFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditCateringExtrasFormComponent);
