import React from 'react';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SocialLoginButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from '../AuthenticationPage/AuthenticationPage.module.css';
import routeConfiguration from '../../routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import { pathByRouteName } from '../../util/routes';
import { FormattedMessage } from 'react-intl';

const AuthenticationPageForDecathlon = () => {
  const from = null;
  const defaultReturn = null;
  const defaultConfirm = null;

  const getDefaultRoutes = () => {
    const routes = routeConfiguration();
    const baseUrl = apiBaseUrl();

    // Route where the user should be returned after authentication
    // This is used e.g. with EditListingPage and ListingPage
    const fromParam = from ? `from=${from}` : '';

    // Default route where user is returned after successfull authentication
    const defaultReturn = pathByRouteName('LandingPage', routes);
    const defaultReturnParam = defaultReturn ? `&defaultReturn=${defaultReturn}` : '';

    // Route for confirming user data before creating a new user
    const defaultConfirm = pathByRouteName('ConfirmPage', routes);
    const defaultConfirmParam = defaultConfirm ? `&defaultConfirm=${defaultConfirm}` : '';

    return { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam };
  };

  const authWithDecathlon = () => {
    const defaultRoutes = getDefaultRoutes();
    const { baseUrl, fromParam, defaultReturnParam, defaultConfirmParam } = defaultRoutes;
    window.location.href = `${baseUrl}/api/auth/decathlon/login?${fromParam}${defaultReturnParam}${defaultConfirmParam}`;
  };

  return (
    <Page
      title={'Decathlon Login'}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Decathlon Login',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root}>
            <div className={css.content}>
              <h1>
                <FormattedMessage id="AuthenticationPageForDecathlon.title" />
              </h1>
              <p>
                <FormattedMessage id="AuthenticationPageForDecathlon.description" />
              </p>
              <div className={css.socialButtonWrapper}>
                <SocialLoginButton onClick={() => authWithDecathlon()}>
                  <FormattedMessage id="AuthenticationPageForDecathlon.button" />
                </SocialLoginButton>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export default AuthenticationPageForDecathlon;
