import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { NamedLink } from '..';

import css from './SectionGoCustomQuote.module.css';

const SectionGoCustomQuote = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const tr = {
    url: {
      en: 'http://event.youna.co/en/ask-for-a-quote',
      fr: 'http://event.youna.co/demande-de-devis',
    },
  };

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <div className={css.headings}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionGoCustomQuote.title" />
          </h2>
        </div>
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
          }}
          className={css.goAll}
        >     */}
        <a href={tr.url[config.locale]} className={css.goAll}>
          <FormattedMessage id="SectionGoCustomQuote.askFor" />
        </a>
        {/* </NamedLink>  */}
      </div>
    </div>
  );
};

SectionGoCustomQuote.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionGoCustomQuote.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGoCustomQuote;
