import React, { useState } from 'react';
import { object, string, filterConfig } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney, getLineItemValue, getLineItemUnitPrice } from '../../util/currency';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import css from './ListingPage.module.css';

import noImage from '../../components/EditVenueSpacesPanel/noimage.png';
import sun from '../../assets/icon/Spaces_amenities/sun.svg';
import banquetIcon from '../../assets/icon/banquet.svg';
import boardroomIcon from '../../assets/icon/boardroom.svg';
import classroomIcon from '../../assets/icon/classroom.svg';
import receptionIcon from '../../assets/icon/reception.svg';
import theatreIcon from '../../assets/icon/theatre.svg';
import ushapeIcon from '../../assets/icon/ushape.svg';
import coworkingIcon from '../../assets/icon/coworking.svg';

import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import soundSystem from '../../assets/icon/Spaces_amenities/sound_system.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import paperBoard from '../../assets/icon/Spaces_amenities/paper_board.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import paper from '../../assets/icon/Spaces_amenities/paper.svg';
import hydroalcoholicGel from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import projector from '../../assets/icon/Spaces_amenities/projector.svg';
import pens from '../../assets/icon/Spaces_amenities/pen.svg';
import screen from '../../assets/icon/Spaces_amenities/screen.svg';
import water from '../../assets/icon/Spaces_amenities/water.svg';

import videoconference from '../../assets/icon/videoconferenceWhite.svg';
import { languagesHelper as language } from '../../helpers/languages';
import { packages, spacePricesScheme } from '../../marketplace-custom-config';

const { Money } = sdkTypes;
const { spacePackagePricesScheme } = config.custom;

const SPACE_MAIN_OPTIONS_LIST = {
  soundSystem,
  wifi,
  paperBoard,
  television,
  paper,
  conditionedAir,
  hydroalcoholicGel,
  projector,
  pens,
  screen,
  water,
};
const SPACE_INTERNET_SPEED_OPTIONS = ['slowInternet', 'normalInternet', 'highSpeedInternet'];
const SPACE_VIRTUAL_SERVICES_OPTIONS = [
  'tvStudio',
  'greenScreen',
  'videoConferenceDevice',
  'avTechnicianOnSite',
];

const icons = {
  board: boardroomIcon,
  class: classroomIcon,
  banquet: banquetIcon,
  theatre: theatreIcon,
  ushape: ushapeIcon,
  reception: receptionIcon,
  coworking: coworkingIcon,
};
const getStyles = (values) => {
  return icons[values];
};

const getFormulasPrice = (lineItems, intl) => {
  if (!lineItems) return null;

  const unitPrice = getLineItemUnitPrice('line-item/units', lineItems);

  return unitPrice?.amount ? formatMoney(intl, unitPrice) : null;
};

const getSelectedPackageOffer = (packageOffers, bookingData) => {
  return packageOffers.length && bookingData?.duration
    ? packageOffers.find((offer) => offer.key === bookingData.duration)
    : null;
};

const SpacesCardListing = (props) => {
  const {
    className,
    rootClassName,
    listing,
    mobileLayout,
    filterConfig,
    selectListing,
    isSelected,
    intl,
    isSafeSpacingClicked,
    lineItems,
    bookingData,
  } = props;

  const classes = classNames(
    rootClassName || isSelected ? css.rootSpacescardSelected : css.rootSpacescard,
    className
  );
  const id = listing.id.uuid;
  const { state, publicData, title } = listing.attributes;
  const { category, sizes, styles, pubAmenities, priceScheme, prices, spaceType } = publicData;
  const slug = createSlug(title);
  const image = listing.images[0] && listing.images[0].attributes.variants['landscape-crop2x'].url;
  const naturalLight = pubAmenities && pubAmenities.naturalLight;

  const allPrices = [];
  const packageOffers = [];

  const unitPrice = lineItems && getLineItemValue('line-item/units', lineItems);

  const formulasPrice = getFormulasPrice(lineItems, intl);

  if (prices && prices.length > 0) {
    prices.map((item) => {
      Object.values(priceScheme[item])
        .filter((i) => i !== null)
        .map((i) => {
          if (i.amount) {
            allPrices.push(i.amount);
          }
        });
    });
  }

  spacePackagePricesScheme.map((item) => {
    if (prices && prices.includes(item.key)) {
      packageOffers.push(item);
    }
  });

  const selectedPackageOffer = getSelectedPackageOffer(packageOffers, bookingData);

  const minPrice = (allPrices.length > 0 && Math.min(...allPrices)) || null;
  const price = minPrice && new Money(minPrice, config.currency);
  const formattedPrice = minPrice ? formatMoney(intl, price) : null;

  const priceToDisplay = (isSelected && formulasPrice) || formattedPrice;

  const formatSpaceType =
    spaceType === 'meetings' ? (
      <FormattedMessage id="SpaceCard.meeting" />
    ) : (
      <FormattedMessage id="SpaceCard.coworking" />
    );

  const isDraft = state === LISTING_STATE_DRAFT;
  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const options = findOptionsForSelectFilter('pubAmenities', filterConfig); 

  const filterOption = () =>
    options
      .filter(
        (item) =>
          typeof pubAmenities[item.key] !== 'undefined' &&
          typeof SPACE_MAIN_OPTIONS_LIST[item.key] !== 'undefined'
      )
      .map((item) => {
        return {
          key: item.key,
          label: item.label,
          img: SPACE_MAIN_OPTIONS_LIST[item.key],
          isFree: pubAmenities[item.key].isFree || false,
        };
      });

  return (
    <div className={classes}>
      <div className={css.childrenInfoContainer}>
        {isDraft ? (
          <div className={css.draftContainer}>
            <p className={css.draftText}>
              <FormattedMessage id="SpaceCard.draft" />
            </p>
          </div>
        ) : null}
        <div className={css.imageSection}>
          <div className={css.imageContainer}>
            <img src={image ? image : noImage} alt="Listing image" className={css.listingImage} />
          </div>
          <div className={css.imagePriceContainer}>
            <p className={css.cardPrice}>
              {minPrice ? (
                <FormattedMessage
                  id={
                    packages.includes(bookingData?.duration)
                      ? 'SpaceCard.formulasPrice'
                      : 'SpaceCard.priceFrom'
                  }
                  values={{ price: priceToDisplay }}
                />
              ) : (
                <FormattedMessage id="SpaceCard.noPrice" />
              )}
            </p>
            <div className={css.priceImageWrapper}>
              <img src={videoconference} alt="price image" className={css.iconPrice} />
            </div>
          </div>
          {styles ? (
            <div className={css.listingStylesWrapper}>
              {styles.map((item, index) => {
                return (
                  <div className={css.listingStylesContainer} key={index}>
                    <img
                      src={getStyles(item)}
                      alt="listing styles"
                      className={css.listingStylesImage}
                    />
                    <p className={css.listingStyleNumber}>
                      {isSafeSpacingClicked && isSelected
                        ? publicData.structure[item].safeValue
                        : publicData.structure[item].value}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className={css.sizeContainer}>
          <h3 className={css.listingTitle}>{title}</h3>
          <h4 className={css.listingSpaceType}>{formatSpaceType}</h4>
          {naturalLight ? (
            <div className={css.daylightContainer}>
              <img src={sun} alt="sun" className={css.daylightImage} />
              <p className={css.listingDaylight}>
                <FormattedMessage id="SpaceCard.naturalLight" />
              </p>
            </div>
          ) : null}
          {sizes ? (
            <p className={css.listingSize}>{`${sizes.w}M x ${sizes.l}M x ${sizes.h}M (WxLxH)`}</p>
          ) : null}
          {pubAmenities ? (
            <div className={css.optionsContainer}>
              {filterOption().map((item, index) => {
                if (index < 5) {
                  return (
                    <div className={css.optionWrapper} key={item.label}>
                      <div className={css.optionImageContainer}>
                        <img
                          src={item.img}
                          alt="options image"
                          className={css.listingOptionImage}
                        />
                      </div>
                      <p className={css.optionlabel}>{language.labelsTranslator(item.key, intl)}</p>
                      {item.isFree ? (
                        <p className={css.optionlabelSpaceCardFree}>
                          <FormattedMessage id="SpaceCard.free" />
                        </p>
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          ) : null}
        </div>
        <button
          type="button"
          className={isSelected ? css.checkedButton : css.buttonForSelect}
          onClick={() => selectListing(id)}
        >
          <div className={isSelected ? css.circleChecked : css.circle} />
        </button>
      </div>
      {!spacePricesScheme.some(item => item.key === bookingData?.duration) && (<div class={css.packagesContainer}>
        {selectedPackageOffer ? (
          <div class={css.package}>
            <div class={css.packageTitle}>
              <FormattedMessage id={`ConfigFilter.${selectedPackageOffer.key}`} />
            </div>
            <div class={css.packageDetails}>{selectedPackageOffer.packageDetails?.join(' + ')}</div>
          </div>
        ) : (
          packageOffers?.length > 0 &&
          packageOffers.map((offer) => (
            <div class={css.package}>
              <div class={css.packageTitle}>
                <FormattedMessage id={`ConfigFilter.${offer.key}`} />
              </div>
              <div class={css.packageDetails}>{offer.packageDetails?.join(' + ')}</div>
            </div>
          ))
        )}
      </div>)}
    </div>
  );
};

SpacesCardListing.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  filterConfig: config.custom.filters,
};

SpacesCardListing.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,
  filterConfig: filterConfig,
};

export default SpacesCardListing;
