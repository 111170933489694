import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';
import VideoHeading from './VideoHeading';
import classNames from 'classnames';
import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    handleViewPhotosButtonClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    isMobileLayout,
    publicData,
    listingCategory,
    currentImageIndex,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const secondImage = hasImages && listing.images.length > 1 ? listing.images[1] : null;
  const thirdImage = hasImages && listing.images.length > 2 ? listing.images[2] : null;
  const fourthImage = hasImages && listing.images.length > 3 ? listing.images[3] : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()} className={css.actionBarHoler}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosButtonClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const videoPresent = publicData && publicData.videos && !!publicData.videos.length;

  const sectionClasses = classNames(css.sectionImages, { [css.sectionWithVideos]: videoPresent });

  return (
    <div className={sectionClasses}>
      {actionBar}

      {listingCategory !== 'shuttle' && videoPresent ? (
        <VideoHeading
          publicData={publicData}
          hasImages={hasImages}
          firstImage={firstImage}
          secondImage={secondImage}
          handleViewPhotosClick={handleViewPhotosClick}
          handleViewPhotosButtonClick={handleViewPhotosButtonClick}
          listing={listing}
          title={title}
        />
      ) : (
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            <ResponsiveImage
              rootClassName={secondImage ? css.rootForImage : css.singleRootForImage}
              alt={title}
              slideindex={0}
              image={firstImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
            {secondImage && !isMobileLayout ? (
              <div className={css.otherImageContainer}>
                <ResponsiveImage
                  rootClassName={thirdImage ? css.rootForSecondImage : css.secondImage}
                  alt={title}
                  slideindex={1}
                  image={secondImage}
                  variants={[
                    'landscape-crop',
                    'landscape-crop2x',
                    'landscape-crop4x',
                    'landscape-crop6x',
                  ]}
                />
                {thirdImage ? (
                  <div className={css.twoImageContainer}>
                    <ResponsiveImage
                      rootClassName={fourthImage ? css.rootForThirdImage : css.thirdImage}
                      alt={title}
                      slideindex={2}
                      image={thirdImage}
                      variants={[
                        'landscape-crop',
                        'landscape-crop2x',
                        'landscape-crop4x',
                        'landscape-crop6x',
                      ]}
                    />
                    {fourthImage ? (
                      <ResponsiveImage
                        rootClassName={css.rootForFourthImage}
                        alt={title}
                        slideindex={3}
                        image={fourthImage}
                        variants={[
                          'landscape-crop',
                          'landscape-crop2x',
                          'landscape-crop4x',
                          'landscape-crop6x',
                        ]}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            {viewPhotosButton}
          </div>
        </div>
      )}

      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} currentImageIndex={currentImageIndex} />
      </Modal>
    </div>
  );
};

export default SectionImages;
