import React, { useEffect, useState } from 'react';
import { arrayOf, bool, number, oneOf, shape, string, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsRequested,
  txHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATETIME } from '../../util/types';
import { createSlug, stringify } from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  TabNavBookings,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  SearchFormBar,
  ResponsiveImage,
  Button,
} from '../../components';
import { animateScroll as scroll } from 'react-scroll';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import { category } from '../../marketplace-custom-config';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng, LatLngBounds } = sdkTypes;
import {
  loadData,
  getTransactionsIntegration,
  updateTransactionData,
  toggleReverse,
  toggleRoleSupplier,
} from './InboxPage.duck';
import InboxPageSearchBar from './InboxPageSearchBar';
import css from './InboxPage.module.css';
import InBoxPageLazyLoading from './InBoxPageLazyLoading';
import { animateScroll as scrolling } from 'react-scroll';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    medium: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type, currentUserName, processName) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx, processName)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx, processName)) {
    const requested = isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.stateRequested',
          }),
        }
      : {
          nameClassName: css.nameEmphasized,
          bookingClassName: css.bookingActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({
            id: 'InboxPage.statePending',
          }),
        };

    return requested;
  } else if (txIsPaymentPending(tx, processName)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx, processName)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx, processName)) {
    return isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
          stateClassName: css.stateDeclined,
          notificationClassName: css.notificationDeclined,
          state: intl.formatMessage({
            id: 'InboxPage.stateDeclinedCustomer',
          }),
          infoText: intl.formatMessage(
            { id: 'InboxPage.stateDeclinedCustomerInfo' },
            { name: currentUserName }
          ),
          infoTextClassName: css.stateInfoTextDeclined,
        }
      : {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
          stateClassName: css.stateDeclined,
          // notificationClassName: css.notificationDeclined,
          state: intl.formatMessage({
            id: 'InboxPage.stateDeclined',
          }),
        };
  } else if (txIsAccepted(tx, processName)) {
    return isOrder
      ? {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
          stateClassName: css.stateSucces,
          notificationClassName: css.notificationAccepted,
          state: intl.formatMessage({
            id: 'InboxPage.stateAcceptedCustomer',
          }),
          infoText: intl.formatMessage(
            { id: 'InboxPage.stateAcceptedCustomerInfo' },
            { name: currentUserName }
          ),
          infoTextClassName: css.stateInfoTextAccepted,
        }
      : {
          nameClassName: css.nameNotEmphasized,
          bookingClassName: css.bookingNoActionNeeded,
          lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
          stateClassName: css.stateSucces,
          // notificationClassName: css.notificationAccepted,
          state: intl.formatMessage({
            id: 'InboxPage.stateAccepted',
          }),
        };
  } else if (txIsCanceled(tx, processName)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txHasBeenDelivered(tx, processName)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }
  const listingAttributes = ensureListing(tx.listing).attributes;
  const timeZone = listingAttributes.availabilityPlan
    ? listingAttributes.availabilityPlan.timezone
    : 'Etc/UTC';

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

export const InboxItem = props => {
  const {
    unitType,
    type,
    tx,
    intl,
    stateData,
    history,
    onUpdateTransactionData,
    processName,
  } = props;
  const { customer, provider, listing, messages, attributes } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;
  const lastMessageText =
    messages &&
    messages.length > 0 &&
    messages[length - 1] &&
    messages[length - 1].attributes &&
    messages[length - 1].attributes.content;

  const isSaleNotification = !isOrder && txIsRequested(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);
  const customerViewedTransaction = tx.attributes.metadata && tx.attributes.metadata.customerViewed;

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPanel.deletedListingTitle',
  });

  const listingLink = listing ? createListingLink(listing, otherUser) : null;
  const firstImage =
    listing && listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const listingTitle =
    listing && listing.attributes.deleted ? deletedListingTitle : listing.attributes.title;
  const isSpace =
    listing && listing.attributes.publicData && listing.attributes.publicData.category === 'space';
  const parentTitle =
    listing && listing.attributes.publicData && listing.attributes.publicData.parentTitle;
  const duration = attributes && attributes.metadata.duration;

  const openTransaction = async () => {
    if (customerViewedTransaction === false) {
      await onUpdateTransactionData({ id: tx.id.uuid, metadata: { customerViewed: true } });
    }

    history.push(
      createResourceLocatorString(
        isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage',
        routeConfiguration(),
        { id: tx.uuid || tx.id.uuid },
        {}
      )
    );
  };

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        {isOrder && listing ? listingLink : <Avatar user={otherUser} />}
      </div>
      <div
        className={linkClasses}
        // name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        // params={{ id: tx.id.uuid }}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <p className={css.userNameContainer}>
            {isOrder ? (
              <FormattedMessage id="TransactionPanel.to" />
            ) : (
              <FormattedMessage id="TransactionPanel.from" />
            )}{' '}
            <span className={css.userName}>{otherUserDisplayName}</span>
          </p>
          <div className={css.transactionInfoContainer}>
            <div className={css.lisitngImageContainer}>
              <div className={css.aspectWrapper}>
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  alt={listingTitle}
                  image={firstImage}
                  variants={['landscape-crop', 'landscape-crop2x']}
                />
              </div>
            </div>
            <div className={css.bookingInfoContainer}>
              <p className={css.infoTransactionText}>{isSpace ? parentTitle : listingTitle}</p>
              <div className={css.infoWrapper}>
                <div className={css.infoTransactionText}>
                  <BookingInfoMaybe
                    bookingClassName={css.bookingDate}
                    intl={intl}
                    isOrder={isOrder}
                    tx={tx}
                    unitType={unitType}
                  />
                </div>
                {isSpace ? <p className={css.infoTransactionText}>{listingTitle}</p> : null}
                {duration && (
                  <p className={css.infoTransactionText}>
                    <FormattedMessage id={`ConfigFilter.${duration}`} />
                  </p>
                )}
              </div>
              {/* {lastMessageText ? (
                <div className={css.lastMessageText}>{lastMessageText}</div>
              ) : null} */}
              {stateData.infoText ? (
                <div className={stateData.infoTextClassName}>{stateData.infoText}</div>
              ) : null}
              <Button onClick={openTransaction} className={css.seeTransactionButtonMobile}>
                {isOrder ? (
                  <FormattedMessage id="InboxPage.seeBooking" />
                ) : txIsRequested(tx, processName) || txIsDeclined(tx, processName) ? (
                  <FormattedMessage id="InboxPage.seeRequest" />
                ) : (
                  <FormattedMessage id="InboxPage.seeBooking" />
                )}
              </Button>
              {/* <div className={css.moreContainer}>
                <p className={css.infoTransactionMoreText}>
                  <FormattedMessage id="TransactionPanel.moreDetails" />
                </p>
                <div className={css.arrow} />
              </div> */}
            </div>
          </div>

          {/* <div className={css.itemHeading}>
            <div className={classNames(css.itemUsername, stateData.nameClassName)}>
              {otherUserDisplayName}
            </div>
            <BookingInfoMaybe
              bookingClassName={stateData.bookingClassName}
              intl={intl}
              isOrder={isOrder}
              tx={tx}
              unitType={unitType}
            />
          </div> */}
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {customerViewedTransaction === false ? (
              <div className={classNames(css.notificationCircle, stateData.notificationClassName)}>
                !
              </div>
            ) : null}
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.medium}
          </div>
          <Button onClick={openTransaction} className={css.seeTransactionButton}>
            {isOrder ? (
              <FormattedMessage id="InboxPage.seeBooking" />
            ) : txIsRequested(tx, processName) || txIsDeclined(tx, processName) ? (
              <FormattedMessage id="InboxPage.seeRequest" />
            ) : (
              <FormattedMessage id="InboxPage.seeBooking" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    customerNotificationCount,
    scrollingDisabled,
    transactions,
    getTransactionsProgress,
    getTransactionsError,
    allTransactions,
    allTransactionRefs,
    history,
    onGetTransactions,
    onUpdateTransactionData,
    getTransactionsIntegrationProgress,
    isRevers,
    roleSupplier,
    onToggleRoleSupplier,
    toRevers,
  } = props;

  const pageSize = 10;

  const [stateTransactions, setStateTransactions] = useState(transactions);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [pageShow, setPageShow] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState();
  const [scrollBar, setScroll] = useState(0);
  const [resultCount, setResultCount] = useState(null);

  useEffect(() => {
    isOrders ? switchBooker() : switchSupplier();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (location.pathname === '/inbox/sales') switchSupplier();
    if (location.pathname === '/inbox/orders') switchBooker();
  }, [location.pathname]);

  const handleScroll = () => setScroll(window.scrollY);
  const goToTopInBoxPage = () => scrolling.scrollToTop();

  useEffect(() => {
    if (isRevers) {
      setPageShow(
        transactions
          .sort((a, b) => a.attributes.lastTransitionedAt - b.attributes.lastTransitionedAt)
          .slice(0, pageSize)
      );
    } else setPageShow(transactions.slice(0, pageSize));
  }, [isRevers]);

  useEffect(() => {
    toRevers(false);
    setStateTransactions(transactions);
    setPageShow(transactions.slice(0, pageSize));
    setKeywordSearch(null);
  }, [params]);

  useEffect(() => {
    setStateTransactions(transactions);
    setResultCount(transactions.length);
    setPageShow(transactions.slice(0, pageSize));
    setKeywordSearch(null);
    goToTopInBoxPage();
    setKeywordSearch(null);
  }, [transactions]);

  useEffect(() => {
    if (!fetchInProgress) {
      setSelectedTransaction(null);
      setKeywordSearch(null);
    }
  }, [fetchInProgress]);

  useEffect(() => {
    if (keywordSearch) {
      const filterTransactions = transactions.filter(i => {
        const keywords = i.attributes.metadata.keywordsForSearch;
        return keywords && keywords.toLowerCase().includes(keywordSearch);
      });
      setPageShow(filterTransactions);
      setResultCount(filterTransactions.length);
    } else {
      setPageShow(transactions);
      setResultCount(transactions.length);
    }
  }, [keywordSearch]);

  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const onVenueTransactionsChange = tx => {
    // setStateTransactions(tx);
    setSelectedTransaction(tx[0].listing.attributes.publicData.parent);
  };

  const onTransactioncahange = params => {
    setSelectedTransaction(params.tab);
    onGetTransactions(params);
  };

  const onKeywordsSearch = value => {
    const kewords = value.keywords;

    if (kewords) {
      setKeywordSearch(kewords.toLowerCase());
    } else {
      setKeywordSearch(null);
    }
  };

  const uniqueTransactions = [];
  allTransactions.map(item => {
    const index = uniqueTransactions.findIndex(x => x.listing.id.uuid === item.listing.id.uuid);
    const isNotification = item.attributes && item.attributes.metadata.customerViewed === false;
    const newItem = Object.assign({}, item);
    newItem.countNotfications = isNotification ? 1 : 0;
    if (item.listing.attributes.publicData.parent) {
      uniqueTransactions.push(newItem);
    } else if (index <= -1) {
      uniqueTransactions.push(newItem);
    } else {
      uniqueTransactions.map(i => {
        if (i.listing.id.uuid === item.listing.id.uuid) {
          if (isNotification) {
            i.countNotfications += 1;
          }
        }
      });
    }
    return null;
  });

  const venueTransactions = [];
  const uniqueTransactionWithVenue = uniqueTransactions.filter(i => {
    const parentId = i.listing.attributes.publicData.parent;
    const parentTitle = i.listing.attributes.publicData.parentTitle;
    const countNotfications = i.countNotfications;
    if (parentId) {
      if (venueTransactions.length < 1) {
        venueTransactions.push({
          parentId: parentId,
          parentTitle: parentTitle,
          customerId: i.customer.id.uuid,
          providerId: i.provider.id.uuid,
          transactions: [i],
          countNotfications: countNotfications,
        });
      } else {
        const j = venueTransactions.findIndex(x => x.parentId === parentId);
        if (j <= -1) {
          venueTransactions.push({
            parentId: parentId,
            parentTitle: parentTitle,
            customerId: i.customer.id.uuid,
            providerId: i.provider.id.uuid,
            transactions: [i],
            countNotfications: countNotfications,
          });
        }

        if (venueTransactions[j] && venueTransactions[j].parentId === parentId) {
          venueTransactions[j].transactions.push(i);
          if (countNotfications > 0) {
            venueTransactions[j].countNotfications += countNotfications;
          }
        }
      }
    } else {
      return true;
    }
  });

  if (venueTransactions.length > 0) {
    uniqueTransactionWithVenue.push(...venueTransactions);
  }

  const allListingsId = uniqueTransactions.map(i => i.listing.id.uuid);
  const allListingsWithVenueId = uniqueTransactionWithVenue.map(
    i => i.parentId || i.listing.id.uuid
  );

  const validTab =
    tab === 'featureOrders' ||
    tab === 'orders' ||
    tab === 'ordersInProgress' ||
    tab === 'ordersDeclined' ||
    tab === 'ordersPast' ||
    tab === 'featureSales' ||
    tab === 'sales' ||
    tab === 'salesInProgress' ||
    tab === 'salesDeclined' ||
    tab === 'salesPast' ||
    allListingsId.includes(tab.split('_')[2]) ||
    allListingsWithVenueId.includes(tab.split('_')[2]);

  if (!validTab) {
    return <NotFoundPage />;
  }
  const isOrders =
    tab === 'orders' ||
    tab === 'featureOrders' ||
    tab === 'ordersInProgress' ||
    tab === 'ordersDeclined' ||
    tab === 'ordersPast';
  const userType =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.userType;

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const currentUserName =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.displayName;

  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const isDecathlonUser =
      currentUser?.attributes?.email?.includes('@decathlon.') ||
      tx?.customer?.attributes?.profile?.publicData?.email?.includes('@decathlon.');
    const processName = config.decathlonProcessAlias;

    const stateData = txState(intl, tx, type, currentUserName, processName);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          history={history}
          onUpdateTransactionData={onUpdateTransactionData}
          processName={processName}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const handleSubmit = ({ location, category }) => {
    const { history, currentSearchParams } = props;
    let searchLocation = {
      origin: null,
      address: null,
      bounds: new LatLngBounds(
        new LatLng(83.15311098437887, 180),
        new LatLng(-83.15311098437887, -180)
      ),
    };

    if (location) {
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      searchLocation = {
        ...originMaybe,
        address: search,
        bounds,
      };
    }

    const searchParams = {
      ...currentSearchParams,
      ...searchLocation,
      ...(category ? { pub_category: category } : {}),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const noResults =
    !fetchInProgress && stateTransactions.length === 0 && !fetchOrdersOrSalesError ? (
      <div className={css.noResultsContainer}>
        <li key="noResults" className={css.noResults}>
          <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
        </li>
        {isOrders ? (
          <p className={css.noResultsText}>
            <FormattedMessage id={'InboxPage.startBooking'} />
          </p>
        ) : null}
        {isOrders ? (
          <SearchFormBar
            initialValues={{
              category: category[0].key,
            }}
            onSubmit={handleSubmit}
            withoutBar={true}
            darkLabel={true}
            darkBackground={true}
          />
        ) : null}
      </div>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(stateTransactions, isOrders, ensuredCurrentUser);

  const providerNotificationBadge =
    providerNotificationCount > 0 ? (
      <NotificationBadge count={providerNotificationCount} className={css.notoficationBadge} />
    ) : null;

  const providerRequestNotificationBadge =
    providerNotificationCount > 0 ? (
      <NotificationBadge
        count={providerNotificationCount}
        className={css.notoficationBadgeRequest}
      />
    ) : null;

  const customerAcceptedNotificationBadge =
    customerNotificationCount && customerNotificationCount.accepted.length > 0 ? (
      <NotificationBadge
        count={customerNotificationCount.accepted.length}
        className={css.notoficationBadgeAccept}
      />
    ) : null;

  const customerDeclinedNotificationBadge =
    customerNotificationCount && customerNotificationCount.declined.length > 0 ? (
      <NotificationBadge
        count={customerNotificationCount.declined.length}
        className={css.notoficationBadge}
      />
    ) : null;

  // Tabs for provider

  const tabs = [
    {
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.scheduledEvents" />
          {/* {providerNotificationBadge} */}
        </span>
      ),
      selected: tab === 'featureSales',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'featureSales' },
      },
      subTabs: uniqueTransactionWithVenue.map(i => {
        const parentTitle = i.parentTitle || i.parentId;
        return {
          type: 'feature',
          text: (
            <span className={css.tabTitle}>
              {parentTitle || i.listing.attributes.title}
              {/* {providerNotificationBadge} */}
            </span>
          ),
          selectedSubTab:
            i.parentId === selectedTransaction ||
            (i.listing && i.listing.id.uuid === selectedTransaction),
          linkProps: {
            name: 'InboxPage',
            params: {
              tab: i.parentId || i.listing.id.uuid,
              listingId: i.parentId || (i.listing && i.listing.id.uuid),
              providerId: (i.provider && i.provider.id.uuid) || i.providerId,
              parentId: i.parentId || i.listing.attributes.publicData.parent,
            },
            transactions: i.transactions || null,
          },
        };
      }),
    },
    {
      selected: tab === 'sales',
      selectedRequest: tab === 'sales' || tab === 'salesInProgress' || tab === 'salesDeclined',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.myRequests" />
          {providerRequestNotificationBadge}
        </span>
      ),
      subTabs: [
        {
          type: 'progress',
          selectedSubTab: tab === 'salesInProgress',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'salesInProgress' },
          },
          text: (
            <span className={css.tabTitle}>
              <FormattedMessage id="InboxPage.pending" />
              {providerRequestNotificationBadge}
            </span>
          ),
        },
        {
          type: 'declined',
          selectedSubTab: tab === 'salesDeclined',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'salesDeclined' },
          },
          text: (
            <span className={css.tabTitle}>
              <FormattedMessage id="InboxPage.declined" />
              {/* {providerNotificationBadge} */}
            </span>
          ),
        },
      ],
    },
    {
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.pastEvents" />
          {/* {providerNotificationBadge} */}
        </span>
      ),
      selected: tab === 'salesPast',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'salesPast' },
      },
    },
  ];

  // Tabs for customer
  const tabsCustomer = [
    {
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.scheduledEvents" />
          {customerAcceptedNotificationBadge}
        </span>
      ),
      selected: tab === 'featureOrders',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'featureOrders' },
      },
      subTabs: uniqueTransactionWithVenue.map(i => {
        const parentTitle = i.parentTitle || i.parentId;
        const countNotfications = i.countNotfications;
        return {
          type: 'feature',
          text: (
            <span className={css.tabTitle}>
              {parentTitle || i.listing.attributes.title}
              {countNotfications > 0 ? (
                <NotificationBadge
                  count={countNotfications}
                  className={css.notoficationBadgeAccept}
                />
              ) : null}
            </span>
          ),
          selectedSubTab:
            i.parentId === selectedTransaction ||
            (i.listing && i.listing.id.uuid === selectedTransaction),
          linkProps: {
            name: 'InboxPage',
            params: {
              tab: i.parentId || i.listing.id.uuid,
              customerId: (i.customer && i.customer.id.uuid) || i.customerId,
              parentId: i.parentId || i.listing.attributes.publicData.parent,
              listingId: i.parentId || i.listing.id.uuid,
            },
            transactions: i.transactions || null,
          },
        };
      }),
    },
    {
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.myRequests" />
          {customerDeclinedNotificationBadge}
        </span>
      ),
      selected: tab === 'orders',
      selectedRequest: tab === 'orders' || tab === 'ordersInProgress' || tab === 'ordersDeclined',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
      subTabs: [
        {
          type: 'progress',
          text: (
            <span className={css.tabTitle}>
              <FormattedMessage id="InboxPage.inProgress" />
              {providerNotificationBadge}
            </span>
          ),
          selectedSubTab: tab === 'ordersInProgress',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'ordersInProgress' },
          },
        },
        {
          type: 'declined',
          text: (
            <span className={css.tabTitle}>
              <FormattedMessage id="InboxPage.declined" />
              {customerDeclinedNotificationBadge}
            </span>
          ),
          selectedSubTab: tab === 'ordersDeclined',
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'ordersDeclined' },
          },
        },
      ],
    },
    {
      text: (
        <span className={css.tabTitle}>
          <FormattedMessage id="InboxPage.pastEvents" />
          {providerNotificationBadge}
        </span>
      ),
      selected: tab === 'ordersPast',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'ordersPast' },
      },
    },
  ];
  const [styleSwitchBooker, setStyleSwitchBooker] = useState(classNames(css.sectionSwitchRole));
  const [styleSwitchSupplier, setStyleSwitchSupplier] = useState(classNames(css.sectionSwitchRole));
  const switchBooker = () => {
    onToggleRoleSupplier('booker');
    setStyleSwitchBooker(classNames(css.sectionSwitchRole, css.activeRole));
    setStyleSwitchSupplier(classNames(css.sectionSwitchRole));
  };
  const switchSupplier = () => {
    onToggleRoleSupplier('supplier');
    setStyleSwitchSupplier(classNames(css.sectionSwitchRole, css.activeRole));
    setStyleSwitchBooker(classNames(css.sectionSwitchRole));
  };
  const RoleSwitcherSupplier = () => {
    return (
      <div className={css.wrapperSwitchRole}>
        <NamedLink className={styleSwitchBooker} {...tabsCustomer[1].linkProps}>
          Booker
        </NamedLink>
        <NamedLink className={styleSwitchSupplier} {...tabs[1].linkProps}>
          Supplier
        </NamedLink>
      </div>
    );
  };

  const nav = (
    <TabNavBookings
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabs}
      history={history}
      onGetTransactions={onTransactioncahange}
      onVenueTransactionsChange={onVenueTransactionsChange}
    />
  );
  const navCustomer = (
    <TabNavBookings
      rootClassName={css.tabs}
      tabRootClassName={css.tab}
      tabs={tabsCustomer}
      history={history}
      onGetTransactions={onTransactioncahange}
      onVenueTransactionsChange={onVenueTransactionsChange}
    />
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
            // showSearchBar={userType === 'supplier' ? true : false}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <h1 className={css.title}>
            <FormattedMessage id="InboxPage.title" />
          </h1>
          {userType === 'supplier' && <RoleSwitcherSupplier />}
          {roleSupplier === 'supplier' ? nav : navCustomer}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {error}
          <InboxPageSearchBar
            intl={intl}
            onChange={onKeywordsSearch}
            initialValues={{ keywords: keywordSearch }}
            pagination={pagination}
            isRevers={isRevers}
            toRevers={toRevers}
            resultCount={resultCount}
          />
          <ul className={css.itemList}>
            {fetchInProgress || getTransactionsIntegrationProgress ? (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            ) : (
              // stateTransactions.map(toTxItem)
              pageShow.map(toTxItem)
            )}
            {noResults}
          </ul>
          <InBoxPageLazyLoading
            isRevers={isRevers}
            setPageShow={setPageShow}
            stateTransactions={stateTransactions}
            scrollBar={scrollBar}
            pagination={pagination}
            pageShow={pageShow}
            pageSize={pageSize}
            intl={intl}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserListing: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    getTransactionsProgress,
    allTransactionRefs,
    getTransactionsError,
    getTransactionsIntegrationProgress,
    roleSupplier,
    isRevers,
  } = state.InboxPage;
  const {
    currentUser,
    currentUserListing,
    currentUserNotificationCount: providerNotificationCount,
    customerNotificationCount,
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    isRevers,
    roleSupplier,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    getTransactionsProgress,
    getTransactionsError,
    allTransactions: getMarketplaceEntities(state, allTransactionRefs),
    allTransactionRefs,
    customerNotificationCount,
    getTransactionsIntegrationProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onGetTransactions: params => dispatch(getTransactionsIntegration(params)),
  onUpdateTransactionData: params => dispatch(updateTransactionData(params)),
  toRevers: params => dispatch(toggleReverse(params)),
  onToggleRoleSupplier: params => dispatch(toggleRoleSupplier(params)),
});

const InboxPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
