import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  MultiLanguageSelect,
  ExternalLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import decathlonLogo from '../../assets/partners/decathlon-logo.svg';

import css from './TopbarDesktop.module.css';
import config from '../../config';

import MessageIcon from '../Topbar/MessageIcon';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    isProvider,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const transparentTopBar = currentPage == 'LandingPage' ? css.transparent : null;

  const classes = classNames(rootClassName || css.root, transparentTopBar, className);

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const notificationCountMarker =
    notificationCount > 0 ? (
      <div className={css.notificationCountMarker}>{notificationCount}</div>
    ) : null;

  const username =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.firstName
      ? currentUser.attributes.profile.firstName
      : null;

  const userType = currentUser && currentUser.attributes.profile.publicData.userType;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: userType === 'supplier' ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <MessageIcon />
        {notificationCountMarker}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <div>
          <div className={css.username}>{username}</div>
          {/* {notificationDot} */}
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ServicesPage">
          {isProvider && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ServicesPage'))}
              name="ServicesPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.myServicesLink" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const tr = {
    url: {
      en: 'http://event.youna.co/en/corporate',
      fr: 'http://event.youna.co/corporate-fr',
    },
  };

  const listYourServicesUrl = {
    en: 'https://event.youna.co/en/how-to-sell-small-meetings',
    fr: 'https://event.youna.co/fr/comment-vendre-des-petites-reunions',
  };

  const partnerLogo = currentUser?.attributes?.email?.includes('@decathlon.')
    ? decathlonLogo
    : null;

  return (
    <nav className={classes}>
      <div className={css.container}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={css.logo}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          />
          {partnerLogo && (
            <>
              <span style={{ padding: '0 .5rem', color: 'white', verticalAlign: 'sub' }}>✕</span>
              <img src={partnerLogo} height="35" alt="Decathlon" />
            </>
          )}
        </NamedLink>
        <div>
          {isProvider && userType === 'supplier' ? (
            <NamedLink className={css.listYourServices} name="CreateServicesPage">
              <FormattedMessage id="TopbarDesktop.listYourServices" />
            </NamedLink>
          ) : userType === 'booker' ? null : (
            <ExternalLink
              href={listYourServicesUrl[config.locale]}
              className={css.listYourServices}
            >
              <span>{intl.formatMessage({ id: 'TopbarDesktop.listYourServices' })}</span>
            </ExternalLink>
          )}
          <ExternalLink href={tr.url[config.locale]} className={css.corporateAccounts}>
            <span>{intl.formatMessage({ id: 'TopbarDesktop.corporateAccounts' })}</span>
          </ExternalLink>
          {/*<a href={tr.url[config.locale]} name="LandingPage"*/}
          {/*   className={css.corporateAccounts}>*/}
          {/*  <FormattedMessage id="TopbarDesktop.corporateAccounts" />*/}
          {/*</a>*/}

          {/*<NamedLink className={css.corporateAccounts} name="LandingPage">*/}
          {/*  <FormattedMessage id="TopbarDesktop.corporateAccounts" />*/}
          {/*</NamedLink>*/}
        </div>
        <div className={css.authGroup}>
          {inboxLink}
          {profileMenu}
          {signupLink}
          {loginLink}
          <MultiLanguageSelect />
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  isProvider: bool.isRequired,
};

export default TopbarDesktop;
