import { getDefaultTimeZoneOnBrowser } from '../util/dates';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const compareAvailability = (newAvailability, oldAvailability) => {
  const newArr = newAvailability.entries.filter(i => {
    const filterArr = oldAvailability.entries.filter(j => j.dayOfWeek === i.dayOfWeek);
    return filterArr.length > 0;
  });

  const copyAvailability = Object.assign({}, oldAvailability);
  copyAvailability.entries = newArr;

  return copyAvailability;
};

export const updateAvailabilityPlan = (params, currentListing, numberSeats) => {
  const { priceScheme } = params;
  const { availabilityPlan } = currentListing.attributes;

  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();

  const timeArray = [];
  for (let key in priceScheme) {
    const [start, end] = priceScheme[key].duration;
    timeArray.push(start);
    timeArray.push(end);
  }

  timeArray.sort();
  const startTime = timeArray[0];
  const endTime = timeArray[timeArray.length - 1];

  const newAvailabilityPlan = {
    entries: WEEKDAYS.map(i => {
      return {
        dayOfWeek: i,
        endTime: endTime === '24:00' ? '00:00' : endTime,
        seats: numberSeats ? numberSeats : 1,
        startTime: startTime,
      };
    }),
    type: 'availability-plan/time',
    timezone: tz,
  };

  const updatedAvailabilityPlan = availabilityPlan
    ? compareAvailability(newAvailabilityPlan, availabilityPlan)
    : newAvailabilityPlan;

  return updatedAvailabilityPlan;
};
