import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { resetToStartOfDay, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { getListings } from '../../util/api';
import config from '../../config';
import { requestUpdateListing } from '../../containers/EditServicesPage/EditServicesPage.duck';

import * as log from '../../util/log';

const parentCategories = config.custom.category
  .filter(({ parent }) => !parent)
  .map(({ key }) => key);

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ServicesPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ServicesPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ServicesPage/FETCH_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ServicesPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ServicesPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ServicesPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ServicesPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ServicesPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ServicesPage/CLOSE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ServicesPage/ADD_OWN_ENTITIES';

export const CREATE_LISTING_DRAFT_REQUEST = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_REQUEST';
export const CREATE_LISTING_DRAFT_SUCCESS = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_SUCCESS';
export const CREATE_LISTING_DRAFT_ERROR = 'app/EditServicesWizard/CREATE_LISTING_DRAFT_ERROR';

export const CREATE_DUPLICATE_LISTING_REQUEST = 'app/ServicesPage/CREATE_DUPLICATE_LISTING_REQUEST';
export const CREATE_DUPLICATE_LISTING_SUCCESS = 'app/ServicesPage/CREATE_DUPLICATE_LISTING_SUCCESS';
export const CREATE_DUPLICATE_LISTING_ERROR = 'app/ServicesPage/CREATE_DUPLICATE_LISTING_ERROR';

export const REMOVE_LISTING_REQUEST = 'app/ServicesPage/REMOVE_LISTING_REQUEST';
export const REMOVE_LISTING_SUCCESS = 'app/ServicesPage/REMOVE_LISTING_SUCCESS';
export const REMOVE_LISTING_ERROR = 'app/ServicesPage/REMOVE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  createDuplicateProgress: false,
  createDuplicateSuccess: false,
  createDuplicateError: null,
  createListingDraftInProgress: false,
  createListingDraftError: null,
  submittedListingId: null,
  listingDraft: null,
  removeListingLoading: false,
  removeListingResult: false,
  removeListingRequestError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const servicesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    case CREATE_LISTING_DRAFT_REQUEST:
      return {
        ...state,
        createListingDraftInProgress: true,
        createListingDraftError: null,
        submittedListingId: null,
        listingDraft: null,
      };

    case CREATE_LISTING_DRAFT_SUCCESS:
      return {
        ...state,
        createListingDraftInProgress: false,
        submittedListingId: payload.data.id,
        listingDraft: payload.data,
      };
    case CREATE_LISTING_DRAFT_ERROR:
      return {
        ...state,
        createListingDraftInProgress: false,
        createListingDraftError: payload,
      };

    case CREATE_DUPLICATE_LISTING_REQUEST:
      return {
        ...state,
        createDuplicateProgress: true,
        createDuplicateError: null,
        createDuplicateSuccess: false,
      };
    case CREATE_DUPLICATE_LISTING_SUCCESS:
      return { ...state, createDuplicateSuccess: true, createDuplicateProgress: false };
    case CREATE_DUPLICATE_LISTING_ERROR:
      return { ...state, createDuplicateProgress: false, createDuplicateError: payload.error };

    case REMOVE_LISTING_REQUEST:
      return { ...state, removeListingLoading: true, removeListingRequestError: false };
    case REMOVE_LISTING_SUCCESS:
      return {
        ...state,
        removeListingLoading: false,
        removeListingRequestError: false,
        removeListingResult: true,
      };
    case REMOVE_LISTING_ERROR:
      return { ...state, removeListingLoading: false, removeListingRequestError: payload.error };

    default:
      return state;
  }
};

export default servicesPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ServicesPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const createDuplicateListingRequest = () => ({
  type: CREATE_DUPLICATE_LISTING_REQUEST,
});
export const createDuplicateListingSuccess = () => ({
  type: CREATE_DUPLICATE_LISTING_SUCCESS,
});
export const createDuplicateListingError = e => ({
  type: CREATE_DUPLICATE_LISTING_ERROR,
  payload: e,
});

export const removeListingRequest = () => ({
  type: REMOVE_LISTING_REQUEST,
});
export const removeListingSuccess = () => ({
  type: REMOVE_LISTING_SUCCESS,
});
export const removeListingError = () => ({
  type: REMOVE_LISTING_ERROR,
  payload: e,
});

// SDK method: ownListings.create
export const createListingDraft = requestAction(CREATE_LISTING_DRAFT_REQUEST);
export const createListingDraftSuccess = successAction(CREATE_LISTING_DRAFT_SUCCESS);
export const createListingDraftError = errorAction(CREATE_LISTING_DRAFT_ERROR);

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.ownListings
    .query(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      // dispatch(closeListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const queryOnlyParentListings = params => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(params));
  const { currentUser } = getState().user;
  const userData = currentUser
    ? Promise.resolve(currentUser)
    : sdk.currentUser.show({}).then(response => response.data.data);

  return userData.then(user => {
    const authorId = user.id.uuid;
    const { perPage, ...rest } = params;
    const requesParams = { ...rest, authorId, pub_category: parentCategories, per_page: perPage };

    return getListings(requesParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(queryListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(queryListingsError(storableError(e)));
        throw e;
      });
  });
};

export function requestCreateListingDraft(data) {
  return (dispatch, getState, sdk) => {
    dispatch(createListingDraft(data));

    const queryParams = {
      expand: true,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    };

    return sdk.ownListings
      .createDraft(data, queryParams)
      .then(response => {
        //const id = response.data.data.id.uuid;

        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));

        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
      })
      .catch(e => {
        log.error(e, 'create-listing-draft-failed', { listingData: data });
        return dispatch(createListingDraftError(storableError(e)));
      });
  };
}

export const requestFetchAvailabilityExceptions = fetchParams => (dispatch, getState, sdk) => {
  return sdk.availabilityExceptions
    .query(fetchParams, { expand: true })
    .then(response => {
      const availabilityExceptions = denormalisedResponseEntities(response);
      return availabilityExceptions;
    })
    .catch(e => {
      return e;
    });
};

export const requestAddAvailabilityException = params => (dispatch, getState, sdk) => {
  return sdk.availabilityExceptions
    .create(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return availabilityException;
    })
    .catch(e => {
      throw e;
    });
};

export const createDuplicateListing = (params, queryParams, id) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createDuplicateListingRequest());

  // If the listing doesn't have availabilityPlan yet
  // use the defaul timezone
  const availabilityPlan = params.availabilityPlan;

  const tz = availabilityPlan
    ? params.availabilityPlan.timezone
    : typeof window !== 'undefined'
    ? getDefaultTimeZoneOnBrowser()
    : 'Etc/UTC';

  const today = new Date();
  const start = resetToStartOfDay(today, tz, 0);
  // Query range: today + 364 days
  const exceptionRange = 364;
  const end = resetToStartOfDay(today, tz, exceptionRange);

  const exсeptionParams = {
    listingId: id,
    start,
    end,
  };

  let oldListinsExсeptions = [];

  try {
    oldListinsExсeptions = await dispatch(requestFetchAvailabilityExceptions(exсeptionParams));
  } catch (error) {
    console.log('Error on fetch exсeption for duplicate', error);
  }

  dispatch(requestCreateListingDraft(params))
    .then(response => {
      const listing = response.data.data;
      return listing;
    })
    .then(async response => {
      for (let i = 0; i < oldListinsExсeptions.length; i++) {
        const newExсeptionParams = {
          listingId: response.id,
          start: oldListinsExсeptions[i].attributes.start,
          end: oldListinsExсeptions[i].attributes.end,
          seats: oldListinsExсeptions[i].attributes.seats,
        };
        try {
          const newExсeption = await dispatch(requestAddAvailabilityException(newExсeptionParams));
        } catch (error) {
          console.log('Error on create exсeption for duplicate', error);
        }
      }
      setTimeout(() => {
        return dispatch(queryOnlyParentListings(queryParams));
      }, 1000);
    })
    .then(response => {
      dispatch(createDuplicateListingSuccess(response));
      return response;
    })
    .catch(e => {
      log.error(e, 'duplicate-listing-failed', { listingData: params });
      dispatch(createDuplicateListingError(storableError(e)));
      throw e;
    });
};

export const removeListing = ({ id, updateValues, queryParams }) => (dispatch, getState, sdk) => {
  dispatch(removeListingRequest());
  const data = {
    id: id,
    title: updateValues.title,
  };

  const childrens = updateValues.childrens;

  dispatch(closeListing(id))
    .then(async result => {
      if (childrens) {
        for (let i = 0; i < childrens.length; i++) {
          const listing = await dispatch(closeListing(childrens[i]));
          const childrendata = {
            id: childrens[i],
            title: `${listing.data.data.attributes.title} (delete)`,
          };
          try {
            await dispatch(requestUpdateListing(null, childrendata, true));
          } catch (error) {
            throw new Error('error with update listing');
          }
        }
      }

      try {
        return await dispatch(requestUpdateListing(null, data, true));
      } catch (error) {
        throw new Error('error with update listing');
      }
    })
    .then(res => {
      dispatch(removeListingSuccess());
      return queryParams && dispatch(queryOnlyParentListings(queryParams));
    })
    .catch(e => {
      log.error(e, 'remove-listing-failed', { listingData: updateValues });
      dispatch(removeListingError(storableError(e)));
      throw e;
    });
};
