import React from 'react';
import config from '../../config';

import { getLabelsFromFilters } from '../../util/data';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingPage.module.css';

const VenueDescriptionItems = props => {
  const { publicData, venueType, hotelCategory, venueCloseTo, intl } = props;

  const venueTypeOfLocationLabel = intl.formatMessage({ id: 'ListingPage.venueHotelType' });
  const venueBrandLabel = intl.formatMessage({ id: 'ListingPage.venueBrand' });
  const venueCategory = intl.formatMessage({ id: 'ListingPage.venueCategory' });
  const venueClose = intl.formatMessage({ id: 'ListingPage.venueCloseTo' });

  const locationType = publicData.locationType;
  const venueHotelCategory = publicData.hotelCategory;
  const closeTo = publicData.closeTo;

  const venueTypeOfLocationFormattedList = getLabelsFromFilters(venueType, locationType);
  const venueCategoriesFormattedList = getLabelsFromFilters(hotelCategory, venueHotelCategory);
  const venueCloseToFormattedList = getLabelsFromFilters(venueCloseTo, closeTo);

  const brand = publicData.brand ? publicData.brand : '';

  return (
    <div className={css.descriptionItems}>
      <div className={css.descriptionColumnContainer}>
        {venueTypeOfLocationFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{venueTypeOfLocationLabel}</span>
            {/* <span>{venueTypeOfLocationFormattedList}</span> */}
            <span>
              {locationType
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
        {venueCloseToFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{venueClose}</span>
            {/* <span>{venueCloseToFormattedList}</span> */}
            <span>
              {closeTo
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>

      <div className={css.descriptionColumnContainer}>
        {venueCategoriesFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{venueCategory}</span>
            <span>{venueCategoriesFormattedList}</span>
          </div>
        ) : null}

        {brand ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{venueBrandLabel}</span>
            <span>{brand}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

VenueDescriptionItems.defaultProps = {
  venueType: config.custom.venueType,
  hotelCategory: config.custom.hotelCategory,
  venueCloseTo: config.custom.venueCloseTo,
};

export default VenueDescriptionItems;
