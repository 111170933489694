import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form, AmenityItem, FieldRadioButton } from '../../components';

import css from './EditShuttleAmenitiesForm.module.css';

import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import softDrinks from '../../assets/icon/Shuttle_amenities/soft-drink.svg';
import newspaper from '../../assets/icon/Shuttle_amenities/newspaper.svg';
import mask from '../../assets/icon/Shuttle_amenities/medical-mask.svg';
import handSanitizer from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';

const SHUTTLE_AMENITIES_OPTIONS_LIST = {
  wifi,
  softDrinks,
  newspaper,
  mask,
  conditionedAir,
  handSanitizer,
  television,
};

const EditShuttleAmenitiesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter(name, filterConfig);
      const filteredOptionsList = options
        .filter(option => {
          return typeof SHUTTLE_AMENITIES_OPTIONS_LIST[option.key] !== 'undefined';
        })
        .map(item => {
          return {
            key: item.key,
            label: item.label,
            img: SHUTTLE_AMENITIES_OPTIONS_LIST[item.key],
          };
        });
      const formValues = form.getState().values;

      const onChange = ({ values }) => {
        Object.keys(values).forEach(key => {
          if (
            key.indexOf('free') !== 0 &&
            (values[key] && !values[key].length) &&
            values[`free${key}`] &&
            values[`free${key}`].length
          ) {
            form.change(`free${key}`, null);
          }
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} subscription={{ values: true }} />
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.optionsPart}>
            <AmenityItem items={filteredOptionsList} formValues={formValues} intl={intl} />
          </div>

          {/* <p>Waiting time max</p>
          <div className={css.radioButtonRow}>
            <FieldRadioButton id="15min" name="waitingTime" label={'15 minutes'} value="15min" />
            <FieldRadioButton
              id="30min"
              name="waitingTime"
              label={'30 minutes'}
              value="30min"
              className={css.radioItem}
            />
            <FieldRadioButton
              id="60min"
              name="waitingTime"
              label={'60 minutes'}
              value="60min"
              className={css.radioItem}
            />
          </div> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditShuttleAmenitiesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditShuttleAmenitiesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditShuttleAmenitiesFormComponent);
