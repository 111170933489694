import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import css from './ImagedLink.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const ImagedLink = props => {
  const {
    rootClassName,
    className,
    name,
    image,
    searchQuery,
    sectionName,
    textClassName,
    messageValues = {},
    disabled,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const content = (
    <>
      {image && (
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage src={image} alt={name} className={css.linkImage} />
          </div>
        </div>
      )}
      <div className={classNames(css.linkText, textClassName)}>
        <FormattedMessage id={`${sectionName}.${name}`} values={{ ...messageValues }} />
      </div>
    </>
  );

  if (disabled) {
    return <div className={classes}>{content}</div>;
  }

  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={classes}>
      {content}
    </NamedLink>
  );
};

ImagedLink.defaultProps = {
  rootClassName: null,
  className: null,
  image: null,
  searchQuery: null,
  sectionName: null,
};

ImagedLink.propTypes = {
  rootClassName: string,
  className: string,
  image: string,
  searchQuery: string,
  sectionName: string,
};

export default ImagedLink;
