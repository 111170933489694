import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  validAddressRequired,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldCheckboxGroup,
  MeetSafeLabel,
} from '../../components';

import css from './EditShuttleDescriptionForm.module.css';
import arrayMutators from 'final-form-arrays';
import config from '../../config';

const TITLE_MAX_LENGTH = 60;

const identity = v => v;

const RenderGroups = props => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const EditShuttleDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
        } = formRenderProps;
        const titleMessage = intl.formatMessage({ id: 'EditSocialDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditSocialDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderEn',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.descriptionRequired',
        });

        const brandMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brand',
        });
        const brandPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brandPlaceholder',
        });

        const locationLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.location' });
        const locationPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationPlaceholder',
        });
        const locationRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationRequired',
        });
        const locationNotRecognizedMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationNotRecognized',
        });
        const addressValidMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.addressInvalid',
        });

        const maxSeatsLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.maxSeatsLabel',
        });
        const safeMaxLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.safeMaxLabel',
        });
        const descriptionPlaceholderMessageFr = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderFr',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const activityTypeCheckboxesLabel = intl.formatMessage({
          id: 'EditShuttleDescriptionForm.activityTypeLabel',
        });
        const activityEnergyCheckboxesLabel = intl.formatMessage({
          id: 'EditShuttleDescriptionForm.activityEnergyLabel',
        });
        const activityWaitingTimeMaxCheckboxesLabel = intl.formatMessage({
          id: 'EditShuttleDescriptionForm.activityWaitingTimeMaxLabel',
        });
        const languageCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.languageLabel',
        });
        const tooltiptext = intl.formatMessage({
          id: 'EditShuttleDescriptionForm.tooltipText',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const maximumAttendees = values.maxSeats;
        const safeMaximumAttendees = maximumAttendees && Math.floor(+maximumAttendees / 2);

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <FieldTextInput
              id="title"
              name="title"
              className={classNames(css.title, css.field)}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />
            <p className={css.titleInputIcon}>{descriptionMessage}</p>
            {config.locale === 'en' ? (
              <>
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={classNames(css.description, css.field)}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />

                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={classNames(css.description, css.field)}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            ) : (
              <>
                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={classNames(css.description, css.field)}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={classNames(css.description, css.field)}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            )}
            <FieldTextInput
              id="brand"
              name="brand"
              className={classNames(css.description, css.field)}
              type="textarea"
              label={brandMessage}
              placeholder={brandPlaceholderMessage}
            />

            <LocationAutocompleteInputField
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={locationLabel}
              placeholder={locationPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(locationRequiredMessage),
                autocompletePlaceSelected(locationNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
            />
            <div className={css.maxSeatsWrap}>
              <FieldTextInput
                id="maxSeats"
                name="maxSeats"
                className={css.maxSeats}
                type="number"
                min={0}
                label={maxSeatsLabel}
                placeholder="100"
              />
              <div className={css.safeMaxContainer}>
                <FieldTextInput
                  id="safeMaxAttendees"
                  name="safeMaxAttendees"
                  className={css.maxSafeAttendees}
                  type="number"
                  disabled={true}
                  min={0}
                  label={safeMaxLabel}
                  placeholder="50"
                  defaultValue={safeMaximumAttendees}
                />
                <div className={css.tipContainer}>
                  <span className={css.safeMaxItem} title={tooltiptext} tabIndex="0">
                    i
                  </span>
                </div>
              </div>
            </div>

            <RenderGroups
              label={activityTypeCheckboxesLabel}
              options={props.shuttleVehicleTypes}
              name="typeOfVehicle"
              className={css.groupsFourRows}
              intl={intl}
            />

            <RenderGroups
              label={activityEnergyCheckboxesLabel}
              options={props.shuttleEnergyTypes}
              name="energy"
              className={css.groupsThreeRows}
              intl={intl}
            />

            <RenderGroups
              label={activityWaitingTimeMaxCheckboxesLabel}
              options={props.shuttleWaitingTimeMaxTypes}
              name="waitingTimeMax"
              className={css.groupsThreeRows}
              intl={intl}
            />

            <RenderGroups
              label={languageCheckboxesLabel}
              options={props.shuttleActivityLanguageType}
              name="shuttleLanguage"
              className={css.groupsThreeRows}
              intl={intl}
            />

            {/* <p>
              <FormattedMessage id="EditSocialDescriptionForm.respectCovidLabel" />
            </p> */}
            <MeetSafeLabel />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditShuttleDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  shuttleVehicleTypes: config.custom.shuttleVehicleTypes,
  shuttleEnergyTypes: config.custom.shuttleEnergyTypes,
  shuttleWaitingTimeMaxTypes: config.custom.shuttleWaitingTimeMaxTypes,
  shuttleActivityLanguageType: config.custom.shuttleActivityLanguageType,
};

EditShuttleDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditShuttleDescriptionFormComponent);
