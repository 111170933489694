import React from 'react';
import { bool, func, object, shape } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isProviderRole, propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  Footer,
  NamedRedirect,
  NamedLink,
  Page,
  UserNav,
  LayoutSideNavigation,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperServiceSideNav,
} from '../../components';
import { CreateServiceForm } from '../../forms';

import { TopbarContainer } from '..';

import css from './CreateServicesPage.module.css';

export const CreateServicesPageComponent = props => {
  const { currentUser, history, intl, page, scrollingDisabled, listings } = props;

  const isProvider = isProviderRole(currentUser);

  if (currentUser && !isProvider) {
    return <NamedRedirect name="LandingPage" />;
  }

  const handleCreate = values => {
    const { category } = values;

    history.push(
      createResourceLocatorString('NewServicesPage', routeConfiguration(), { category })
    );
  };

  const title = intl.formatMessage({ id: 'CreateServicesPage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ServicesPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperServiceSideNav currentTab="CreateServicesPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1>
              <FormattedMessage id="CreateServicesPage.contentTitle" />
            </h1>

            <CreateServiceForm onSubmit={handleCreate} />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

CreateServicesPageComponent.defaultProps = {
  currentUser: null,
};

CreateServicesPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  page: object.isRequired,
  scrollingDisabled: bool.isRequired,

  /* from withRouter */
  history: shape({
    push: func.isRequired,
  }).isRequired,

  /* from injectIntl */
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const page = state.ServicesPage;

  const { currentUser } = state.user;

  return {
    currentUser,
    page,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const CreateServicesPage = compose(
  withRouter,
  connect(mapStateToProps)
)(injectIntl(CreateServicesPageComponent));

export default CreateServicesPage;
