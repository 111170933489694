import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditShuttleDescriptionForm } from '../../forms';

import css from './EditShuttleDescriptionPanel.module.css';

const EditShuttleDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, title, description } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;

    const location = publicData && publicData.location ? publicData.location : {};
    const brand = publicData && publicData.brand;
    const descriptionFr = publicData && publicData.descriptionFr;
    const maxAttendees = publicData && publicData.maxAttendees;
    const safeMaxAttendees = publicData && publicData.safeMaxAttendees;
    const antiCovid = publicData && publicData.antiCovid;
    const typeOfVehicle = publicData && publicData.typeOfVehicle;
    const energy = publicData && publicData.energy;
    const waitingTimeMax = publicData && publicData.waitingTimeMax;
    const shuttleLanguage = publicData && publicData.shuttleLanguage;
    const showForSearchPub = publicData && publicData.showForSearch;

    const { address, addressValid } = location;

    return {
      title,
      description,
      descriptionFr,
      brand,
      maxSeats: maxAttendees,
      // safeMaxAttendees,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, addressValid },
          }
        : null,
      antiCovid,
      typeOfVehicle,
      energy,
      waitingTimeMax,
      shuttleLanguage,
      showForSearchPub,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditVenueDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditVenueDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditShuttleDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditShuttleDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            brand = null,
            descriptionFr,
            location,
            duration = 1,
            attendees = 1,
            antiCovid,
            maxSeats,
            safeMaxAttendees,
            typeOfVehicle,
            energy,
            waitingTimeMax,
            shuttleLanguage,
          } = values;
          const {
            selectedPlace: { address, origin, addressValid },
          } = location;
          const safeSpacing = !!safeMaxAttendees;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            publicData: {
              brand,
              descriptionFr,
              location: { address, addressValid },
              ...(isNewListingFlow ? { category: params.category } : {}),
              duration: +duration,
              attendees: +attendees,
              maxAttendees: +maxSeats,
              safeMaxAttendees: +safeMaxAttendees,
              typeOfVehicle,
              energy,
              waitingTimeMax,
              shuttleLanguage,
              // meetSafe,
              showForSearch:
                initialValues.showForSearchPub === true || initialValues.showForSearchPub === false
                  ? initialValues.showForSearchPub
                  : true,
              antiCovid: antiCovid,
              safeSpacing,
            },
          };

          setInitialValues({
            title,
            description,
            descriptionFr,
            brand,
            duration: +duration,
            attendees: +attendees,
            location: { search: address, selectedPlace: { address, origin, addressValid } },
            maxSeats,
            typeOfVehicle,
            energy,
            waitingTimeMax,
            shuttleLanguage,
            safeSpacing,
            antiCovid,
            // safeMaxAttendees,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditShuttleDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditShuttleDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditShuttleDescriptionPanel;
