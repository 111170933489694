import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 17 24"
      version="1.1"
      viewBox="0 0 17 24"
      xmlSpace="preserve"
    >
      <path d="M8.5 0C3.8 0 0 3.8 0 8.6c0 6.7 7.7 15 8 15.3.2 0 .3.1.5.1s.3-.1.5-.2c.3-.3 8-8.6 8-15.3C17 3.8 13.2 0 8.5 0zm0 13.2c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7z"></path>
      <image
        width="233"
        height="108"
        display="none"
        overflow="visible"
        transform="translate(-128.206 -47.353)"
      ></image>
    </svg>
  );
};

IconLocation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLocation.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLocation;
