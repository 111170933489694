import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    // showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    transaction,
    transactionDurationLabel,
    bookingBreakdownOpen,
    listingSpaceTitle,
  } = props;

  // return showDetailCardHeadings ? (
  //   <div className={css.detailCardHeadings}>
  //     <h2 className={css.detailCardTitle}>{listingTitle}</h2>
  //     {/* <p className={css.detailCardSubtitle}>{subTitle}</p> */}
  //     <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
  //   </div>
  // ) : null;

  const transactionStartDate =
    transaction && transaction.booking && transaction.booking.attributes.displayStart;
  const transactionEndDate =
    transaction && transaction.booking && transaction.booking.attributes.displayEnd;

  const listingPublicData =
    transaction && transaction.listing && transaction.listing.attributes.publicData;

  const { parentTitle, category } = listingPublicData;

  const transactionMetadata =
    (transaction && transaction.attributes && transaction.attributes.metadata) || {};
  const { pickUpLocation, destination } = transactionMetadata;
  const pickupUpLocationRes =
    pickUpLocation && typeof pickUpLocation === 'string'
      ? pickUpLocation
      : pickUpLocation && pickUpLocation.selectedPlace && pickUpLocation.selectedPlace.address;
  const destinationRes =
    destination && typeof destination === 'string'
      ? destination
      : destination && destination.selectedPlace && destination.selectedPlace.address;

  const listingTitleClasses = classNames(css.detailCardTitle, {
    [css.detailCardSpaceTitle]: category === 'space' && parentTitle,
  });

  return (
    <div className={css.detailCardHeadings}>
      {category === 'space' && parentTitle && (
        <h2 className={css.detailCardParentTitle}>{parentTitle}</h2>
      )}

      <div className={css.detailCardTitleHolder}>
        <div>
          <h2 className={listingTitleClasses}>{listingTitle}</h2>
          {listingSpaceTitle && <h3 className={css.detailsTitle}>{listingSpaceTitle}</h3>}
        </div>

        <div className={css.detailCardDate}>
          <div className={css.detailCardDateItem}>
            {moment(transactionStartDate).format('ll')}
            <br />
            {moment(transactionStartDate).format('LT')}
          </div>

          <div className={css.detailCardDateItemDivider}>-</div>

          <div className={css.detailCardDateItem}>
            {moment(transactionEndDate).format('ll')}
            <br />
            {moment(transactionEndDate).format('LT')}
          </div>
        </div>
      </div>
      {category === 'shuttle' && pickupUpLocationRes && destination && (
        <div className={css.detailCardLocation}>
          <div className={css.detailCardLocationItem}>
            <FormattedMessage id="TransactionPanel.from" />
            <span className={css.detailCardLocationValue}>{pickupUpLocationRes}</span>
          </div>
          <div className={css.detailCardLocationItem}>
            <FormattedMessage id="TransactionPanel.to" />
            <span className={css.detailCardLocationValue}>{destinationRes}</span>
          </div>
        </div>
      )}

      {bookingBreakdownOpen === false && (
        <span className={css.transactionDuration}>{transactionDurationLabel}</span>
      )}

      {/* <p className={css.detailCardSubtitle}>{subTitle}</p> */}

      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  );
};

export default DetailCardHeadingsMaybe;
