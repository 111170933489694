import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditVenueDescriptionForm } from '../../forms';

import css from './EditVenueDescriptionPanel.module.css';

const EditVenueDescriptionPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const isNew = params.type === 'new';

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, title, description } = currentListing.attributes;
    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, addressValid } = location;
    const brand = publicData && publicData.brand;
    const descriptionFr = publicData && publicData.descriptionFr;
    const meetSafe = publicData && publicData.rse;
    const rse = publicData && publicData.rse;
    const instantBooking = publicData && publicData.instantBooking;
    const locationType = publicData && publicData.locationType;
    const hotelCategory = publicData && publicData.hotelCategory && publicData.hotelCategory[0];
    const closeTo = publicData && publicData.closeTo;
    const facilities = publicData && publicData.facilities;
    const showForSearchPub = publicData && publicData.showForSearch;

    return {
      title,
      description,
      brand,
      descriptionFr,
      // meetSafe,
      showForSearchPub,
      rse,
      instantBooking,
      locationType,
      hotelCategory,
      closeTo,
      facilities,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, addressValid },
          }
        : null,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditVenueDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditVenueDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditVenueDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditVenueDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={(values) => {
          const { category } = params;
          const {
            title,
            description,
            descriptionFr,
            brand = null,
            location,
            // meetSafe,
            rse,
            instantBooking,
            locationType,
            hotelCategory,
            closeTo,
            facilities,
          } = values;
          const {
            selectedPlace: { address, origin, addressValid },
          } = location;
          // const antiCovid = meetSafe;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            publicData: {
              descriptionFr,
              brand,
              rse: rse,
              instantBooking,
              location: { address, addressValid },
              locationType: locationType && locationType.length ? locationType : null,
              hotelCategory: hotelCategory && hotelCategory.length ? [hotelCategory] : null,
              closeTo: closeTo && closeTo.length ? closeTo : null,
              facilities: facilities && facilities.length ? facilities : null,
              ...(isNew ? { category } : {}),
              showForSearch:
                initialValues.showForSearchPub === true || initialValues.showForSearchPub === false
                  ? initialValues.showForSearchPub
                  : true,
            },
          };

          setInitialValues({
            title,
            description,
            descriptionFr,
            brand,
            rse,
            instantBooking,
            // meetSafe: antiCovid,
            location: { search: address, selectedPlace: { address, origin, addressValid } },
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditVenueDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditVenueDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditVenueDescriptionPanel;
