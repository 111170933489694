import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, FieldDateInput, Form, InlineTextButton } from '../../components';

import css from '../../components/EditSpaceAvailabilityPanel/EditSpaceAvailabilityPanel.module.css';

const EditSpaceDowntimeForm = props => {
  const { initialValues, inProgress, isNextButtonDisabled, submitButtonText, onSubmit } = props;

  const addDowntimePeriod = (e, fields) => {
    e.preventDefault();
    fields.push({ spaceDowntimeStart: null, spaceDowntimeEnd: null });
  };

  const isDayBlocked = (endDate, startDate) => {
    return moment(endDate).isBefore(startDate);
  };

  const handleEndDateValue = (e, field, form, downtimeValues) => {
    const start = e.date;
    const end =
      downtimeValues && downtimeValues.spaceDowntimeEnd && downtimeValues.spaceDowntimeEnd.date;
    if (!!(start && end) && moment(end).isBefore(start)) {
      form.batch(() => {
        form.change(`${field}.spaceDowntimeEnd`, { date: null });
      });
    }
  };

  return (
    <>
      <section className={css.section}>
        <header className={css.sectionHeader}>
          <h2 className={css.sectionTitle}>
            <FormattedMessage id="EditListingAvailabilityPanel.spaceDowntimeLabel" />
          </h2>
        </header>
      </section>

      <FinalForm
        initialValues={initialValues}
        inProgress={inProgress}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={fieldRenderProps => {
          const { handleSubmit, inProgress, form } = fieldRenderProps;
          const downtimeFormValues = form.getState().values && form.getState().values.spaceDowntime;
          return (
            <Form className={css.downtimeForm} onSubmit={handleSubmit}>
              <FieldArray name="spaceDowntime">
                {({ fields }) => {
                  return (
                    <div>
                      {fields.map((field, idx) => {
                        return (
                          <div className={css.downtimeFieldsWrap}>
                            <FieldDateInput
                              name={`${field}.spaceDowntimeStart`}
                              id={`${field}.spaceDowntimeStart`}
                              label={'Start Date'}
                              placeholderText={'Wed, Apr 19'}
                              onChange={e => {
                                handleEndDateValue(e, field, form, downtimeFormValues[idx]);
                              }}
                            ></FieldDateInput>

                            <FieldDateInput
                              name={`${field}.spaceDowntimeEnd`}
                              id={`${field}.spaceDowntimeEnd`}
                              className={css.downtimeField}
                              label={'End Date'}
                              placeholderText={'Wed, Apr 19'}
                              isDayBlocked={e =>
                                isDayBlocked(e, downtimeFormValues[idx].spaceDowntimeStart.date)
                              }
                              disabled={
                                !downtimeFormValues[idx] ||
                                !downtimeFormValues[idx].spaceDowntimeStart ||
                                !downtimeFormValues[idx].spaceDowntimeStart.date
                              }
                            ></FieldDateInput>
                          </div>
                        );
                      })}
                      <InlineTextButton
                        className={css.addExceptionButton}
                        onClick={e => addDowntimePeriod(e, fields)}
                      >
                        <div className={css.plusIcon} />
                        <FormattedMessage id="EditListingAvailabilityPanel.addDowntimePeriod" />
                      </InlineTextButton>
                    </div>
                  );
                }}
              </FieldArray>
              <Button
                className={css.goToNextTabButton}
                disabled={isNextButtonDisabled}
                inProgress={inProgress}
                type="submit"
              >
                {submitButtonText}
              </Button>
            </Form>
          );
        }}
      />
    </>
  );
};

export default EditSpaceDowntimeForm;
