import React, { useEffect, useState } from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { FieldTextInput } from '../../components';
import config from '../../config';

import css from './BookingAddExtraForm.module.css';

const { Money } = sdkTypes;

const InputRow = (props) => {
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(0);
  const [price, setPrice] = useState(0);

  const { i, index, intl, type, id, form } = props;

  let amount = 0;
  let currency = config.currency;
  let name = '';
  let maxCount = 1;

  if (type === 'bedroom') {
    amount = i.bedroomPrice.amount;
    currency = i.bedroomPrice.currency;
    name = i.bedroomName;
    maxCount = 50;
  }

  if (type === 'extras') {
    amount = i.extrasPrice.amount;
    currency = i.extrasPrice.currency;
    name = i.extrasName;
    maxCount = 100;
  }

  useEffect(() => {
    const totalpriceToMoney = new Money(amount * count, currency);
    const formattedTotlaPrice = formatMoney(intl, totalpriceToMoney);
    setPrice(formattedTotlaPrice);
    setValue({ amount, currency, quantity: count });
    form.change(`${type}/${id}`, { amount, currency, quantity: count });
  }, [count]);

  const extraPriceToMoney = new Money(amount, currency);
  const formattedPrice = formatMoney(intl, extraPriceToMoney);

  const decrementClick = () => {
    if (count >= 1) {
      setCount(count - 1);
    }
  };

  const incrementClick = () => {
    if (count < maxCount) {
      setCount(count + 1);
    }
  };

  return (
    <div className={css.rowContainer}>
      <div className={css.nameContainer}>
        <span className={css.rowText}>
          {`${name}`} - <em>{formattedPrice}</em>
        </span>
      </div>
      <div className={css.inputContainer}>
        <button className={css.decrementButton} onClick={decrementClick} type="button" />
        <p className={css.numberText}>{count}</p>
        {/* <FieldTextInput
          type="number"
          name={`${name}${index}`}
          placeholder={0}
          disabled={true}
          className={css.numberInput}
          defaultValue={count}
        /> */}
        <button className={css.incrementButton} onClick={incrementClick} type="button" />
      </div>
      <div className={css.totalPriceContainer}>
        <FieldTextInput
          type="text"
          id={`${type}/${id}`}
          name={`${type}/${id}`}
          defaultValue={value}
          className={css.hideInput}
        />
        <p className={css.rowText}>{price}</p>
      </div>
    </div>
  );
};

export default InputRow;
