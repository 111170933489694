import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ValidationError } from '..';
import debounce from 'lodash/debounce';
// import * as validators from '../../util/validators';
import { required, isPercents } from '../../util/validators';
import { Form, FieldTextInput, FieldCheckbox, FieldCurrencyInput, FieldTimePeriod } from '..';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import config from '../../config';

import css from './FieldPriceTable.module.css';
import { isNumber } from 'lodash';

const DELAY = 200;

const createName = (type, name, pricingSchemeKeys) => {
  return `FieldPriceTable_${name}__${type}__${pricingSchemeKeys}`;
};

const getName = (name) => {
  return name.split('__').slice(1);
};

class FieldRow extends Component {
  render() {
    const {
      item,
      name,
      pricingSchemeKeys,
      intl,
      values,
      form,
      isMobile,
      isDowntimePrice,
      isCorporateRate,
      isVatRate,
      vatLabel,
      vatPlaceholder,
      vatValid,
    } = this.props;
    const { label, key, noDuration } = item;

    const placeholder = intl.formatMessage({ id: `FieldPriceTable.pricePlaceholder` });
    // const requiredMessage = intl.formatMessage({id: `FieldPriceTable.valueRequired`});

    const checkboxName = createName('c', name, pricingSchemeKeys);
    const durationName = createName('d', name, pricingSchemeKeys);
    const standartPriceName = createName('sp', name, pricingSchemeKeys);
    const downtimePriceName = createName('dp', name, pricingSchemeKeys);
    const corporatePriceName = createName('cp', name, pricingSchemeKeys);
    const vatRateName = createName('vr', name, pricingSchemeKeys);
    const minGuestsName = createName('minGuests', name, pricingSchemeKeys);
    const maxGuestsName = createName('maxGuests', name, pricingSchemeKeys);
    const isActive = values[checkboxName] && values[checkboxName].length;
    const pricesKey = isActive ? 'active' : 'inactive';

    const cb = (
      <FieldCheckbox
        className={css.checkbox}
        id={checkboxName}
        name={checkboxName}
        label={intl.formatMessage({ id: `ConfigFilter.${key}` }) || label}
        value={key}
      />
    );

    const dr = !noDuration ? (
      <FieldTimePeriod
        key={`${pricesKey}D`}
        name={durationName}
        form={form}
        disabled={!isActive}
        validate={required('required')}
        itemKey={key}
      />
    ) : null;

    const sp = (
      <FieldCurrencyInput
        key={`${pricesKey}SP`}
        className={css.currencyInput}
        name={standartPriceName}
        placeholder={placeholder}
        disabled={!isActive}
        currencyConfig={config.currencyConfig}
        validate={required('required')}
      />
    );

    const dp = (
      <FieldCurrencyInput
        key={`${pricesKey}DP`}
        className={css.currencyInput}
        name={downtimePriceName}
        placeholder={placeholder}
        disabled={!isActive}
        currencyConfig={config.currencyConfig}
      />
    );

    const cp = (
      <FieldCurrencyInput
        key={`${pricesKey}CP`}
        className={css.currencyInput}
        name={corporatePriceName}
        placeholder={placeholder}
        disabled={!isActive}
        currencyConfig={config.currencyConfig}
      />
    );

    const vr = (
      <FieldTextInput
        key={`${pricesKey}VR`}
        className={css.currencyInput}
        name={vatRateName}
        placeholder={vatPlaceholder}
        disabled={!isActive}
        validate={isPercents(vatValid)}
      />
    );

    const minGuestsInput = (
      <FieldTextInput
        key={`${pricesKey}MinGuests`}
        className={css.currencyInput}
        name={minGuestsName}
        type="number"
        placeholder={'Min'}
        defaultValue={1}
        disabled={!isActive}
      />
    );

    const maxGuestsInput = (
      <FieldTextInput
        key={`${pricesKey}MaxGuests`}
        className={css.currencyInput}
        name={maxGuestsName}
        type="number"
        placeholder={'Max'}
        defaultValue={100}
        disabled={!isActive}
      />
    );

    if (isMobile) {
      return (
        <>
          <tr className={css.tableRow}>
            <td>{cb}</td>
            <td>{dr}</td>
          </tr>
          <tr className={css.tableRow}>
            <td>
              <span className={css.label}>
                {intl.formatMessage({
                  id: isPerPerson
                    ? 'FieldPriceTable.tableHeaderStandartPerPersonPrice'
                    : 'FieldPriceTable.tableHeaderStandartPrice',
                })}
              </span>
            </td>
            <td>{sp}</td>
          </tr>
          {isDowntimePrice ? (
            <tr className={css.tableRow}>
              <td>
                <span className={css.label}>
                  {intl.formatMessage({ id: 'FieldPriceTable.tableHeaderDowntimePrice' })}
                </span>
              </td>
              <td>{dp}</td>
            </tr>
          ) : null}
          {isCorporateRate ? (
            <tr className={classNames(css.tableRow, css.lastRow)}>
              <td>
                <span className={css.label}>
                  {intl.formatMessage({ id: 'FieldPriceTable.tableHeaderCorporate' })}
                </span>
              </td>
              <td>{cp}</td>
            </tr>
          ) : null}
          {isVatRate ? (
            <tr className={classNames(css.tableRow, css.lastRow)}>
              <td>
                <span className={css.label}>{vatLabel}</span>
              </td>
              <td>{vr}</td>
            </tr>
          ) : null}
        </>
      );
    }

    return (
      <>
        <tr className={css.tableRow}>
          <td>
            {cb}
            {Array.isArray(item.packageDetails) && item.packageDetails.length > 0 && (
              <span className={css.packageDetails}>{item?.packageDetails?.join(' + ')}</span>
            )}
          </td>
          <td>{dr}</td>
          <td>{sp}</td>
          {isDowntimePrice ? <td>{dp}</td> : null}
          {isCorporateRate ? <td>{cp}</td> : null}
          {isVatRate ? <td>{vr}</td> : null}
        </tr>
      </>
    );
  }
}

class FieldPriceTableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prices: {},
      initialValues: {},
      isMobile: false,
    };

    this._mounted = false;
    this.handleWindowSizeDb = debounce(this.handleWindowSize.bind(this), DELAY);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    if (typeof window !== undefined) {
      window.addEventListener('resize', this.handleWindowSizeDb);
    }

    this.setState({ initialValues: this.initialValues() });
    this.handleWindowSize();
  }

  componentWillUnmount() {
    this._mounted = false;
    if (typeof window !== undefined) {
      window.removeEventListener('resize', this.handleWindowSizeDb);
    }
  }

  handleWindowSize() {
    if (!this._mounted || typeof window === undefined) {
      return;
    }

    this.setState({ isMobile: window.matchMedia('(max-width: 768px)').matches });
  }

  initialValues() {
    const {
      input: { value, name },
      items,
      packageItems = [],
    } = this.props;
    const structure = (value && value.priceScheme) || {};
    const initialValues = {};
    Object.keys(structure).forEach((elementkey) => {
      const { key: pricingSchemeKeys, label } =
        [...items, ...packageItems].find((item) => item.key === elementkey) || {};
      if (!pricingSchemeKeys) {
        return;
      }
      const checkboxName = createName('c', name, pricingSchemeKeys);
      const durationName = createName('d', name, pricingSchemeKeys);
      const standartPriceName = createName('sp', name, pricingSchemeKeys);
      const downtimePriceName = createName('dp', name, pricingSchemeKeys);
      const corporatePriceName = createName('cp', name, pricingSchemeKeys);
      const vatRateName = createName('vr', name, pricingSchemeKeys);
      const minGuestsName = createName('minGuests', name, pricingSchemeKeys);
      const maxGuestsName = createName('maxGuests', name, pricingSchemeKeys);

      initialValues[checkboxName] = [elementkey];
      initialValues[durationName] = structure[elementkey].duration;
      initialValues[standartPriceName] = structure[elementkey].standartPrice;
      initialValues[downtimePriceName] = structure[elementkey].downtimePrice;
      initialValues[corporatePriceName] = structure[elementkey].corporatePrice;
      initialValues[vatRateName] = structure[elementkey].vatRate;
      initialValues[minGuestsName] = structure[elementkey].minGuestsName;
      initialValues[maxGuestsName] = structure[elementkey].maxGuestsName;
    });
    return initialValues;
  }

  checkDiffs(newPrices) {
    const { prices } = this.state;

    return !isEqual(prices, newPrices);
  }

  checkValues(values, name, form, isDowntimePrice, isCorporateRate, isVatRate) {
    Object.keys(values).forEach((key) => {
      const [type, pricingSchemeKeys] = getName(key);
      const value = values[key];

      if (type !== 'c' || (type === 'c' && value.length)) {
        return;
      }

      const durationName = createName('d', name, pricingSchemeKeys);
      const standartPriceName = createName('sp', name, pricingSchemeKeys);
      const downtimePriceName = createName('dp', name, pricingSchemeKeys);
      const corporatePriceName = createName('cp', name, pricingSchemeKeys);
      const vatRateName = createName('vr', name, pricingSchemeKeys);
      const minGuestsName = createName('minGuests', name, pricingSchemeKeys);
      const maxGuestsName = createName('maxGuests', name, pricingSchemeKeys);

      form.batch(() => {
        form.change(durationName, null);
        form.change(standartPriceName, '');
        isDowntimePrice && form.change(downtimePriceName, '');
        isCorporateRate && form.change(corporatePriceName, '');
        isVatRate && form.change(vatRateName, '');
      });
    });
  }

  createStructure(values, isDowntimePrice, isCorporateRate, isVatRate) {
    const {
      input: { name },
      safeSpacing,
      form,
    } = this.props;
    const priceScheme = {};

    Object.keys(values).forEach((key) => {
      const [type, pricingSchemeKeys] = getName(key);

      const checkboxName = createName('c', name, pricingSchemeKeys);
      const durationName = createName('d', name, pricingSchemeKeys);
      const standartPriceName = createName('sp', name, pricingSchemeKeys);
      const downtimePriceName = createName('dp', name, pricingSchemeKeys);
      const corporatePriceName = createName('cp', name, pricingSchemeKeys);
      const vatRateName = createName('vr', name, pricingSchemeKeys);
      const minGuestsName = createName('minGuests', name, pricingSchemeKeys);
      const maxGuestsName = createName('maxGuests', name, pricingSchemeKeys);

      const checkbox = values[checkboxName];

      if (checkbox && checkbox.length && !priceScheme[checkbox[0]]) {
        const duration = values[durationName];
        const standartPrice = values[standartPriceName];
        const downtimePrice = values[downtimePriceName];
        const corporatePrice = values[corporatePriceName];
        const vatRate = values[vatRateName];
        const minGuests = values[minGuestsName];
        const maxGuests = values[maxGuestsName];

        priceScheme[checkbox[0]] = {
          duration,
          standartPrice,
        };

        if (isDowntimePrice) priceScheme[checkbox[0]].downtimePrice = downtimePrice;
        if (isCorporateRate) priceScheme[checkbox[0]].corporatePrice = corporatePrice;
        if (isVatRate) priceScheme[checkbox[0]].vatRate = vatRate;
      }
    });

    const prices = {
      priceScheme,
      prices: Object.keys(priceScheme),
    };

    // items.forEach(({key}) => {
    //   prices[`${key}Value`] = structure[key] && structure[key].value || null;
    //   prices[`${key}SafeValue`] = structure[key] && structure[key].safeValue || null;
    // })

    if (this.checkDiffs(prices)) {
      this.setState({ prices }, () => {
        form.change(name, prices);
      });
    }
  }

  handleChange({ values }, innerForm) {
    const {
      input: { name },
      isDowntimePrice,
      isCorporateRate,
      isVatRate,
    } = this.props;
    this.checkValues(values, name, innerForm, isDowntimePrice, isCorporateRate, isVatRate);
    this.createStructure(values, isDowntimePrice, isCorporateRate, isVatRate);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      placeholder,
      form,
      items,
      intl,
      isDowntimePrice,
      isCorporateRate,
      isVatRate,
      packageItems,
      title,
      isPerPerson,
      ...rest
    } = this.props;

    const { initialValues, isMobile } = this.state;

    const tabTitle = title ? intl.formatMessage({ id: title }) : '';
    const tableHeaderStandartPrice = intl.formatMessage({
      id: isPerPerson
        ? 'FieldPriceTable.tableHeaderStandartPerPersonPrice'
        : 'FieldPriceTable.tableHeaderStandartPrice',
    });
    const tableHeaderDowntimePrice = intl.formatMessage({
      id: 'FieldPriceTable.tableHeaderDowntimePrice',
    });
    const tableHeaderCorporate = intl.formatMessage({ id: 'FieldPriceTable.tableHeaderCorporate' });

    const vatLabel = intl.formatMessage({ id: 'EditSocialPricingForm.vatInputLabel' });
    const vatPlaceholder = intl.formatMessage({
      id: 'EditSocialPricingForm.vatInputPlaceholder',
    });
    const vatValid = intl.formatMessage({ id: 'EditSpacePricingForm.vatValid' });

    const { value, touched: inputTouched } = this.state;
    /* eslint-enable no-unused-vars */

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError =
      (inputTouched && errorText) || !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;

    const wrapperClasses = classNames(
      css.inputsWrapper
      // {
      //   [css.inputSuccess]: valid,
      //   [css.inputError]: hasError,
      // }
    );

    const inputHiddenProps = {
      // defaultValue,
      ...inputWithoutValue,
      ...rest,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {label && <label>{label}</label>}

        <FinalForm
          onSubmit={() => {}}
          initialValues={initialValues}
          render={({ form, values }) => {
            return (
              <div className={css.tableWrapper}>
                <FormSpy onChange={(values) => this.handleChange(values, form)} />

                <table className={css.table}>
                  {!isMobile && (
                    <thead>
                      <tr className={css.tableRowHead}>
                        <th></th>
                        <th>
                          <span className={css.absoluteHead}>{tabTitle}</span>
                        </th>
                        <th>{tableHeaderStandartPrice}</th>
                        {isDowntimePrice ? <th>{tableHeaderDowntimePrice}</th> : null}
                        {isCorporateRate ? <th>{tableHeaderCorporate}</th> : null}
                        {isVatRate ? <th>{vatLabel}</th> : null}
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {items.map((item, i) => (
                      <FieldRow
                        key={item.key}
                        item={item}
                        pricingSchemeKeys={item.key}
                        name={input.name}
                        intl={intl}
                        form={form}
                        values={values}
                        isMobile={isMobile}
                        isDowntimePrice={isDowntimePrice}
                        isCorporateRate={isCorporateRate}
                        isVatRate={isVatRate}
                        vatLabel={vatLabel}
                        vatPlaceholder={vatPlaceholder}
                        vatValid={vatValid}
                      />
                    ))}
                    {Array.isArray(packageItems) && packageItems.length ? (
                      <>
                        <div className={css.packageTitle}>
                          <span>{intl.formatMessage({ id: 'FieldPriceTable.packageTitle' })}</span>
                        </div>

                        {packageItems.map((item, i) => {
                          const checkboxName = createName('c', input.name, item.key);
                          const isChecked = values[checkboxName] && values[checkboxName].length;

                          return (
                            <>
                              <FieldRow
                                pricingSchemeKeys={item.key}
                                key={item.key}
                                item={item}
                                name={input.name}
                                intl={intl}
                                form={form}
                                values={values}
                                isMobile={isMobile}
                                isDowntimePrice={isDowntimePrice}
                                isCorporateRate={isCorporateRate}
                                isVatRate={isVatRate}
                                vatLabel={vatLabel}
                                vatPlaceholder={vatPlaceholder}
                                vatValid={vatValid}
                              />
                            </>
                          );
                        })}
                      </>
                    ) : null}
                  </tbody>
                </table>
              </div>
            );
          }}
        />

        <input value={value} {...inputHiddenProps} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldPriceTableComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
};

FieldPriceTableComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
  }).isRequired,
  meta: object.isRequired,
  form: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

class FieldPriceTable extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldPriceTableComponent} {...this.props} type="hidden" />;
  }
}

export default compose(injectIntl)(FieldPriceTable);
