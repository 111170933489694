import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAlert } from '../../components';

import css from './TransactionStatusBadge.module.css';

const TransactionStatusBadge = props => {
  // Note: now only one status, according to the design

  const { rootClassName, className, status } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.pending}>
        <IconAlert />
        <FormattedMessage id="TransactionStatusBadge.pending" />
      </div>
    </div>
  );
};

TransactionStatusBadge.defaultProps = {
  rootClassName: null,
  className: null,
};

TransactionStatusBadge.propTypes = {
  rootClassName: string,
  className: string,
  status: string,
};

export default TransactionStatusBadge;
