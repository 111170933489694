import React, { useState } from 'react';
import { object, string, filterConfig } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import css from './ListingPage.module.css';

import noImage from '../../components/EditVenueSpacesPanel/noimage.png';
import sun from '../../assets/icon/Spaces_amenities/sun.svg';
import banquetIcon from '../../assets/icon/banquet.svg';
import boardroomIcon from '../../assets/icon/boardroom.svg';
import classroomIcon from '../../assets/icon/classroom.svg';
import receptionIcon from '../../assets/icon/reception.svg';
import theatreIcon from '../../assets/icon/theatre.svg';
import ushapeIcon from '../../assets/icon/ushape.svg';
import coworkingIcon from '../../assets/icon/coworking.svg';

import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import soundSystem from '../../assets/icon/Spaces_amenities/sound_system.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import paperBoard from '../../assets/icon/Spaces_amenities/paper_board.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import paper from '../../assets/icon/Spaces_amenities/paper.svg';
import hydroalcoholicGel from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import projector from '../../assets/icon/Spaces_amenities/projector.svg';
import pens from '../../assets/icon/Spaces_amenities/pen.svg';
import screen from '../../assets/icon/Spaces_amenities/screen.svg';
import water from '../../assets/icon/Spaces_amenities/water.svg';

import videoconference from '../../assets/icon/videoconferenceWhite.svg';
import { languagesHelper as language } from '../../helpers/languages';

const { Money } = sdkTypes;

const SPACE_MAIN_OPTIONS_LIST = {
  soundSystem,
  wifi,
  paperBoard,
  television,
  paper,
  conditionedAir,
  hydroalcoholicGel,
  projector,
  pens,
  screen,
  water,
};
const SPACE_INTERNET_SPEED_OPTIONS = ['slowInternet', 'normalInternet', 'highSpeedInternet'];
const SPACE_VIRTUAL_SERVICES_OPTIONS = [
  'tvStudio',
  'greenScreen',
  'videoConferenceDevice',
  'avTechnicianOnSite',
];

const icons = {
  board: boardroomIcon,
  class: classroomIcon,
  banquet: banquetIcon,
  theatre: theatreIcon,
  ushape: ushapeIcon,
  reception: receptionIcon,
  coworking: coworkingIcon,
};

const getStyles = (values) => {
  return icons[values];
};

const MenuCardListing = (props) => {
  const {
    className,
    rootClassName,
    listing,
    mobileLayout,
    filterConfig,
    selectListing,
    isSelected,
    intl,
    isSafeSpacingClicked,
  } = props;

  const classes = classNames(
    rootClassName || isSelected ? css.rootSpacescardSelected : css.rootSpacescard,
    className
  );
  const id = listing.id.uuid;
  const { state, publicData, title } = listing.attributes;
  const { category, sizes, styles, pubServices, priceScheme, prices, menuItems } = publicData;
  const slug = createSlug(title);
  const image = listing.images[0] && listing.images[0].attributes.variants['landscape-crop2x'].url;
  const allPrices = [];

  if (prices && prices.length > 0) {
    prices.map((item) => {
      Object.values(priceScheme[item])
        .filter((i) => i !== null)
        .map((i) => {
          if (i.amount) {
            allPrices.push(i.amount);
          }
        });
    });
  }

  const minPrice = (allPrices.length > 0 && Math.min(...allPrices)) || null;
  const price = minPrice && new Money(minPrice, config.currency);
  const formattedPrice = minPrice ? formatMoney(intl, price) : null;

  const isDraft = state === LISTING_STATE_DRAFT;
  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const options = findOptionsForSelectFilter('pubServices', filterConfig);

  const filterOption = () =>
    options
      .filter(
        (item) =>
          typeof pubServices[item.key] !== 'undefined' &&
          typeof SPACE_MAIN_OPTIONS_LIST[item.key] !== 'undefined'
      )
      .map((item) => {
        return {
          key: item.key,
          label: item.label,
          img: SPACE_MAIN_OPTIONS_LIST[item.key],
          isFree: pubServices[item.key].isFree || false,
        };
      });

  return (
    <div className={classes}>
      <div className={css.childrenInfoContainer}>
        {isDraft ? (
          <div className={css.draftContainer}>
            <p className={css.draftText}>
              <FormattedMessage id="SpaceCard.draft" />
            </p>
          </div>
        ) : null}
        <div className={css.imageSection}>
          <div className={css.imageContainer}>
            <img src={image ? image : noImage} alt="Listing image" className={css.listingImage} />
          </div>
          <div className={css.imagePriceContainer}>
            <p className={css.cardPrice}>
              {minPrice ? (
                <FormattedMessage id="SpaceCard.priceFrom" values={{ price: formattedPrice }} />
              ) : (
                <FormattedMessage id="SpaceCard.noPrice" />
              )}
            </p>
          </div>
          {styles ? (
            <div className={css.listingStylesWrapper}>
              {styles.map((item, index) => {
                return (
                  <div className={css.listingStylesContainer} key={index}>
                    <img
                      src={getStyles(item)}
                      alt="listing styles"
                      className={css.listingStylesImage}
                    />
                    <p className={css.listingStyleNumber}>
                      {isSafeSpacingClicked && isSelected
                        ? publicData.structure[item].safeValue
                        : publicData.structure[item].value}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className={classNames(css.sizeContainer, 'flex flex-col gap-3')}>
          <h3 className={css.listingTitle}>{title}</h3>
          {menuItems?.items && (
            <div class="flex flex-col gap-1 text-xs text-slate-500">
              {menuItems.items.map((item) => (
                <div>
                  <span className="font-bold">
                    <FormattedMessage id={`ConfigFilter.${item}`} />:{' '}
                  </span>
                  <span>{menuItems[item]}</span>
                </div>
              ))}
            </div>
          )}
          {!!Object.keys(pubServices)?.length && (
            <div class="flex gap-1 text-xs text-slate-500">
              {Object.keys(pubServices).map((service, index) => (
                <span>
                  <FormattedMessage id={`Services.${service}`} />
                  {index === Object.keys(pubServices).length - 1 ? '.' : ','}
                </span>
              ))}
            </div>
          )}
        </div>
        <button
          type="button"
          className={isSelected ? css.checkedButton : css.buttonForSelect}
          onClick={() => selectListing(id)}
        >
          <div className={isSelected ? css.circleChecked : css.circle} />
        </button>
      </div>
    </div>
  );
};

MenuCardListing.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  filterConfig: config.custom.filters,
};

MenuCardListing.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,
  filterConfig: filterConfig,
};

export default MenuCardListing;
