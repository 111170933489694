import React from 'react';
import getVideoId from 'get-video-id';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage } from '../../components';
import classNames from 'classnames';
import css from './ListingPage.module.css';

const videoIdReplaceToken = '{{VIDEOID}}';

const serviceBase = {
  youtube: `https://www.youtube.com/embed/${videoIdReplaceToken}`,
  vimeo: `https://player.vimeo.com/video/${videoIdReplaceToken}`,
};

const VideoHeading = props => {
  const {
    hasImages,
    firstImage,
    secondImage,
    handleViewPhotosClick,
    handleViewPhotosButtonClick,
    listing,
    title,
    publicData: { videos },
  } = props;

  if (!videos) {
    return null;
  }

  const videoIds = videos
    .map(url => getVideoId(url))
    .filter(({ id, service }) => !!id && !!service);

  // const vid_id = videoIds[currentVideoIndex];
  const { id, service } = videoIds[0];
  const vid_url = serviceBase[service].replace(videoIdReplaceToken, id);

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosButtonClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const classes = classNames(css.videoHeading, { [css.videoHeadingWithTwoImages]: secondImage });

  return (
    <div className={classes}>
      <div className={css.videoFrame}>
        <iframe
          title="Video Carousel Item"
          key={vid_url}
          className={css.videoWrapper}
          frameBorder="0"
          src={vid_url}
          allow="
              accelerometer;
              autoplay;
              encrypted-media;
              gyroscope;
              picture-in-picture
            "
          allowFullScreen
        />
      </div>

      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
          <ResponsiveImage
            rootClassName={css.singleRootForImage}
            alt={title}
            slideindex={0}
            image={firstImage}
            variants={[
              'landscape-crop',
              'landscape-crop2x',
              'landscape-crop4x',
              'landscape-crop6x',
            ]}
          />
        </div>
        {secondImage && (
          <div className={css.aspectWrapper} onClick={handleViewPhotosClick}>
            <ResponsiveImage
              rootClassName={css.singleRootForImage}
              alt={title}
              slideindex={1}
              image={secondImage}
              variants={[
                'landscape-crop',
                'landscape-crop2x',
                'landscape-crop4x',
                'landscape-crop6x',
              ]}
            />
          </div>
        )}
        {viewPhotosButton}
      </div>
    </div>
  );
};

export default VideoHeading;
