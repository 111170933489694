import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { NamedLink, ImagedLink } from '../../components';

import css from './SectionLocations.module.css';

// import footGolf from './images/football.jpg';
// import wine from './images/wine.jpg';
// import yoga from './images/yoga.jpg';

import footGolf from './images/face2face.jpg';
import wine from './images/hybrid.jpg';
import yoga from './images/stream.jpg';

const locations = [
  {
    key: 'yoga',
    src: yoga,
    link:
      'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
  },
  {
    key: 'wine',
    src: wine,
    link:
      'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
  },
  {
    key: 'footGolf',
    src: footGolf,
    link:
      'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
  },
];

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionLocations.title" />
        </h2>
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
          }}
          className={css.goAll}
        >
          <FormattedMessage id="SectionLocations.locationsAll" />
        </NamedLink> */}

        <a href="#" className={css.goAll}>
          <FormattedMessage id="SectionLocations.locationsAll" />
        </a>
      </div>
      <div className={css.locations}>
        <div className={css.locationsWrapper}>
          {locations.map(location => (
            <div className={css.location}>
              <ImagedLink
                sectionName="SectionLocations"
                key={location.key}
                name={location.key}
                image={location.src}
                searchQuery={location.link}
                disabled
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
