import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '..';

import css from './SectionNews.module.css';

import businessbuilding from './images/businessbuilding.jpg';
import businessteam from './images/businessteam.jpg';
import businessdocuments from './images/businessdocuments.jpg';
import businessvideocall from './images/businessvideocall.jpg';
import thelouvre from './images/thelouvre.jpg';
import hands from './images/hands.jpg';

import distance from './svg/distance.svg';
import medical from './svg/medical.svg';
import spray from './svg/spray.svg';

const SingleNews = ({
  title = 'YEM Innovative Lab 4 - La transformation du MICE, c’est possible!',
  src,
  date = 'September 25th, 2021',
  link = '#',
}) => {
  return (
    // <NamedLink name="SearchPage" to={link} className={css.singleNews}>
    <div className={css.singleNews}>
      <div className={css.newsImage}>
        <img src={src} alt={src} />
      </div>
      <div className={css.newsTitle}>
        <FormattedMessage id={title} />
        <div className={css.newsSubtitle}>
          <FormattedMessage id={date} />
        </div>
      </div>
    </div>
    // </NamedLink>
  );
};

const PopLink = ({ title = 'Popular Link Title', src, link = '#', button = 'Explore All' }) => {
  return (
    <div className={css.popLink}>
      <div className={css.popLinkBackground}>
        <img src={src} alt={src} />
      </div>
      <div className={css.popLinkTitle}>
        <FormattedMessage id={`SectionNews.${title}`} values={{ lineBreak: <br /> }} />
      </div>
      {/* <NamedLink name="SearchPage" to={link} className={css.popLinkButton}> */}
      <div className={css.popLinkButton}>
        <FormattedMessage id={`SectionNews.${button}`} values={{ lineBreak: <br /> }} />
      </div>
      {/* </NamedLink> */}
    </div>
  );
};

const SectionNews = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.newsWrapper}>
        <div className={css.news}>
          <h2 className={css.title}>
            <FormattedMessage id="SectionNews.title" />
          </h2>

          <SingleNews src={businessbuilding} />
          <SingleNews src={businessteam} />
          <SingleNews src={businessdocuments} />
          <SingleNews src={businessvideocall} />
        </div>

        <div className={css.popularLinks}>
          {/* <NamedLink name="SearchPage" to="#" className={classNames(css.popLink, css.covidPrivacy)}> */}
          <div className={classNames(css.popLink, css.covidPrivacy)}>
            <div className={css.covidTitle}>
              <FormattedMessage id="SectionNews.covid" values={{ lineBreak: <br /> }} />
            </div>
            <div className={css.covidRules}>
              <div className={css.covidRule}>
                <div className={css.covidImage}>
                  <img src={medical} alt="svg" />
                </div>
                <FormattedMessage id="SectionNews.protection" />
              </div>
              <div className={css.covidRule}>
                <div className={css.covidImage}>
                  <img src={distance} alt="svg" />
                </div>
                <FormattedMessage id="SectionNews.distance" />
              </div>
              <div className={css.covidRule}>
                <div className={css.covidImage}>
                  <img src={spray} alt="svg" />
                </div>
                <FormattedMessage id="SectionNews.cleaning" />
              </div>
            </div>
          </div>
          {/* </NamedLink> */}
          <PopLink title="top20" button="exploreAll" src={thelouvre} />
          <PopLink title="ourGreen" button="exploreAll" src={hands} />
        </div>
      </div>
    </div>
  );
};

SectionNews.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionNews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNews;
