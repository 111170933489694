import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './TabNavBookings.module.css';

const Tab = props => {
  const {
    className,
    id,
    disabled,
    text,
    selected,
    selectedRequest,
    linkProps,
    activeLinkClass,
    subTabs,
    history,
    onGetTransactions,
    onVenueTransactionsChange,
  } = props;
  const linkClasses = classNames(css.link, {
    [css.selectedLink]: selected,
    [css.disabled]: disabled,
    [activeLinkClass]: activeLinkClass && selected,
  });

  const [showSubTabs, setShowSubTabs] = useState(false);

  const onTabClick = () => {
    setShowSubTabs(true);

    const routes = routeConfiguration();
    const orderDetailsPath = pathByRouteName(linkProps.name, routes, linkProps.params);
    history.push(orderDetailsPath);
  };

  const onSubTabClick = linkProps => {
    // if (linkProps.transactions) {
    //   onVenueTransactionsChange(linkProps.transactions);
    //   return;
    // }

    if (linkProps.transactions) {
      onVenueTransactionsChange(linkProps.transactions);
    }
    onGetTransactions({ ...linkProps.params });
  };

  return subTabs ? (
    <div>
      <div id={id} className={className}>
        <div className={linkClasses} onClick={onTabClick}>
          {text}
        </div>
      </div>
      {showSubTabs &&
        (selected || selectedRequest) &&
        subTabs.map((tab, index) => {
          const id = typeof tab.id === 'string' ? tab.id : `${index}`;
          const subLinkClasses = classNames(css.subLink, {
            [css.selectedLink]: tab.selectedSubTab,
            [css.disabled]: disabled,
            [activeLinkClass]: tab.activeLinkClass && tab.selectedSubTab,
          });
          return (
            <div id={id} className={css.subTab} key={id}>
              {tab.type === 'feature' ? (
                <div className={subLinkClasses} onClick={() => onSubTabClick(tab.linkProps)}>
                  {tab.text}
                </div>
              ) : (
                <NamedLink className={subLinkClasses} {...tab.linkProps}>
                  {tab.text}
                </NamedLink>
              )}
            </div>
          );
        })}
    </div>
  ) : (
    <div id={id} className={className}>
      <NamedLink className={linkClasses} {...linkProps}>
        {text}
      </NamedLink>
    </div>
  );
};

Tab.defaultProps = { className: null, disabled: false, selected: false };

const { arrayOf, bool, node, object, string, shape, func } = PropTypes;

Tab.propTypes = {
  id: string.isRequired,
  className: string,
  text: node.isRequired,
  disabled: bool,
  selected: bool,
  linkProps: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const TabNavBookings = props => {
  const {
    className,
    rootClassName,
    tabRootClassName,
    tabs,
    activeLinkClass,
    history,
    onGetTransactions,
    onVenueTransactionsChange,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const id = typeof tab.id === 'string' ? tab.id : `${index}`;
        return (
          <Tab
            activeLinkClass={activeLinkClass}
            key={id}
            id={id}
            className={tabClasses}
            {...tab}
            history={history}
            onGetTransactions={onGetTransactions}
            onVenueTransactionsChange={onVenueTransactionsChange}
          />
        );
      })}
    </nav>
  );
};

TabNavBookings.defaultProps = {
  className: null,
  rootClassName: null,
  tabRootClassName: null,
  tabClassName: null,
};

TabNavBookings.propTypes = {
  className: string,
  rootClassName: string,
  tabRootClassName: string,
  tabs: arrayOf(object).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default TabNavBookings;
