import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  validStyles,
  validMenuItems,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldRadioButton,
  FieldBoolean,
  FieldMenusItems,
} from '../../components';
import config from '../../config';

import css from './EditMenuDescriptionForm.module.css';

import banquetIcon from '../../assets/icon/banquet.svg';
import boardroomIcon from '../../assets/icon/boardroom.svg';
import classroomIcon from '../../assets/icon/classroom.svg';
import receptionIcon from '../../assets/icon/reception.svg';
import theatreIcon from '../../assets/icon/theatre.svg';
import amphitheaterIcon from '../../assets/icon/amphitheater.svg';
import ushapeIcon from '../../assets/icon/ushape.svg';
import coworkingIcon from '../../assets/icon/coworking.svg';
import { mealType } from '../../marketplace-custom-config';

const TITLE_MAX_LENGTH = 60;

const identity = (v) => v;

const { menuItems } = config.custom;

const icons = {
  meetings: [
    boardroomIcon,
    classroomIcon,
    banquetIcon,
    theatreIcon,
    amphitheaterIcon,
    ushapeIcon,
    receptionIcon,
  ],
  coworking: [coworkingIcon],
};

const EditMenuDescriptionFormComponent = (props) => (
  <FinalForm
    {...props}
    initialValues={props.initialValues}
    render={(formRenderProps) => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        isNew,
        errors,
      } = formRenderProps;

      const seatingLabel = intl.formatMessage({ id: 'EditMenuDescriptionForm.seatingLabel' });
      const standingLabel = intl.formatMessage({ id: 'EditMenuDescriptionForm.standingLabel' });

      const minNumberOfGuestsLabel = intl.formatMessage({
        id: 'EditMenuDescriptionForm.minNumberOfGuestsLabel',
      });
      const maxNumberOfGuestsLabel = intl.formatMessage({
        id: 'EditMenuDescriptionForm.maxNumberOfGuestsLabel',
      });

      const titleMessage = intl.formatMessage({ id: 'EditMenuDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditMenuDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditMenuDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditMenuDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const menuItemsTitle = intl.formatMessage({
        id: 'EditMenuDescriptionForm.menuItemsTitle',
      });
      const menuItemsRequiredMessage = intl.formatMessage({
        id: 'EditMenuDescriptionForm.menuItemsRequiredMessage',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditMenuDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditMenuDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditMenuDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className, 'flex flex-col gap-4');
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const showAsRequired = pristine;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div>
            <label className={css.label}>
              <FormattedMessage id="EditMenuDescriptionForm.titleType" />
            </label>
            <div className={css.radioButtonRow}>
              {mealType.map((type) => (
                <FieldRadioButton
                  className={css.radio}
                  id={type.key}
                  name="mealType"
                  label={intl.formatMessage({
                    id: `ConfigFilter.${type.key}`,
                  })}
                  value={type.key}
                  showAsRequired={showAsRequired}
                />
              ))}
              {/* <FieldRadioButton
                className={css.radio}
                id="seating"
                name="menuType"
                label={seatingLabel}
                value="seating"
                showAsRequired={showAsRequired}
                // disabled={!isNew}
              />
              <FieldRadioButton
                className={css.radio}
                id="standing"
                name="menuType"
                label={standingLabel}
                value="standing"
                showAsRequired={showAsRequired}
                //disabled={!isNew}
              /> */}
            </div>
          </div>

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <div class="flex gap-4">
            <FieldTextInput
              id="minAttendees"
              name="minAttendees"
              className={css.field}
              type="number"
              min={1}
              label={minNumberOfGuestsLabel}
            />
            <FieldTextInput
              id="maxAttendees"
              name="maxAttendees"
              className={css.field}
              type="number"
              min={1}
              label={maxNumberOfGuestsLabel}
            />
          </div>

          <FieldMenusItems
            key={values.menuType}
            form={form}
            values={props.initialValues}
            name="menuItems"
            label={menuItemsTitle}
            items={menuItems}
            validate={validMenuItems(menuItemsRequiredMessage)}
          />

          <Button
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditMenuDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditMenuDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditMenuDescriptionFormComponent);
