import React from 'react';
import config from '../../config';

import { getLabelsFromFilters } from '../../util/data';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingPage.module.css';

const SocialDescriptionItems = props => {
  const {
    publicData,
    socialActivityTypes,
    socialActivityWhereType,
    socialActivityThemesType,
    socialActivityLanguageType,
    intl,
  } = props;

  const socialTypeOfActivityLabel = intl.formatMessage({ id: 'ListingPage.socialTypeOfActivity' });
  const socialThemesLabel = intl.formatMessage({ id: 'ListingPage.socialThemes' });
  const socialWhereLabel = intl.formatMessage({ id: 'ListingPage.socialWhere' });
  const socialLangsLabel = intl.formatMessage({ id: 'ListingPage.socialLanguages' });

  const typeOfActivity = publicData.typeOfActivity;
  const whereType = publicData.socialWhereType;
  const socialThemes = publicData.socialThemes;
  const socialLanguage = publicData.socialLanguage;

  const socialActivityTypeFormattedList = getLabelsFromFilters(socialActivityTypes, typeOfActivity);
  const socialWhereTypeFormattedList = getLabelsFromFilters(socialActivityWhereType, whereType);
  const socialThemesTypeFormattedList = getLabelsFromFilters(
    socialActivityThemesType,
    socialThemes
  );
  const socialLanguageTypeFormattedList = getLabelsFromFilters(
    socialActivityLanguageType,
    socialLanguage
  );

  return (
    <div className={css.descriptionItems}>
      <div>
        {socialActivityTypeFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{socialTypeOfActivityLabel}</span>
            {/* <span>{socialActivityTypeFormattedList}</span> */}
            <span>
              {typeOfActivity
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
        {socialWhereTypeFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{socialWhereLabel}</span>
            {/* <span>{socialWhereTypeFormattedList}</span> */}
            <span>
              {whereType
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>

      <div>
        {socialThemesTypeFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{socialThemesLabel}</span>
            {/* <span>{socialThemesTypeFormattedList}</span> */}
            <span>
              {socialThemes
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
        {socialLanguageTypeFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{socialLangsLabel}</span>
            {/* <span>{socialLanguageTypeFormattedList}</span> */}
            <span>
              {socialLanguage
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

SocialDescriptionItems.defaultProps = {
  socialActivityTypes: config.custom.socialActivityTypes,
  socialActivityWhereType: config.custom.socialActivityWhereType,
  socialActivityThemesType: config.custom.socialActivityThemesType,
  socialActivityLanguageType: config.custom.socialActivityLanguageType,
};

export default SocialDescriptionItems;
