export const vatHelper = (arr, key, values, vatName, nameItem, nameVat) => {
  const idArr = key.split('/');
  idArr.splice(0, 1);
  const id = idArr.join('/');
  arr.map(i => {
    if ((i.id === id && values[key]) || (i[nameItem] === id && values[key] && i[nameVat])) {
      const { amount, quantity } = values[key];
      vatName = vatName + (amount * quantity + (amount * quantity * +i[nameVat]) / 100);
    }
  });

  return vatName;
};
