import React from 'react';

import VenueDescriptionItems from './VenueDescriptionItems';
import SocialDescriptionItems from './SocialDescriptionItems';
import ShuttleDescriptionItems from './ShuttleDescriptionItems';
import VirtualDescriptionItems from './VirtualDescriptionItems';
import config from '../../config';

import css from './ListingPage.module.css';

const SectionDescriptionMaybe = props => {
  const { description, isReadMoreActive, listingCategory, publicData, intl } = props;

  const tr = {
    en: description,
    fr: publicData.descriptionFr ? publicData.descriptionFr : description,
  };

  let descriptionLocal = tr[config.locale];

  let shortDescription = descriptionLocal;
  if (description && description.length > 200) {
    shortDescription = `${descriptionLocal.slice(0, 200)}...`;
  }

  let descriptionItems = null;
  if (listingCategory === 'venue') {
    descriptionItems = <VenueDescriptionItems publicData={publicData} intl={intl} />;
  }
  if (listingCategory === 'social') {
    descriptionItems = <SocialDescriptionItems publicData={publicData} intl={intl} />;
  }
  if (listingCategory === 'shuttle') {
    descriptionItems = <ShuttleDescriptionItems publicData={publicData} intl={intl} />;
  }
  if (listingCategory === 'virtual') {
    descriptionItems = <VirtualDescriptionItems publicData={publicData} intl={intl} />;
  }

  return description ? (
    <div className={css.sectionDescription}>
      {isReadMoreActive ? descriptionLocal : shortDescription}
      {isReadMoreActive ? descriptionItems : null}
    </div>
  ) : null;
};

SectionDescriptionMaybe.defaultProps = {};

export default SectionDescriptionMaybe;
