import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldSelect } from '../../components';
import config from '../../config';

import css from './CreateServiceForm.module.css';

const { category: categories } = config.custom;

const CreateServiceFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, formId, handleSubmit, intl, values } = fieldRenderProps;

      const categoryLabel = intl.formatMessage({
        id: 'CreateServiceForm.categoryLabel',
      });
      const categoryPlaceholder = intl.formatMessage({
        id: 'CreateServiceForm.categoryPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = !values || !values.category;

      const renderCategories = () => {
        return categories.map(({ key, label }) => (
          <option key={key} value={key}>
            {intl.formatMessage({ id: `ConfigFilter.${key}` }) || label}
          </option>
        ));
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldSelect
              name="category"
              id={formId ? `${formId}.category` : 'category'}
              label={categoryLabel}
            >
              <option disabled value="">
                {categoryPlaceholder}
              </option>
              {renderCategories()}
            </FieldSelect>
          </div>

          <div className={css.bottomWrapper}>
            <PrimaryButton type="submit" disabled={submitDisabled}>
              <FormattedMessage id="CreateServiceForm.next" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

CreateServiceFormComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const CreateServiceForm = compose(injectIntl)(CreateServiceFormComponent);
CreateServiceForm.displayName = 'CreateServiceForm';

export default CreateServiceForm;
