import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditCateringDescriptionForm } from '../../forms';
import css from './EditCateringDescriptionPanel.module.css';

const EditCateringDescriptionPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const isNew = params.type === 'new';

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, title, description } = currentListing.attributes;
    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, addressValid } = location;
    const brand = publicData && publicData.brand;
    const descriptionFr = publicData && publicData.descriptionFr;
    const meetSafe = publicData && publicData.antiCovid;
    const antiCovid = publicData && publicData.antiCovid;
    const cateringType = publicData && publicData.cateringType;
    const foodType = publicData && publicData.foodType;
    const menuType = publicData && publicData.menuType;
    const hotelCategory = publicData && publicData.hotelCategory && publicData.hotelCategory[0];
    const closeTo = publicData && publicData.closeTo;
    const specificDiets = publicData && publicData.specificDiets;
    const socialResponsability = publicData && publicData.socialResponsability;
    const facilities = publicData && publicData.facilities;
    const showForSearchPub = publicData && publicData.showForSearch;
    const others = publicData && publicData.others;

    return {
      title,
      description,
      brand,
      descriptionFr,
      foodType,
      menuType,
      // meetSafe,
      showForSearchPub,
      antiCovid,
      cateringType,
      hotelCategory,
      closeTo,
      specificDiets,
      socialResponsability,
      facilities,
      others,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, addressValid },
          }
        : null,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditCateringDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditCateringDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditCateringDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditCateringDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={(values) => {
          const { category } = params;
          const {
            title,
            description,
            descriptionFr,
            brand = null,
            location,
            cateringType,
            foodType,
            hotelCategory,
            specificDiets,
            minSeats,
            maxSeats,
            others,
          } = values;
          const {
            selectedPlace: { address, origin, addressValid },
          } = location;

          const updateValues = {
            title: title.trim(),
            description,
            geolocation: origin,
            publicData: {
              descriptionFr,
              brand,
              location: { address, addressValid },
              cateringType: cateringType && cateringType.length ? cateringType : null,
              foodType: foodType && foodType.length ? foodType : null,
              hotelCategory: hotelCategory && hotelCategory.length ? [hotelCategory] : null,
              specificDiets: specificDiets && specificDiets.length ? specificDiets : null,
              ...(isNew ? { category } : {}),
              showForSearch:
                initialValues.showForSearchPub === true || initialValues.showForSearchPub === false
                  ? initialValues.showForSearchPub
                  : true,
              minSeats,
              maxSeats,
              others,
            },
          };

          setInitialValues({
            title,
            description,
            descriptionFr,
            brand,
            cateringType,
            foodType,
            specificDiets,
            minSeats,
            maxSeats,
            location: { search: address, selectedPlace: { address, origin, addressValid } },
            others,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditCateringDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditCateringDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditCateringDescriptionPanel;
