import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
  LocationAutocompleteInputField,
  ExternalLink,
} from '../../components';
import { userRoles } from '../../util/types';

import config from '../../config';

import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const identity = v => v;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        values,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // Address
      const addressLabel = intl.formatMessage({ id: 'SignupForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'SignupForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'SignupForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'SignupForm.addressNotRecognized',
      });

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // role
      const roleLabel = intl.formatMessage({
        id: 'SignupForm.roleLabel',
      });
      const rolePlaceholder = intl.formatMessage({
        id: 'SignupForm.rolePlaceholder',
      });
      const roleRequiredMessage = intl.formatMessage({
        id: 'SignupForm.roleRequiredMessage',
      });
      const roleRequired = validators.required(roleRequiredMessage);

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // Company name
      const companyNameLabel = intl.formatMessage({
        id: 'SignupForm.companyNameLabel',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.companyNamePlaceholder',
      });
      const companyNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.companyNameRequired',
      });
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      // Additions
      const conditionsLabel = intl.formatMessage({
        id: 'SignupForm.conditionsLabel',
      });
      const signupNewsletterLabel = intl.formatMessage({
        id: 'SignupForm.signupNewsletterLabel',
      });
      const signupNewsletterNote = intl.formatMessage({
        id: 'SignupForm.signupNewsletterNote',
      });

      const newslettersLabel = (
        <div className={css.newsletters}>
          <p>{signupNewsletterLabel}</p>
          <small>{signupNewsletterNote}</small>
        </div>
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const conditionsAccepted = values && values.conditions && values.conditions.length;
      const submitDisabled = !conditionsAccepted || invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };

      const termsLinkEn = (
        <ExternalLink
          href="https://event.youna.co/en/terms-policies-youna"
          className={css.termsLink}
          title="Terms and conditions & the privacy policy"
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </ExternalLink>
      );

      const termsLinkFr = (
        <ExternalLink
          href="https://event.youna.co/en/terms-policies-youna-0"
          className={css.termsLink}
          title="Termes et Conditions & Politique de Confidentialité"
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </ExternalLink>
      );

      const termsLink = config.locale === 'fr' ? termsLinkFr : termsLinkEn;

      const getRoles = roles =>
        roles.map(role => (
          <option key={role} value={role}>
            {intl.formatMessage({ id: `Role.${role}` })}
          </option>
        ));

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldSelect
              name="userType"
              id={formId ? `${formId}.role` : 'role'}
              label={roleLabel}
              validate={validators.composeValidators(roleRequired)}
            >
              <option disabled value="">
                {rolePlaceholder}
              </option>
              {getRoles(userRoles)}
            </FieldSelect>

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              className={css.email}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>

            <FieldTextInput
              type="text"
              id={formId ? `${formId}.companyName` : 'companyName'}
              className={css.field}
              name="companyName"
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
              validate={companyNameRequired}
            />

            <LocationAutocompleteInputField
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={addressLabel}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              validate={validators.composeValidators(
                // validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompleteValidPlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />
          </div>

          <div className={css.additional}>
            <div className={css.checkbox}>
              <FieldCheckbox
                id={formId ? `${formId}.signupNewsletter` : 'signupNewsletter'}
                name="signupNewsletters"
                label={newslettersLabel}
                value={true}
              />
            </div>

            <div className={css.checkbox}>
              <FieldCheckbox
                id={formId ? `${formId}.conditions` : 'conditions'}
                name="conditions"
                label={conditionsLabel}
                value={true}
              />
            </div>
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
