import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { validPrices, isPercents, composeValidators, isDurationSet } from '../../util/validators';
import { Button, Form, FieldPriceTable, FieldTextInput } from '../../components';

import css from './EditMenuPricingForm.module.css';

const { menuPricesScheme } = config.custom;

const schemeSpecialOptions = [{ noDuration: false }];

const createScheme = (scheme, options) =>
  scheme.map((item, i) => ({ ...item, ...(options[i] || {}) }));

export const EditMenusPricingFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(formRenderProps) => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
      } = formRenderProps;

      const isDisable = () => {
        const arrPrices = values.prices.prices;
        const priceSchema = values.prices.priceScheme;

        const disabled = arrPrices
          ? arrPrices
              .map((item) => {
                const isPerHour = item === 'perHour';
                const hasDuration =
                  priceSchema[item].duration !== null &&
                  priceSchema[item].duration &&
                  priceSchema[item].duration.length > 1;
                // const hasAmPm = hasDuration
                //   ? priceSchema[item].duration.filter(item => item.includes('AM') || item.includes('PM')).length > 1
                //   : isPerHour;
                const hasFullDuration =
                  hasDuration && priceSchema[item].duration[0] !== priceSchema[item].duration[1];
                // isPerHour
                // ? isPerHour
                // : hasDuration && priceSchema[item].duration[0] !== priceSchema[item].duration[1];
                return !!hasFullDuration && !!priceSchema[item].standartPrice;
              })
              .filter((i) => i === false).length > 0
          : true;

        return disabled;
      };

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

      const pricesValid = intl.formatMessage({ id: 'EditMenuPricingForm.pricesValid' });

      const vatLabel = intl.formatMessage({ id: 'EditMenuPricingForm.vatLabel' });
      const vatPlaceholder = intl.formatMessage({ id: 'EditMenuPricingForm.vatPlaceholder' });

      const vatValid = intl.formatMessage({ id: 'EditMenuPricingForm.vatValid' });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || pristine || submitInProgress || isDisable();
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FieldPriceTable
            className={css.priceTable}
            form={form}
            values={props.initialValues}
            name="prices"
            items={createScheme(menuPricesScheme, schemeSpecialOptions)}
            validate={composeValidators(isDurationSet('test'), validPrices(pricesValid))}
            isDowntimePrice={false}
            isCorporateRate={false}
            isVatRate={false}
            isPerPerson={true}
          />

          <div style={{ display: 'none' }}>
            <FieldTextInput
              id="vatPrice"
              name="vatPrice"
              label={vatLabel}
              placeholder={vatPlaceholder}
              validate={isPercents(vatValid)}
              className={css.vatRate}
              defaultValue="20"
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditMenusPricingFormComponent.defaultProps = { fetchErrors: null };

EditMenusPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditMenusPricingFormComponent);
