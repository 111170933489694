import React, { Component } from 'react';
import { func, instanceOf, object, node, string, bool } from 'prop-types';
import { Field } from 'react-final-form';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { DatePicker } from 'antd';
import moment from 'moment';

import { ValidationError } from '../../components';

import css from './FieldAntDatePickerInput.module.css';
import 'antd/dist/antd.css';

function FieldAntDatePickerInputComponent({
  defaultValue,
  id,
  className,
  rootClassName,
  label,
  placeholder,
  format,
  validate,
  form,
  input: { value, name },
}) {
  const dateFormat = 'DD/MM/YYYY';
  const classes = classNames(rootClassName || css.fieldRoot, className);

  const getInitialValue = () => {
    return value ? moment(moment(value).format(dateFormat), dateFormat) : null;
  };

  const handleChange = date => {
    form.change(name, date.toString());
  };

  return (
    <div className={classes}>
      {label && (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      )}
      <DatePicker
        className={css.mainDatePicker}
        defaultValue={getInitialValue()}
        onChange={handleChange}
        format={dateFormat}
        placeholder={placeholder}
      />
    </div>
  );
}

const FieldBirthdayInput = props => {
  return <Field component={FieldAntDatePickerInputComponent} {...props} type="hidden" />;
};

export default FieldBirthdayInput;
