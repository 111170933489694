import React from 'react';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Form, FieldTextInput } from '../../components';
import css from './InboxPage.module.css';

const SortByIcon = () => {
  return (
    <svg className={css.icon} width="10" height="16" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#4a4a4a"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.25 7.125v7.438M5 12.813l-1.75 1.75-1.75-1.75M6.75 8.875V1.438M5 3.188l1.75-1.75 1.75 1.75" />
      </g>
    </svg>
  );
};

const KeywordsForm = ({ intl, handleChange, formCallbacks, onChange, ...rest }) => {
  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const { id, form, handleSubmit, paddingClasses, contentRef } = formRenderProps;

        const searchPlaceholder = intl.formatMessage({ id: 'InboxPage.kewordsSearch' });
        const classes = classNames(css.inboxSearchBarRoot);
        const spy = onChange ? (
          <FormSpy onChange={handleChange} subscription={{ values: true }} />
        ) : null;

        return (
          <Form id={id} className={classes} onSubmit={handleSubmit} contentRef={contentRef}>
            <div className={classNames(paddingClasses || css.contentWrapperInput)}>
              <FieldTextInput
                name={'keywords'}
                id={`keywords`}
                // isUncontrolled
                type="text"
                placeholder={searchPlaceholder}
                autoComplete="off"
                className={css.inputKeywords}
              />
            </div>
            {spy}
          </Form>
        );
      }}
    />
  );
};

const CountForm = ({ pagination, resultCount, intl }) => {
  // let results = pagination ? pagination.totalItems : 0
  const results = resultCount ? resultCount : 0;
  return (
    <p className={css.typeFilterCount}>
      {intl.formatMessage({ id: 'SortByPopup.results' }, { count: results })}
    </p>
  );
};

const SortForm = ({ isRevers, toRevers, intl }) => {
  let textFilter = isRevers
    ? intl.formatMessage({ id: 'SortByPopup.-createdAt' })
    : intl.formatMessage({ id: 'SortByPopup.createdAt' });
  const handleReverse = () => {
    if (isRevers) toRevers(false);
    else toRevers(true);
  };

  return (
    <div className={css.typeFilterRevers} onClick={handleReverse}>
      <SortByIcon />
      <p className={css.textFilter}>{textFilter}</p>
    </div>
  );
};

const InboxPageSearchBar = ({
  toRevers,
  pagination,
  isRevers,
  onChange,
  intl,
  resultCount,
  ...rest
}) => {
  const handleChange = formState => {
    // if (formState.dirty) {
    onChange(formState.values);
    // }
  };

  const formCallbacks = { onSubmit: () => null };

  return (
    <div className={css.searchBarContainer}>
      <CountForm pagination={pagination} intl={intl} resultCount={resultCount} />
      <SortForm isRevers={isRevers} toRevers={toRevers} intl={intl} />
      <KeywordsForm
        handleChange={handleChange}
        intl={intl}
        formCallbacks={formCallbacks}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default InboxPageSearchBar;
