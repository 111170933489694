import React from 'react';
import config from '../../config';

import { getLabelsFromFilters } from '../../util/data';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingPage.module.css';

const VirtualDescriptionItems = props => {
  const { publicData, virtualTargetsTypes, virtualLanguageType, intl } = props;

  const virtualTypeOfEventLabel = intl.formatMessage({ id: 'ListingPage.virtualTypeOfEvent' });
  const virtualLanguageLabel = intl.formatMessage({ id: 'ListingPage.virtualLanguage' });
  const virtualTargetLabel = intl.formatMessage({ id: 'ListingPage.virtualTarget' });

  const eventType = publicData.eventType ? publicData.eventType : '';
  const virtualLanguage = publicData.virtualLanguage;
  const targets = publicData.targets;

  const virtualTargetFormattedList = getLabelsFromFilters(virtualTargetsTypes, targets);
  const virtualLanguageFormattedList = getLabelsFromFilters(virtualLanguageType, virtualLanguage);

  return (
    <div className={css.descriptionItems}>
      <div>
        {eventType ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{virtualTypeOfEventLabel}</span>
            {/* <span>{eventType}</span> */}
            <span>{language.labelsTranslator(eventType, intl)}</span>
          </div>
        ) : null}
        {virtualTargetFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{virtualTargetLabel}</span>
            {/* <span>{virtualTargetFormattedList}</span> */}
            <span>
              {targets
                .map(t => {
                  return language.labelsTranslator(t, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>
      <div>
        {virtualLanguageFormattedList ? (
          <div className={css.descriptionItem}>
            <span className={css.descriptionLabel}>{virtualLanguageLabel}</span>
            {/* <span>{virtualLanguageFormattedList}</span> */}
            <span>
              {virtualLanguage
                .map(l => {
                  return language.labelsTranslator(l, intl);
                })
                .join(', ')}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

VirtualDescriptionItems.defaultProps = {
  virtualTargetsTypes: config.custom.virtualTargetsTypes,
  virtualLanguageType: config.custom.virtualLanguageType,
};

export default VirtualDescriptionItems;
