import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { EditVenueBedroomsForm } from '../../forms';

import css from './EditVenueBedroomsPanel.module.css';

const EditVenueBedroomsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
  } = props;

  const parsePublicData = publicData => {
    const { bedrooms, singleBedroomsCount, twinBedroomsCount, singleBedsCount } = publicData;
    return {
      ...(bedrooms && { bedrooms: JSON.parse(bedrooms) }),
      ...(singleBedroomsCount && { singleBedroomsCount }),
      ...(twinBedroomsCount && { twinBedroomsCount }),
      ...(singleBedsCount && { singleBedsCount }),
    };
  };

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;
    return parsePublicData(publicData);
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());
  const classes = classNames(rootClassName || css.root, className);

  const panelTitle = <FormattedMessage id="EditVenueBedroomsPanel.title" />;
  const pricesHint = <FormattedMessage id="EditVenueBedroomsPanel.priceHint" />;

  const formatValuesForPublicData = values => {
    const { bedrooms, singleBedroomsCount, twinBedroomsCount, singleBedsCount } = values;
    const simpleRoom = values.bedrooms?.filter(i => i.bedroomType === 'single').length;
    const doubleRoom = values.bedrooms?.filter(i => i.bedroomType === 'doubleRoom').length;
    return {
      ...(bedrooms && { bedrooms: JSON.stringify(bedrooms) }),
      ...(simpleRoom && { simpleRoom }),
      ...(doubleRoom && { doubleRoom }),
      ...(singleBedroomsCount && { singleBedroomsCount: Number(singleBedroomsCount) }),
      ...(twinBedroomsCount && { twinBedroomsCount: Number(twinBedroomsCount) }),
      ...(singleBedsCount && { singleBedsCount: Number(singleBedsCount) }),
    };
  };

  const handleSubmit = (values, noRedirect) => {
    setInitialValues({
      ...getInitialValues(),
      ...parsePublicData(formatValuesForPublicData(values)),
    });
    const { bedrooms, singleBedroomsCount, twinBedroomsCount, singleBedsCount } = values;
    const simpleRoom = values.bedrooms?.filter(i => i.bedroomType === 'single').length;
    const doubleRoom = values.bedrooms?.filter(i => i.bedroomType === 'doubleRoom').length;
    onSubmit(
      {
        publicData: formatValuesForPublicData(values),
      },
      noRedirect
    );
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <i className={css.priceHint}>{pricesHint}</i>
      <EditVenueBedroomsForm
        className={css.form}
        saveActionMsg={submitButtonText}
        onSubmit={handleSubmit}
        redirect={onSubmit}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        formId="EditVenueBedroomsForm"
        initVal={initialValues}
      />
    </div>
  );
};

EditVenueBedroomsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditVenueBedroomsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditVenueBedroomsPanel;
