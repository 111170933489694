import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form, AmenityItem } from '../../components';

import css from './EditSocialAmenitiesForm.module.css';

import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import recording from '../../assets/icon/Social_activities_amenities/movie-recording.svg';
import photoReport from '../../assets/icon/Social_activities_amenities/photo_report.svg';
import handSanitizer from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import certificate from '../../assets/icon/Social_activities_amenities/certificate.svg';

const SOCIAL_ACTIVITY_OPTIONS_LIST = {
  wifi,
  recording,
  photoReport,
  handSanitizer,
  certificate,
};

const EditSocialAmenitiesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
      } = formRenderProps;
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter(name, filterConfig);
      const filteredOptionsList = options
        .filter(option => {
          return typeof SOCIAL_ACTIVITY_OPTIONS_LIST[option.key] !== 'undefined';
        })
        .map(item => {
          return {
            key: item.key,
            label: item.label,
            img: SOCIAL_ACTIVITY_OPTIONS_LIST[item.key],
          };
        });
      const formValues = form.getState().values;

      const onChange = ({ values }) => {
        Object.keys(values).forEach(key => {
          if (
            key.indexOf('free') !== 0 &&
            !values[key].length &&
            values[`free${key}`] &&
            values[`free${key}`].length
          ) {
            form.change(`free${key}`, null);
          }
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} subscription={{ values: true }} />
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.optionsPart}>
            <AmenityItem items={filteredOptionsList} formValues={formValues} intl={intl} />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditSocialAmenitiesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditSocialAmenitiesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditSocialAmenitiesFormComponent);
