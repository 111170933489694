import React from 'react';
import { findOptionsForSelectFilter } from '../../util/search';
import { getLabelsFromFilters } from '../../util/data';
import config from '../../config';
import css from './ListingPage.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import { languagesHelper as language } from '../../helpers/languages';

import naturalLight from '../../assets/icon/Spaces_amenities/sun.svg';
import soundSystem from '../../assets/icon/Spaces_amenities/sound_system.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import recording from '../../assets/icon/Social_activities_amenities/movie-recording.svg';
import photoReport from '../../assets/icon/Social_activities_amenities/photo_report.svg';
import handSanitizer from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import certificate from '../../assets/icon/Social_activities_amenities/certificate.svg';
import paperBoard from '../../assets/icon/Spaces_amenities/paper_board.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import paper from '../../assets/icon/Spaces_amenities/paper.svg';
import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import projector from '../../assets/icon/Spaces_amenities/projector.svg';
import pens from '../../assets/icon/Spaces_amenities/pen.svg';
import screen from '../../assets/icon/Spaces_amenities/screen.svg';
import water from '../../assets/icon/Spaces_amenities/water.svg';
import softDrinks from '../../assets/icon/Shuttle_amenities/softDrink.svg';
import newspaper from '../../assets/icon/Shuttle_amenities/newspaper.svg';
import mask from '../../assets/icon/Shuttle_amenities/mask.svg';
import branding from '../../assets/icon/Virtual_services_amenities/branding.svg';
import invitations from '../../assets/icon/Virtual_services_amenities/invitation.svg';
import liveStreaming from '../../assets/icon/Virtual_services_amenities/live.svg';
import translations from '../../assets/icon/Virtual_services_amenities/translating.svg';
import eventManagement from '../../assets/icon/Virtual_services_amenities/monitor.svg';
import survey from '../../assets/icon/Virtual_services_amenities/call-center.svg';
import registrationPage from '../../assets/icon/Virtual_services_amenities/browser.svg';
import captation from '../../assets/icon/Social_activities_amenities/movie-recording.svg';
import moderation from '../../assets/icon/Virtual_services_amenities/chat.svg';
import liveTraduction from '../../assets/icon/Virtual_services_amenities/translating.svg';
import reporting from '../../assets/icon/Virtual_services_amenities/reporting.svg';

const amenitiesIconsList = {
  naturalLight,
  soundSystem,
  wifi,
  recording,
  photoReport,
  handSanitizer,
  certificate,
  paperBoard,
  television,
  paper,
  conditionedAir,
  projector,
  pens,
  screen,
  water,
  softDrinks,
  newspaper,
  mask,
  branding,
  invitations,
  liveStreaming,
  translations,
  eventManagement,
  survey,
  registrationPage,
  captation,
  moderation,
  liveTraduction,
  reporting,
};

const ListingAmenities = props => {
  const { publicData, intl, filterConfig } = props;

  const listingAmenitiesLabel = intl.formatMessage({ id: 'ListingPage.listingAmenitiesLabel' });
  const amenities = publicData && publicData.pubAmenities;

  if (!amenities) {
    return null;
  }

  const amenitiesKeys = amenities ? Object.keys(amenities) : [];
  const options = findOptionsForSelectFilter('pubAmenities', filterConfig);

  const collectedAmenitiesAndLabels = [];
  amenitiesKeys.map(key => {
    if (key.substring(0, 4) !== 'free') {
      collectedAmenitiesAndLabels.push({
        label: getLabelsFromFilters(options, [key]),
        isFree: amenities[key].isFree,
        icon: amenitiesIconsList[key] ? amenitiesIconsList[key] : null,
        key: key,
      });
    }
  });

  return (
    <div className={css.amenitiesWrapper}>
      <h2 className={css.subTitle}>{listingAmenitiesLabel}</h2>
      <div className={css.amenitiesItemsWrap}>
        {collectedAmenitiesAndLabels.map(({ label, isFree, icon, key }) => (
          <div className={css.amenityItem} key={key}>
            {icon && <img src={icon} alt="amenityIcon" className={css.amenityItemIcon} />}
            <div className={css.amenityItemText}>
              {/* <span>{label}</span> */}
              <span>{language.labelsTranslator(key, intl)}</span>
              {isFree && (
                <p className={css.optionlabelFree}>
                  <FormattedMessage id="SpaceCard.free" />
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ListingAmenities.defaultProps = {
  filterConfig: config.custom.filters,
};

export default ListingAmenities;
