import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { EditVenueExtrasForm } from '../../forms';

import css from './EditVenueExtrasPanel.module.css';

const EditVenueExtrasPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    edit,
  } = props;

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    const services = publicData && publicData.extraServices && JSON.parse(publicData.extraServices);
    return services ? services.extraServices : [];
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());
  const classes = classNames(rootClassName || css.root, className);

  const panelTitle = <FormattedMessage id="EditVenueExtrasPanel.title" />;

  const handleSubmit = (values, noRedirect, noPublishListing) => {
    setInitialValues(values);

    onSubmit(
      {
        publicData: {
          extraServices: values && values.length ? JSON.stringify({ extraServices: values }) : null,
        },
      },
      noRedirect,
      noPublishListing
    );
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditVenueExtrasForm
        className={css.form}
        saveActionMsg={submitButtonText}
        onSubmit={handleSubmit}
        publishListing={onSubmit}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        formId="EditVenueExtraServicesForm"
        initVal={initialValues}
        edit={edit}
      />
    </div>
  );
};

EditVenueExtrasPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditVenueExtrasPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditVenueExtrasPanel;
