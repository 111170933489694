import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { OwnListingLink } from '../../components';

import saveTime from '../../assets/icon/save_time.svg';
import saveMoney from '../../assets/icon/save_money.svg';
import saveEnergy from '../../assets/icon/save_energy.svg';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className, currentUserListing, currentUserListingFetched } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={saveTime} alt="Save time" />
          </div>
          <div className={css.stepText}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part1Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part1Text" />
            </p>
          </div>
        </div>

        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={saveEnergy} alt="Save time" />
          </div>
          <div className={css.stepText}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part2Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part2Text" />
            </p>
          </div>
        </div>

        <div className={css.step}>
          <div className={css.stepImage}>
            <img src={saveMoney} alt="Save time" />
          </div>
          <div className={css.stepText}>
            <h2 className={css.stepTitle}>
              <FormattedMessage id="SectionHowItWorks.part3Title" />
            </h2>
            <p>
              <FormattedMessage id="SectionHowItWorks.part3Text" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
