import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
  LocationAutocompleteInputField,
} from '../../components';
import { userRoles } from '../../util/types';

import css from './ConfirmSignupForm.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';

const { LatLng } = sdkTypes;

const KEY_CODE_ENTER = 13;

const identity = v => v;

const ConfirmSignupFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        authInfo,
        idp,
        values,
      } = formRenderProps;

      // If authInfo is not available we should not show the ConfirmForm
      if (!authInfo) {
        return;
      }

      // email
      const emailLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // Address
      const addressLabel = intl.formatMessage({ id: 'SignupForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.addressNotRecognized',
      });

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // Company name
      const companyNameLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.companyNameLabel',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.companyNamePlaceholder',
      });
      const companyNameRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.companyNameRequired',
      });
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      // role
      const roleLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.roleLabel',
      });
      const rolePlaceholder = intl.formatMessage({
        id: 'ConfirmSignupForm.rolePlaceholder',
      });
      const roleRequiredMessage = intl.formatMessage({
        id: 'ConfirmSignupForm.roleRequiredMessage',
      });
      const roleRequired = validators.required(roleRequiredMessage);

      // Additions
      const conditionsLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.conditionsLabel',
      });
      const signupNewsletterLabel = intl.formatMessage({
        id: 'ConfirmSignupForm.signupNewsletterLabel',
      });
      const signupNewsletterNote = intl.formatMessage({
        id: 'ConfirmSignupForm.signupNewsletterNote',
      });

      const newslettersLabel = (
        <div className={css.newsletters}>
          <p>{signupNewsletterLabel}</p>
          <small>{signupNewsletterNote}</small>
        </div>
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const conditionsAccepted = values && values.conditions && values.conditions.length;
      const submitDisabled = !conditionsAccepted || invalid || submitInProgress;

      console.log('submitDisabled', submitDisabled);
      console.log('conditionsAccepted', conditionsAccepted);
      console.log('invalid', invalid);
      console.log('submitInProgress', submitInProgress);

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      const getRoles = roles =>
        roles.map(role => (
          <option key={role} value={role}>
            {intl.formatMessage({ id: `Role.${role}` })}
          </option>
        ));

      // Initial values from idp provider
      const {
        email,
        firstName,
        lastName,
        userType,
        companyName,
        location,
        address,
        city,
        zipCode,
        country,
        uid,
        businessUnit,
        costCenter,
        manager,
        mobile,
        preferredlanguage,
      } = authInfo;

      console.log('authInfo', authInfo);
      console.log('location.selectedPlace.origin', location.selectedPlace.origin instanceof LatLng);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldSelect
              name="userType"
              id={formId ? `${formId}.role` : 'role'}
              label={roleLabel}
              initialValue={userType}
              validate={validators.composeValidators(roleRequired)}
            >
              <option disabled value="">
                {rolePlaceholder}
              </option>
              {getRoles(userRoles)}
            </FieldSelect>

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              initialValue={email}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.firstName` : 'firstName'}
                name="firstName"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                initialValue={firstName}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lastName` : 'lastName'}
                name="lastName"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                initialValue={lastName}
                validate={lastNameRequired}
              />
            </div>

            <FieldTextInput
              type="text"
              id={formId ? `${formId}.companyName` : 'companyName'}
              className={css.field}
              name="companyName"
              label={companyNameLabel}
              placeholder={companyNamePlaceholder}
              initialValue={companyName}
              validate={companyNameRequired}
            />

            <LocationAutocompleteInputField
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={addressLabel}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              initialValue={location}
              format={identity}
              validate={validators.composeValidators(
                // validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompleteValidPlaceSelected(addressNotRecognizedMessage)
              )}
            />
          </div>
          <div className={css.additional}>
            <div className={css.checkbox}>
              <FieldCheckbox
                id={formId ? `${formId}.signupNewsletter` : 'signupNewsletter'}
                name="signupNewsletters"
                label={newslettersLabel}
                value={true}
              />
            </div>

            <div className={css.checkbox}>
              <FieldCheckbox
                id={formId ? `${formId}.conditions` : 'conditions'}
                name="conditions"
                label={conditionsLabel}
                value={true}
              />
            </div>
          </div>
          <div style={{ display: 'none' }}>
            {[
              'address',
              'city',
              'zipCode',
              'country',
              'uid',
              'businessUnit',
              'costCenter',
              'manager',
              'mobile',
              'preferredlanguage',
              'country',
            ].map(key => (
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.${key}` : key}
                name={key}
                label={key}
                placeholder={''}
                initialValue={authInfo[key]}
              />
            ))}
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="ConfirmSignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage
                id="ConfirmSignupForm.signUp"
                values={{ idp: companyName ? companyName : idp }}
              />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
