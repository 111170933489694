import React from 'react';
import { object, string, filterConfig } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { NamedLink, Button } from '..';
import config from '../../config';
import { duplicateListingHelper } from '../../helpers/duplicateListingHelper';
import css from './EditVenueSpacesPanel.module.css';

import noImage from './noimage.png';
import sun from '../../assets/icon/Spaces_amenities/sun.svg';
import banquetIcon from '../../assets/icon/banquet.svg';
import boardroomIcon from '../../assets/icon/boardroom.svg';
import classroomIcon from '../../assets/icon/classroom.svg';
import receptionIcon from '../../assets/icon/reception.svg';
import theatreIcon from '../../assets/icon/theatre.svg';
import ushapeIcon from '../../assets/icon/ushape.svg';
import coworkingIcon from '../../assets/icon/coworking.svg';

import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import soundSystem from '../../assets/icon/Spaces_amenities/sound_system.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import paperBoard from '../../assets/icon/Spaces_amenities/paper_board.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import paper from '../../assets/icon/Spaces_amenities/paper.svg';
import hydroalcoholicGel from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import projector from '../../assets/icon/Spaces_amenities/projector.svg';
import pens from '../../assets/icon/Spaces_amenities/pen.svg';
import screen from '../../assets/icon/Spaces_amenities/screen.svg';
import water from '../../assets/icon/Spaces_amenities/water.svg';

const SPACE_MAIN_OPTIONS_LIST = {
  soundSystem,
  wifi,
  paperBoard,
  television,
  paper,
  conditionedAir,
  hydroalcoholicGel,
  projector,
  pens,
  screen,
  water,
};
const SPACE_INTERNET_SPEED_OPTIONS = ['slowInternet', 'normalInternet', 'highSpeedInternet'];
const SPACE_VIRTUAL_SERVICES_OPTIONS = [
  'tvStudio',
  'greenScreen',
  'videoConferenceDevice',
  'avTechnicianOnSite',
];

const icons = {
  board: boardroomIcon,
  class: classroomIcon,
  banquet: banquetIcon,
  theatre: theatreIcon,
  ushape: ushapeIcon,
  reception: receptionIcon,
  coworking: coworkingIcon,
};
const getStyles = values => {
  return icons[values];
};

const SpacesCard = props => {
  const {
    className,
    rootClassName,
    listing,
    mobileLayout,
    filterConfig,
    onCreateDuplicateListing,
    listingsNames,
    parent,
    removeClick,
    isLoading,
    isLastSpace,
  } = props;

  const classes = classNames(rootClassName || css.rootSpacescard, className);
  const id = listing.id.uuid;
  const {
    state,
    publicData,
    title,
    availabilityPlan,
    description,
    geolocation,
    metadata,
    price,
    privateData,
  } = listing.attributes;
  const { category, sizes, styles, pubAmenities } = publicData;
  const slug = createSlug(title);
  const image = listing.images[0] && listing.images[0].attributes.variants['landscape-crop2x'].url;
  const naturalLight = pubAmenities && pubAmenities.naturalLight;
  const timezone = availabilityPlan && availabilityPlan.timezone;

  const isDraft = state === LISTING_STATE_DRAFT;
  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const options = findOptionsForSelectFilter('pubAmenities', filterConfig);

  const filterOption = () =>
    options
      .filter(
        item =>
          typeof pubAmenities[item.key] !== 'undefined' &&
          typeof SPACE_MAIN_OPTIONS_LIST[item.key] !== 'undefined'
      )
      .map(item => {
        return {
          label: item.label,
          img: SPACE_MAIN_OPTIONS_LIST[item.key],
          isFree: pubAmenities[item.key].isFree || false,
        };
      });

  const duplicateListingClick = () => {
    const copyListing = duplicateListingHelper(listing, listingsNames);

    onCreateDuplicateListing(copyListing, id);
  };

  const removeListingClick = () => {
    const newTitle = `${title} (delete)`;
    const updateValues = {
      title: newTitle,
      parent: parent,
      timezone: timezone,
    };

    removeClick({ id, updateValues, isLastSpace });
  };

  return (
    <div className={classes}>
      <div className={css.imageContainer}>
        <img src={image ? image : noImage} alt="Listing image" className={css.listingImage} />
        <div className={css.createContainer}>
          <NamedLink
            className={css.buttonEditMobile}
            name="EditServicesPage"
            params={{ id, slug, type: editListingLinkType, tab: 'description', category }}
          >
            <FormattedMessage id="SpaceCard.modify" />
          </NamedLink>
          <Button className={css.duplicateButtonMobile} onClick={duplicateListingClick}>
            <FormattedMessage id="SpaceCard.duplicate" />
          </Button>
          <Button
            className={css.removeButtonMobile}
            onClick={removeListingClick}
            // inProgress={isLoading}
          >
            <FormattedMessage id="ManageListingCard.remove" />
          </Button>
        </div>
      </div>

      <div className={css.sizeContainer}>
        <h3 className={css.listingTitle}>{title}</h3>
        {naturalLight ? (
          <div className={css.daylightContainer}>
            <img src={sun} alt="sun" className={css.daylightImage} />
            <p className={css.listingDaylight}>
              <FormattedMessage id="SpaceCard.naturalLight" />
            </p>
          </div>
        ) : null}
        {sizes ? (
          <p className={css.listingSize}>{`${sizes.w}M x ${sizes.l}M x ${sizes.h}M (WxLxH)`}</p>
        ) : null}
        {styles ? (
          <div className={css.listingStylesWrapper}>
            {styles.map(item => {
              return (
                <div className={css.listingStylesContainer} key={item}>
                  <img
                    src={getStyles(item)}
                    alt="listing styles"
                    className={css.listingStylesImage}
                  />
                  <p className={css.listingStyleNumber}>{publicData[`${item}Value`]}</p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

      {pubAmenities ? (
        <div className={css.optionsContainer}>
          {filterOption().map((item, index) => {
            if (index < 5) {
              return (
                <div className={css.optionWrapper} key={item.label}>
                  <div className={css.optionImageContainer}>
                    <img src={item.img} alt="options image" className={css.listingOptionImage} />
                  </div>
                  <p className={css.optionlabel}>{item.label}</p>
                  {item.isFree ? (
                    <p className={css.optionlabelFree}>
                      <FormattedMessage id="SpaceCard.free" />
                    </p>
                  ) : null}
                </div>
              );
            }
          })}
        </div>
      ) : null}

      <div className={css.createContainer}>
        <NamedLink
          className={css.buttonEdit}
          name="EditServicesPage"
          params={{ id, slug, type: editListingLinkType, tab: 'description', category }}
        >
          <FormattedMessage id="SpaceCard.modify" />
        </NamedLink>
        <Button className={css.duplicateButton} onClick={duplicateListingClick}>
          <FormattedMessage id="SpaceCard.duplicate" />
        </Button>
        <Button className={css.removeButton} onClick={removeListingClick}>
          <FormattedMessage id="ManageListingCard.remove" />
        </Button>
      </div>
    </div>
  );
};

SpacesCard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  filterConfig: config.custom.filters,
};

SpacesCard.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,
  filterConfig: filterConfig,
};

export default SpacesCard;
