import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditMenuServicesForm } from '../../forms';
import { ListingLink } from '../../components';
import { formatServicesData, formatServicesBeforeSubmit } from '../../util/data';

import css from './EditMenuServicesPanel.module.css';

const FEATURES_NAME = 'pubServices';

const EditMenuServicesPanel = (props) => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditMenuServicesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditMenuServicesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditSocialServicesPanel.createListingTitle" />
  );

  const pubServices = (publicData && publicData.pubServices) || {};
  const internetDataFromServices = [];
  pubServices && pubServices.slowInternet && internetDataFromServices.push('slowInternet');
  pubServices && pubServices.normalInternet && internetDataFromServices.push('normalInternet');
  pubServices &&
    pubServices.highSpeedInternet &&
    internetDataFromServices.push('highSpeedInternet');
  const collectedInitialDataObject = formatServicesData(pubServices);
  let initialValues = { ...collectedInitialDataObject, internetSpeed: internetDataFromServices };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditMenuServicesForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={({
          internetSpeed,
          tvStudio,
          greenScreen,
          videoConferenceDevice,
          avTechnicianOnSite,
          ...values
        }) => {
          const internetValuesToPubservices = {};
          internetSpeed &&
            internetSpeed.length &&
            internetSpeed.map((item) => {
              return (internetValuesToPubservices[item] = [item]);
            });
          const pubServices = formatServicesBeforeSubmit(values);
          const updatedValues = {
            publicData: {
              pubServices: {
                ...pubServices,
                ...internetValuesToPubservices,
                ...(tvStudio && tvStudio.length ? { tvStudio: tvStudio } : {}),
                ...(greenScreen && greenScreen.length ? { greenScreen: greenScreen } : {}),
                ...(videoConferenceDevice && videoConferenceDevice.length
                  ? { videoConferenceDevice: videoConferenceDevice }
                  : {}),
                ...(avTechnicianOnSite && avTechnicianOnSite.length
                  ? { avTechnicianOnSite: avTechnicianOnSite }
                  : {}),
              },
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditMenuServicesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditMenuServicesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditMenuServicesPanel;
