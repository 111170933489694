import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import {
  Form,
  Button,
  PrimaryButton,
  SecondaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCurrencyInput,
} from '../../components';
import config from '../../config';

import css from './EditVenueBedroomsForm.module.css';
import { required } from '../../util/validators';

const BEDROOM_TYPES = [
  { key: 'single', label: 'Single' },
  { key: 'doubleRoom', value: 'Double Room' },
  { key: 'twin', value: 'Twin Room' },
  { key: 'dormitory', value: 'Dormitory' },
  { key: 'apartment', value: 'Apartment' },
  { key: 'cottageOrMaisonnette', value: 'Cottage / Maisonnette' },
];

const EditVenueBedroomsFormComponent = props => {
  const [bedroomsItems, setBedroomsItems] = useState(props.initVal.bedrooms);
  const [isAddingABedroom, setIsAddingABedroom] = useState(false);

  console.log('props.initVal', props.initVal);

  useEffect(() => {
    setBedroomsItems(props.initVal.bedrooms);
  }, [props.initVal]);

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          initVal,
          form,
        } = formRenderProps;
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const bedroomNamePlaceholderMessage = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomNamePlaceholder',
        });
        const bedroomPriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomPriceLabel',
        });
        const bedroomPricePlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomPricePlaceholder',
        });

        const bedroomDowntimePriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDowntimePriceLabel',
        });
        const bedroomDowntimePricePlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDowntimePricePlaceholder',
        });

        const bedroomCorporatePriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomCorporatePriceLabel',
        });
        const bedroomCorporatePricePlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomCorporatePricePlaceholder',
        });

        const descriptionMessage = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDescriptionLabel',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDescriptionPlaceholder',
        });

        const bedroomVatLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomVatLabel',
        });
        const bedroomVatPlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomVatPlaceholder',
        });

        const bedroomCapacityLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomCapacityLabel',
        });
        const bedroomCapacityPlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomCapacityPlaceholder',
        });

        const bedroomDataNameLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataNameLabel',
        });
        const bedroomDataTypeLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataTypeLabel',
        });
        const bedroomDataStandardPriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataStandardPriceLabel',
        });
        const bedroomDataDowntimePriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataDowntimePriceLabel',
        });
        const bedroomDataCorporatePriceLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataCorporatePriceLabel',
        });
        const bedroomDataVatLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataVatLabel',
        });
        const bedroomDataCapacityLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomDataCapacityLabel',
        });
        const bedroomTypePlaceholder = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.typeOfBedroom',
        });
        const bedroomRequired = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.bedroomRequired',
        });
        const singleBedroomsCountLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.singleBedroomsCountLabel',
        });
        const twinBedroomsCountLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.twinBedroomsCountLabel',
        });
        const singleBedsCountLabel = intl.formatMessage({
          id: 'EditVenueBedroomsPanel.singleBedsCountLabel',
        });

        const saveBedroomsCounts = e => {
          e.preventDefault();
          const noPublishListing = true;
          const noRedirect = true;
          props.onSubmit(values, noRedirect, noPublishListing);
        };

        // Just save the bedrooms and no need to redirect to next step.
        const saveBedroom = e => {
          e.preventDefault();
          const noPublishListing = true;
          const noRedirect = true;
          const bedroomsList = initVal?.bedrooms ? Object.values(initVal.bedrooms) : [];
          const newBedroom = Object.assign(
            { id: `${values.bedroomName}_${Math.floor(Math.random() * 100)}` },
            {
              bedroomName: values.bedroomName,
              bedroomType: values.bedroomType,
              bedroomPrice: values.bedroomPrice,
              bedroomDowntimePrice: values.bedroomDowntimePrice,
              bedroomCorporatePrice: values.bedroomCorporatePrice,
              bedroomVat: values.bedroomVat,
              bedroomCapacity: values.bedroomCapacity,
              bedroomDescription: values.bedroomDescription,
            }
          );
          bedroomsList.push(newBedroom);
          props.onSubmit({ bedrooms: bedroomsList }, noRedirect, noPublishListing);
          form.restart(initVal);
          setIsAddingABedroom(false);
        };

        const deleteBedroom = e => {
          e.preventDefault();
          const val = +e.currentTarget.value;
          const currentBedrooms = [...bedroomsItems];
          const updatedBedroomsList = [
            ...currentBedrooms.slice(0, val),
            ...currentBedrooms.slice(val + 1),
          ];

          const noPublishListing = true;
          const noRedirect = true;
          props.onSubmit({ bedrooms: updatedBedroomsList }, noRedirect, noPublishListing);
        };

        console.log('bedroomsItems', bedroomsItems);

        return (
          <>
            <Form className={classes}>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <h2>
                <FormattedMessage id="EditVenueBedroomsPanel.bedroomsCount" />
              </h2>
              <div className={css.bedroomsCount}>
                <div className={css.row}>
                  <FieldTextInput
                    id={`singleBedroomsCount`}
                    name={`singleBedroomsCount`}
                    defaultValue={initVal?.singleBedroomsCount}
                    className={css.title}
                    label={singleBedroomsCountLabel}
                    type="number"
                    placeholder={0}
                    validate={required(bedroomRequired)}
                  />
                  <FieldTextInput
                    id={`twinBedroomsCount`}
                    name={`twinBedroomsCount`}
                    defaultValue={initVal?.twinBedroomsCount}
                    className={css.title}
                    label={twinBedroomsCountLabel}
                    type="number"
                    placeholder={0}
                    validate={required(bedroomRequired)}
                  />
                  <FieldTextInput
                    id={`singleBedsCount`}
                    name={`singleBedsCount`}
                    defaultValue={initVal?.singleBedsCount}
                    className={css.title}
                    label={singleBedsCountLabel}
                    type="number"
                    placeholder={0}
                    validate={required(bedroomRequired)}
                  />
                </div>
                <div className={css.row}>
                  <PrimaryButton type="submit" onClick={saveBedroomsCounts}>
                    <FormattedMessage id="EditVenueBedroomsPanel.saveButton" />
                  </PrimaryButton>
                </div>
              </div>

              <h2>
                <FormattedMessage id="EditVenueBedroomsPanel.bedroomsTypes" />
              </h2>
              <div class={css.bedroomList} style={{ marginBottom: '8px' }}>
                {Array.isArray(bedroomsItems) &&
                  bedroomsItems?.map((item, idx) => {
                    return (
                      <div className={css.bedroomItemWrap} key={`bedroom${idx}`}>
                        <div className={css.bedroomDataItemWrap}>
                          <div className={css.bedroomDataItem}>
                            <span>
                              <b>{bedroomDataNameLabel}</b> {item.bedroomName}
                            </span>
                            <span>
                              <b>{bedroomDataTypeLabel}</b>{' '}
                              {intl.formatMessage({
                                id: `EditVenueBedroomsPanel.${item?.bedroomType}`,
                              })}
                            </span>
                          </div>
                          <div className={css.bedroomDataItem}>
                            <span>
                              <b>{bedroomDataStandardPriceLabel}</b> €
                              {item?.bedroomPrice?.amount / 100}
                            </span>
                            <span>
                              <b>{bedroomDataDowntimePriceLabel}</b> €
                              {item?.bedroomDowntimePrice?.amount / 100}
                            </span>
                            <span>
                              <b>{bedroomDataCorporatePriceLabel}</b> €
                              {item?.bedroomCorporatePrice?.amount / 100}
                            </span>
                          </div>
                          <div className={css.bedroomDataItem}>
                            <span>
                              <b>{bedroomDataVatLabel}</b> {item.bedroomVat}%
                            </span>
                            <span>
                              <b>{bedroomDataCapacityLabel}</b> {item.bedroomCapacity}
                            </span>
                          </div>
                          <button
                            onClick={deleteBedroom}
                            className={css.saveBedroomBtn}
                            value={idx}
                          >
                            <FormattedMessage id="EditVenueBedroomsPanel.deleteButton" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {!isAddingABedroom && (
                <SecondaryButton type="button" onClick={() => setIsAddingABedroom(true)}>
                  <FormattedMessage id="EditVenueBedroomsPanel.addBedrooms" />
                </SecondaryButton>
              )}

              {isAddingABedroom && (
                <div className={css.addBedRoomForm}>
                  <div className={css.row}>
                    <FieldTextInput
                      id={`bedroomName`}
                      name={`bedroomName`}
                      className={css.title}
                      label={bedroomDataNameLabel}
                      type="text"
                      placeholder={bedroomNamePlaceholderMessage}
                      validate={required(bedroomRequired)}
                    />
                    <FieldSelect
                      id={`bedroomType`}
                      name={`bedroomType`}
                      label={bedroomDataTypeLabel}
                      className={css.bedroomSelect}
                      selectClassName={css.selectInput}
                      validate={required(bedroomRequired)}
                    >
                      <option disabled value="" className={css.option}>
                        {bedroomTypePlaceholder}
                      </option>
                      {BEDROOM_TYPES.map(item => (
                        <option key={item.key} value={item.key} className={css.option}>
                          {intl.formatMessage({ id: `EditVenueBedroomsPanel.${item.key}` })}
                        </option>
                      ))}
                    </FieldSelect>
                  </div>
                  <div className={classNames(css.priceContainer, css.row)}>
                    <FieldCurrencyInput
                      id={`bedroomPrice`}
                      name={`bedroomPrice`}
                      className={css.title}
                      autoFocus
                      label={bedroomPriceLabel}
                      placeholder={bedroomPricePlaceholder}
                      currencyConfig={config.currencyConfig}
                      validate={required(bedroomRequired)}
                    />
                    <FieldCurrencyInput
                      id={`bedroomDowntimePrice`}
                      name={`bedroomDowntimePrice`}
                      className={css.title}
                      label={bedroomDowntimePriceLabel}
                      placeholder={bedroomDowntimePricePlaceholder}
                      currencyConfig={config.currencyConfig}
                      validate={required(bedroomRequired)}
                    />
                    <div className={css.currencyContainer} title=".....">
                      <span className={css.covidInfoItem}>i</span>
                      <FieldCurrencyInput
                        id={`bedroomCorporatePrice`}
                        name={`bedroomCorporatePrice`}
                        className={css.title}
                        label={bedroomCorporatePriceLabel}
                        placeholder={bedroomCorporatePricePlaceholder}
                        currencyConfig={config.currencyConfig}
                        validate={required(bedroomRequired)}
                      />
                    </div>
                  </div>

                  <div className={css.row}>
                    <FieldTextInput
                      id={`bedroomDescription`}
                      name={`bedroomDescription`}
                      type="textarea"
                      label={descriptionMessage}
                      placeholder={descriptionPlaceholderMessage}
                    />
                  </div>
                  <div className={classNames(css.additionalBedroomInfo, css.row)}>
                    <FieldTextInput
                      id={`bedroomVat`}
                      name={`bedroomVat`}
                      label={bedroomVatLabel}
                      placeholder={bedroomVatPlaceholder}
                      type="number"
                      min={0}
                      max={100}
                      validate={required(bedroomRequired)}
                    />
                    <FieldTextInput
                      id={`bedroomCapacity`}
                      name={`bedroomCapacity`}
                      type="number"
                      min={0}
                      label={bedroomCapacityLabel}
                      placeholder={bedroomCapacityPlaceholder}
                      validate={required(bedroomRequired)}
                    />
                  </div>
                  <footer className={css.addBedRoomFormFooter}>
                    <SecondaryButton type="button" onClick={() => setIsAddingABedroom(false)}>
                      <FormattedMessage id="cancel" />
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      onClick={saveBedroom}
                    >
                      <FormattedMessage id="EditVenueBedroomsPanel.saveButton" />
                    </PrimaryButton>
                  </footer>
                </div>
              )}
            </Form>
            <Button className={css.submitButton} onClick={() => props.redirect({})}>
              {saveActionMsg}
            </Button>
          </>
        );
      }}
    />
  );
};

EditVenueBedroomsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditVenueBedroomsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditVenueBedroomsFormComponent);
