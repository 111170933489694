import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import moment from 'moment';
import css from './BookingBreakdown.module.css';
import { startCase } from 'lodash';

const { Money } = sdkTypes;

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl, isPerSession, isPerPerson, standartPrice } = props;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const duration =
    transaction && transaction.attributes.metadata && transaction.attributes.metadata.duration;
  const perSession = isPerSession || (duration && duration !== 'perHour');
  const translationKey = perSession
    ? isPerPerson
      ? 'LineItemUnitPriceMaybe.pricePerSessionPerPerson'
      : 'LineItemUnitPriceMaybe.priceAllFixed'
    : isPerPerson
    ? 'LineItemUnitPriceMaybe.pricePerHourPerPerson'
    : 'LineItemUnitPriceMaybe.pricePerHourPerPackage';

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const units = unitPurchase && unitPurchase.units && parseInt(unitPurchase.units);
  const seats = unitPurchase && unitPurchase.seats && parseInt(unitPurchase.seats);
  const quantityResult = seats && units ? seats * units : null;
  const { start, end } = transaction && transaction.booking && transaction.booking.attributes;
  const hours = moment(end).diff(moment(start), 'h');
  const quantity = units ? units : unitPurchase.quantity.toString();
  const lineTotal = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const formattedStandartPrice =
    standartPrice && formatMoney(intl, new Money(standartPrice.amount, standartPrice.currency));
  const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;

  return formattedUnitPrice && isPerSession ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id={translationKey}
          values={{ lineTotal, seats, units: quantity, hours }}
        />
      </span>
      {formattedStandartPrice && (
        <span className={css.standartPrice}>{formattedStandartPrice}</span>
      )}
      <span className={css.itemValue}>{lineTotal}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
