import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form, AmenityItem, FieldCheckbox, FieldCheckboxGroup } from '../../components';

import css from './EditMenuServicesForm.module.css';

const RenderGroups = (props) => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const EditMenuServicesFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
      } = formRenderProps;

      const SPECIFIC_DIETS = ['vegan', 'organicFood', 'halal', 'glutenFree', 'kosher'];
      const SERVICE_TYPES = ['seating', 'cocktail', 'breakfast'];

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.showListingFailed" />
        </p>
      ) : null;

      const internetCheckboxesLabel = (
        <FormattedMessage id="EditMenuServicesForm.internetServicesLabel" />
      );
      const virtualServicesCheckboxesLabel = (
        <FormattedMessage id="EditMenuServicesForm.virtualServicesServicesLabel" />
      );

      // const options = findOptionsForSelectFilter(name, filterConfig);
      // const filteredOptionsList = options
      //   .filter((option) => {
      //     return typeof MENU_MAIN_OPTIONS_LIST[option.key] !== 'undefined';
      //   })
      //   .map((item) => {
      //     return {
      //       key: item.key,
      //       label: item.label,
      //       img: MENU_MAIN_OPTIONS_LIST[item.key],
      //     };
      //   });

      const formValues = form.getState().values;

      const onChange = ({ values }) => {
        Object.keys(values).forEach((key) => {
          if (
            key.indexOf('free') !== 0 &&
            values[key] &&
            !values[key].length &&
            values[`free${key}`] &&
            values[`free${key}`].length
          ) {
            form.change(`free${key}`, null);
          }
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} subscription={{ values: true }} />
          {errorMessage}
          {errorMessageShowListing}

          {SPECIFIC_DIETS.map((key) => (
            <FieldCheckbox
              id={key}
              name={key}
              value={key}
              label={intl.formatMessage({ id: `ConfigFilter.${key}` })}
              className={classNames(css.servicesCheckbox, css.checkboxPadding)}
            />
          ))}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditMenuServicesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditMenuServicesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditMenuServicesFormComponent);
