export const selectedExtraValues = (values, extraType) => {
  return Object.entries(values).filter(
    service => service[0].includes(extraType) && !!service[1].quantity
  );
};

export const selectedBedroomsHelper = (values, selectedValues) => {
  return (
    values &&
    values
      .filter(i => selectedValues.find(b => b[0].includes(i.id)))
      .map(i => {
        return {
          id: i.id,
          bedroomVat: i.bedroomVat,
          bedroomType: i.bedroomType,
          bedroomName: i.bedroomName,
          bedroomPriceAmount: i.bedroomPrice.amount,
          bedroomPriceidCurrency: i.bedroomPrice.currency,
          bedroomCapacity: i.bedroomCapacity,
        };
      })
  );
};

export const selectedExtraServicesHelper = (values, selectedValues) => {
  return (
    values &&
    values
      .filter(i => selectedValues.find(b => b[0].includes(i.id)))
      .map(i => {
        return {
          id: i.id,
          extrasVat: i.extrasVat,
          extrasType: i.extrasType,
          extrasName: i.extrasName,
          extrasPriceAmount: i.extrasPrice.amount,
          extrasPriceidCurrency: i.extrasPrice.currency,
        };
      })
  );
};

export const selectedExtrasObj = values => {
  return Object.entries(values).reduce((obj, [key, value]) => {
    if (!value.quantity) {
      return obj;
    }

    const idArr = key.split('/');
    idArr.splice(0, 1);
    const id = idArr.join('/');

    return {
      ...obj,
      [id]: value,
    };
  }, {});
};
