import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form, AmenityItem, FieldCheckbox, FieldCheckboxGroup } from '../../components';

import css from './EditSpaceAmenitiesForm.module.css';

import naturalLight from '../../assets/icon/Spaces_amenities/sun.svg';
import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import soundSystem from '../../assets/icon/Spaces_amenities/sound_system.svg';
import wifi from '../../assets/icon/Spaces_amenities/wi-fi.svg';
import paperBoard from '../../assets/icon/Spaces_amenities/paper_board.svg';
import television from '../../assets/icon/Spaces_amenities/television.svg';
import paper from '../../assets/icon/Spaces_amenities/paper.svg';
import hydroalcoholicGel from '../../assets/icon/Spaces_amenities/alcohol-gel.svg';
import projector from '../../assets/icon/Spaces_amenities/projector.svg';
import pens from '../../assets/icon/Spaces_amenities/pen.svg';
import screen from '../../assets/icon/Spaces_amenities/screen.svg';
import water from '../../assets/icon/Spaces_amenities/water.svg';

const RenderGroups = (props) => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const EditSpaceAmenitiesFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        intl,
        pristine,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
      } = formRenderProps;

      const SPACE_MAIN_OPTIONS_LIST = {
        naturalLight,
        soundSystem,
        wifi,
        paperBoard,
        television,
        paper,
        conditionedAir,
        hydroalcoholicGel,
        projector,
        pens,
        screen,
        water,
      };

      const SPACE_INTERNET_SPEED_OPTIONS = ['slowInternet', 'normalInternet', 'highSpeedInternet'];
      const SPACE_VIRTUAL_SERVICES_OPTIONS = [
        'tvStudio',
        'greenScreen',
        'videoConferenceDevice',
        'avTechnicianOnSite',
      ];

      const tvStudio = intl.formatMessage({
        id: 'EditSpaceAmenitiesForm.tvStudio',
      });
      const videoConferenceDevice = intl.formatMessage({
        id: 'EditSpaceAmenitiesForm.videoConferenceDevice',
      });

      const ITEMS_WITH_TOOLTIPS = {
        tvStudio: tvStudio,
        videoConferenceDevice: videoConferenceDevice,
      };

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress || invalid;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPackagesForm.showListingFailed" />
        </p>
      ) : null;

      const internetCheckboxesLabel = (
        <FormattedMessage id="EditSpaceAmenitiesForm.internetAmenitiesLabel" />
      );
      const virtualServicesCheckboxesLabel = (
        <FormattedMessage id="EditSpaceAmenitiesForm.virtualServicesAmenitiesLabel" />
      );

      const options = findOptionsForSelectFilter(name, filterConfig);
      const filteredOptionsList = options
        .filter((option) => {
          return typeof SPACE_MAIN_OPTIONS_LIST[option.key] !== 'undefined';
        })
        .map((item) => {
          return {
            key: item.key,
            label: item.label,
            img: SPACE_MAIN_OPTIONS_LIST[item.key],
          };
        });

      const internetSpeedOptions = options.filter((option) => {
        return SPACE_INTERNET_SPEED_OPTIONS.some((itm) => option.key === itm);
      });
      const virtualServicesOptions = options.filter((option) => {
        return SPACE_VIRTUAL_SERVICES_OPTIONS.some((itm) => option.key === itm);
      });
      const formValues = form.getState().values;

      const onChange = ({ values }) => {
        Object.keys(values).forEach((key) => {
          if (
            key.indexOf('free') !== 0 &&
            values[key] &&
            !values[key].length &&
            values[`free${key}`] &&
            values[`free${key}`].length
          ) {
            form.change(`free${key}`, null);
          }
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormSpy onChange={onChange} subscription={{ values: true }} />
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.optionsPart}>
            <AmenityItem
              className={css.wrapperAmenityItem}
              childClassName={css.spaceAmenityItem}
              items={filteredOptionsList}
              formValues={formValues}
              intl={intl}
            />
            <div className={css.additionalOptionsPart}>
              <div className={css.internetsoeedContainer}>
                <RenderGroups
                  label={internetCheckboxesLabel}
                  options={internetSpeedOptions}
                  name="internetSpeed"
                  intl={intl}
                />
              </div>
              {/* <div className={css.vsWrapper}>
                <p className={css.vsLabel}>Virtual Services</p>
                <div className={css.additionalOptionsPartItem}>
                  {virtualServicesOptions.map(({ key, label }) => {
                    return (
                      <>
                        {ITEMS_WITH_TOOLTIPS[key] ? (
                          <div
                            className={classNames(css.checkboxWithTooltip, css.checkboxPadding)}
                            title={ITEMS_WITH_TOOLTIPS[key]}
                          >
                            <span className={css.covidInfoItem}>i</span>
                            <FieldCheckbox
                              id={key}
                              name={key}
                              value={key}
                              label={intl.formatMessage({ id: `ConfigFilter.${key}` }) || label}
                            />
                          </div>
                        ) : (
                          <FieldCheckbox
                            id={key}
                            name={key}
                            value={key}
                            label={intl.formatMessage({ id: `ConfigFilter.${key}` }) || label}
                            className={classNames(css.amenitiesCheckbox, css.checkboxPadding)}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div> */}
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditSpaceAmenitiesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditSpaceAmenitiesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditSpaceAmenitiesFormComponent);
