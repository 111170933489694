import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_EXPIRE,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getAllTransactionsIntegration, updateTransactionMetaData } from '../../util/api';
import { fetchCustomerNotifications } from '../../ducks/user.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';
export const SHOW_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/SHOW_ORDERS_OR_SALES_SUCCESS';

export const TOGGLE_REVERS = 'app/InboxPage/TOGGLE_REVERS';
export const TOGGLE_ROLE_SUPPLIER = 'app/InboxPage/TOGGLE_ROLE_SUPPLIER';

export const GET_ALL_TRANSACTIONS_REQUEST = 'app/InboxPage/GET_ALL_TRANSACTIONS_REQUEST';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'app/InboxPage/GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_ERROR = 'app/InboxPage/GET_ALL_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_INTEGRATION_REQUEST =
  'app/InboxPage/GET_TRANSACTION_INTEGRATION_REQUEST';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  getTransactionsProgress: false,
  allTransactionRefs: [],
  getTransactionsError: null,
  isRevers: false,
  roleSupplier: null,
  getTransactionsIntegrationProgress: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);

      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
        getTransactionsIntegrationProgress: false,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: payload,
        getTransactionsIntegrationProgress: false,
      };
    case SHOW_ORDERS_OR_SALES_SUCCESS: {
      const transactions = state.isRevers ? payload.data : sortedTransactions(payload.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        getTransactionsIntegrationProgress: false,
      };
    }

    case GET_ALL_TRANSACTIONS_REQUEST:
      return { ...state, getTransactionsProgress: true, getTransactionsError: null };
    case GET_ALL_TRANSACTIONS_SUCCESS: {
      const transactions = sortedTransactions(payload);

      return {
        ...state,
        getTransactionsProgress: false,
        allTransactionRefs: entityRefs(transactions),
      };
    }
    case GET_ALL_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, getTransactionsProgress: false, getTransactionsError: payload };

    case TOGGLE_REVERS:
      return { ...state, isRevers: payload };

    case TOGGLE_ROLE_SUPPLIER:
      return { ...state, roleSupplier: payload };

    case GET_TRANSACTION_INTEGRATION_REQUEST:
      return { ...state, getTransactionsIntegrationProgress: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});
const showOrdersOrSalesSuccess = response => ({
  type: SHOW_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});

const getAllTransacionsRequest = () => ({
  type: GET_ALL_TRANSACTIONS_REQUEST,
});

const getAllTransacionsSuccess = response => ({
  type: GET_ALL_TRANSACTIONS_SUCCESS,
  payload: response,
});
const getAllTransacionsError = e => ({
  type: GET_ALL_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const toggleReverse = value => ({
  type: TOGGLE_REVERS,
  payload: value,
});
export const toggleRoleSupplier = value => ({
  type: TOGGLE_ROLE_SUPPLIER,
  payload: value,
});

const getTransactionIntegrationRequest = () => ({ type: GET_TRANSACTION_INTEGRATION_REQUEST });

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 100;

function setParams({
  transactionId,
  onlyFilter,
  lastTransition,
  page,
  perPage,
  customerId,
  providerId,
  listingId,
  parentId,
}) {
  const params = {
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
      'listing.images',
      'messages',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'metadata',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
  };

  if (transactionId) {
    params.id = transactionId;
    return params;
  }

  if (customerId || providerId) {
    customerId && (params.customerId = customerId);
    providerId && (params.providerId = providerId);
    params.listingId = listingId;
    params.page = page;
    params.perPage = perPage;
    params.lastTransitions = lastTransition;
    params.parentId = parentId;

    return params;
  }

  params.only = onlyFilter;
  params.lastTransitions = lastTransition;
  params.page = page;
  params.perPage = perPage;

  return params;
}

const getTransactions = params => (dispatch, getState, sdk) => {
  return sdk.transactions
    .query(setParams(params))
    .then(response => {
      return response;
    })
    .catch(e => {
      console.log('Error when get transactions', e);
      throw e;
    });
};

export const getAllTransactions = params => async (dispatch, getState, sdk) => {
  dispatch(getAllTransacionsRequest(params));

  const { onlyFilter, lastTransition, page, perPage } = params;
  const allTransactions = [];

  try {
    const response = await dispatch(getTransactions(params));
    dispatch(addMarketplaceEntities(response));

    const data = response.data.data;
    const totalPages = response.data.meta.totalPages;

    data && data.length > 0 && allTransactions.push(...allTransactions, ...data);
    for (let i = 2; i <= totalPages; i++) {
      const res = await dispatch(getTransactions({ onlyFilter, lastTransition, page: i, perPage }));
      dispatch(addMarketplaceEntities(res));
      const data = res.data.data;
      data && data.length > 0 && allTransactions.push(...allTransactions, ...data);
    }

    dispatch(getAllTransacionsSuccess(allTransactions));
  } catch (e) {
    dispatch(getAllTransacionsError(storableError(e)));
    throw e;
  }
};

export const getTransactionsIntegration = params => (dispatch, getstate, sdk) => {
  const { tab, customerId, providerId, listingId, parentId } = params;

  dispatch(getTransactionIntegrationRequest());

  return getAllTransactionsIntegration(
    setParams({
      customerId: customerId,
      providerId: providerId,
      listingId: listingId,
      parentId: parentId,
      page: 1,
      perPage: 100,
      lastTransition: TRANSITIONS.filter(i => i === TRANSITION_ACCEPT),
    })
  )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab } = params;

  let filterTab = tab;
  let txId = null;

  if (tab.includes('id')) {
    filterTab = tab.split('_')[0];
    txId = tab.split('_')[2];
  }

  const onlyFilterValues = {
    featureOrders: 'order',
    orders: 'order',
    ordersInProgress: 'order',
    ordersDeclined: 'order',
    ordersPast: 'order',
    sales: 'sale',
    featureSales: 'sale',
    salesInProgress: 'sale',
    salesDeclined: 'sale',
    salesPast: 'sale',
  };

  let lastTransition = TRANSITIONS;

  switch (filterTab) {
    case 'featureOrders':
    case 'featureSales':
      lastTransition = TRANSITIONS.filter(i => i === TRANSITION_ACCEPT);
      break;
    case 'orders':
    case 'sales':
      lastTransition = lastTransition = TRANSITIONS.filter(
        i =>
          i === TRANSITION_ENQUIRE ||
          i === TRANSITION_REQUEST_PAYMENT ||
          i === TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY ||
          i === TRANSITION_CONFIRM_PAYMENT ||
          i === TRANSITION_EXPIRE_PAYMENT ||
          i === TRANSITION_DECLINE ||
          i === TRANSITION_EXPIRE ||
          i === TRANSITION_CANCEL
      );
      break;
    case 'ordersInProgress':
    case 'salesInProgress':
      lastTransition = TRANSITIONS.filter(
        i =>
          i === TRANSITION_ENQUIRE ||
          i === TRANSITION_REQUEST_PAYMENT ||
          i === TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY ||
          i === TRANSITION_CONFIRM_PAYMENT
      );
      break;
    case 'ordersDeclined':
    case 'salesDeclined':
      lastTransition = TRANSITIONS.filter(
        i =>
          i === TRANSITION_EXPIRE_PAYMENT ||
          i === TRANSITION_DECLINE ||
          i === TRANSITION_EXPIRE ||
          i === TRANSITION_CANCEL
      );
      break;
    case 'ordersPast':
    case 'salesPast':
      lastTransition = TRANSITIONS.filter(
        i =>
          i === TRANSITION_COMPLETE ||
          i === TRANSITION_EXPIRE_REVIEW_PERIOD ||
          i === TRANSITION_REVIEW_1_BY_CUSTOMER ||
          i === TRANSITION_REVIEW_1_BY_PROVIDER ||
          i === TRANSITION_REVIEW_2_BY_PROVIDER ||
          i === TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD ||
          i === TRANSITION_REVIEW_2_BY_CUSTOMER ||
          i === TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD
      );
      break;

    default:
      break;
  }
  const AllGetTransactions = page => (dispatch, getState, sdk) => {
    return sdk.transactions
      .query(setParams({ onlyFilter, lastTransition, page: page, perPage: INBOX_PAGE_SIZE }))
      .then(response => {
        return response;
      })
      .catch(e => {
        console.log('Error when get transactions', e);
        throw e;
      });
  };

  const onlyFilter = onlyFilterValues[filterTab];
  if (!onlyFilter) return Promise.reject(new Error(`Invalid tab for InboxPage: ${filterTab}`));

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  if (txId) {
    return sdk.transactions
      .show(setParams({ transactionId: txId }))
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(showOrdersOrSalesSuccess(response));
        if (filterTab === 'featureOrders' || filterTab === 'featureSales') {
          dispatch(
            getAllTransactions({
              onlyFilter,
              lastTransition,
              page: 1,
              perPage: 100,
            })
          );
        }
        return response;
      })
      .catch(e => {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      });
  }
  try {
    let response = await dispatch(AllGetTransactions(1));
    if (response.data.meta.totalPages > 1) {
      for (let i = 2; i < response.data.meta.totalPages + 1; i++) {
        let newArr = await dispatch(AllGetTransactions(i));
        response.data.data.push(...newArr.data.data);
        response.data.included.push(...newArr.data.included);
      }
    }
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchOrdersOrSalesSuccess(response));

    if (filterTab === 'featureOrders' || filterTab === 'featureSales') {
      dispatch(
        getAllTransactions({
          onlyFilter,
          lastTransition,
          page: 1,
          perPage: 100,
        })
      );
      dispatch(fetchCustomerNotifications());
    }
  } catch (e) {
    dispatch(fetchOrdersOrSalesError(storableError(e)));
  }
};

export const updateTransactionData = params => (dispatch, getState, sdk) => {
  return updateTransactionMetaData(params);
};
