import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '..';
import { EditSpaceDescriptionForm } from '../../forms';
import { updateAvailabilityPlan } from '../../helpers/availabilityPlanHelper';

import css from './EditSpaceDescriptionPanel.module.css';

const EditSpaceDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    isNewListingFlow,
  } = props;

  const isNew = params.type === 'new';

  const getInitialValues = () => {
    const currentListing = ensureOwnListing(listing);
    const { publicData, title } = currentListing.attributes;
    const { structure, styles, spaceType = 'meetings', safeSpacing, sizes } = publicData || {};

    const style = structure && styles ? { structure, styles } : null;

    return {
      title,
      spaceType,
      sizes,
      safeSpacing,
      style,
    };
  };

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isAvailabilityPlan = currentListing && currentListing.attributes.availabilityPlan;
  const priceScheme = currentListing && currentListing.attributes.publicData.priceScheme;
  const spaceTypeData = currentListing && currentListing.attributes.publicData.spaceType;
  const isCoworking = spaceTypeData === 'coworking';

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditSpaceDescriptionPanel.title" />
  ) : (
    <FormattedMessage id="EditSpaceDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditSpaceDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        isNew={isNew}
        isCoworking={isCoworking}
        onSubmit={values => {
          const { category } = params;
          const { title, spaceType, sizes, safeSpacing, style } = values;
          const seatsNumber = style && style.coworkingValue;

          const updateValues = {
            title: title.trim(),
            publicData: {
              ...(isNew ? { spaceType, category } : {}),
              sizes,
              showForSearch: false,
              safeSpacing,
              ...style,
            },
          };

          if (isAvailabilityPlan && seatsNumber && priceScheme && isCoworking) {
            updateValues.availabilityPlan = updateAvailabilityPlan(
              { priceScheme },
              currentListing,
              seatsNumber
            );
          }

          setInitialValues({
            title,
            spaceType,
            sizes,
            safeSpacing,
            style,
          });

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditSpaceDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditSpaceDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditSpaceDescriptionPanel;
