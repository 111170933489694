import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MessageIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMessageIcon, className);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.717" height="25.5" viewBox="0 0 27.717 25.5">
      <path
        d="M23.906-1.332H3.811A3.815 3.815 0 000 2.479v12.3a3.815 3.815 0 003.8 3.811v5.581l8.02-5.581h12.086a3.815 3.815 0 003.811-3.811v-12.3a3.815 3.815 0 00-3.811-3.811zm2.187 16.109a2.189 2.189 0 01-2.187 2.187h-12.6l-5.887 4.1v-4.1H3.811a2.189 2.189 0 01-2.187-2.187V2.479A2.189 2.189 0 013.811.292h20.1a2.189 2.189 0 012.187 2.187zm0 0"
        className="a"
        transform="translate(0 1.332)"
      ></path>
      <path
        d="M7.418 4.407h12.88v1.628H7.418zm0 0M7.418 7.871h12.88v1.628H7.418zm0 0M7.418 11.336h12.88v1.628H7.418zm0 0"
        className="a"
        transform="translate(0 1.332)"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

MessageIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MessageIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MessageIcon;
