import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  validAddressRequired,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckboxGroup,
  MeetSafeLabel,
  FieldRadioButton,
  LocationAutocompleteInputField,
  FieldSquareRadioButton,
} from '../../components';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { virtualTargetsTypes, virtualLanguageType } from '../../marketplace-custom-config';

import css from './EditVirtualDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const identity = v => v;

const RenderGroups = props => {
  return <FieldCheckboxGroup {...props} id={props.name} />;
};

const EditVirtualDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          form,
        } = formRenderProps;
        const titleMessage = intl.formatMessage({ id: 'EditVirtualDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditSocialDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderEn',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.descriptionRequired',
        });
        const descriptionPlaceholderMessageFr = intl.formatMessage({
          id: 'EditVenueDescriptionForm.descriptionPlaceholderFr',
        });
        const brandMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brand',
        });
        const brandPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.brandPlaceholder',
        });
        const locationLabel = intl.formatMessage({ id: 'EditSocialDescriptionForm.location' });
        const locationPlaceholderMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationPlaceholder',
        });
        const locationRequiredMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationRequired',
        });
        const locationNotRecognizedMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.locationNotRecognized',
        });
        const addressValidMessage = intl.formatMessage({
          id: 'EditSocialDescriptionForm.addressInvalid',
        });
        const durationMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.duration',
        });
        const durationFixedMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.fixed',
        });
        const durationPerHourMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.perHour',
        });
        const durationHoursMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.hours',
        });
        const durationHoursPlaceholderMessage = '0';

        const maxAttendeesMessage = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.maxAttendees',
        });
        const virtualLabel = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.virtualLabel',
        });
        const hybridLabel = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.hybridLabel',
        });

        const maxAttendeesPlaceholderMessage = 100;

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditVenueDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const activityTypeCheckboxesLabel = intl.formatMessage({
          id: 'EditVirtualDescriptionForm.activityTypeLabel',
        });
        const languageCheckboxesLabel = intl.formatMessage({
          id: 'EditSocialDescriptionForm.languageLabel',
        });
        const typeOfEvent = intl.formatMessage({
          id: 'EditSocialDescriptionForm.typeOfEvent',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const handleChange = formState => {
          if (
            formState.values.durationType !== values.durationType &&
            values.durationType == 'fixed' &&
            values.durationValue
          ) {
            form.change('durationValue', null);
          }
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy onChange={handleChange} subscription={{ values: true }} />
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />
            <p className={css.titleInputIcon}>{descriptionMessage}</p>
            {config.locale === 'en' ? (
              <>
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            ) : (
              <>
                <FieldTextInput
                  icon={'iconFr'}
                  id="descriptionFr"
                  name="descriptionFr"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessageFr}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
                <FieldTextInput
                  icon={'iconEn'}
                  id="description"
                  name="description"
                  className={css.description}
                  type="textarea"
                  placeholder={descriptionPlaceholderMessage}
                  validate={composeValidators(required(descriptionRequiredMessage))}
                />
              </>
            )}
            <FieldTextInput
              id="brand"
              name="brand"
              className={css.description}
              type="textarea"
              label={brandMessage}
              placeholder={brandPlaceholderMessage}
            />

            <LocationAutocompleteInputField
              className={css.location}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label={locationLabel}
              placeholder={locationPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              validate={composeValidators(
                autocompleteSearchRequired(locationRequiredMessage),
                autocompletePlaceSelected(locationNotRecognizedMessage),
                validAddressRequired(addressValidMessage)
              )}
            />

            <p className={css.durationTitle}>{durationMessage}</p>
            <div className={css.durationBlock}>
              <div className={css.durationFixed}>
                <FieldSquareRadioButton
                  id="Fixed"
                  name="durationType"
                  className={css.durationFixedRadioButton}
                  label={durationFixedMessage}
                  value="fixed"
                />
                <FieldTextInput
                  id="durationValue"
                  name="durationValue"
                  className={css.durationValue}
                  type="number"
                  defaultValue={1}
                  min="1"
                  label={durationHoursMessage}
                  placeholder={durationHoursPlaceholderMessage}
                />
              </div>
              <FieldSquareRadioButton
                id="perHour"
                name="durationType"
                label={durationPerHourMessage}
                value="perHour"
                className={css.durationPerHourMessage}
              />
            </div>
            <FieldTextInput
              id="maxAttendees"
              name="maxAttendees"
              className={css.maxAttendees}
              type="number"
              defaultValue={1}
              min="1"
              label={maxAttendeesMessage}
              placeholder={maxAttendeesPlaceholderMessage}
            />
            <p>{typeOfEvent}</p>
            <div className={css.radioButtonRow}>
              <FieldSquareRadioButton
                id="virtual"
                name="eventType"
                label={virtualLabel}
                value="virtual"
              />
              <FieldSquareRadioButton
                id="hybrid"
                name="eventType"
                label={hybridLabel}
                value="hybrid"
                className={css.radioItem}
              />
            </div>

            <RenderGroups
              label={activityTypeCheckboxesLabel}
              options={virtualTargetsTypes}
              name="targets"
              className={css.groupsTwoRows}
              intl={intl}
            />

            <RenderGroups
              label={languageCheckboxesLabel}
              options={virtualLanguageType}
              name="virtualLanguage"
              className={css.groupsThreeRows}
              intl={intl}
            />

            <MeetSafeLabel />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditVirtualDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditVirtualDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditVirtualDescriptionFormComponent);
