import React from 'react';
import config from '../../config';

import { getLabelsFromFilters } from '../../util/data';
import { languagesHelper as language } from '../../helpers/languages';

import airport_shuttle from '../../assets/icon/airport_shuttle.svg';
import bar from '../../assets/icon/bar.svg';
import concierge from '../../assets/icon/concierge.svg';
import disabled from '../../assets/icon/disabled.svg';
import golf from '../../assets/icon/golf.svg';
import health_club from '../../assets/icon/health_club.svg';
import hybrid_event from '../../assets/icon/hybrid.svg';
import parking from '../../assets/icon/parking.svg';
import pool from '../../assets/icon/pool.svg';
import restaurant from '../../assets/icon/restaurant.svg';
import spa from '../../assets/icon/spa.svg';
import visioconference from '../../assets/icon/videoconference.svg';
import tvStudio from '../../assets/icon/Spaces_amenities/television.svg';
import conditionedAir from '../../assets/icon/Spaces_amenities/air-conditioner.svg';
import lightingSystem from '../../assets/icon/extra_services/virtualServices.svg';
import liverstreamControl from '../../assets/icon/coworking.svg';

import css from './ListingPage.module.css';

const facilitiesImages = {
  hybrid_event,
  visioconference,
  restaurant,
  parking,
  golf,
  airport_shuttle,
  bar,
  health_club,
  disabled,
  concierge,
  pool,
  spa,
  tvStudio,
  conditionedAir,
  lightingSystem,
  liverstreamControl,
};

const VenueEventFacilities = props => {
  const { publicData, venueFacilities, intl } = props;

  const venueFacilitiesLabel = intl.formatMessage({ id: 'ListingPage.venueFacilitiesLabel' });

  const listingFacilities = publicData.facilities;
  const facilitiesList = [];

  listingFacilities &&
    listingFacilities.map((item, idx) => {
      const facilityLabel = language.labelsTranslator(item, intl); //getLabelsFromFilters(venueFacilities, [item]);
      const facilityIcon = facilitiesImages[item];

      facilitiesList.push(
        <div key={`facility${idx}`} className={css.facilityItem}>
          <img src={facilityIcon} alt="facilityIcon" className={css.facilityImage} />
          <p className={css.facilitiesText}>{facilityLabel}</p>
        </div>
      );
    });

  return (
    <div className={css.facilitiesWrapper}>
      <h2 className={css.subTitle}>{venueFacilitiesLabel}</h2>
      <div className={css.groupsFourRows}>{facilitiesList}</div>
    </div>
  );
};

VenueEventFacilities.defaultProps = {
  venueFacilities: config.custom.venueFacilities,
};

export default VenueEventFacilities;
