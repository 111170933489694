import pick from 'lodash/pick';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getListings, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, nextMonthFn, monthIdStringInTimeZone } from '../../util/dates';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const CHILD_LISTINGS_REQUEST = 'app/ListingPage/CHILD_LISTINGS_REQUEST';
export const CHILD_LISTINGS_SUCCESS = 'app/ListingPage/CHILD_LISTINGS_SUCCESS';
export const CHILD_LISTINGS_ERROR = 'app/ListingPage/CHILD_LISTINGS_ERROR';

const resultIds = (data) => data.data.map((l) => l.id);
const sortedTransactions = (txs) =>
  reverse(
    sortBy(txs, (tx) => {
      return tx.attributes ? tx.attributes.createdAt : null;
    })
  );
// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  childListingsIds: [],
  childListingsLoading: false,
  childListingsError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      ////////////////
      // ToDo sort
      //////////

      return { ...state, reviews: sortedTransactions(payload) };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        lineItems: payload.lineItems,
        bookingData: payload.bookingData,
      };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };
    case CHILD_LISTINGS_REQUEST:
      return { ...state, childListingsLoading: true };
    case CHILD_LISTINGS_SUCCESS:
      return { ...state, childListingsIds: resultIds(payload.data), childListingsLoading: false };
    case CHILD_LISTINGS_ERROR:
      return { ...state, childListingsLoading: false, childListingsError: payload.error };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = (id) => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = (e) => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = (reviews) => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = (error) => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = (monthId) => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = (lineItems, bookingData) => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: { lineItems, bookingData },
});
export const fetchLineItemsError = (error) => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = (e) => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const childListingsRequest = (searchParams) => ({
  type: CHILD_LISTINGS_REQUEST,
});

export const childListingsSuccess = (response) => ({
  type: CHILD_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const childListingsError = (e) => ({
  type: CHILD_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing =
  (listingId, isOwn = false) =>
  (dispatch, getState, sdk) => {
    dispatch(showListingRequest(listingId));
    dispatch(fetchCurrentUser());
    const params = {
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

    return show
      .then((data) => {
        dispatch(addMarketplaceEntities(data));
        return data;
      })
      .catch((e) => {
        dispatch(showListingError(storableError(e)));
      });
  };

const getSpaceReviews = (listingId) => (dispatch, getState, sdk) => {
  return sdk.reviews.query({
    listing_id: listingId,
    state: 'public',
    include: ['author', 'author.profileImage'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
};

export const fetchReviews = (listingId) => (dispatch) => {
  dispatch(fetchReviewsRequest());

  return dispatch(getSpaceReviews(listingId))
    .then((response) => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
      return response;
    })
    .catch((e) => dispatch(fetchReviewsError(storableError(e))));
};

const timeSlotsRequest = (params) => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then((response) => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then((timeSlots) => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch((e) => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.decathlonProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then((response) => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch((e) => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
export const fetchMonthlyTimeSlots = (listing) => (dispatch) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing?.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems =
  ({ bookingData, listingId, isOwnListing }) =>
  (dispatch) => {
    dispatch(fetchLineItemsRequest());
    transactionLineItems({ bookingData, listingId, isOwnListing })
      .then((response) => {
        const lineItems = response.data;
        dispatch(fetchLineItemsSuccess(lineItems, bookingData));
      })
      .catch((e) => {
        dispatch(fetchLineItemsError(storableError(e)));
        log.error(e, 'fetching-line-items-failed', {
          listingId: listingId.uuid,
          bookingData: bookingData,
        });
      });
  };

export const queryChildListings =
  (listingId, childListingCategory = 'space') =>
  (dispatch, getState, sdk) => {
    dispatch(childListingsRequest());
    const params = {
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
      ],
      pub_category: childListingCategory,
      pub_parent: listingId,
    };
    const requestParams = { ...params, page: 1, per_page: 100 };
    return getListings(requestParams)
      .then((response) => {
        dispatch(allReviews(response.data.data));
        dispatch(addMarketplaceEntities(response));
        dispatch(childListingsSuccess(response));
        return response;
      })
      .catch((e) => {
        dispatch(childListingsError(storableError(e)));
        throw e;
      });
  };

const allReviews = (listingId) => async (dispatch) => {
  // allChildListings reviews
  try {
    let normalReviews = [];
    for (let i = 0; i < listingId.length; i++) {
      let queryRew = await dispatch(getSpaceReviews(listingId[i].id));
      if (queryRew.data.included) normalReviews.push(...denormalisedResponseEntities(queryRew));
    }
    dispatch(fetchReviewsSuccess(normalReviews));
  } catch (e) {
    dispatch(childListingsError(storableError(e)));
  }
};

export const loadData = (params, search) => (dispatch) => {
  const listingId = new UUID(params.id);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    (responses) => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const listing = responses[0].data.data;
        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        dispatch(fetchMonthlyTimeSlots(listing));
        const category = listing.attributes.publicData.category;
        if (category === 'venue' || category === 'catering') {
          const childListingCategory = category === 'catering' ? 'menus' : 'space';
          return dispatch(queryChildListings(params.id, childListingCategory)).then(() => {
            return responses;
          });
        }
      }
      return responses;
    }
  );
};
